import { Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import Filter from "../../assets/images/Filter.png";
import Search from "../../assets/images/Search.png";
import Sort from "../../assets/images/Sort.png";
import PropTypes from "prop-types";
import VedioUpload from "../VedioUpload/VedioUpload";
import { CustomButtonOutlined } from "../Controls/CustomButton";
import { openVedioDialog } from "../../utility/dialogAppear";
import CustomTextField from "../Controls/CustomTextField";

export default function SearchBar(props) {
  const [openVedio, setopenVedio] = useState(true);
  const [inputVal, setInputVal] = useState({})
  const { title, showVideoButton } = props;
  const getInputVal = (e) => {
    console.log(e.target)
    let { name, value } = e.target;
    setInputVal((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  return (
    <div className="dis-flex country-search-bar">
      <Grid container spacing={2} >
        <Grid item md={8} xs={7}>
          {showVideoButton ? (
            <div>
              <Grid container>
                <Grid item md={10} xs={8}>
                  <Typography
                    variant="h6"
                    className="header-poppins-12"
                    style={{ textTransform: "inherit" }}
                  >
                    {title}
                  </Typography>
                </Grid>
                <Grid item md={2} xs={4} style={{ alignSelf: "center" }}>
                  <CustomButtonOutlined
                    onClick={props.handleVedio}
                    text="Upload Big Stage"
                  />
                </Grid>
              </Grid>
            </div>
          ) : (
            <div>
              <Grid item md={9} xs={9}>
                <Typography
                  variant="h6"
                  className="header-poppins-12"
                  style={{ textTransform: "inherit" }}
                >
                  {title}
                </Typography>
              </Grid>
            </div>
          )}
        </Grid>
        <Grid item md={3} xs={3}>
          <CustomTextField
            label="Search"
            onChange={getInputVal}
            name="search"
            value={inputVal.search}
          ></CustomTextField>
        </Grid>
        <Grid item md={1} xs={3}>
          <div className="dis-flex-around">
            <img src={Search} alt="Logo" style={{ width: " 36px" }} onClick={() => props.sendvalue(inputVal.search)} />
            {/* <img
              src={Sort}
              onClick={props.handleSort}
              alt="Logo"
              style={{ width: " 36px", cursor: "pointer" }}
            /> */}
            {/* <img src={Filter} alt="Logo" style={{ width: " 36px" }} /> */}
          </div>
        </Grid>
      </Grid>
      {openVedio ? "" : <VedioUpload></VedioUpload>}
    </div>
  );
}
SearchBar.propTypes = {
  showVideoButton: PropTypes.bool,
  title: PropTypes.string,
};
