const ArtistDetailsStyle = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    borderRadius: "8px",
  },
  Add: {
    // padding: theme.spacing(1),
    // color: theme.palette.text.secondary,
    // borderRadius: "8px",
    background: "transparent",
    // height: "50px",
    border: "1px solid rgba(161, 152, 180, 0.1)",
    color: "white",
    fontSize: "15px",
  },
  Add1: {
    // height: "50px",
    background: "rgb(24, 26, 32)",
    color: "white",
  },
  Follower_details: {
    display: "flex",
    textAlign: "Center",
    border: "1px solid #121216",
    marginTop: "10px",
    padding: "5px",
    borderRadius: "8px",
    justifyContent: "center",
    background: "#121216",
  },
  Follower_part: {
    padding: "5px",
    fontSize: "12px",
    color: "white",
  },
  ButtonAlign: {
    padding: "6px",
    fontSize: "12px",
    opacity: "0.6",
    margin: "8px",
    borderRadius: "8px",
    background: "rgb(24, 26, 32)",
    border: "1px solid rgb(24, 26, 32) ",
  },
  p: {
    color: "white",
  },
  Switcher: {
    width: "25px",
    marginBottom:"10px",
  },
  heightFix:{
   
    minHeight:" 123px",
    display: "flex",
    flexDirection: "column",
  },
});
export default ArtistDetailsStyle;
