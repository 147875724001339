import { Card, Grid } from "@material-ui/core";
import React from "react";
import ArtistImage2 from "../../assets/images/ArtistImage/ArtistImage2.png";
import moment from "moment";
import {
  CardEmail,
  CardPublishDate,
  CardDuration,
  CardCity,
  CardState,
} from "../CardNew/CardLocation";
import CardCategory from "../CardNew/CardCategory";
import "./SongDetails.scss";
import {
  CommentCard,
  LikeCard,
  ReportedCard,
  ViewCard,
} from "../CardNew/CommonCards";
import {
  CustomButtonContained,
  CustomButtonOutlined,
} from "../Controls/CustomButton";
import {
  openCommentDialog,
  openLikeDialog,
  openReportDialog,
  openViewDialog,
} from "../../utility/dialogAppear";

export default function SongDetails(props) {
  const { songItem, musicBlock, musicBlockText, musicRemove } = props;
  console.log(songItem);

  const handleReport = (data) => {
    // window.alert("report");
    console.log(data);
    const ReportData = getReportData(data);
    openReportDialog(ReportData);
  };
  const getReportData = (dataItem) => {
    console.log(dataItem);
    if (dataItem.length !== 0 && dataItem.length !== undefined) {
      let formattedData = dataItem.map((reportedData, key) => {
        return [
          {
            reportDate: reportedData.reportsong_created,
            reportMessage: reportedData.reportsong_comment,
            userImage: reportedData.user.urlImage,
            userName:
              reportedData.user.firstName + " " + reportedData.user.lastName,
            userPhone: reportedData.user.phone,
          },
        ];
      });
      return formattedData;
    } else {
      return [];
    }
    //  openCommentDialog(data);
  };
  const handleView = (data) => {
    openViewDialog(data);
  };
  // const handleComment = (data) => {
  //   openCommentDialog(data);
  // };

  const handleComment = (data) => {
    console.log(data);
    const commentData = getCommentData(data);
    openCommentDialog(commentData);
  };
  const getCommentData = (dataItem) => {
    console.log(dataItem);
    if (dataItem.length !== 0 && dataItem.length !== undefined) {
      let formattedData = dataItem.map((commentData, key) => {
        return [
          {
            commentDate: commentData.comments_created,
            commentMessage: commentData.comments_comment,
            userImage: commentData.user.urlImage,
            userName:
              commentData.user.firstName + " " + commentData.user.lastName,
            userPhone: commentData.user.phone,
          },
        ];
      });
      return formattedData;
    } else {
      return [];
    }
    //  openCommentDialog(data);
  };
  const handleLike = (data) => {
    openLikeDialog(data);
  };
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Card
                style={{
                  background: "rgba(24, 26, 32, 0.5)",
                  color: "white",
                  opacity: "0.7",
                  borderRadius: "8px",
                  padding: "10px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div>
                    <img
                      src={ArtistImage2}
                      alt="Imgloaderror"
                      className="song-image image-40"
                      // style={{ width: "40px", height: "40px" }}
                    />
                  </div>
                  <div className="dis-flex-column  lineheight-15">
                    <span className="font-12 opacity-90">
                      {songItem.music_title}
                    </span>
                    <span className="font-10 opacity-40">
                      {songItem.album.artist.firstName +
                        " " +
                        songItem.album.artist.lastName}
                    </span>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CardPublishDate
                publishDate={moment(songItem.music_created).format(
                  "DD MMM yyyy"
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CardDuration />
            </Grid>
            <Grid item xs={12} sm={12}>
              <CardEmail email={songItem.album.artist.email} />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <CardCity />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CardState />
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <CustomButtonOutlined text="Remove" onClick={musicRemove} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomButtonContained
                text={musicBlockText}
                onClick={musicBlock}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <div
                // onClick={() => handleView()}
                style={{ cursor: "pointer" }}
              >
                <ViewCard />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div
                onClick={() => handleLike(songItem.likes)}
                style={{ cursor: "pointer" }}
              >
                <LikeCard totalLike={songItem.likes.length} />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div
                onClick={() => handleComment(songItem.comments)}
                style={{ cursor: "pointer" }}
              >
                <CommentCard totalComment={songItem.comments.length} />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div
                onClick={() => handleReport(songItem.reported)}
                style={{ cursor: "pointer" }}
              >
                <ReportedCard totalReport={songItem.reported.length} />
              </div>
            </Grid>
            <Grid item md={12} xs={12}>
              <CardCategory name="Category(s)" isGray isTransparent categoriesItem={songItem?.album?.categories} type="songs" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
