import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import {
  CustomButtonContained,
  CustomButtonOutlined,
} from "../../components/Controls/CustomButton";
import CustomTextField from "../../components/Controls/CustomTextField";
import reactDom from "react-dom";
import { constants } from "../../utility/constants";
import { patchDataApi, postDataApi } from "../../utility/apiCalling";
import { Form, useForm } from "../Controls/useForm";
import { addErrorMessage, addInformationMessage, addSuccessMessage } from "../../utility/LoadingContent";
import Files from "react-files";

const styles = (theme) => ({
  root: {
    margin: 0,
    // padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    // padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CategoryEditDialog(props) {
  console.log(props)
  const { data, Name, renderElement ,url,selectedTab} = props;
  console.log(data);
  const initialFValues = {
    id: 0,
    categoryName: Name,

  };

  const [open, setOpen] = useState(true);
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(url);


  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const onFilesChange2 = (files) => {
    console.log(selectedFile2);
    if (files.length !== 0) {
      let fileMessage = files[0].name + " Added Successfully";
      setSelectedFile2(files[0]);
      addInformationMessage(fileMessage);

      // addSuccessMessage(fileMessage);
    }
    // console.log(files);
  };
  const handleCloseTab = () => {
    setSelectedFile2(null);
  };
  console.log(selectedFile2)
  const handleClose = () => {
    reactDom.unmountComponentAtNode(renderElement);
    setOpen(false);
  };
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("categoryName" in fieldValues) {
      temp.categoryName = fieldValues.categoryName.trim()
        ? ""
        : "This field is required.";
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const saveChanges = () => {
    if (validate()) {
      if(selectedFile2==null && selectedTab==1){
        return addErrorMessage("Please Select Image")
      }
      getSave();
    }
  };
  async function geturlimage(){
    var data = new FormData();
    data.append(constants.uplFileParameter, selectedFile2);
    let uploadThumbnail = await postDataApi(constants.subcategoryThumbnailFileUploadURL, data);//let uploadVedio = await postDataApi(constants.bigStageVideo, data);
    if (uploadThumbnail.data.status === 200) {
      return uploadThumbnail.data.data.filename;
    }
  }
  async function getSave() {
    let url1
    if(selectedFile2?.name && selectedTab==1){
       url1 = await geturlimage()
    }

    let jsonData = {
      name: values.categoryName.trim()
    };

    if(selectedTab==1) jsonData.urlImage=url1?url1:url
    // console.log(id);
    // console.log("handle Not Show----------------------->");
    // console.log(constants.getOnlySubCategory + "/" + data);
    let CategorySave = await patchDataApi(
      constants.getOnlySubCategory + "/" + data,
      jsonData
    );
    console.log(CategorySave);
    if (CategorySave.status === 200 || CategorySave.statusText === "OK") {
      addSuccessMessage("Edit Success");
      reactDom.unmountComponentAtNode(renderElement);
      window.location.reload()
    } else {
      // console.log("User Not  successfully Deleted");
    }
  }
  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );
  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Open dialog
            </Button> */}
      <Dialog
        //   scroll="body"
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        style={{ borderRadius: "32px" }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Edit Category
          <div className="header-type-3">
            Please enter category name to edit a Category.
          </div>
        </DialogTitle>
        <DialogContent>
          <Form>
            <Grid container spacing={2} style={{ margin: "0px" }}>
            {selectedTab==1?<><Grid item md={12} xs={12}>
                <div className="dis-flex-between">
                  <div className="header-type-3">Upload Subcategory Thumbnail</div>
                  <div>
                    <Files
                      className="files-dropzone"
                      onChange={onFilesChange2}
                      accepts={["image/*"]}
                      // multiple
                      // maxFiles={1}
                      maxFileSize={3000000}
                      minFileSize={0}
                      // onError={true}
                      clickable
                    >
                      <CustomButtonOutlined
                        text="Browse"
                        // onClick={handleClose}
                      />
                    </Files>
                  </div>
                </div>
              </Grid>
              <Grid item md={12} xs={12}>
                {selectedFile2 && (
                  <div
                    className="addBorder dis-flex-between"
                    style={{
                      background: "rgba(161, 152, 180, 0.1)",
                    }}
                  >
                    <div className="dis-inline-row">
                      <div>
                        <img
                          src={selectedFile2?.preview?.url? selectedFile2.preview.url :constants.podcastthumbanailcategoryFileURL + selectedFile2}
                          alt="notfound"
                          className="table-image image-40 addBorder"
                        ></img>
                      </div>
                      <div className="header-poppins-12">
                        {selectedFile2?.preview?.url?selectedFile2.name:selectedFile2}
                      </div>
                    </div>
                    <IconButton
                      aria-label="close"
                      //   className={classes.closeButton}
                      onClick={handleCloseTab}
                    >
                      <CloseIcon htmlColor="#FFD874" />
                    </IconButton>
                  </div>
                )}
              </Grid></>:null}
              <Grid item xs={12} md={12}>
                <CustomTextField
                  label="Category Name"
                  name="categoryName"
                  value={values.categoryName}
                  onChange={handleInputChange}
                  error={errors.categoryName}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomButtonOutlined text="Cancel" onClick={handleClose} />
              </Grid>

              <Grid item xs={12} md={6}>
                <CustomButtonContained
                  text="Save Changes"
                  onClick={() => saveChanges()}
                />
              </Grid>
            </Grid>
          </Form>
        </DialogContent>

        {/* <DialogActions>
                    <Grid container spacing={2} style={{ margin: "0px" }}>
                        <Grid item xs={12} md={6}>
                            <CustomButtonOutlined text="Cancel" />

                        </Grid>

                        <Grid item xs={12} md={6}>
                            <CustomButtonContained text="Block Artist" />

                        </Grid>
                    </Grid>
                </DialogActions> */}
      </Dialog>
    </div>
  );
}
