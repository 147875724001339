import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import StreetviewIcon from "@material-ui/icons/Streetview";
import DeleteIcon from "@material-ui/icons/Delete";
import CustomTableNew from "../../components/CustomTable/CustomTableNew";
// import { CustomButtonContained, CustomButtonOutlined } from "../../components/Controls/CustomButton"
// import IconArrowBottom from "../../assets/images/IconArrowBottom.svg";
import "../Users/Users.scss";
import { deleteDataApi, getdata, patchDataApi } from "../../utility/apiCalling";
import {
  openConfirmationDeleteDialog,
  UserInformation,
} from "../../utility/dialogAppear";
import { BlueCard, OrangeCard } from "../../components/CardNew/CommonCards";
import SearchBar from "../../components/SearchBar/SearchBar";
import NavSearchBar from "../../components/SearchBar/NavSearchBar";
import { constants } from "../../utility/constants";
import reactDom from "react-dom";
import { addErrorMessage, addSuccessMessage } from "../../utility/LoadingContent";
import LoaderforTable from "../../components/SkeletonLoader/LoaderforTable";
// import { useRef } from "react";

export default function Users() {
  const [posts, setPosts] = useState([]);
  const [stopLoader, setStopLoader] = useState(true)

  useEffect(() => {
    getUser();
  }, []);

  async function getUser() {
    let response = await getdata(constants.baseURL + "support");//Change by Hitendra - 05Mar2022
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      // console.log(response.data)
      if (response.data.length !== 0) {
        return setPosts(response.data);

      } else {
        return setStopLoader(false)
      }
      // return setPosts(response.data);
    }
  }
  const data = (posts) => {
    console.log("----------->users posts");
    console.log(posts);
    var formatted = posts.map((item, key) => {
      console.log(item);
      // console.log(item.id)
      var imagelink = constants.profileURL; //constants.baseURL + "profilefiles";
      return [
        <div style={{ display: "flex" }} key={item.id.toString()}>
          <img
            src={item?.user?.urlImage !== undefined && item?.user?.urlImage !== null && item?.user?.urlImage !== "" && item?.user?.urlImage !== "undefined" ? imagelink + item?.user?.urlImage : constants?.userImageURL}
            alt={item?.user?.firstName + " " + item?.user?.lastName}
            className="table-image"
          />
          <div>{" " + item?.user?.firstName + " " + item?.user?.lastName}</div>
        </div>,
        item.title,
        item.description,
        // item.status === true ? (
        //   <span style={{ color: "rgb(76, 196, 124)" }}>Active</span>
        // ) : (
        //   <span style={{ color: "rgb(214, 88, 122)" }}>Inactive</span>
        // ),
        // item.usertype === "Artist" ? (
        //   <p style={{ color: "#376BC5" }}>{item.usertype}</p>
        // ) : (
        //   <p style={{ color: "#BF8643" }}>{item.usertype}</p>
        // ),
        // <div>
        //   <span>
        //     <StreetviewIcon
        //       fontSize="small"
        //       titleAccess="view"
        //       style={{
        //         cursor: "pointer",
        //         color: "#376BC5",
        //         marginRight: "20px",
        //       }}
        //       onClick={() => showUserDetails(key)}
        //     />
        //   </span>
        //   <span>
        //     <DeleteIcon
        //       fontSize="small"
        //       style={{ cursor: "pointer", color: "#D6587A" }}
        //       titleAccess="Delete"
        //       onClick={() => handleDelete(item.id)}
        //     />
        //   </span>
        // </div>,
      ];
    });
    return formatted;
  };
  return (
    <>
      <div className="main-window">
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <NavSearchBar title="Support" />
          </Grid>
          {/* <Grid container spacing={2}>
            <Grid item md={3} xs={6}>
              <BlueCard
                totalBlue={totalStandard(posts)}
                title="Standard User"
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <OrangeCard
                totalBlocked={totalBlocked(posts)}
                title="Blocked User"
              />
            </Grid>
          </Grid> */}
          <Grid item md={12} xs={12}>
            <SearchBar title={posts.length + " Support(s)"} />
          </Grid>
          <Grid item md={12} xs={12} className="support-table">

            {/* <LoaderforTable /> */}
            {stopLoader === true ? <CustomTableNew
              tableHead={[
                "Name",
                "Title",
                "Description",
                // "status",
                // "Type",
                // "Action",
              ]}
              tableData={data(posts)}
            /> : <p style={{ paddingTop: "150px", textAlign: "center" }}>
              No data found
            </p>}
          </Grid>
        </Grid>
      </div>
    </>
  );
}
