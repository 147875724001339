import { Container, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ArtistTypeCard from "../../components/ArtistTypeCard/ArtistTypeCard";
import SearchBar from "../../components/SearchBar/SearchBar";
import {
  PinkCard,
  OrangeCard,
  GreenCard,
} from "../../components/CardNew/CommonCards";
import NavSearchBar from "../../components/SearchBar/NavSearchBar";
import { deleteDataApi, getdata, patchDataApi } from "../../utility/apiCalling";
import { constants } from "../../utility/constants";
import {
  CustomButtonContained,
  CustomButtonOutlined,
} from "../../components/Controls/CustomButton";
import {
  openCommentDialog,
  openConfirmationDeleteDialog,
  openLikeDialog,
  openVedioUpdateDialog,
  openViewDialog,
} from "../../utility/dialogAppear";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { addErrorMessage, addSuccessMessage } from "../../utility/LoadingContent";
import reactDom from "react-dom";
import { TrendingUpRounded } from "@material-ui/icons";
import { NoMoreDataFound } from "../../components/NoDataFound/NoDataFound";

export default function NewsPage() {
  const [newsData, setNewsData] = useState([]);
  const [stopLoader, setStopLoader] = useState(true);
  // const [nextPageNotFound, setnextPageNotFound] = useState(false)
  const [showPageNumberButton, setshowPageNumberButton] = useState(false);
  var [page, setPage] = useState(1);
  const [serc, setserc] = useState(null);

  useEffect(() => {
    getNews(page);
  }, []);
  let history = useHistory();

  // -------------------------menu button

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // ----------------------------menu Button

  // --------------------view Button function
  const handleView = (key) => {
    console.log(key);
    let data = newsData.data[key];
    console.log("----------------------handle view log");
    console.log(data.news_id);
    history.push({
      pathname: `news-details/${data.news_id}`,
      state: {
        newsId: data.news_id,
      },
    });
  };
  //---------------------------------handle Edit
  const handleEdit = (id) => {
    window.alert(id);
    openVedioUpdateDialog(id);
  };
  //--------------------Delete Button Function
  const handleDelete = (id) => {
    openConfirmationDeleteDialog(() => {
      deleteNews(id);
    }, "Delete");
  };
  const deleteNews = async (id) => {
    let jsonData = {
      isDeleted: 1,
    };
    // console.log(id);
    console.log("handle Delete ----------------------->");
    console.log(constants.getnewsURL + "/" + id);
    let deleteNewsData = await patchDataApi(
      constants.getnewsURL + "/" + id,
      jsonData
    );
    if (deleteNewsData.status === 200 || deleteNewsData.statusText === "OK") {
      addSuccessMessage("News Delete");
      setPage(1);
      getNews(1);
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {

      addErrorMessage("News Not Deleted")
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }

    // let deleteNewsData = await deleteDataApi(constants.admingetnewsURL + "/" + id);
    // if (deleteNewsData.status === 200 && deleteNewsData.statusText === "OK") {
    //   // console.log("User Successfully Deleted");
    //   addSuccessMessage("News Delete");
    //   getNews(page);
    //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    // } else {
    //   console.log("News Not Deleted");
    //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    // }
  };

  async function getNews(page) {
    console.log("------------------podcast page");
    console.log(constants.admingetnewsURL + "/getAll?page=" + page + "&limit=10");
    let response = await getdata(
      constants.admingetnewsURL + "/getAll?page=" + page + "&limit=10"
    );
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      if (response.data.data.length !== 0) {
        if (response.data.data.length === 10) {
          setshowPageNumberButton(true);
        }
        return setNewsData(response.data);

      } else {
        setNewsData(response.data);
        return setStopLoader(false);
      }
      // console.log("hello");
      // console.log(response.data);
    }
  }
  const getCommentData = (key) => {
    let data = newsData.data[key].comments;
    console.log(data);
    if (data.length !== 0 && data.length !== undefined) {
      let formattedData = data.map((commentData, key) => {
        return [
          {
            commentDate: commentData.newscomments_created,
            commentMessage: commentData.newscomments_comment,
            userImage: commentData.user.urlImage,
            userName:
              commentData.user.firstName + " " + commentData.user.lastName,
            userPhone: commentData.user.phone,
          },
        ];
      });
      return formattedData;
    } else {
      return [];
    }
    //  openCommentDialog(data);
  };

  const handleComment = (key) => {
    const data = getCommentData(key);
    openCommentDialog(data);
  };

  // const handleComment = (key) => {
  //   let data = newsData.data[key].comments;
  //   openCommentDialog(data);
  // };
  const handleLike = (key) => {
    let data = newsData.data[key].likes;
    // openViewDialog(data);
    openLikeDialog(data);
  };
  const sendvalue = (i) =>{
    console.log(i)
    setserc(i)
    setPage(1)
    getNextPage(1,i);

  }
  const nextPage = () => {
    setPage(page + 1);
    getNextPage(page + 1);
  };

  console.log("--------------------page" + page);
  async function getNextPage(p,ser) {
    let apiURL =  constants.admingetnewsURL + "/getAll?page=" + p + "&limit=10"
    if (ser) {
      apiURL += "&searchText=" + ser
    }
    let response = await getdata(
      apiURL
    );
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      console.log("--------------------> next page");
      console.log(response);
      if (response.data.data.length !== 0) {
        if ((response.data.limit*response.data.page)<response.data.totalCount) {
          setshowPageNumberButton(true);
        }else{
          setshowPageNumberButton(false);
        }
        if(p==1){
          setNewsData(response.data);
        }else{
        setNewsData((prevState) => {
          return {
            ...prevState,
            // totalCount: prevState.totalCount + response.data.totalCount,
            data: [...prevState.data, ...response.data.data],
          };
        });
      }
      } else {
        return setshowPageNumberButton(false);
      }
      // return setBigStageData(response.data);
    }
  }
  const data = (newsData) => {
    console.log(newsData);
    console.log(newsData);
    console.log(newsData !== undefined && newsData.length !== 0);
    if (newsData !== undefined && newsData.length !== 0) {
      console.log("hello----------------------andar");
      var formatted = newsData.data.map((items, key) => {
        console.log(key);

        var imagelink = constants.newsFileURL;//constants.baseURL + "newsfiles/";
        return [
          {
            id: items.news_id,
            image: [
              {
                itemImage:
                  items.news_urlImage === ""
                    ? constants.musicURL
                    : imagelink + items.news_urlImage,
                handleImage: () => handleView(key),
              },
            ],
            // image: imagelink + items.news_urlImage,
            vedioTitle: items.news_title,
            imageTagName: "Active",
            vedioTrack: items.news_description.substring(0, 40),
            view: "0",
            like: [
              {
                totalLike: items.likes.length,
                handleLike: () => handleLike(key),
              },
            ],
            comment: [
              {
                totalComment: items.comments.length,
                handleComment: () => handleComment(key),
              },
            ],
            handleDel: () => handleDelete(items.news_id),
          },
        ];
      });
      return formatted;
    } else return [];
  };

  return (
    <div
      className="main-window"
    // style={{ background: "#121216", paddingLeft: "12px", paddingRight: "12px", paddingTop: "12px" }}
    >
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <NavSearchBar title="News" />
        </Grid>
        <Grid item xs={6} md={3}>
          <GreenCard totalGreen={newsData.activeCount} title="Active News" />
        </Grid>
        {/* <Grid item xs={6} md={3}>
          <PinkCard totalPink={newsData.reportedCount} title="Reported News" />
        </Grid> */}
        <Grid item xs={6} md={3}>
          <OrangeCard
            totalBlocked={newsData.inactiveCount}
            title="Blocked News"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <SearchBar
            title={
              newsData.totalCount === undefined
                ? "0 News"
                : newsData.totalCount + " News"
            }
            sendvalue={sendvalue}

          ></SearchBar>
        </Grid>
        <Grid item xs={12} md={12}>

          {stopLoader === true ? (
            <ArtistTypeCard
              sourceData={data(newsData)}
              variant="News"
            ></ArtistTypeCard>
          ) : (
            <p style={{ paddingTop: "150px", textAlign: "center" }}>
              No data found
            </p>
          )}

        </Grid>
        <Grid item md={12} xs={12}>
          <div style={{ textAlign: "-webkit-center" }}>
            {showPageNumberButton && (
              <div>
                <Grid item md={12} style={{ width: "20%" }}>
                  <CustomButtonOutlined
                    text={"view More "}
                    onClick={() => nextPage()}
                  />
                </Grid>
              </div>
            )}
          </div>
        </Grid>
        {/* {nextPageNotFound === true ? <NoMoreDataFound /> :

          <Grid item md={12} xs={12}>
            <div style={{ textAlign: "-webkit-center" }}>
              {newsData.length !== 0 ? (
                <div>
                  {newsData.data.length === 10 ? (
                    <div>
                      <Grid item md={12} style={{ width: "20%" }}>
                        <CustomButtonOutlined
                          text={"view More "}
                          onClick={() => nextPage()}
                        />
                      </Grid>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </Grid>
        } */}

      </Grid>
    </div>
  );
}
