import React from "react";

import Skeleton from "@material-ui/lab/Skeleton";
import { Grid } from "@material-ui/core";
import CustomSkeleton from "./CustomSkeleton";

export default function LoaderforNews() {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <CustomSkeleton width="100%" />
        </Grid>
        <Grid item md={8} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <CustomSkeleton height="200px" />
            </Grid>
            <Grid item md={12} xs={12}>
              <CustomSkeleton height="40px" />
            </Grid>
            <Grid item md={12} xs={12}>
              <CustomSkeleton height="150px" />
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={4} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <CustomSkeleton height="100px" />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomSkeleton height="60px" />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomSkeleton height="60px" />
            </Grid>
            <Grid item xs={12} md={12}>
              <CustomSkeleton height="60px" />
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <CustomSkeleton height="60px" />
            </Grid> */}
            <Grid item xs={12} md={6}>
              <CustomSkeleton height="60px" />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomSkeleton height="60px" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
