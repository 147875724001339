import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "../../assets/CardIcon/Avatar.png";
import video from "../../assets/video.png";
import CloseIcon from "@material-ui/icons/Close";
import { DialogContent, DialogTitle, Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {
  CustomButtonContained,
  CustomButtonOutlined,
} from "../Controls/CustomButton";
import UploadImage from "../../assets/UploadImage/Upload.png";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomTextField from "../Controls/CustomTextField";
import { Form, useForm } from "../Controls/useForm";
import Files from "react-files";
import "./VedioUpload.scss";
import reactDom from "react-dom";
import { getdata, patchDataApi, postDataApi } from "../../utility/apiCalling";
import { constants } from "../../utility/constants";
import {
  addInformationMessage,
  addLoader,
  addSuccessMessage,
  removeLoader,
} from "../../utility/LoadingContent";
import CustomProgress from "../CustomProgress/CustomProgress";

const initialFValues = {
  id: 0,
  bigstagetitle: "",
  artistname: "",
  VedioOptionBox: "",
};
const VedioOption = [
  { title: "Music" },
  { title: "Event" },
  { title: "Podcast" },
  { title: "Audio" },
];

export default function VedioUpdate(props) {
  const { data, renderElement } = props;
  const [selectedFile1, setSelectedFile1] = useState([]);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState("");
  const [vedioFileName, setVedioFileName] = useState("");
  const [progressValue, setProgressValue] = useState(30);
  const [progressShow, setprogressShow] = useState(false);

  // const [textfieldDefault, setTextfieldDefault] = useState("");
  const [showError, setShowError] = useState(false);
  var textfieldDefault = "";

  useEffect(() => {
    vedioValues();
  }, [progressValue]);
  async function vedioValues() {
    let response = await getdata(constants.getBigStage + "/" + data);
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      console.log("hello");
      console.log(response.data);
      console.log("----------------------------update values");
      // setTextfieldDefault(response.data.name);
      // textfieldDefault = response.data.name;
      setValues((prevState) => ({
        ...prevState,
        bigstagetitle: response.data.name,
      }));
      setImage(constants.bigStageThumbnail + "/" + response.data.thumbnailURL);
      setFileName(response.data.thumbnailURL);
      setVedioFileName(response.data.videoURL);
      console.log(response.data.videoURL + "--------------->");
      // setSelectedFile2()

      // console.log(textfieldDefault);
      // console.log(textfieldDefault);

      // return setBigStageData(response.data);
    }
  }

  // const [imageUrl, setImageUrl] = useState("");
  // const [vedioUrl, setVedioUrl] = useState("");

  var imageUrl = "";
  var vedioUrl = "";

  const [open, setOpen] = React.useState(true);
  // const [selectedFile, setSelectedFile] = useState(null);
  // const [selectedFile, setSelectedFile] = useState(null);
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("bigstagetitle" in fieldValues) {
      temp.bigstagetitle = fieldValues.bigstagetitle
        ? ""
        : "This field is required.";
    }
    // if ("artistname" in fieldValues)
    //   temp.artistname = fieldValues.artistname ? "" : "This field is required.";
    // if ("VedioOptionBox" in fieldValues)
    //   temp.VedioOptionBox = fieldValues.VedioOptionBox
    //     ? ""
    //     : "This field is required.";
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const onFilesError1 = (error, file) => {
    console.log("error");
    if (error) {
      let FileErrorMessage = error.message;
      // addErrorMessage(FileErrorMessage);
    }
  };
  const onFilesChange1 = (files) => {
    if (files.length !== 0) {
      let fileMessage = files[0].name + " Added Successfully";
      console.log("hii");
      setSelectedFile1(files[0]);
      setVedioFileName(files[0].name);
      addInformationMessage(fileMessage);
      // addSuccessMessage(fileMessage);
    }
    console.log(files);
  };

  const onFilesError2 = (error, file) => {
    console.log("error");
    if (error) {
      let FileErrorMessage = error.message;
      // addErrorMessage(FileErrorMessage);
    }
  };
  const onFilesChange2 = (files) => {
    console.log(selectedFile2);
    if (files.length !== 0) {
      let fileMessage = files[0].name + " Added Successfully";
      setSelectedFile2(files[0]);
      setImage(files[0].preview.url);
      setFileName(files[0].name);
      addInformationMessage(fileMessage);
    }
  };

  const handleClose = () => {
    // window.location.reload();
    reactDom.unmountComponentAtNode(renderElement);
    setOpen(false);
  };
  const handleCloseTab = () => {
    setImage(null);
    setFileName("");
    // setSelectedFile2(null);
  };
  const handleCloseVedioTab = () => {
    setVedioFileName("");
  };
  const handleSubmit = () => {
    console.log("selectedFile1.length", selectedFile1.length);
    console.log("selectedFile2", selectedFile2);
    console.log("fileName", fileName);
    console.log("vedioFileName", vedioFileName);

    if (validate()) {
      if (selectedFile1.length === 0 || fileName === "") {
        setShowError(true);
      }
      if (
        (selectedFile1.length === 0 || selectedFile2 === null) &&
        (fileName === "" || vedioFileName === "")
      ) {
        setShowError(true);
      } else {
        setShowError(false);
        if (
          selectedFile1.length !== 0 &&
          selectedFile2 !== null &&
          fileName !== "" &&
          vedioFileName !== ""
        ) {
          // addLoader();
          setprogressShow(true);
          uploadBigStage();
        }
        if (
          selectedFile1.length === 0 &&
          fileName !== 0 &&
          selectedFile2 === null &&
          vedioFileName !== ""
        ) {
          // console.log("-----------------------------yeh jagah hai")
          imageUrl = fileName;
          vedioUrl = vedioFileName;
          //  console.log("Selected FIle 1:  "+selectedFile1)
          // console.log("Selected FIle 2:  "+selectedFile2)
          //  console.log("vedioFileName:  "+vedioFileName.length)
          //   console.log("fileName:  "+fileName)
          createBigStage();
          return;
        }

        if (
          (selectedFile1.length !== 0 && vedioFileName.length !== 0) ||
          (selectedFile2 === null && fileName !== 0)
        ) {
          // console.log( (selectedFile1.length !== 0 && vedioFileName.length !== 0) ||
          // (selectedFile2 === null && fileName !== 0))
          // console.log(selectedFile1.length === 0 &&
          // fileName !== 0 &&
          // selectedFile2 === null &&
          // vedioFileName !== "")
          //  console.log("Selected FIle 1:  "+selectedFile1)
          // console.log("Selected FIle 2:  "+selectedFile2)
          //  console.log("vedioFileName:  "+vedioFileName.length)
          //   console.log("fileName:  "+fileName)

          // addLoader();
          setprogressShow(true);
          // uploadBigStage();
          // createBigStage()
          uploadVideo();
        }
        if (
          (selectedFile1.length === 0 && vedioFileName !== 0) ||
          (selectedFile2 !== null && fileName !== 0)
        ) {
          // console.log("hiiiii Image yhi hai")
          // addLoader();
          setprogressShow(true);
          uploadImage();
        }
      }
    } else {
      console.log("hiii");
    }
  };
  const uploadImage = async () => {
    var data = new FormData();

    data.append(constants.uplFileParameter, selectedFile2);
    // console.log(constants.bigStageThumbnail);

    let uploadThumbnail = await postDataApi(constants.bigStageThumbnail, data);
    if (uploadThumbnail.data.status === 200) {
      console.log(uploadThumbnail.data.data.filename);
      setProgressValue("70");
      // setProgressValue((prevProgress) => prevProgress + 10);
      imageUrl = uploadThumbnail.data.data.filename;
      vedioUrl = vedioFileName;
      createBigStage();
    } else {
      // removeLoader();
      setprogressShow(false);
      // console.log("helllo data not upload successfully");
    }
  };

  const uploadVideo = async () => {
    console.log(selectedFile1);
    // setProgressValue((prevProgress) => prevProgress + 10);

    setProgressValue("50");
    var data = new FormData();
    data.append(constants.uplFileParameter, selectedFile1);
    // console.log(constants.uploadBigStage);

    let uploadVedio = await postDataApi(constants.bigStageVideo, data);
    console.log(uploadVedio.data);
    if (uploadVedio.data.status === 200) {
      vedioUrl = uploadVedio.data.data.filename;
      imageUrl = fileName;
      // setProgressValue((prevProgress) => prevProgress + 10);
      setProgressValue("60");
      createBigStage();
    } else {
      // removeLoader();
      setprogressShow(false);
      // console.log("helllo data not upload successfully");
    }
  };

  const uploadBigStage = async () => {
    console.log(selectedFile1);
    var data = new FormData();
    data.append(constants.uplFileParameter, selectedFile1);
    // console.log(constants.uploadBigStage);

    let uploadVedio = await postDataApi(constants.bigStageVideo, data);
    console.log(uploadVedio.data.status);
    if (uploadVedio.data.status === 200) {
      vedioUrl = uploadVedio.data.data.filename;
      // var vedioUrl = uploadVedio.data.filename;
      // setVedioUrl(uploadVedio.data.data.filename);
      // setURL({ vedioUrl: uploadVedio.data.data.filename });
      // console.log(URL.vedioUrl);
      setProgressValue("60");
      // setProgressValue((prevProgress) => prevProgress + 10);
      uploadBigStageImage();
      // console.log("file uploaded");
    } else {
      // removeLoader();
      setprogressShow(false);
      // console.log("helllo data not upload successfully");
    }
  };
  const uploadBigStageImage = async () => {
    var data = new FormData();
    data.append(constants.uplFileParameter, selectedFile2);
    // console.log(constants.bigStageThumbnail);

    let uploadThumbnail = await postDataApi(constants.bigStageThumbnail, data);
    console.log(uploadThumbnail);
    if (uploadThumbnail.data.status === 200) {
      console.log(uploadThumbnail.data.data.filename);
      // setImageUrl(uploadThumbnail.data.data.filename);
      imageUrl = uploadThumbnail.data.data.filename;
      setProgressValue("80");
      // setProgressValue((prevProgress) => prevProgress + 10);
      // setImageUrl("hello");
      // setURL({ imageUrl: uploadThumbnail.data.data.filename });

      // console.log("Image uploaded");
      createBigStage();
    } else {
      // removeLoader();
      setprogressShow(false);
      // console.log("helllo data not upload successfully");
    }
  };
  const createBigStage = async () => {
    setProgressValue("90");
    // setProgressValue((prevProgress) => prevProgress + 10);
    console.log();
    const jsonData = {
      name: values.bigstagetitle,
      user: JSON.parse(localStorage.getItem("UserInfo")).id,
      videoURL: vedioUrl,
      thumbnailURL: imageUrl,
    };
    console.log(jsonData);
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    // let createBigStageProfile = await postDataApi(
    //   constants.createBigStage,
    //   formBody,
    //   authHeader
    // );
    let UpdateBigStage = await patchDataApi(
      constants.createBigStage + "/" + data,
      formBody,
      authHeader
    );
    // console.log(UpdateBigStage);
    if (UpdateBigStage.status === 200 || UpdateBigStage.statusText === "OK") {
      // removeLoader();
      setProgressValue("100");
      // setProgressValue((prevProgress) => 100);
      setprogressShow(false);

      addSuccessMessage("BigStage Update ");
      // window.location.reload();

      reactDom.unmountComponentAtNode(renderElement);
      // handleClose();
      // setOpen(false);
      // console.log("UserUpdate Successfully");
    } else {
      // removeLoader();
      setprogressShow(false);
      console.log("User Mot Update successfully");
    }
  };

  return (
    <div>
      <Dialog
        maxWidth="sm"
        onClose={handleClose}
        // aria-labelledby="customized-dialog-title"
        open={open}
        style={{ borderRadius: "32px" }}
      // className={classes.dialog}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        //   className={classes.dialogtitle}
        >
          {/* {open ? (
            <IconButton
              aria-label="close"
            
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null} */}
          <div className="dis-flex-between">
            <div> Update Big Stage</div>
            {/* <CustomProgress value={progressValue} /> */}
            {progressShow && (
              <div>
                <CustomProgress value={progressValue} />
              </div>
            )}
          </div>
        </DialogTitle>
        <DialogContent
        // className={classes.DialogContent}
        >
          <Form
          //   onSubmit={handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <div>
                  <Files
                    className="files-dropzone"
                    onChange={onFilesChange1}
                    onError={onFilesError1}
                    accepts={["video/mp4"]}
                    // multiple
                    // maxFiles={1}
                    // maxFileSize={3000000}
                    minFileSize={0}
                    // onError={true}
                    clickable
                  >
                    <div className="ImageUpload ">
                      <img src={UploadImage} alt="not found"></img>
                      <div className="font-14">Upload Vedio</div>
                      <div className="header-type-3">
                        Drag & drop your file here or
                        <span style={{ color: "#ffd874" }}>browse</span>
                      </div>
                    </div>
                  </Files>
                </div>
              </Grid>
              <Grid item md={12} xs={12}>
                {vedioFileName !== "" ? (
                  <div
                    className="addBorder dis-flex-between"
                    style={{
                      background: "rgba(161, 152, 180, 0.1)",
                    }}
                  >
                    <div className="dis-inline-row">
                      <div>
                        <img
                          src={video}
                          alt="notfound"
                          className="table-image image-40"
                        ></img>
                      </div>
                      <div className="header-poppins-12">{vedioFileName}</div>
                    </div>
                    <IconButton
                      aria-label="close"
                      // className={classes.closeButton}
                      onClick={handleCloseVedioTab}
                    >
                      <CloseIcon htmlColor="#FFD874" />
                    </IconButton>
                  </div>
                ) : (
                  ""
                )}
              </Grid>

              <Grid item md={12} xs={12}>
                <div className="dis-flex-between">
                  <div className="header-type-3">Upload Video Thumbnail</div>
                  <div>
                    <Files
                      className="files-dropzone"
                      onChange={onFilesChange2}
                      onError={onFilesError2}
                      accepts={["image/*"]}
                      // multiple
                      // maxFiles={1}
                      maxFileSize={3000000}
                      minFileSize={0}
                      // onError={true}
                      clickable
                    >
                      <CustomButtonOutlined
                        text="Browse"
                      // onClick={handleClose}
                      />
                    </Files>
                  </div>
                </div>
              </Grid>
              <Grid item md={12} xs={12}>
                {image && (
                  <div
                    className="addBorder dis-flex-between"
                    style={{
                      background: "rgba(161, 152, 180, 0.1)",
                    }}
                  >
                    <div className="dis-inline-row">
                      <div>
                        <img
                          src={image}
                          alt="notfound"
                          className="table-image image-40 addBorder"
                        ></img>
                      </div>
                      <div className="header-poppins-12">{fileName}</div>
                    </div>
                    <IconButton
                      color="red"
                      aria-label="close"
                      //   className={classes.closeButton}
                      onClick={handleCloseTab}
                    >
                      <CloseIcon htmlColor="#FFD874" />
                    </IconButton>
                  </div>
                )}
              </Grid>
              <Grid item md={12} xs={12}>
                <CustomTextField
                  name="bigstagetitle"
                  label="Big Stage Title"
                  value={values.bigstagetitle}
                  // defaultValue={textfieldDefault}
                  onChange={handleInputChange}
                  error={errors.bigstagetitle}
                  fullWidth
                ></CustomTextField>
              </Grid>

              {/* <Grid item md={12}>
                <CustomTextField
                  name="artistname"
                  label="Artist Name"
                  value={values.artistname}
                  onChange={handleInputChange}
                  error={errors.artistname}
                  fullWidth
                ></CustomTextField>
              </Grid> */}
              {/* <Grid item md={12}>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={VedioOption}
                  getOptionLabel={(option) => option.title}
                  defaultValue={[VedioOption[1]]}
                  filterSelectedOptions
                  fullWidth
                  renderInput={(params) => (
                    <CustomTextField
                      {...params
                      label="Add Category"
                      name="VedioOptionBox"
                    />
                  )}
                />
              </Grid> */}

              {showError ? (
                <Grid item md={12} xs={12}>
                  <div
                    className="header-type-3"
                    style={{ color: "red", textAlign: "center" }}
                  >
                    Please Upload Video and Thumbnail.
                  </div>
                </Grid>
              ) : (
                ""
              )}

              <Grid item md={12} xs={12}>
                <Grid container spacing={2} style={{ margin: "0px" }}>
                  <Grid item xs={6} md={6}>
                    <CustomButtonOutlined text="Cancel" onClick={handleClose} />
                  </Grid>

                  <Grid item xs={6} md={6}>
                    <CustomButtonContained
                      text="Update"
                      onClick={handleSubmit}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
