import {
  Grid,
  InputBase,
  Container,
  Typography,
  SwipeableDrawer,
} from "@material-ui/core";
import React, { useState } from "react";
// import Filter from "../../assets/images/Filter.png";
// import Search from "../../assets/images/Search.png";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import { fade, makeStyles } from "@material-ui/core/styles";
// import Avatar from "../../assets/CardIcon/Avatar.png";
import PropTypes from "prop-types";
import { CustomButtonOutlined } from "../Controls/CustomButton";
import { NavLink } from "react-router-dom";
// import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";
import "./NavSearchBar.scss";
import DehazeIcon from "@material-ui/icons/Dehaze";
import { ManageData, MediaData, SideBarData } from "../Navbar/SideBarData";
import Drawer from "rc-drawer";
import logo from "../../assets/logo.png";

import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  // title: {
  //   display: 'none',
  //   [theme.breakpoints.up('sm')]: {
  //     display: 'block',
  //   },
  // },
  search: {
    position: "relative",
    borderRadius: "8px",
    border: " 2px solid rgba(161, 152, 180, 0.1)",
    // backgroundColor: fade(theme.palette.common.white, 0.15),
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "12px",
    padding: "2px 0px",
  },
  list: {
    width: 250,
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "30ch",
    },
  },
}));

export default function NavSearchBar(props) {
  var name = JSON.parse(localStorage.getItem("UserInfo"));
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { title } = props;
  const handleLogOut = () => {
    localStorage.clear();
    window.location.href = "/admin/login";
    // history.push("/login");
  };
  const handleClickOpen = () => {
    // window.alert("open");
    setOpen(true);
  };
  const handleClose = () => {
    // window.alert("close");
    setOpen(false);
  };
  const list = () => (
    <div
      className={clsx(classes.list)}
      style={{
        backgroundColor: "#121216",
        padding: "0px 20px",
        minHeight: "800px",
      }}
    >
      <div style={{ textAlignLast: "center" }}>
        <img
          src={logo}
          alt="not found"
          style={{ height: "70px", width: "70px" }}
        />
      </div>
      <div style={{ backgroundColor: "#121216" }}>
        <Typography className="subHeading" style={{ paddingTop: "0px" }}>
          {"Menu"}
        </Typography>
        <div className="SideBar">
          <List className="SideBarData">
            {SideBarData.map((data, key) => {
              return (
                <Container key={key}>
                  <NavLink
                    to={data.path}
                    className="row"
                    style={{ textDecoration: "none" }}
                    activeClassName="activeData"
                  >
                    <div id="icon">{data.icon}</div>
                    <div id="title">{data.title}</div>
                  </NavLink>
                </Container>
              );
            })}
          </List>
        </div>
        <Divider style={{ backgroundColor: "#8b8398" }} />
      </div>
      <div style={{ backgroundColor: "#121216" }}>
        <Typography className="subHeading">{"Reported"}</Typography>
        <div className="SideBar">
          <List className="SideBarData">
            {MediaData.map((data, key) => {
              return (
                <Container key={key}>
                  <NavLink
                    to={data.path}
                    className="row"
                    style={{ textDecoration: "none" }}
                    activeClassName="activeData"
                  >
                    <div id="icon">{data.icon}</div>
                    <div id="title">{data.title}</div>
                  </NavLink>
                </Container>
              );
            })}
          </List>
        </div>
        <Divider style={{ backgroundColor: "#8b8398" }} />
      </div>
      <div style={{ backgroundColor: "#121216" }}>
        <Typography className="subHeading">{"Manage"}</Typography>
        <div className="SideBar">
          <List className="SideBarData">
            {ManageData.map((data, key) => {
              return (
                <Container key={key}>
                  <NavLink
                    style={{ textDecoration: "none" }}
                    to={data.path}
                    className="row"
                    activeClassName="activeData"
                  >
                    <div id="icon">{data.icon}</div>
                    <div id="title">{data.title}</div>
                  </NavLink>
                </Container>
              );
            })}
          </List>
        </div>
      </div>
    </div>
  );
  return (
    <div className="dis-flex" style={{ margin: "12px 0px" }}>
      <Grid container spacing={2}>
        <Grid item md={9} xs={12}>
          <div style={{ display: "flex" }}>
            <div className="NavSearch">
              <DehazeIcon
                fontSize="large"
                style={{
                  cursor: "pointer",
                  marginRight: "10px",
                  color: "#FFD874",
                }}
                onClick={handleClickOpen}
              ></DehazeIcon>
              <React.Fragment>
                <SwipeableDrawer onOpen={handleClickOpen} open={open} onClose={handleClose}>
                  {list()}
                </SwipeableDrawer>
              </React.Fragment>
            </div>

            <Typography
              variant="h4"
              style={{ textTransform: "capitalize" }}
              className="header-poppins-12"
            >
              {title}
            </Typography>
          </div>
        </Grid>
        {/* <Grid item md={4} xs={8}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
        </Grid> */}
        <Grid item md={2} xs={10}>
          <Typography
            variant="h6"
            style={{ textTransform: "capitalize", color: "#FFD874" }}
            className="header-poppins-12"
            id="UserText"
          >
            {/* {console.log(name)} */}
            {"Welcome " + name?.firstName }
          </Typography>
        </Grid>
        <Grid item md={1} xs={2}>
          <div className="dis-flex-around">
            <CustomButtonOutlined text="Log Out" onClick={handleLogOut} />
            {/* <img src={Search} alt="Logo" style={{ width: " 36px" }} /> */}
            {/* <img src={Avatar} alt="Logo" style={{ width: " 36px" }} /> */}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
NavSearchBar.propTypes = {
  title: PropTypes.string,
};
