import {
  warningColor,
  primaryColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  defaultFont,
} from "../../utility/colors.js";

const CustomTableNewStyle = (theme) => ({
  warningTableHeader: {
    color: warningColor[0],
  },
  primaryTableHeader: {
    color: primaryColor[0],
  },
  dangerTableHeader: {
    color: dangerColor[0],
  },
  successTableHeader: {
    color: successColor[0],
  },
  infoTableHeader: {
    color: infoColor[0],
  },
  roseTableHeader: {
    color: roseColor[0],
  },
  grayTableHeader: {
    color: grayColor[0],
  },
  table: {
    marginBottom: "0",
    width: "100%",
    minWidth: "400px",
    backgroundColor: "#121216",
    borderSpacing: "0",
    // borderCollapse: "collapse",
    color: "#A198B4",
  },
  tableHeadCell: {
    color: "#FFFFFF",
    ...defaultFont,
    "&, &$tableCell": {
      fontSize: "1rem",
      color: "#FFFFFF",
      // backgroundColor: "black",
      opacity: "0.8",
      paddingBottom: "10px",
    },
  },
  tableCell: {
    ...defaultFont,
    lineHeight: "1.42857143",
    padding: "8px 4px",
    verticalAlign: "middle",
    fontSize: "0.9rem",
    color: "#A198B4",
    opacity: "0.9",
    textTransform: "Capitalize",
    // paddingTop: "10px",
    // paddingBottom: "10px",
    borderBottom: "1px solid rgb(139, 131, 152,0.3)",
  },
  container: {
    maxHeight: 530,
  },
  tableResponsive: {
    width: "100%",
    marginTop: theme.spacing(1),
    overflowX: "auto",
  },
  tableHeadRow: {
    // height: "56px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle",
  },
  tableBodyRow: {
    // height: "48px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle",
    borderCollapse: "none",
  },
});

export default CustomTableNewStyle;
