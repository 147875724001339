import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import Files from "react-files";


import reactDom from "react-dom";
import { Form, useForm } from "./Controls/useForm";
import {
  CustomButtonOutlined,
  CustomButtonContained,
} from "./Controls/CustomButton";
import CustomTextField from "./Controls/CustomTextField";
import { constants } from "../utility/constants";
import { postDataApi } from "../utility/apiCalling";
import {
  addErrorMessage,
  addInformationMessage,
  addLoader,
  addSuccessMessage,
  removeLoader,
} from "../utility/LoadingContent";
const styles = (theme) => ({
  root: {
    margin: 0,
    // padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    // padding: theme.spacing(1),
  },
}))(MuiDialogActions);
const initialFValues = {
  id: 0,
  categoryName: "",
};

export default function AddCategoryDialog(props) {
  const { data, renderElement ,k} = props;
  console.log(data);

  const [open, setOpen] = useState(true);
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);


  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const onFilesChange2 = (files) => {
    console.log(selectedFile2);
    if (files.length !== 0) {
      let fileMessage = files[0].name + " Added Successfully";
      setSelectedFile2(files[0]);
      addInformationMessage(fileMessage);

      // addSuccessMessage(fileMessage);
    }
    // console.log(files);
  };
  // const onFilesError2 = (error, file) => {
  //   console.log("error");
  //   if (error) {
  //     // let FileErrorMessage = error.message;
  //     addErrorMessage(error.message);
  //   }
  // };
  const handleCloseTab = () => {
    setSelectedFile2(null);
  };
  const handleClose = () => {
    reactDom.unmountComponentAtNode(renderElement);
    setOpen(false);
  };
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("categoryName" in fieldValues) {
      temp.categoryName = fieldValues.categoryName.trim()
        ? ""
        : "This field is required.";
    }
   

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const CreateCategory = () => {
    if (validate()) {
      if( data==2 && selectedFile2==null){
        return addErrorMessage("Please Select Image")
      }
      getCreateCategory();
    }
  };
  async function geturlimage(){
    var data = new FormData();
    data.append(constants.uplFileParameter, selectedFile2);
    let uploadThumbnail = await postDataApi(constants.subcategoryThumbnailFileUploadURL, data);//let uploadVedio = await postDataApi(constants.bigStageVideo, data);
    if (uploadThumbnail.data.status === 200) {
      return uploadThumbnail.data.data.filename;
    }
  }
  async function getCreateCategory() {
    let url
    if(data==2){
      url = await geturlimage()
    }
    let jsonData = {
      name: values.categoryName.trim(),
      category: data,
      urlImage: url,
    };

    if(data==2) jsonData.urlImage = url
    // console.log(id);
    console.log("handle Not Show----------------------->");
    console.log(constants.getOnlySubCategory);
    let CreateCategory = await postDataApi(
      constants.getOnlySubCategory,
      jsonData
    );
    console.log(CreateCategory);
    if (
      CreateCategory.status === 201 &&
      CreateCategory.statusText === "Created"
    ) {
      addSuccessMessage("Create Category");
      window.location.reload()
      // reactDom.unmountComponentAtNode(renderElement);
    } else {
      // console.log("User Not  successfully Deleted");
    }
  }
  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );
  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Open dialog
            </Button> */}
      <Dialog
        //   scroll="body"
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        style={{ borderRadius: "32px" }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Create Subcategory
          <div className="header-type-3">
            Please enter Subcategory name to create a Subcategory.
          </div>
        </DialogTitle>
        <DialogContent>
          <Form>
            <Grid container spacing={2} style={{ margin: "0px" }}>
            {data==2 ? <><Grid item md={12} xs={12}>
                <div className="dis-flex-between">
                  <div className="header-type-3">Upload Subcategory Thumbnail</div>
                  <div>
                    <Files
                      className="files-dropzone"
                      onChange={onFilesChange2}
                      accepts={["image/*"]}
                      // multiple
                      // maxFiles={1}
                      maxFileSize={3000000}
                      minFileSize={0}
                      // onError={true}
                      clickable
                    >
                      <CustomButtonOutlined
                        text="Browse"
                        // onClick={handleClose}
                      />
                    </Files>
                  </div>
                </div>
              </Grid>
              <Grid item md={12} xs={12}>
                {selectedFile2 && (
                  <div
                    className="addBorder dis-flex-between"
                    style={{
                      background: "rgba(161, 152, 180, 0.1)",
                    }}
                  >
                    <div className="dis-inline-row">
                      <div>
                        <img
                          src={selectedFile2.preview.url}
                          alt="notfound"
                          className="table-image image-40 addBorder"
                        ></img>
                      </div>
                      <div className="header-poppins-12">
                        {selectedFile2.name}
                      </div>
                    </div>
                    <IconButton
                      aria-label="close"
                      //   className={classes.closeButton}
                      onClick={handleCloseTab}
                    >
                      <CloseIcon htmlColor="#FFD874" />
                    </IconButton>
                  </div>
                )}
              </Grid></>:null}
              <Grid item xs={12} md={12}>
                <CustomTextField
                  label="Category Name"
                  name="categoryName"
                  value={values.categoryName}
                  onChange={handleInputChange}
                  error={errors.categoryName}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomButtonOutlined text="Cancel" onClick={handleClose} />
              </Grid>

              <Grid item xs={12} md={6}>
                <CustomButtonContained
                  text="Create Category"
                  onClick={() => CreateCategory()}
                />
              </Grid>
            </Grid>
          </Form>
        </DialogContent>

        {/* <DialogActions>
                    <Grid container spacing={2} style={{ margin: "0px" }}>
                        <Grid item xs={12} md={6}>
                            <CustomButtonOutlined text="Cancel" />

                        </Grid>

                        <Grid item xs={12} md={6}>
                            <CustomButtonContained text="Block Artist" />

                        </Grid>
                    </Grid>
                </DialogActions> */}
      </Dialog>
    </div>
  );
}
