import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import UserInformation from "../../components/CardNew/UserInformation";
import {
  CardLocation,
  CardEmail,
  CardSubscribe,
} from "../../components/CardNew/CardLocation";
import CardCategory from "../../components/CardNew/CardCategory";
import { constants } from "../../utility/constants";

export default function ReportedArtistNew(props) {
  console.log(props);
  const { data } = props;
  var Userimagelink = constants.profileURL; //constants.baseURL + "profilefiles";
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <UserInformation
            isBlack
            isGray
            urlImageUser={Userimagelink + data.urlImage}
            userEmail={data.email}
            userName={data.firstName + " " + data.lastName}
            userAlbum={data.albums.length}
            userFollower={data.followed.length}
            userFollowing={data.following.length}
            notshowpic={true}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <CardLocation location={data.fullAddress} />
        </Grid>
        <Grid item md={12} xs={12}>
          <CardEmail email={data.email} />
        </Grid>
        <Grid item md={12} xs={12}>
          <CardCategory
            isTransparent
            isBlack
            name="Type(s)"
            categoriesItem={data.selCategories}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <CardCategory
            isTransparent
            isBlack
            name="Category(s)"
            categoriesItem={data.selCategories}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <CardSubscribe subscribe={data.subscribeYouTubeChannel} />
        </Grid>
        <Grid item md={12} xs={12}>
          <div className="addBorder">
            <p className="header-poppins-12 lineheight-28 ">
              {data.reported.length}
            </p>
            <p className=" header-type-3 lineheight-18">
              People Reported Artist
            </p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
