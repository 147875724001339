import React from "react";
import CustomSkeleton from "./CustomSkeleton";
import { Grid } from "@material-ui/core";

export default function LoaderForLikeBigStagePage() {
  var x = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <Grid container spacing={1}>
      <Grid item md={12} xs={12}>
        <div className="loader">
          {/* 1at code */}

          {x.map((data, key) => {
            return (
              <div
                className="mar-right-10"
                style={{ marginBottom: "10px" }}
                key={key}
              >
                <CustomSkeleton height="150px" width="203px"></CustomSkeleton>
                <div className="mar-top-10">
                  <CustomSkeleton height="20px" width="203px"></CustomSkeleton>
                </div>
                <div
                  className="mar-top-10 dis-flex-between"
                  style={{ width: "203px" }}
                >
                  <div>
                    <CustomSkeleton
                      variant="circle"
                      height="30px"
                      width="40px"
                    ></CustomSkeleton>
                  </div>
                  <br></br>
                  <div>
                    <CustomSkeleton
                      variant="circle"
                      height="30px"
                      width="40px"
                    ></CustomSkeleton>
                  </div>
                  <br></br>
                  <div>
                    <CustomSkeleton
                      variant="circle"
                      height="30px"
                      width="40px"
                    ></CustomSkeleton>
                  </div>
                  <br></br>
                  <div>
                    <CustomSkeleton
                      variant="circle"
                      height="30px"
                      width="40px"
                    ></CustomSkeleton>
                  </div>
                  <br></br>
                </div>
              </div>
            );
          })}
        </div>
      </Grid>
    </Grid>
  );
}
