import { Card, Menu, MenuItem } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MessageIcon from "@material-ui/icons/Message";
import Grid from "@material-ui/core/Grid";
import React, { createContext, useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import classNames from "classnames";
import PropTypes from "prop-types";
import EditIcon from "@material-ui/icons/Edit";
import "./ArtistTypeCard.scss";
import LoaderForLikeBigStagePage from "../SkeletonLoader/LoaderForLikeBigStagePage";
import Fade from "react-reveal/Fade";
import { constants } from "../../utility/constants";
import { patchDataApi } from "../../utility/apiCalling";


// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// // import PodcastPage from "../../pages/PodcastPage/PodcastPage";
// import TabSearchBar from "../SearchBar/TabSearchBar";
// import ArtistVedioDetails from "../../pages/Artist/ArtistVedioDetails";
// import styles from "./ArtistTypeCard.scss";

export default function ArtistTypeCard(props) {
  console.log("ArtistTypeCard: ",props)
  const { isPodCastPage, variant, sourceData, totalcount, callbigstage, showOrderDropdown } = props;
  console.log(sourceData);
  var ChangeCard = false;
  var ChangeImage = false;
  // console.log(variant === "Vedio");

  if (variant === "News" || variant === "Vedio" || variant === "Event") {
    ChangeCard = true;
    ChangeImage = true;
  }
  const card = classNames({
    IsMusicard: variant === "Music " || variant === "News" || variant === "Vedio" || variant === "Event" || variant === "PodCast",
    isvediocard: ChangeCard === true,
    ispodcastcard: variant === "PodCast",
    isPodCastCardPage: isPodCastPage === true,
  });
  const Image = classNames({
    isPodcastImage: variant === "PodCast",
    isPodcastImagetwo: isPodCastPage === true,
    ismusicimage: variant === "Music",
    isVedioImage: ChangeImage === true,
  });
  const orderchange = async (e, i) => {
    let jsonData = {
      id: parseInt(i),
      order: parseInt(e)
    };
    let deleteData = await patchDataApi(
      constants.setbigstageorder,
      jsonData
    );
    console.log()
    if (deleteData.status == 200) {
      callbigstage()
    }
  }

  return (
    <div >
      {sourceData.length > 0 ? (
        <Fade top cascade>
          {/* <LoaderForLikeBigStagePage /> */}


          <div className="ArtistMain type">

            {sourceData.map((prop, key) => {
              return (
                <div
                  key={key}
                //  className="ArtistMain type"
                >
                  {prop.map((selectedData, keyType) => {
                    // {
                    //   //console.log(selectedData, keyType);
                    // }
                    return (

                      <Card key={keyType}>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12}>
                            <div className="isvediocard">
                              <img
                                style={{ cursor: "pointer" }}
                                // onClick={() => handleDataCard(prop.customHandle, key)}
                                onClick={selectedData.image[0].handleImage}
                                src={selectedData.image[0].itemImage}
                                alt="Imgloaderror"
                                className={Image}
                              />

                              <div className="ArtistLabel">
                                <span
                                  className="heading-13 text-wrap"
                                  style={{ width: "177px" }}
                                >
                                  {/* {selectedData.vedioTitle.substring(0, 20)} */}
                                  {/* {selectedData.vedioTitle} */}

                                  {/* {selectedData.vedioTitle === ""
                                  ? ""
                                  : selectedData.vedioTitle.length >= 22
                                  ? selectedData.vedioTitle.substring(0, 22) +
                                    "..."
                                  : selectedData.vedioTitle} */}
                                  {selectedData.vedioTitle === ""
                                    ? ""
                                    : selectedData.vedioTitle}
                                </span>
                                <span
                                  className="subheading-10 text-wrap"
                                  style={{
                                    width: "177px",
                                    lineHeight: "20px",
                                  }}
                                >
                                  {/* {selectedData.vedioTrack} */}
                                  {/* {selectedData.vedioTrack.substring(0, 32)} */}
                                  {selectedData.vedioTrack === null
                                    ? ""
                                    : selectedData.vedioTrack}
                                  {/* {console.log(
                                  selectedData.vedioTrack === null
                                    ? ""
                                    : selectedData.vedioTrack.length >= 30
                                )} */}
                                </span>

                                {selectedData.isAdminManaged ? <div className="dis-flex-between">
                                  {/* {variant === "Event" ? null : (<> */}
                                  <div className="dis-inline-row opacity-50 ">
                                    <VisibilityIcon
                                      fontSize="small"
                                      titleAccess="View(s)"
                                    />
                                    <span className="font-10 mar-left-4">
                                      {selectedData.view}
                                    </span>
                                  </div>
                                  <div className="dis-inline-row opacity-50 ">
                                    <FavoriteIcon
                                      fontSize="small"
                                      style={{ cursor: "pointer" }}
                                      titleAccess="Like(s)"
                                      onClick={selectedData.like[0].handleLike}
                                    />
                                    <span className="font-10 mar-left-4">
                                      {selectedData.like[0].totalLike}
                                    </span>
                                  </div>
                                  <div className="dis-inline-row opacity-50 ">
                                    <MessageIcon
                                      fontSize="small"
                                      style={{ cursor: "pointer" }}
                                      titleAccess="Comment(s)"
                                      onClick={
                                        selectedData.comment[0].handleComment
                                      }
                                    />
                                    <span className="font-10 mar-left-4">
                                      {selectedData.comment[0].totalComment}
                                    </span>
                                  </div>
                                  {/* </> )} */}
                                  {/* {variant !== "Vedio" ? null : ( */}
                                  <div className="dis-inline-row  ">
                                    <EditIcon
                                      fontSize="small"
                                      style={{
                                        cursor: "pointer",
                                        color: "#376BC5",
                                      }}
                                      titleAccess="Edit"
                                      onClick={selectedData.handleEdit}
                                    />
                                    {/* <span className="font-10 mar-left-4">
                                      {selectedData.comment[0].totalComment}
                                    </span> */}
                                  </div>
                                  {/* )} */}
                                  {/* {variant === "Event" ? null : (<> */}
                                  <div className="dis-inline-row  ">
                                    <DeleteIcon
                                      fontSize="small"
                                      style={{
                                        cursor: "pointer",
                                        color: "#D6587A",
                                      }}
                                      titleAccess="Delete"
                                      onClick={selectedData.handleDel}
                                    />
                                    {/* <span className="font-10 mar-left-4">
                                    {selectedData.comment[0].totalComment}
                                  </span> */}
                                  </div>
                                  {/* </>)} */}
                                  <div>
                                    {/* <MoreVertIcon
                                    onClick={selectedData.onMoreVertIcon}
                                    style={{ cursor: "pointer" }}
                                    fontSize="small"
                                    className="yellowcolor"
                                  /> */}
                                    {/* {selectedData.design} */}
                                  </div>
                                </div> : <div className="dis-flex-between">
                                  {variant === "Event" ? null : (<>
                                    <div className="dis-inline-row opacity-50 ">
                                      <VisibilityIcon
                                        fontSize="small"
                                        titleAccess="View(s)"
                                      />
                                      <span className="font-10 mar-left-4">
                                        {selectedData.view}
                                      </span>
                                    </div>
                                    <div className="dis-inline-row opacity-50 ">
                                      <FavoriteIcon
                                        fontSize="small"
                                        style={{ cursor: "pointer" }}
                                        titleAccess="Like(s)"
                                        onClick={selectedData.like[0].handleLike}
                                      />
                                      <span className="font-10 mar-left-4">
                                        {selectedData.like[0].totalLike}
                                      </span>
                                    </div>
                                    <div className="dis-inline-row opacity-50 ">
                                      <MessageIcon
                                        fontSize="small"
                                        style={{ cursor: "pointer" }}
                                        titleAccess="Comment(s)"
                                        onClick={
                                          selectedData.comment[0].handleComment
                                        }
                                      />
                                      <span className="font-10 mar-left-4">
                                        {selectedData.comment[0].totalComment}
                                      </span>
                                    </div>

                                    {/* {variant !== "Vedio" ? null : (
                                    <div className="dis-inline-row  ">
                                      <EditIcon
                                        fontSize="small"
                                        style={{
                                          cursor: "pointer",
                                          color: "#376BC5",
                                        }}
                                        titleAccess="Edit"
                                        onClick={selectedData.handleEdit}
                                      />
                                    </div>
                                  )}  */}

                                    {showOrderDropdown && totalcount
                                      ? <div className="dis-inline-row orderDropDrown">
                                        <select
                                          key={selectedData.id}
                                          className="floating-input floating-select1"
                                          placeholder="Select Order"
                                          name="order"
                                          //style={{ width: "100%" }}
                                          value={selectedData.order}
                                          onChange={(e) => orderchange(e.target.value, selectedData.id)}
                                        >
                                          <option selected value="0">0</option>
                                          {(Array(parseInt(totalcount)).fill().map((element, index) => index + 1)).map((option, key) => (<>
                                            <option key={option} value={option}>
                                              {option}
                                            </option>
                                          </>
                                          ))}

                                        </select>
                                        {/* <span className="font-10 mar-left-4">
                                    {selectedData.comment[0].totalComment}
                                  </span> */}
                                      </div> : null}



                                    <div className="dis-inline-row  ">
                                      <DeleteIcon
                                        fontSize="small"
                                        style={{
                                          cursor: "pointer",
                                          color: "#D6587A",
                                        }}
                                        titleAccess="Delete"
                                        onClick={selectedData.handleDel}
                                      />
                                      {/* <span className="font-10 mar-left-4">
                                    {selectedData.comment[0].totalComment}
                                  </span> */}
                                    </div>
                                  </>)}

                                  <div>
                                    {/* <MoreVertIcon
                                    onClick={selectedData.onMoreVertIcon}
                                    style={{ cursor: "pointer" }}
                                    fontSize="small"
                                    className="yellowcolor"
                                  /> */}
                                    {/* {selectedData.design} */}
                                  </div>
                                </div>}

                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Card>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </Fade>
      ) : (
        <LoaderForLikeBigStagePage />
        // <div style={{ paddingTop: "150px", textAlign: "center" }}>
        //   {" "}
        //   No Data Found
        // </div>
      )}
    </div>
  );
}
ArtistTypeCard.propTypes = {
  isPodcastPage: PropTypes.bool,
  variant: PropTypes.oneOf(["Music", "Vedio", "PodCast", "Event", "News"]),
  sourceData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
};
