import React, { useEffect, useState } from "react";
import CustomTableNew from "../../components/CustomTable/CustomTableNew";
import SearchBar from "../../components/SearchBar/SearchBar";
import { Container, Grid } from "@material-ui/core";
import IconArrowBottom from "../../assets/images/IconArrowBottom.svg";
import { Menu, MenuItem } from "@material-ui/core";
import {
  CustomButtonContained,
  CustomButtonOutlined,
} from "../../components/Controls/CustomButton";
import {
  openConfirmationDeleteDialog,
  openReportedArtistDialog,
} from "../../utility/dialogAppear";
import NavSearchBar from "../../components/SearchBar/NavSearchBar";
import StreetviewIcon from "@material-ui/icons/Streetview";
import DeleteIcon from "@material-ui/icons/Delete";
import { constants } from "../../utility/constants";
import { getdata, patchDataApi } from "../../utility/apiCalling";
import {
  addLoader,
  addSuccessMessage,
  removeLoader,
} from "../../utility/LoadingContent";
import Popup from "../../components/PopUp/Popup";

import ReportedArtistNew from "./ReportedArtistNew";
import reactDom from "react-dom";

export default function ReportedArtist() {
  const [posts, setPosts] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [responseById, setresponseById] = useState([]);
  const [stopLoader, setStopLoader] = useState(true)
  const [showPageNumberButton, setshowPageNumberButton] = useState(false);
  var [artistBlock, setArtistBlock] = useState(false);
  var [page, setPage] = useState(1);
  const [serc, setserc] = useState(null);

  useEffect(() => {
    // document.title = "Artist";
    console.warn("----------------------------useEffect");
    getReportedArtist();
  }, []);

  async function getArtistInfo(id) {
    // addLoader()
    // console.log(constants.user + "/getById/" + id)
    let response = await getdata(constants.user + "/adminGetById/" + id);
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      // removeLoader()
      console.log(response.data);
      setresponseById(response.data);
      setArtistBlock(response.data.status);
      setOpenPopup(true);
      // return openReportedArtistDialog(response.data);
    }
  }
  // ------------------------------------handle Block
  const handleBlock = () => {
    // window.alert("hande block");
    // window.alert(bigBlock);
    {
      artistBlock === true
        ? openConfirmationDeleteDialog(Block, "Block")
        : openConfirmationDeleteDialog(unBlock, "Unblock");
    }
  };

  const unBlock = async () => {
    // window.alert(bigBlock);
    let jsonData = {
      // id: data.id,
      status: 1,
    };
    // console.log(id);
    console.log("handle block----------------------->");
    console.log(constants.user + "/" + responseById.id);
    let blockArtistData = await patchDataApi(
      constants.user + "/" + responseById.id,
      jsonData
    );
    if (blockArtistData.status === 200 || blockArtistData.statusText === "OK") {
      setOpenPopup(false);
      addSuccessMessage("Artist Unblock");
      getReportedArtist();
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
    }
  };
  const Block = async () => {
    let jsonData = {
      status: 0,
    };
    console.log("handle block----------------------->");
    console.log(constants.user + "/" + responseById.id);
    let blockArtistData = await patchDataApi(
      constants.user + "/" + responseById.id,
      jsonData
    );
    if (blockArtistData.status === 200 || blockArtistData.statusText === "OK") {
      setArtistBlock(true);
      setOpenPopup(false);

      addSuccessMessage("Artist Block");
      getReportedArtist();
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };

  // --------------------getReportedArtist
  async function getReportedArtist() {
    console.log("get Reported Artist");
    let response = await getdata(
      constants.adminreportedArtist + "/getAll?page=" + page + "&limit=10"
    );
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      console.log(response)
      if (response.data.data.length !== 0) {
        if (response.data.data.length === 10) {
          setshowPageNumberButton(true);
        }
        return setPosts(response.data);

      } else {
        setPosts(response.data);
        return setStopLoader(false);
      }
      // if (response.data.data.length !== 0) {
      //   return setPosts(response.data);

      // } else {
      //   setPosts(response.data);
      //   return setStopLoader(false)
      // }
      // return setPosts(response.data);
    }
  }
  const sendvalue = (i) =>{
    console.log(i)
    setserc(i)
    setPage(1)
    getNextPage(1,i);

  }
  const nextPage = () => {
    setPage(page + 1);
    getNextPage(page + 1);
  };

  // console.log("--------------------page" + page);
  async function getNextPage(p,ser) {
    let apiURL = constants.adminreportedArtist + "/getAll?page=" + p + "&limit=10"
    if (ser) {
      apiURL += "&searchText=" + ser
    }
    let response = await getdata(
      apiURL
    );
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      // console.log("--------------------> next page");
      // console.log(response);
      if (response.data.data.length !== 0) {
        if ((response.data.limit*response.data.page)<response.data.totalCount) {
          setshowPageNumberButton(true);
        }else{
          setshowPageNumberButton(false);
        }
        if(p==1){
          setPosts(response.data);
        }else{
          setPosts((prevState) => {
          // console.log("---------------------------------------------------------andar aa gya")
          return {
            ...prevState,
            // totalCount: prevState.totalCount + response.data.totalCount,
            data: [...prevState.data, ...response.data.data],
          };
        });
      }
      } else {
        return setshowPageNumberButton(false);
      }
    }
  }

  const data = (posts) => {
    // console.log("----------->users posts")
    // console.log(posts)
    if (posts !== undefined && posts.length !== 0) {
      var formatted = posts.data.map((item) => {
        // console.log(item)
        // console.log(item.id)
        var imagelink = constants.profileURL; //constants.baseURL + "profilefiles";
        return [
          <div style={{ display: "flex" }} key={item.id.toString()}>
            <img
              src={item.artist.urlImage !== undefined && item.artist.urlImage !== null 
                && item.artist.urlImage !== "" && item.artist.urlImage !== "undefined" ? imagelink + item.artist.urlImage : constants.userImageURL}
              alt={item.artist.firstName + " " + item.artist.lastName}
              className="table-image"
            />
            <div>
              {" " + item.artist.firstName + " " + item.artist.lastName}
            </div>
          </div>,
          item.comment === null ? "" : item.comment,
          // item.artist.email,
          // item.artist.fullAddress,
          item.reporteduser.firstName + " " + item.reporteduser.lastName,
          // item.reporteduser.subscribeYouTubeChannel === true ? <p>Yes</p> : <p>No</p>,
          item.artist.status === true ? (
            <span style={{ color: "rgb(76, 196, 124)" }}>Active</span>
          ) : (
            <span style={{ color: "rgb(214, 88, 122)" }}>Inactive</span>
          ),
          item.artist.usertype === "Artist" ? (
            <p style={{ color: "#376BC5" }}>{item.artist.usertype}</p>
          ) : (
            <p style={{ color: "#BF8643" }}>{item.artist.usertype}</p>
          ),
          <div>
            <span>
              <StreetviewIcon
                fontSize="small"
                titleAccess="view"
                style={{
                  cursor: "pointer",
                  color: "#376BC5",
                  marginRight: "10px",
                }}
                onClick={() => getArtistInfo(item.artist.id)}
              />
            </span>
            <span>
              {/* <DeleteIcon fontSize="small" style={{ cursor: "pointer", color: "#D6587A", }} titleAccess="Delete"
          /> */}
            </span>
          </div>,
        ];
      });
      return formatted;
    } else return [];
  };
  return (
    <>
      <div className="main-window">
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <NavSearchBar title="Reported Artist" />
          </Grid>
          <Grid item md={12} xs={12}>
            <SearchBar
              title={
                posts.data === undefined
                  ? "0 Artist(s)"
                  : posts.data.length + " Artist(s)"
              }
            sendvalue={sendvalue}

            ></SearchBar>
            {/* <SearchBar title={
              posts.length + " Artist(s)"
            } ></SearchBar> */}
          </Grid>
          <Grid item md={12} xs={12}>
            {stopLoader === true ? <CustomTableNew
              tableHead={[
                "Name",
                // "Email",
                // "Address",
                "Reported Comments",
                "Reported By",
                "Status",
                "Type",
                "Action",
              ]}
              tableData={data(posts)}
            /> : <p style={{ paddingTop: "150px", textAlign: "center" }}>
              No data found
            </p>}

            {/* <Grid item md={12} xs={12}>
              <div style={{ textAlign: "-webkit-center" }}>
                {posts !== undefined && posts.length !== 0 ? (
                  <div>
                    {posts.data.length === 10 ? (
                      <div>
                        <Grid item md={12} style={{ width: "20%" }}>
                          <CustomButtonOutlined
                            text={"view More "}
                            onClick={() => nextPage()}
                          />
                        </Grid>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Grid> */}
          </Grid>
          <Grid item md={12} xs={12}>
            <div style={{ textAlign: "-webkit-center" }}>
              {showPageNumberButton && (
                <div>
                  <Grid item md={12} style={{ width: "20%" }}>
                    <CustomButtonOutlined
                      text={"view More "}
                      onClick={() => nextPage()}
                    />
                  </Grid>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        <Popup
          openPopup={openPopup}
          buttonText={artistBlock === true ? "Block" : "UnBlock"}
          title="Artist Info"
          setOpenPopup={setOpenPopup}
          buttonClick={() => handleBlock()}
        >
          {/* <ReportedArtistDialog data={responseById} /> */}
          <ReportedArtistNew data={responseById} />
        </Popup>
        {/* <ReportedArtistDialog openPopup={openPopup} setOpenPopup={setOpenPopup} ></ReportedArtistDialog> */}
      </div>
    </>
  );
}
