import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CardCategory from "../../components/CardNew/CardCategory";
import CardMore from "../../components/CardNew/CardMore";
import {
  CommentCard,
  ReportedCard,
  ViewCard,
  LikeCard,
} from "../../components/CardNew/CommonCards";
import UserInformation from "../../components/CardNew/UserInformation";
import PodcastSong from "../../components/Song/PodcastSong";
import NavSearchBar from "../../components/SearchBar/NavSearchBar";
import {
  openCommentDialog,
  openConfirmationDeleteDialog,
  openLikeDialog,
  openReportDialog,
  openViewDialog,
} from "../../utility/dialogAppear";
import { deleteDataApi, getdata, patchDataApi } from "../../utility/apiCalling";
import ListPodcast from "../../components/ListPodcast/ListPodcast";
import { constants } from "../../utility/constants";
import moment from "moment";
import reactDom from "react-dom";
import {
  addErrorMessage,
  addLoader,
  addSuccessMessage,
  removeLoader,
} from "../../utility/LoadingContent";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  CustomButtonContained,
  CustomButtonOutlined,
} from "../../components/Controls/CustomButton";
import LoaderforPodcast from "../../components/SkeletonLoader/LoaderforPodcast";

export default function PodcastPageDetails(props) {
  console.log(props);
  const { podcastId } = props.location.state;
  let history = useHistory();
  const [podcastUser, setPodcastUser] = useState([]);
  var [podcastBlock, setPodcastBlock] = useState(null);
  var imagelink = constants.podcastFileURL; //constants.baseURL + "podcastfiles/";
  var Userimagelink = constants.profileURL; //constants.baseURL + "profilefiles";

  useEffect(() => {
    getPodcastUser(props.location.state.podcastId);
    // getPodcastUser(2);
  }, []);

  async function getPodcastUser(userId) {
    console.log(constants.getPodcastURL + "/getById/" + userId);
    let response = await getdata(
      constants.getPodcastURL + "/getById/" + userId
    );

    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      console.log(response.data.data);
      setPodcastBlock(response.data.data.podcasts_status);
      return setPodcastUser(response.data.data);
    }
  }
  const handleBlock = () => {
    // window.alert("hande block");
    // window.alert(bigBlock);
    {
      podcastBlock === 1
        ? openConfirmationDeleteDialog(Block, "Block")
        : openConfirmationDeleteDialog(unBlock, "Unblock");
    }
  };

  const unBlock = async () => {
    // window.alert(bigBlock);
    let jsonData = {
      status: 1,
    };
    // console.log(id);
    console.log("handle block----------------------->");
    console.log(constants.getPodcastURL + "/" + podcastUser.podcasts_id);
    let blockPodcastData = await patchDataApi(
      constants.getPodcastURL + "/" + podcastUser.podcasts_id,
      jsonData
    );
    if (
      blockPodcastData.status === 200 ||
      blockPodcastData.statusText === "OK"
    ) {
      // console.log("User Successfully Block");
      // setBigBlock(0);

      setPodcastBlock(0);
      // window.alert(bigBlock);
      getPodcastUser(props.location.state.podcastId);

      addSuccessMessage("Podcast UnBlocked");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      // console.log("User Not  successfully Deleted");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };
  const Block = async () => {
    // window.alert(bigBlock);
    let jsonData = {
      status: 0,
    };
    // console.log(id);
    console.log("handle block----------------------->");
    console.log(constants.getPodcastURL + "/" + podcastUser.podcasts_id);
    let blockPodcastData = await patchDataApi(
      constants.getPodcastURL + "/" + podcastUser.podcasts_id,
      jsonData
    );
    if (
      blockPodcastData.status === 200 ||
      blockPodcastData.statusText === "OK"
    ) {
      // console.log("User Successfully Block");
      setPodcastBlock(1);
      // window.alert(bigBlock);
      getPodcastUser(props.location.state.podcastId);
      addSuccessMessage("Podcast Blocked");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      // console.log("User Not  successfully Deleted");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };

  const handleRemove = () => {
    openConfirmationDeleteDialog(remove, "Remove");
  };
  const remove = async () => {
    let jsonData = {
      isDeleted: 1,
    };
    let deleteData = await patchDataApi(
      constants.getPodcastURL + "/" + props.location.state.podcastId,
      jsonData
    );
    // console.log(props)
    if (deleteData.status === 200 || deleteData.statusText === "OK") {
      addSuccessMessage("Podcast Delete");
      history.goBack()
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      addErrorMessage("Podcast Not Delete");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
    //   let removePodcast = await deleteDataApi(
    //     constants.getPodcastURL + "/" + props.location.state.id
    //   );
    //   if (removePodcast.status === 200 && removePodcast.statusText === "OK") {
    //     addSuccessMessage("Podcast Remove Successfully");
    //     history.push("podcast");
    //     reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    //   } else {
    //     // console.log("User Not  successfully Deleted");
    //     reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    //   }
  };

  // const handleReport = (data) => {
  //   // window.alert("report");
  //   openReportDialog(data);
  // };
  const handleReport = (data) => {
    // window.alert("report");
    const ReportData = getReportData(data);
    openReportDialog(ReportData);
  };
  const getReportData = (dataItem) => {
    console.log(dataItem);
    if (dataItem.length !== 0 && dataItem.length !== undefined) {
      let formattedData = dataItem.map((reportedData, key) => {
        return [
          {
            reportDate: reportedData.reportpodcasts_created,
            reportMessage: reportedData.reportpodcasts_comment,
            userImage: reportedData.user.urlImage,
            userName:
              reportedData.user.firstName + " " + reportedData.user.lastName,
            userPhone: reportedData.user.phone,
          },
        ];
      });
      return formattedData;
    } else {
      return [];
    }
    //  openCommentDialog(data);
  };
  const handleView = (data) => {
    openViewDialog(data);
  };
  // const handleComment = (data) => {
  //   openCommentDialog(data);
  // };
  const handleComment = (data) => {
    const commentData = getCommentData(data);
    openCommentDialog(commentData);
  };
  const getCommentData = (dataItem) => {

    console.log(dataItem);
    if (dataItem.length !== 0 && dataItem.length !== undefined) {
      let formattedData = dataItem.map((commentData, key) => {
        return [
          {
            commentDate: commentData.podcastcomments_created,
            commentMessage: commentData.podcastcomments_comment,
            userImage: commentData.user.urlImage,
            userName:
              commentData.user.firstName + " " + commentData.user.lastName,
            userPhone: commentData.user.phone,
          },
        ];
      });
      return formattedData;
    } else {
      return [];
    }
    //  openCommentDialog(data);
  };





  const handleLike = (data) => {
    openLikeDialog(data);
  };
  if (podcastUser.length !== 0) {

    return (
      <div className="main-window">

        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <NavSearchBar title="Podcast" />
          </Grid>
          <Grid item md={8} xs={12}>
            {/* <PodcastSong /> */}
            <ListPodcast
              podcastImage={
                podcastUser.podcasts_urlImage === ""
                  ? constants.musicURL
                  : imagelink + podcastUser.podcasts_urlImage
              }
              podcastName={podcastUser.podcasts_name}
              podcastUserName={
                podcastUser.user.firstName + " " + podcastUser.user.lastName
              }
              podcastDate={moment(podcastUser.podcasts_created).format(
                "DD MMM yyyy"
              )}
              podcastDescription={podcastUser.podcasts_description}
            />
          </Grid>
          <Grid item md={4}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <UserInformation
                  urlImageUser={Userimagelink + podcastUser.user.urlImage}
                  userEmail={podcastUser.user.email}
                  userName={
                    podcastUser.user.firstName + " " + podcastUser.user.lastName
                  }
                  userAlbum={podcastUser.user.albums.length}
                  userFollower={podcastUser.user.followed.length}
                  userFollowing={podcastUser.user.following.length}
                />
              </Grid>
              <Grid item md={12}>
                <CardCategory
                  name="Category(s)"
                  isTransparent
                  isGray
                  categoriesItem={podcastUser.categories}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <div
                  // onClick={() => handleView()}
                  style={{ cursor: "pointer" }}
                >
                  <ViewCard />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div
                  onClick={() => handleLike(podcastUser.likes)}
                  style={{ cursor: "pointer" }}
                >
                  <LikeCard totalLike={podcastUser.likes.length} />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div
                  onClick={() => handleComment(podcastUser.comments)}
                  style={{ cursor: "pointer" }}
                >
                  <CommentCard totalComment={podcastUser.comments.length} />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div
                  onClick={() => handleReport(podcastUser.reported)}
                  style={{ cursor: "pointer" }}
                >
                  <ReportedCard totalReport={podcastUser.reported.length} />
                </div>
              </Grid>
              {/* <Grid item md={6}>
                                <CardMore />
                            </Grid> */}
              <Grid item xs={12} md={6}>
                <CustomButtonOutlined
                  onClick={() => {
                    handleRemove();
                  }}
                  text="remove"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomButtonContained
                  onClick={() => {
                    handleBlock();
                  }}
                  text={podcastBlock === 1 ? "Block" : "UnBlock"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return <div className="main-window"><LoaderforPodcast /></div>;
  }
}
