import { Container, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ArtistTypeCard from "../../components/ArtistTypeCard/ArtistTypeCard";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Menu, MenuItem } from "@material-ui/core";
import {
  GreenCard,
  OrangeCard,
  PinkCard,
} from "../../components/CardNew/CommonCards";
import NavSearchBar from "../../components/SearchBar/NavSearchBar";
import SearchBar from "../../components/SearchBar/SearchBar";
import { deleteDataApi, getdata, patchDataApi, postDataApi } from "../../utility/apiCalling";
import { constants } from "../../utility/constants";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import {
  CustomButtonContained,
  CustomButtonOutlined,
} from "../../components/Controls/CustomButton";
import {
  openCommentDialog,
  openConfirmationDeleteDialog,
  openLikeDialog,
  openVedioDialog,
  openVedioUpdateDialog,
  openViewDialog,
} from "../../utility/dialogAppear";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  addErrorMessage,
  addSuccessMessage,
} from "../../utility/LoadingContent";
import reactDom from "react-dom";
import { NoMoreDataFound } from "../../components/NoDataFound/NoDataFound";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  //console.log(props);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          p={2}
          className="bg-black"
          style={{ padding: "10px 0px 10px 0px" }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    minWidth: "0px !important",
  },
}));
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function BigStagePage() {
  const [bigStageData, setBigStageData] = useState([]);
  const [bigStageData1, setBigStageData1] = useState([]);
  const [bigStageData2, setBigStageData2] = useState([]);
  const [commentDataById, setcommentDataById] = useState([]);
  const [nextPageNotFound, setnextPageNotFound] = useState(false);
  const [stopLoader, setStopLoader] = useState(true);
  const [showPageNumberButton, setshowPageNumberButton] = useState(false);
  var [page, setPage] = useState(1);
  var [page1, setPage1] = useState(1);
  var [page2, setPage2] = useState(1);
  const [limit, setLimit] = useState(10);
  const [value, setValue] = React.useState(0);
  const [serch, setserch] = useState("");

  const [randomCount, setRandomCount] = React.useState(0);
  

  const handleChange = async (event, newValue) => {
    if(newValue === 0){
      await getBigStage(1);
    }
    // if(newValue === 1){
    //   await getTop10Bigstage();
    // }
    // if(newValue === 2){
    //   await getTop20Bigstage();
    // }
    console.log(newValue)
    setValue(newValue);

  };
  // var x = 1;
  const jsonData = [];
  
  
  let history = useHistory();
  // -------------------------menu button
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const sendvalue = (i) =>{
    setserch(i)
    setPage(1)
    if(value==1)
    {
      getTop10Bigstage(i)
    }
    if(value==2)
    {
      getTop10Bigstage(i)
    }
    getNextPage(1,i)
  }
  const handleClose = () => {
    setAnchorEl(null);
  };
  // ----------------------------menu Button
  // -------------------------handle Sort
  const handleSort = async () => {
    let sortBigStage = await getdata(constants.getBigStage + "?sort=id,DESC");
    //console.log("---------------------------------->");
    //console.log(sortBigStage.data);
    addSuccessMessage("Sort Successfully");

    // setBigStageData((prevState) => ({
    //   ...prevState,
    //   bigstagetitle: response.data.name,
    // }));
    return setBigStageData(sortBigStage.data);
  };
  // --------------------view Button function
  const handleView = (key) => {
    //console.log("--- Handle view--- ", key);
    //let data = value == 0 ? bigStageData.data[key] : value == 1 ? bigStageData1.data[key] : bigStageData2.data[key];
    let data = value == 0 ? bigStageData.data[key] : value == 1 ? bigStageData1[key] : bigStageData2[key];
    //console.log("view-------------------------------");
    // window.alert(key);
    //console.log(data);

    // console.log(bigStageData[id]);

    history.push({
      pathname: `bigstage-details/${data.bigstage_id}`,
      state: {
        id: data.bigstage_id,
      },
    });
  };
  //
  const handleEdit = (id) => {
    // window.alert(id);
    openVedioUpdateDialog(id);
  };
  //--------------------Delete Button Function
  const handleDelete = (id) => {
    // window.alert(id);
    openConfirmationDeleteDialog(() => {
      deleteBig(id);
    }, "Delete");
  };
  const deleteBig = async (id) => {
    let jsonData = {
      isDeleted: 1,
    };
    let deleteData = await patchDataApi(
      constants.getBigStage + "/" + id,
      jsonData
    );
    if (deleteData.status === 200 || deleteData.statusText === "OK") {
      addSuccessMessage("Big Stage Delete");
      setPage(1);
      getBigStage(1);
      getTop10Bigstage();
      getTop20Bigstage();
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      addErrorMessage("Big Stage Not Delete");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
    // let deleteBigStage = await deleteDataApi(constants.getBigStage + "/" + id);
    // if (deleteBigStage.status === 200 && deleteBigStage.statusText === "OK") {
    //   // console.log("User Successfully Deleted");
    //   addSuccessMessage("Big Stage Delete Successfully");
    //   getBigStage(1);
    //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    // } else {
    //   console.log("User Not  successfully Deleted");
    //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    // }
  };

  const getBigStage = async (page) => {
    //console.log("getBigStage - URL--", constants.getBigStage + "/getAll?page=" + page + "&limit="+limit);
    let response = await getdata(
      constants.admingetBigStage + "/getAll?page=" + page + "&limit="+limit
    );
    console.log(response)
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      //console.log("---getBigStage---", response.data);
      if (response.data.data.length !== 0) {
        if ((response.data.limit*response.data.page)<response.data.totalCount) {
          setshowPageNumberButton(true);
        }
        setBigStageData(response.data);
        //console.log("All bigstate done")
      } else {
        setBigStageData([]);//setBigStageData(response.data);
        // setStopLoader(false);
        //console.log("no bigstage data")
      }
    }
    setRandomCount(randomCount + 1);
  }
  const getTop10Bigstage = async (ser) => {
    // let response = await getdata(
    //   constants.admingetBigStage + "/getTop10Bigstage"
    // );
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const jsonData = {
      searchText:ser?ser:""
    };
    let response = await postDataApi(
      constants.admingetBigStage + "/getTop10Bigstage",
      jsonData
    );
    console.log(response)
    if (
      response.request.status === 201 ||
      response.request.statusText === "Created"
    ) {
      // console.log("hello");
      console.log("---getTop10Bigstage---", response);
      if (response.data.length !== 0) {
        // return setPosts(response.data);
        // if (response.data.length === 10) {
          // setshowPageNumberButton(false);
        // }
        setBigStageData1(response.data);
        //console.log("top 10 bigstage data")
        setStopLoader(true);
      } else {
        setBigStageData1([]);
        // setStopLoader(false);
        //console.log("no top 10 bigstage data")
      }
    }
    setRandomCount(randomCount + 1);
    //console.log("Top 10 done----")
  }
  const getTop20Bigstage = async (ser) =>{
    // let response = await getdata(
    //   constants.getBigStage + "/getTop20Bigstage"
    // );
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const jsonData = {
      searchText:ser?ser:""
    };
    let response = await postDataApi(
      constants.admingetBigStage + "/getTop20Bigstage",
      jsonData
    );
    if (
      response.request.status === 201 ||
      response.request.statusText === "Created"
    ) {
      // console.log("hello");
      //console.log("---getTop20Bigstage---", response.data);
      if (response.data.length !== 0) {
        // return setPosts(response.data);
        // if (response.data.length === 20) {
        //   setshowPageNumberButton(true);
        // }
        setBigStageData2(response.data);
        //console.log("top 20 bigstage data")
        setStopLoader(true);
      } else {
        setBigStageData2([]);//setBigStageData2(response.data);
        // setStopLoader(false);
        //console.log("no top 20 bigstage data")
      }
    }
    setRandomCount(randomCount + 1);
  }
  const getCommentData = (key) => {
    let data = value == 0 ? bigStageData.data[key].comments : value == 1 ? bigStageData1.data[key].comments : bigStageData2.data[key].comments;
    //console.log(data);
    if (data.length !== 0 && data.length !== undefined) {
      let formattedData = data.map((commentData, key) => {
        return [
          {
            commentDate: commentData.bigstagecomments_created,
            commentMessage: commentData.bigstagecomments_comment,
            userImage: commentData.user.urlImage,
            userName:
              commentData.user.firstName + " " + commentData.user.lastName,
            userPhone: commentData.user.phone,
          },
        ];
      });
      return formattedData;
    } else {
      return [];
    }
    //  openCommentDialog(data);
  };

  const handleComment = (key) => {
    const data = getCommentData(key);
    openCommentDialog(data);
  };

  const handleLike = (key) => {
    // window.alert("handle like");
    let data = value == 0 ? bigStageData.data[key].likes : value == 1 ? bigStageData1.data[key].likes : bigStageData2.data[key].likes;
    openLikeDialog(data);
    // openViewDialog(data);
  };

  const nextPage = () => {
    setPage(page + 1);
    getNextPage(page + 1,serch);
  };
 
  const getNextPage = async (ty,ser) => {
    //console.log(constants.getBigStage + "/getAll?page=" + ty + "&limit="+limit);
    let apiURL = constants.admingetBigStage + "/getAll?page=" + ty + "&limit="+limit
    if (ser) {
      apiURL += "&searchText=" + ser
    }
    let response = await getdata(
      apiURL
      
    );
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      //console.log("--------------------> next page");
      console.log(response);
      if (response.data.data.length !== 0) {
        if ((response.data.limit*response.data.page)<response.data.totalCount ) {
          console.log("fdgdf")
          setshowPageNumberButton(true);
        }else{
          setshowPageNumberButton(false);

        }
        if(ty==1){
          setBigStageData(response.data)
        }else{
          setBigStageData((prevState) => {
            return {
              ...prevState,
              // totalCount: prevState.totalCount + response.data.totalCount,
              data: [...prevState.data, ...response.data.data],
            };
          });
        }
        // console.log(stop)
        
      } else {
        // setshowPageNumberButton(false);
        return setshowPageNumberButton(false);
      }
      // return setBigStageData(response.data);
    }
  }
  const allBigstageData = (bigStageData) => {
    console.log("----DATA----- ", bigStageData);
    if (bigStageData !== undefined && bigStageData.length !== 0) {
      var formatted = bigStageData.data.map((items, key) => {
        //console.log("DATA: ", key);
        var imagelink = constants.bigStageThumbnail; //constants.baseURL + "bigstagethumbnailfiles/";
        return [
          {
            id: items.bigstage_id,
            // image: imagelink + items.bigstage_thumbnailURL,
            image: [
              {
                // itemImage: imagelink + items.bigstage_thumbnailURL,
                itemImage:
                  items.bigstage_thumbnailURL === ""
                    ? constants.videoURL
                    : imagelink + items.bigstage_thumbnailURL,
                handleImage: () => handleView(key),
              },
            ],
            vedioTitle: items.bigstage_name.substring(0, 30),
            // imageTagName: "Active",
            vedioTrack:
              items.user !== undefined
                ? items.user.firstName + " " + items.user.lastName
                : "",
            view: "0",
            like: [
              {
                totalLike: items.likes.length,
                handleLike: () => handleLike(key),
              },
            ],
            comment: [
              {
                totalComment: items.comments.length,
                handleComment: () => handleComment(key),
              },
            ],
            order: items.bigstage_order,
            handleEdit: () => handleEdit(items.bigstage_id),
            handleDel: () => handleDelete(items.bigstage_id),
          },
        ];
      });
      return formatted;
    } else return [];
  };
  const top10BigstageData = (bigStageData) => {
    //console.log("---top10BigstageData---- ", bigStageData);
    if (bigStageData !== undefined && bigStageData.length !== 0) {
      var formatted = bigStageData.map((items, key) => {
        var imagelink = constants.bigStageThumbnail;//constants.baseURL + "bigstagethumbnailfiles/";
        return [
          {
            id: items.bigstage_id,
            // image: imagelink + items.bigstage_thumbnailURL,
            image: [
              {
                // itemImage: imagelink + items.bigstage_thumbnailURL,
                itemImage:
                  items.bigstage_thumbnailURL === ""
                    ? constants.videoURL
                    : imagelink + items.bigstage_thumbnailURL,
                handleImage: () => handleView(key),
              },
            ],
            vedioTitle: items.bigstage_name.substring(0, 30),
            // imageTagName: "Active",
            vedioTrack:
              items.user !== undefined
                ? items.user.firstName + " " + items.user.lastName
                : "",
            view: "0",
            like: [
              {
                totalLike: items.likes.length,
                handleLike: () => handleLike(key),
              },
            ],
            comment: [
              {
                totalComment: items.comments.length,
                handleComment: () => handleComment(key),
              },
            ],
            order: items.bigstage_order,
            handleEdit: () => handleEdit(items.bigstage_id),
            handleDel: () => handleDelete(items.bigstage_id),
          },
        ];
      });
      return formatted;
    } else return [];
  };
  const top20BigstageData = (bigStageData) => {
    //console.log("---top10BigstageData---- ", bigStageData);
    if (bigStageData !== undefined && bigStageData.length !== 0) {
      var formatted = bigStageData.map((items, key) => {
        var imagelink = constants.bigStageThumbnail;//constants.baseURL + "bigstagethumbnailfiles/";
        return [
          {
            id: items.bigstage_id,
            // image: imagelink + items.bigstage_thumbnailURL,
            image: [
              {
                // itemImage: imagelink + items.bigstage_thumbnailURL,
                itemImage:
                  items.bigstage_thumbnailURL === ""
                    ? constants.videoURL
                    : imagelink + items.bigstage_thumbnailURL,
                handleImage: () => handleView(key),
              },
            ],
            vedioTitle: items.bigstage_name.substring(0, 30),
            // imageTagName: "Active",
            vedioTrack:
              items.user !== undefined
                ? items.user.firstName + " " + items.user.lastName
                : "",
            view: "0",
            like: [
              {
                totalLike: items.likes.length,
                handleLike: () => handleLike(key),
              },
            ],
            comment: [
              {
                totalComment: items.comments.length,
                handleComment: () => handleComment(key),
              },
            ],
            order: items.bigstage_order,
            handleEdit: () => handleEdit(items.bigstage_id),
            handleDel: () => handleDelete(items.bigstage_id),
          },
        ];
      });
      return formatted;
    } else return [];
  };
  const OpenVedioHandle = () => {
    openVedioDialog();
  };
  
  useEffect(() => {
    getBigStage(page);
    getTop10Bigstage();
    getTop20Bigstage();
  }, []);
  return (
    <div className="main-window">
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <NavSearchBar title="Big Stage" />
        </Grid>
        <Grid item xs={6} md={3}>
          <GreenCard
            totalGreen={bigStageData.activeCount}
            title="Active Big Stage"
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <PinkCard
            totalPink={bigStageData.reportedCount}
            title="Reported Big Stage"
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <OrangeCard
            totalBlocked={bigStageData.inactiveCount}
            title="Blocked Big Stage"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="All BigStage" {...a11yProps(0)} />
                <Tab label="Top 10 BigStage" {...a11yProps(1)} />
                <Tab label="Top 20 BigStage" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Grid item md={12} xs={12}>
                <SearchBar
                  title={
                    bigStageData.totalCount === undefined
                      ? "0 Bigstage(s)"
                      : bigStageData.totalCount + " BigStage(s)"
                  }
                  // handleSort={handleSort}
                  showVideoButton
                  handleVedio={OpenVedioHandle}
                  sendvalue={sendvalue}
                ></SearchBar>
              </Grid>
              <Grid item md={12} xs={12}>
                {stopLoader === true && bigStageData.totalCount > 0 ? (
                  <ArtistTypeCard
                    isVedio
                    variant="Vedio"
                    sourceData={allBigstageData(bigStageData)}
                    totalcount={bigStageData.totalCount}
                    callbigstage={() => getBigStage(1)}
                    showOrderDropdown={false}
                  ></ArtistTypeCard>
                ) : (
                  <p style={{ paddingTop: "150px", textAlign: "center" }}>
                    No data found
                  </p>
                )}
                <Grid item md={12} xs={12}>
                  <div style={{ textAlign: "-webkit-center" }}>
                    {showPageNumberButton && (
                      <div>
                        <Grid item md={12} style={{ width: "20%" }}>
                          <CustomButtonOutlined
                            text={"view More "}
                            onClick={() => nextPage()}
                          />
                        </Grid>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid item md={12} xs={12}>
                <SearchBar
                  title={
                    bigStageData1?.length === undefined
                      ? "0 Bigstage(s)"
                      : bigStageData1?.length + " BigStage(s)"
                  }
                  // handleSort={handleSort}
                  showVideoButton
                  handleVedio={OpenVedioHandle}
                  sendvalue={sendvalue}   
                ></SearchBar>
              </Grid>
              <Grid item md={12} xs={12}>
                {stopLoader === true && bigStageData1?.length > 0? (
                  <ArtistTypeCard
                    isVedio
                    variant="Vedio"
                    sourceData={top10BigstageData(bigStageData1)}
                    totalcount={bigStageData1?.length}
                    callbigstage={() => getTop10Bigstage()}
                    showOrderDropdown={true}
                  ></ArtistTypeCard>
                ) : (
                  <p style={{ paddingTop: "150px", textAlign: "center" }}>
                    No data found
                  </p>
                )}
                {/* <Grid item md={12} xs={12}>
                  <div style={{ textAlign: "-webkit-center" }}>
                    {showPageNumberButton && (
                      <div>
                        <Grid item md={12} style={{ width: "20%" }}>
                          <CustomButtonOutlined
                            text={"view More "}
                            onClick={() => nextPage()}
                          />
                        </Grid>
                      </div>
                    )}
                  </div>
                </Grid> */}
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Grid item md={12} xs={12}>
                <SearchBar
                  title={
                    bigStageData2.length === undefined
                      ? "0 Bigstage(s)"
                      : bigStageData2.length + " BigStage(s)"
                  }
                  // handleSort={handleSort}
                  showVideoButton
                  handleVedio={OpenVedioHandle}
                  sendvalue={sendvalue}

                ></SearchBar>
              </Grid>
              <Grid item md={12} xs={12}>
                {stopLoader === true &&  bigStageData2.length > 0? (
                  <ArtistTypeCard
                    isVedio
                    variant="Vedio"
                    sourceData={top20BigstageData(bigStageData2)}
                    totalcount={bigStageData2.length}
                    callbigstage={() => getTop20Bigstage()}
                    showOrderDropdown={true}
                  ></ArtistTypeCard>
                ) : (
                  <p style={{ paddingTop: "150px", textAlign: "center" }}>
                    No data found
                  </p>
                )}
                {/* <Grid item md={12} xs={12}>
                  <div style={{ textAlign: "-webkit-center" }}>
                    {showPageNumberButton && (
                      <div>
                        <Grid item md={12} style={{ width: "20%" }}>
                          <CustomButtonOutlined
                            text={"view More "}
                            onClick={() => nextPage()}
                          />
                        </Grid>
                      </div>
                    )}
                  </div>
                </Grid> */}
              </Grid>
            </TabPanel>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
