import reactDom from "react-dom";
import UserDetails from "../components/UserDetails/UserDetails";
import VedioUpload from "../components/VedioUpload/VedioUpload";
import VedioUpdate from "../components/VedioUpload/VedioUpdate";
import ViewComponent from "../components/ViewComponent/ViewComponent";
import ReportedComponent from "../components/ReportedComponent/ReportedComponent";
import CommentComponent from "../components/CommentComponent/CommentComponent";

import CategoryEditDialog from "../components/CategoryEditDialog/CategoryEditDialog";
import LikeComponent from "../components/ViewComponent/LikeComponent";
import ConfirmationDeleteDialog from "../components/ConfirmationDeleteDialog/ConfirmationDeleteDialog";

import AddCategoryDialog from "../components/AddCategoryDialog";
import AddCountryDialog from "../components/AddCountryDialog";
import CountryEditDialog from "../components/CountryEditDialog";
export function UserInformation(data) {
  reactDom.render(
    <UserDetails
      data={data}
      renderElement={document.getElementById("dialogcontent")}
    />,
    document.getElementById("dialogcontent")
  );
}

export function openVedioDialog(data) {
  reactDom.render(
    <VedioUpload
      data={data}
      renderElement={document.getElementById("dialogcontent")}
    />,
    document.getElementById("dialogcontent")
  );
}
export function openVedioUpdateDialog(data) {
  reactDom.render(
    <VedioUpdate
      data={data}
      renderElement={document.getElementById("dialogcontent")}
    />,
    document.getElementById("dialogcontent")
  );
}
export function openConfirmationDeleteDialog(data, text) {
  reactDom.render(
    <ConfirmationDeleteDialog
      handleSubmit={data}
      text={text}
      renderElement={document.getElementById("dialogcontent")}
    />,
    document.getElementById("dialogcontent")
  );
}
export function openViewDialog(data) {
  reactDom.render(
    <ViewComponent
      data={data}
      renderElement={document.getElementById("dialogcontent")}
    />,
    document.getElementById("dialogcontent")
  );
}
export function openLikeDialog(data) {
  reactDom.render(
    <LikeComponent
      data={data}
      renderElement={document.getElementById("dialogcontent")}
    />,
    document.getElementById("dialogcontent")
  );
}
export function openCommentDialog(data,i) {
  reactDom.render(
    <CommentComponent
      data={data}
      renderElement={document.getElementById("dialogcontent")}
      variant={i}
    />,
    document.getElementById("dialogcontent")
  );
}
export function openReportDialog(data) {
  reactDom.render(
    <ReportedComponent
      data={data}
      renderElement={document.getElementById("dialogcontent")}
    />,
    document.getElementById("dialogcontent")
  );
}
// export function openReportedArtistDialog(data) {
//   reactDom.render(
//     <ReportedArtistDialog
//       data={data}
//       renderElement={document.getElementById("dialogcontent")}
//     />,
//     document.getElementById("dialogcontent")
//   );
// }
// export function openReportedNewsDialog(data) {
//   reactDom.render(
//     <ReportedNewsDialog
//       data={data}
//       renderElement={document.getElementById("dialogcontent")}
//     />,
//     document.getElementById("dialogcontent")
//   );
// }
// export function openReportedPodcastDialog(data) {
//   reactDom.render(
//     <ReportedPodcastDialog
//       data={data}
//       renderElement={document.getElementById("dialogcontent")}
//     />,
//     document.getElementById("dialogcontent")
//   );
// }
// export function openReportedSongDialog(data) {
//   reactDom.render(
//     <ReportedSongsDialog
//       data={data}
//       renderElement={document.getElementById("dialogcontent")}
//     />,
//     document.getElementById("dialogcontent")
//   );
// }
export function openCategoryEditDialog(Name, data,url,selectedTab) {
  reactDom.render(
    <CategoryEditDialog
      Name={Name}
      data={data}
      url={url}
      selectedTab={selectedTab}
      renderElement={document.getElementById("dialogcontent")}
    />,
    document.getElementById("dialogcontent")
  );
}
export function openCategoryCreateDialog(data,k) {
  reactDom.render(
    <AddCategoryDialog
      data={data}
      k={k}
      renderElement={document.getElementById("dialogcontent")}
    />,
    document.getElementById("dialogcontent")
  );
}

export function openCountryDialog(data,k,callbac,flag,coun,cit) {
  reactDom.render(
    <AddCountryDialog
      data={data}
      k={k}
      callbac={callbac}
      flag={flag}
      coun={coun}
      cit={cit}
      renderElement={document.getElementById("dialogcontent")}
    />,
    document.getElementById("dialogcontent")
  );
}

export function openCountryEditDialog(Name,callbac,flag,coun,cit) {
  reactDom.render(
    <CountryEditDialog
      Name={Name}
      callbac={()=>callbac()}
      flag={flag}
      coun={coun}
      cit={cit}
      renderElement={document.getElementById("dialogcontent")}
    />,
    document.getElementById("dialogcontent")
  );
}
