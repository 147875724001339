import React, { useState } from "react";
import "./CardNewComponent.scss";
import classNames from "classnames";
import PropTypes from "prop-types";

export default function CardCategory(props) {
  const { isBlack, isGray, isTransparent, name, type, categoriesItem } = props;
  console.log("hello:" + isBlack);
  console.log("categoriesItem", categoriesItem);

  const [filterCategory, setfilterCategory] = useState([]);
  const [filterSubCategory, setfilterSubCategory] = useState([]);

  const changeBackgroundToTransParent = classNames("cardSetting", {
    backTransparent: isTransparent === true,
  });
  const changeBackgroundBlack = classNames("buttonAlign", "font-9", {
    backBlack: isBlack === true,
    backLightGray: isGray === true,
  });
  // const categoriesItem=()=>{
  //   categoriesItem.filter(function(item,pos){
  //     return
  //   }
  // }
  const filterCategoryData = (item) => {
    for (let i = 0; i < categoriesItem.length; i++) {
      filterCategory.push(categoriesItem[i].category.name);
    }
    var filtereddata = filterCategory.filter(
      (data, index) => filterCategory.indexOf(data) === index
    );
    console.log(filtereddata);
    return filtereddata;
  };
  const filterSubCategoryData = (item) => {
    for (let i = 0; i < categoriesItem.length; i++) {
      if (categoriesItem[i].subcategory !== undefined) {
        console.log("categoriesItem[i].subcategory.name", categoriesItem[i]);
        filterSubCategory.push(categoriesItem[i].subcategory.name);
      }
    }
    var filtereddata2 = filterSubCategory.filter(
      (data, index) => filterSubCategory.indexOf(data) === index
    );
    return filtereddata2;
  };

  return (
    <div>
      <div className={changeBackgroundToTransParent}>
        <p>{name}</p>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
          }}
        >
          {categoriesItem === undefined || categoriesItem.length === 0 ? (
            <div
              className="header-type-3"
              style={{ textAlign: "center", padding: "10px" }}
            >
              No Data Found
            </div>
          ) : (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {type === "songs" ? (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {categoriesItem.map((data, key) => {
                    return (
                      <p className={changeBackgroundBlack} key={key}>
                        {data.name}
                      </p>
                    );
                  })}
                </div>
              ) : (
                <span style={{ display: "flex", flexWrap: "wrap" }}>
                  {name === "Type(s)" ? (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {filterCategoryData().map((data, index) => {
                        return <p className={changeBackgroundBlack}>{data} </p>;
                      })}
                    </div>
                  ) : (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {filterSubCategoryData().map((data, index) => {
                        return <p className={changeBackgroundBlack}>{data} </p>;
                      })}
                    </div>
                  )}

                  {/* {categoriesItem.map((data, key) => {
                    return (
                      <p className={changeBackgroundBlack} key={key}>
                        {name === "Type(s)"
                          ? filterItem.push(data.category.name)
                          : data.subcategory.name}
                      </p>
                    );
                  })} */}
                </span>
              )}
            </div>
            // data.category.name
            // data.category.name.filter((data,index)=> data.category.name.indexOf(data)===index)
            // listOfTags.filter((tag, index, array) => array.findIndex(t => t.color == tag.color && t.label == tag.label) == index);
          )}
        </div>
      </div>
      {/* {console.log(filterItem)} */}
    </div>
  );
}
CardCategory.propTypes = {
  isTransparent: PropTypes.bool,
  isBlack: PropTypes.bool,
  isGray: PropTypes.bool,
};
