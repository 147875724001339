import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import SearchBar from "../../components/SearchBar/SearchBar";
import Tab from "@material-ui/core/Tab";
import { Tabs } from "@material-ui/core";
import CustomTableNew from "../../components/CustomTable/CustomTableNew";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import NavSearchBar from "../../components/SearchBar/NavSearchBar";
import { deleteDataApi, getdata, patchDataApi } from "../../utility/apiCalling";
import { constants } from "../../utility/constants";
import moment from "moment";
import {
  openCategoryCreateDialog,
  openCategoryEditDialog,
  openConfirmationDeleteDialog,
} from "../../utility/dialogAppear";
import CategoryEditDialog from "../../components/CategoryEditDialog/CategoryEditDialog";
import reactDom from "react-dom";
import { addErrorMessage, addSuccessMessage } from "../../utility/LoadingContent";
import { CustomButtonOutlined } from "../../components/Controls/CustomButton";

export default function Category() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [categories, setcategories] = useState([]);
  const [editDialog, seteditDialog] = useState(false);
  const [catID, setcatID] = useState(null);
  const [subCategories, setsubCategories] = useState([]);
  const [serc, setserc] = useState(null);

  const handleChange = (event, newValue) => {

    setSelectedTab(newValue);
  };
  useEffect(() => {
    getCategories();
  }, []);
  async function getCategories() {
    let response = await getdata(constants.getCategories + "?filter=isDeleted||$eq||0");
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      console.log("hi");
      // console.log(response)
      if (response.data.length !== 0) {
        getSubCategories(1);
        setcatID(1);
      }
      return setcategories(response.data);
    }
  }
  const sendvalue = (i) =>{
    console.log(i)
    setserc(i)
    getSubCategories(catID,i);

  }
  const handleTabData = (categoryId) => {
    setcatID(categoryId);
    setserc(null)
    getSubCategories(categoryId);
  };
  async function getSubCategories(categoryId,ser) {
    
    let apiURL = constants.getSubCategories + categoryId + "&filter=isDeleted||$eq||0"
    if (ser) {
      apiURL += "&filter=name||$cont||" + ser
    }
    let response = await getdata(
      apiURL
    );
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      // console.log(response)

      return setsubCategories(response.data);
    }
  }
  const handleEdit = (Name, key,url,selectedTab) => {
    openCategoryEditDialog(Name, key,url,selectedTab);
    // seteditDialog()
    // let data = key;
  };

  const handleRemove = (id) => {
    // console.log("--------------------------------------->handle")
    // console.warn(id)
    openConfirmationDeleteDialog(() => remove(id), "Remove");
  };
  const remove = async (id) => {
    let jsonData = {
      isDeleted: 1,
    };
    let deleteData = await patchDataApi(
      constants.getOnlySubCategory + "/" + id,
      jsonData
    );
    // console.log(props)
    if (deleteData.status === 200 || deleteData.statusText === "OK") {
      addSuccessMessage("Category Delete");
      // history.goBack()
      getSubCategories(catID);
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));

    } else {
      addErrorMessage("Category Not Delete");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
    // let removeCategory = await deleteDataApi(
    //   constants.getOnlySubCategory + "/" + id
    // );
    // if (removeCategory.status === 200 && removeCategory.statusText === "OK") {
    //   addSuccessMessage("Category Remove");
    //   getSubCategories(catID);
    //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    // } else {
    //   // console.log("User Not  successfully Deleted");
    //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    // }
  };
  const handleAddCategory = (ID,k) => {
    // console.log(ID)
    openCategoryCreateDialog(ID,k);
  };

  const data = () => {
    // console.log("----------->users posts")
    // console.log(subCategories)

    if (subCategories !== undefined && subCategories.length !== 0) {
      var formatted = subCategories.map((item, key) => {
        // console.log(item)

        return [
          selectedTab==1?<div style={{ display: "flex" }} key={item.id.toString()}>
            <img
              src={item.urlImage !== undefined && item.urlImage !== null && item.urlImage !== "" && item.urlImage !== "undefined" ? constants.podcastthumbanailcategoryFileURL + item.urlImage : constants.podcastCategoryURL}
              alt={item.firstName + " " + item.lastName}
              className="table-image"
            />
            <div>{item.name}</div>
          </div>:
          item.name,
          <div>
            <EditIcon
              fontSize="large"
              style={{
                color: "#376BC5",
                cursor: "pointer",
                marginRight: "10px",
              }}
              onClick={() => handleEdit(item.name, item.id,item.urlImage,selectedTab)}
            />
            <DeleteIcon
              style={{ color: "#D6587A", cursor: "pointer" }}
              onClick={() => handleRemove(item.id)}
            />
          </div>,
        ];
      });
      return formatted;
    } else return [];
  };
  return (
    <div className="main-window">
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <NavSearchBar title="Manage Category" />
        </Grid>

        <Grid item md={12} xs={12}>
          <SearchBar title={subCategories.length + " Sub Category(s)"} 
            sendvalue={sendvalue}
            />
        </Grid>

        <Grid item md={12} xs={12}>
          {categories !== undefined && categories !== 0 ? (
            <div>
              <Tabs value={selectedTab} onChange={handleChange}>
                {categories.map((item, key) => {
                  return (
                    <Tab
                      label={item.name}
                      onClick={() => handleTabData(item.id)}
                    />
                  );
                })}
              </Tabs>
            </div>
          ) : (
            "Not found"
          )}

          {selectedTab === 0 && (
            <div>
              <div style={{ textAlign: "-webkit-right" }}>
                <Grid item md={2} xs={3}>
                  <CustomButtonOutlined
                    text="Add Subcategory"
                    onClick={() => handleAddCategory(catID,0)}
                  />
                </Grid>
              </div>

              <CustomTableNew
                tableHead={["Sub Category Name", "Action"]}
                tableData={data()}
              />
            </div>
          )}
          {selectedTab === 1 && (
            <div>
              <div style={{ textAlign: "-webkit-right" }}>
                <Grid item md={2} xs={3}>
                  <CustomButtonOutlined
                    text="Add Subcategory"
                    onClick={() => handleAddCategory(catID,1)}
                  />
                </Grid>
              </div>

              <CustomTableNew
                tableHead={["Sub Category Name", "Action"]}
                tableData={data()}
              />
            </div>
          )}
          {selectedTab === 2 && (
            <div>
              <div style={{ textAlign: "-webkit-right" }}>
                <Grid item md={2} xs={3}>
                  <CustomButtonOutlined
                    text="Add Subcategory"
                    onClick={() => handleAddCategory(catID,2)}
                  />
                </Grid>
              </div>

              <CustomTableNew
                tableHead={["Sub Category Name", "Action"]}
                tableData={data()}
              />
            </div>
          )}
          {selectedTab === 3 && (
            <div>
              <div style={{ textAlign: "-webkit-right" }}>
                <Grid item md={2} xs={3}>
                  <CustomButtonOutlined
                    text="Add Subcategory"
                    onClick={() => handleAddCategory(catID,3)}
                  />
                </Grid>
              </div>

              <CustomTableNew
                tableHead={["Sub Category Name", "Action"]}
                tableData={data()}
              />
            </div>
          )}
        </Grid>
      </Grid>
      {editDialog ? <CategoryEditDialog /> : ""}
    </div>
  );
}
