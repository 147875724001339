import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";

import { SideBarData } from "./SideBarData";
import "./navbar.scss";
import { NavLink, HashRouter, Switch, Route } from "react-router-dom";
import { Button, Container, Link, Typography } from "@material-ui/core";
import { MediaData, ManageData } from "./SideBarData";
import Artist from "../../pages/Artist/Artist";
import AddArtist from "../../pages/Artist/AddArtist";
import ArtistUserDetails from "../../pages/Artist/ArtistUserDetails";
import UploadMusic from "../../pages/Artist/UploadMusic";
import UploadEpisode from "../../pages/Artist/UploadEpisode";
import UploadNews from "../../pages/Artist/UploadNews";
import PodcastPage from "../../pages/PodcastPage/PodcastPage";
import NewsPage from "../../pages/NewsPage/NewsPage";
import NewsDetailsPage from "../../pages/NewsPage/NewsDetailsPage";
import EventPage from "../../pages/EventPage/EventPage";
import EventsDetailPage from "../../pages/EventPage/EventsDetailPage";
import ReportedArtist from "../../pages/ReportedArtist/ReportedArtist";
import ReportedSongs from "../../pages/ReportedSongs/ReportedSongs";
import Users from "../../pages/Users/Users";
import BigStagePage from "../../pages/BigStagePage/BigStagePage";
import BigStageDetails from "../../pages/BigStagePage/BigStageDetails";
import VedioUpload from "../VedioUpload/VedioUpload";
import ArtistUserAlbumDetails from "../../pages/Artist/ArtistUserAlbumDetails";
import PodcastPageDetails from "../../pages/PodcastPage/PodcastPageDetails";
import ReportedPodcast from "../../pages/ReportedPodcast/ReportedPodcast";
import ReportedEpisode from "../../pages/ReportedEpisode/ReportedEpisode";
import Home from "../../pages/Home/Home";
import ReportedNews from "../../pages/ReportedNews/ReportedNews";
import Category from "../../pages/ManageCategory/Category";
import Privacy from "../../pages/ManagePrivacy/Privacy";
import Aboutus from "../../pages/ManageAboutus/Aboutus";
import Support from "../../pages/ManageSupport/Support";
import Country from "../../pages/ManageCountry/Country";
import Tags from "../../pages/ManageTags/Tags";
import Login from "../../pages/Login/Login";
import logo from "../../assets/logo.png";
import Uploadpodcaste from "../../pages/Artist/Uploadpodcaste";
import Uploadevent from "../../pages/Artist/Uploadevent";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // backgroundColor: "faf7f8",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    // overflow: "hidden",
    height: "100%",
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.default,
    // padding: theme.spacing(3),
  },
}));

export default function Navbar1() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <div
        // style={{ backgroundColor: "#faf7f8", height: "0%", display: "block" }}
        // style={{ height: "0%", display: "block" }}
        className="Navbar"
      >
        <Drawer
          style={{ overflow: "hidden" }}
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div
            style={{
              backgroundColor: "#121216",
              padding: "0px 20px",
              // height: "100vh",
            }}
            className="Navbar"
          >
            <div style={{ textAlignLast: "center", paddingTop: "10px" }}>
              <img
                src={logo}
                alt="not found"
                style={{ height: "70px", width: "70px" }}
              />
            </div>

            <div style={{ backgroundColor: "#121216" }}>
              <Typography className="subHeading" style={{ paddingTop: "0px" }}>
                {"Menu"}
              </Typography>
              <div className="SideBar">
                <List className="SideBarData">
                  {SideBarData.map((data, key) => {
                    return (
                      <Container key={key}>
                        <NavLink
                          to={data.path}
                          className="row"
                          style={{ textDecoration: "none" }}
                          activeClassName="activeData"
                        >
                          <div id="icon">{data.icon}</div>
                          <div id="title">{data.title}</div>
                        </NavLink>
                      </Container>
                    );
                  })}
                </List>
              </div>
              <Divider style={{ backgroundColor: "#8b8398" }} />
            </div>
            <div style={{ backgroundColor: "#121216" }}>
              <Typography className="subHeading">{"Reported"}</Typography>
              <div className="SideBar">
                <List className="SideBarData">
                  {MediaData.map((data, key) => {
                    return (
                      <Container key={key}>
                        <NavLink
                          to={data.path}
                          className="row"
                          style={{ textDecoration: "none" }}
                          activeClassName="activeData"
                        >
                          <div id="icon">{data.icon}</div>
                          <div id="title">{data.title}</div>
                        </NavLink>
                      </Container>
                    );
                  })}
                </List>
              </div>
              <Divider style={{ backgroundColor: "#8b8398" }} />
            </div>
            <div style={{ backgroundColor: "#121216" }}>
              <Typography className="subHeading">{"Manage"}</Typography>
              <div className="SideBar">
                <List className="SideBarData">
                  {ManageData.map((data, key) => {
                    return (
                      <Container key={key}>
                        <NavLink
                          style={{ textDecoration: "none" }}
                          to={data.path}
                          className="row"
                          activeClassName="activeData"
                        >
                          <div id="icon">{data.icon}</div>
                          <div id="title">{data.title}</div>
                        </NavLink>
                      </Container>
                    );
                  })}
                </List>
              </div>
            </div>
          </div>
        </Drawer>
      </div>

      <main className={classes.content}>
        <Switch>
          {/* <Route exact path="/" component={Home} /> */}
          <Route exact path={["/","/admin/","/admin/login"]} component={Home} />
          <Route path="/dashboard" component={Home} />
          <Route path="/user" component={Users} />
          <Route
            path="/user-album-details"
            component={ArtistUserAlbumDetails}
          />
          <Route path="/artist" component={Artist} />
          <Route path="/addartist" component={AddArtist} />
          <Route path="/uploadmusic" component={UploadMusic} />
          <Route path="/uploadepisode" component={UploadEpisode} />
          <Route path="/uploadpodcaste" component={Uploadpodcaste} />
          <Route path="/uploadevent" component={Uploadevent} />
          <Route path="/uploadnews" component={UploadNews} />
          <Route path="/user-details/:user?" component={ArtistUserDetails} />
          <Route path="/bigstage" component={BigStagePage} />
          <Route
            path="/bigstage-details/:bigstage?"
            component={BigStageDetails}
          />
          <Route path="/podcast" component={PodcastPage} />
          <Route
            path="/podcast-details/:podcasts?"
            component={PodcastPageDetails}
          />
          <Route path="/news" component={NewsPage} />
          <Route path="/news-details/:news?" component={NewsDetailsPage} />
          <Route path="/events" component={EventPage} />
          <Route path="/events-details/:events?" component={EventsDetailPage} />
          <Route path="/reported-artist" component={ReportedArtist} />

          <Route path="/reported-song" component={ReportedSongs} />

          <Route path="/reported-podcast" component={ReportedPodcast} />
          <Route path="/reported-episode" component={ReportedEpisode} />
          {/* <Route path="/reported-news" component={ReportedNews} /> */}
          <Route path="/vedio-upload" component={VedioUpload} />

          <Route path="/category" component={Category} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/aboutus" component={Aboutus} />
          <Route path="/support" component={Support} />
          <Route path="/country" component={Country} />
        </Switch>
      </main>
    </div>
  );
}
