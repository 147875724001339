import React from "react";
import IconMenu from "../../assets/images/Icons - Admin/IconMenu.png";
import IconArtist from "../../assets/images/Icons - Admin/IconArtist.png";
import IconUsers from "../../assets/images/Icons - Admin/IconUsers.png";
import IconBigStage from "../../assets/images/Icons - Admin/IconBigStage.png";
import IconPodcast from "../../assets/images/Icons - Admin/IconArtist.png";
import IconNews from "../../assets/images/Icons - Admin/IconNews.png";
import IconEvents from "../../assets/images/Icons - Admin/IconEvents.png";
import IconMusic from "../../assets/images/Icons - Admin/IconMusic.png";
import IconPodcastMic from "../../assets/images/Icons - Admin/IconPodcastMic.png";
import IconCategory from "../../assets/images/Icons - Admin/IconCategory.png";
import IconTags from "../../assets/images/Icons - Admin/IconTags.png";

export const SideBarData = [
  {
    title: "Dashboard",
    icon: <img src={IconMenu} alt="Logo" style={{ width: " 18px" }} />,
    path: "/dashboard",
  },
  {
    title: "Artist",
    icon: <img src={IconArtist} alt="Logo" style={{ width: " 18px" }} />,
    path: "/artist",
  },
  {
    title: "User",
    icon: <img src={IconUsers} alt="Logo" style={{ width: " 18px" }} />,
    path: "/user",
  },
  {
    title: "Big Stage",
    icon: <img src={IconBigStage} alt="Logo" style={{ width: " 18px" }} />,
    path: "/bigstage",
  },
  {
    title: "Podcast",
    icon: <img src={IconPodcast} alt="Logo" style={{ width: " 18px" }} />,
    path: "/podcast",
  },
  {
    title: "News",
    icon: <img src={IconNews} alt="Logo" style={{ width: " 18px" }} />,
    path: "/news",
  },
  {
    title: "Events",
    icon: <img src={IconEvents} alt="Logo" style={{ width: " 18px" }} />,
    path: "/events",
  },
];

export const MediaData = [
  {
    title: "Artist",
    icon: <img src={IconArtist} alt="Logo" style={{ width: " 18px" }} />,
    path: "/reported-artist",
  },
  {
    title: "Song",
    icon: <img src={IconMusic} alt="Logo" style={{ width: " 18px" }} />,
    path: "/reported-song",
  },
  {
    title: "Podcast",
    icon: <img src={IconPodcastMic} alt="Logo" style={{ width: " 18px" }} />,
    path: "/reported-podcast",
  },
  {
    title: "Episode",
    icon: <img src={IconPodcastMic} alt="Logo" style={{ width: " 18px" }} />,
    path: "/reported-episode",
  },
  // {
  //   title: "News",
  //   icon: <img src={IconNews} alt="Logo" style={{ width: " 18px" }} />,
  //   path: "/reported-news",
  // },
];
export const ManageData = [
  {
    title: "Category",
    icon: <img src={IconCategory} alt="Logo" style={{ width: " 18px" }} />,
    path: "/category",
  },
  {
    title: "Privacy",
    icon: <img src={IconCategory} alt="Logo" style={{ width: " 18px" }} />,
    path: "/privacy",
  },
  {
    title: "About Us",
    icon: <img src={IconCategory} alt="Logo" style={{ width: " 18px" }} />,
    path: "/aboutus",
  },
  {
    title: "Support",
    icon: <img src={IconCategory} alt="Logo" style={{ width: " 18px" }} />,
    path: "/support",
  },
  {
    title: "Country",
    icon: <img src={IconCategory} alt="Logo" style={{ width: " 18px" }} />,
    path: "/country",
  },
  // {
  //   title: "Tags",
  //   icon: <img src={IconTags} alt="Logo" style={{ width: " 18px" }} />,
  //   path: "/tags",
  // },
];
