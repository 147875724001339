import React from "react";
import { Grid } from "@material-ui/core";
import CustomSkeleton from "./CustomSkeleton";
export default function LoaderforArtistTabPodcastDetails() {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <CustomSkeleton height="40px" />
        </Grid>
        <Grid item md={8}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <CustomSkeleton height="40px" />
            </Grid>
            <Grid item xs={12} md={12}>
              <CustomSkeleton height="40px" />
            </Grid>
            <Grid item xs={12} md={12}>
              <CustomSkeleton height="200px" />
            </Grid>

            <Grid item xs={12} md={6}>
              <CustomSkeleton height="40px" />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomSkeleton height="40px" />
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CustomSkeleton height="60px" />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomSkeleton height="60px" />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomSkeleton height="60px" />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomSkeleton height="60px" />
            </Grid>
            <Grid item md={12}>
              <CustomSkeleton height="40px" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
