import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import SearchBar from "../../components/SearchBar/SearchBar";
import Tab from "@material-ui/core/Tab";
import { Tabs } from "@material-ui/core";
import CustomTableNew from "../../components/CustomTable/CustomTableNew";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import StreetviewIcon from "@material-ui/icons/Streetview";
import VisibilityIcon from '@material-ui/icons/Visibility';
import NavSearchBar from "../../components/SearchBar/NavSearchBar";
import { deleteDataApi, getdata, patchDataApi } from "../../utility/apiCalling";
import { constants } from "../../utility/constants";
import moment from "moment";
import {
  openCategoryCreateDialog,
  openCategoryEditDialog,
  openConfirmationDeleteDialog,
  openCountryDialog,
  openCountryEditDialog,
} from "../../utility/dialogAppear";
import CategoryEditDialog from "../../components/CategoryEditDialog/CategoryEditDialog";
import reactDom from "react-dom";
import { addErrorMessage, addSuccessMessage } from "../../utility/LoadingContent";
import { CustomButtonOutlined } from "../../components/Controls/CustomButton";
import CustomSwitch from "../../components/Controls/CustomSwitch";

export default function Category() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [categories, setcategories] = useState([]);
  const [country, setcountry] = useState([]);
  const [editDialog, seteditDialog] = useState(false);
  const [catID, setcatID] = useState(null);
  const [subCategories, setsubCategories] = useState([]);
  const [serc, setserc] = useState(null);
  const [flag, setflag] = useState(0);
  const [coun, setcoun] = useState(null);
  const [cit, setcity] = useState(null);


  const handleChange = (event, newValue) => {

    setSelectedTab(newValue);
  };
  useEffect(() => {
    getCountry();
    // setflag(0)
  }, []);
  async function getCountry(ser) {
    let apiURL = constants.getCountry + "/admin/getAll?page=1&limit=300"
    if (ser) {
      apiURL += "&searchText=" + ser
    }
    let response = await getdata(apiURL);

    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      console.log("hi");
      console.log(response.data.data)
      setcountry(response.data.data)
      // if (response.data.length !== 0) {
      //   getSubCategories(1);
      //   setcatID(1);
      // }
      // return setcategories(response.data);
    }
  }
  async function getstate(i, ser) {
    //     http://45.80.152.23:4527/states/admin/getAll?page=1&limit=10&countryId=2&searchText=Florida

    // http://45.80.152.23:4527/cities/admin/getAll?page=1&limit=10&stateId=4&searchText=Bartow
    let apiURL = constants.getState + "/admin/getAll?page=1&limit=300&countryId=" + i
    if (ser) {
      apiURL += "&searchText=" + ser
    }
    let response = await getdata(apiURL);
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      console.log("hi");
      console.log(response.data.data)
      setcountry(response.data.data)
      // if (response.data.length !== 0) {
      //   getSubCategories(1);
      //   setcatID(1);
      // }
      // return setcategories(response.data);
    }
  }
  async function getcity(i, ser) {
    let apiURL = constants.getCity + "/admin/getAll?page=1&limit=10&stateId=" + i
    if (ser) {
      apiURL += "&searchText=" + ser
    }
    let response = await getdata(apiURL);
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      console.log("hi");
      console.log(response.data.data)
      setcountry(response.data.data)
      // if (response.data.length !== 0) {
      //   getSubCategories(1);
      //   setcatID(1);
      // }
      // return setcategories(response.data);
    }
  }
  // const sendvalue = (i) =>{
  //   console.log(i)
  //   setserc(i)
  //   getSubCategories(catID,i);

  // }
  const handleTabData = (categoryId) => {
    setcatID(categoryId);
    setserc(null)
    getSubCategories(categoryId);
  };
  async function getSubCategories(categoryId, ser) {

    let apiURL = constants.getSubCategories + categoryId + "&filter=isDeleted||$eq||0"
    if (ser) {
      apiURL += "&filter=name||$cont||" + ser
    }
    let response = await getdata(
      apiURL
    );
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      // console.log(response)

      return setsubCategories(response.data);
    }
  }
  const handleEdit = (Name) => {
    console.log(flag)
    if (flag == 1) {
      openCountryEditDialog(Name, callbac, flag, coun);

    } else if (flag == 2) {
      openCountryEditDialog(Name, callbac, flag, coun, cit);

    } else {
      openCountryEditDialog(Name, callbac);

    }
    // seteditDialog()
    // let data = key;
  };
  const handleBack = () => {
    setflag(0)
    getCountry()
  }
  const callbac = () => {
    if (flag == 0) {
      getCountry()
    } else if (flag == 1) {
      getstate(coun)
    } else {
      getcity(cit)
    }
  }
  const sendvalue = (i) => {
    console.log(i)
    if (flag == 0) {
      getCountry(i)
    } else if (flag == 1) {
      getstate(coun, i)
    } else {
      getcity(cit, i)
    }

  }
  const handleview = (i, flag) => {
    console.log(i, flag)
    if (flag == 0) {
      setflag(1)
      getstate(i.id)
      setcoun(i.id)
    }
    if (flag == 1) {
      setflag(2)
      getcity(i.id)
      setcity(i.id)
    }
  }
  const handleBlock = (id, status) => {
    {
      status
        ? openConfirmationDeleteDialog(() => Block(id), "Block")
        : openConfirmationDeleteDialog(() => unBlock(id), "Unblock");
    }
  };
  const unBlock = async (id) => {
    let jsonData = {
      status: true,
    };

    console.log("handle Music  Unblock----------------------->");
    console.log(constants.getMusic + "/" + id);
    let unBlockSongData = await patchDataApi(
      (flag == 1 ? constants.admingetState : flag == 2 ? constants.admingetCity : constants.admingetCountry) + "/activeInactive" + "/" + id,
      jsonData
    );
    if (unBlockSongData.status === 200 || unBlockSongData.statusText === "OK") {
      addSuccessMessage(flag == 1 ? "UnBlocked State" : flag == 2 ? "UnBlocked City" : "UnBlocked Country");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
      // handleClose()
      console.log(cit)
      if (flag == 0) {
        getCountry()
      } else if (flag == 1) {
        getstate(coun)
      } else {
        getcity(cit)
      }
    } else {
    }
  };

  const Block = async (id) => {
    let jsonData = {
      status: false,
    };

    console.log("handle block----------------------->");
    // console.log(constants.getMusic + "/" + songDetails.id);
    let blockSongData = await patchDataApi(
      (flag == 1 ? constants.admingetState : flag == 2 ? constants.admingetCity : constants.admingetCountry) + "/activeInactive" + "/" + id,
      jsonData
    );
    if (blockSongData.status === 200 || blockSongData.statusText === "OK") {
      // getMusicByKey(songDetails.id);
      console.log(cit)
      addSuccessMessage(flag == 1 ? "Blocked State" : flag == 2 ? "Blocked City" : "Blocked Country");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
      // handleClose()
      if (flag == 0) {
        getCountry()
      } else if (flag == 1) {
        getstate(coun)
      } else {
        getcity(cit)
      }
    } else {
    }
  };
  const handleRemove = (id) => {
    // console.log("--------------------------------------->handle")
    // console.warn(id)
    openConfirmationDeleteDialog(() => remove(id), "Remove");
  };
  const remove = async (id) => {
    let jsonData = {
      isDeleted: true,
    };
    let deleteData = await patchDataApi(
      (flag == 1 ? constants.admingetState : flag == 2 ? constants.admingetCity : constants.admingetCountry) + "/delete" + (flag == 1 ? "State" : flag == 2 ? "City" : "Country") + "/" + id,
      jsonData
    );
    // console.log(props)
    if (deleteData.status === 200 || deleteData.statusText === "OK") {
      addSuccessMessage("Delete");
      console.log(cit)
      // history.goBack()
      if (flag == 0) {
        getCountry()
      } else if (flag == 1) {
        getstate(coun)
      } else {
        getcity(cit)
      }
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));

    } else {
      addErrorMessage("Not Delete");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
    // let removeCategory = await deleteDataApi(
    //   constants.getOnlySubCategory + "/" + id
    // );
    // if (removeCategory.status === 200 && removeCategory.statusText === "OK") {
    //   addSuccessMessage("Category Remove");
    //   getSubCategories(catID);
    //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    // } else {
    //   // console.log("User Not  successfully Deleted");
    //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    // }
  };
  const handleAddCategory = (ID, k) => {
    // console.log(ID)
    if (flag == 1) {
      openCountryDialog(ID, k, callbac, flag, coun);

    }
    else if (flag == 2) {
      openCountryDialog(ID, k, callbac, flag, coun, cit);

    } else {
      openCountryDialog(ID, k, callbac);

    }
  };

  const data = (country) => {
    // console.log("----------->users posts")
    // console.log(subCategories)

    if (country !== undefined && country.length !== 0) {
      var formatted = country.map((item, key) => {
        // console.log(item)

        return [

          item.name,
          <CustomSwitch checked={item.status} onChange={() => handleBlock(item.id, item.status)} size="small" title="Status" />
          // item.status === true ? (
          //   <span style={{ color: "rgb(76, 196, 124)" }}>Active</span>
          // ) : (
          //   <span style={{ color: "rgb(214, 88, 122)" }}>Inactive</span>
          // )
          ,
          <div style={{ display: "flex" }}>
            {flag !== 2 ? <VisibilityIcon
              fontSize="large"
              titleAccess="View"
              style={{
                color: "#376BC5",
                cursor: "pointer",
                marginRight: "10px",
              }}
              onClick={() => handleview(item, flag)}
            /> : null}
            <EditIcon
              fontSize="large"
              titleAccess="Edit"
              style={{
                color: "#376BC5",
                cursor: "pointer",
                marginRight: "10px",
              }}
              onClick={() => handleEdit(item)}
            />

            {/* <span>
              <StreetviewIcon
                fontSize="small"
                titleAccess="Status"
                style={{
                  cursor: "pointer",
                  color: "#376BC5",
                  marginRight: "10px",
                }}
                onClick={() => handleBlock(item.id,item.status)}
              />
            </span> */}
            <DeleteIcon
              style={{ color: "#D6587A", cursor: "pointer", marginRight: "10px", }}
              onClick={() => handleRemove(item.id)}
              titleAccess="Delete"
            />



          </div>,
        ];
      });
      return formatted;
    } else return [];
  };
  return (
    <div className="main-window">
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <NavSearchBar title={flag == 0 ? "Manage Country" : flag == 1 ? "Manage State" : "Manage City"} />
        </Grid>

        {/* <Grid item md={12} xs={12}>
          <SearchBar title={subCategories.length + " SubCategory(s)"} 
            sendvalue={sendvalue}
            />
        </Grid> */}


        <Grid item md={12} xs={12}>
          {/* {categories !== undefined && categories !== 0 ? ( */}
          {/* <div>
              <Tabs value={0} > */}

          {/* <Grid item md={2} xs={3}> 
                <CustomButtonOutlined
                    text={flag==0?"Country":flag==1?"State":"City"}
                    // onClick={() => handleAddCategory(catID,0)}
                  />
                 </Grid>  */}
          {/* </Tabs>
            </div> */}
          {/* ) : (
            "Not found"
          )} */}


          <div>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Grid item md={6} xs={3} style={{ marginRight: "10px" }}>
                <SearchBar

                  // title={
                  //   podcastData.totalCount === undefined
                  //     ? "0 Podcast(s)"
                  //     : podcastData.totalCount + " Podcast(s)"
                  // }

                  sendvalue={sendvalue}

                ></SearchBar>
              </Grid>
              <Grid item md={2} xs={3} style={{}}>
                <CustomButtonOutlined
                  text={flag == 0 ? "Add Country" : flag == 1 ? "Add State" : "Add City"}
                  onClick={() => handleAddCategory(catID, 0)}
                />
              </Grid>
              {flag == 1 || flag == 2 ? <Grid item md={2} xs={3} style={{ marginLeft: "15px" }}>
                <CustomButtonOutlined
                  text={"Back"}
                  onClick={() => handleBack()}
                />
              </Grid> : null}

            </div>

            {country.length > 0 ? <CustomTableNew
              tableHead={["Name", "Status", "Action"]}
              tableData={data(country)}
            /> : <div>
              {/* <LoaderforTable /> */}
              <p style={{ paddingTop: "150px", textAlign: "center" }}>
                NO data found
              </p>
            </div>}
          </div>


        </Grid>
      </Grid>
      {editDialog ? <CategoryEditDialog /> : ""}
    </div >
  );
}
