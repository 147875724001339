// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Fade from "react-reveal/Fade";
// core components
import styles from "./CustomTableNewStyle";
import PropTypes from "prop-types";
import React from "react";
import LoaderforTable from "../SkeletonLoader/LoaderforTable";

const useStyles = makeStyles(styles);
export default function   CustomTableNew(props) {
  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor } = props;
  // console.log(tableData);

  return (
    <div className={classes.tableResponsive}>
      {props.isColumn && tableData.length > 0 ? (
        <TableContainer>
          <Table className={classes.table}>
            <TableBody>
              {tableHead.map((prop, index) => (
                <TableRow key={index}>
                  <React.Fragment>
                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                      key={index}
                    >
                      {prop}
                    </TableCell>
                    <TableCell key={index}>{tableData[0][index]}</TableCell>
                  </React.Fragment>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div>
          {tableData.length > 0 ? (
            <Fade top cascade>
              <TableContainer className={classes.container}>
                <Table
                  className={classes.table}
                  // stickyHeader
                >
                  {tableHead !== undefined ? (
                    <TableHead
                      className={classes[tableHeaderColor + "TableHeader"]}
                    >
                      <TableRow className={classes.tableHeadRow}>
                        {tableHead.map((prop, key) => {
                          return (
                            <TableCell
                              className={
                                classes.tableCell + " " + classes.tableHeadCell
                              }
                              style={{ minWidth: "65px" }}
                              key={key}
                            >
                              {prop}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                  ) : null}

                  <TableBody>
                    {tableData.map((prop, key) => {
                      return (
                        <TableRow key={key} className={classes.tableBodyRow}>
                          {prop.map((prop, key) => {
                            return (
                              <TableCell
                                className={classes.tableCell}
                                key={key}
                              >
                                {prop}
                              </TableCell>
                            );
                          })}
                          {/* console.log(prop); */}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* </TableContainer> */}
            </Fade>
          ) : (
            <div>
              <LoaderforTable />
              {/* <p style={{ paddingTop: "150px", textAlign: "center" }}>
                NO data found
              </p> */}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

CustomTableNew.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTableNew.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.node)),
};
