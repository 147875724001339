import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Link, NavLink } from "react-router-dom";
import CardSwitch from '../../components/CardNew/CardSwitch';
import CardMore from '../../components/CardNew/CardMore';
import CardCategory from '../../components/CardNew/CardCategory';
import UserInformation from '../../components/CardNew/UserInformation';
import map from "../../assets/images/map.jpg";
import { makeStyles } from '@material-ui/core/styles';
import { CardPublishDate, CardStatus } from '../../components/CardNew/CardLocation';
import { deleteDataApi, getdata, patchDataApi } from "../../utility/apiCalling";
import { constants } from "../../utility/constants";
import LoaderforEvent from '../../components/SkeletonLoader/LoaderforEvent';
import {
  CustomButtonContained,
  CustomButtonOutlined,
} from "../../components/Controls/CustomButton";
import {
  openCommentDialog,
  openConfirmationDeleteDialog,
  openReportDialog,
  openVedioUpdateDialog,
  openViewDialog,
} from "../../utility/dialogAppear";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { addErrorMessage, addLoader, addSuccessMessage, removeLoader } from "../../utility/LoadingContent";
import reactDom from "react-dom";
import moment from "moment";
import NavSearchBar from '../../components/SearchBar/NavSearchBar';



export default function EventsDetailPage(props) {
  const { eventsId } = props.location.state;
  let history = useHistory();
  const [eventUser, setEventUser] = useState([]);
  var [eventBlock, setEventBlock] = useState(null);
  var imagelink = constants.eventsFileURL; // constants.baseURL + "eventsfiles/";
  var Userimagelink = constants.profileURL; // constants.baseURL + "profilefiles";
  useEffect(() => {
    getEventUser(props.location.state.eventsId);
    // getBigstageUser(22);
  }, []);

  async function getEventUser(userId) {
    console.log(constants.geteventsURL + "/getById/" + userId);
    let response = await getdata(constants.geteventsURL + "/getById/" + userId);

    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      console.log(response.data.data);
      setEventBlock(response.data.data.events_status);
      return setEventUser(response.data.data);
    }
  }

  const handleBlock = () => {
    // window.alert("hande block");
    // window.alert(bigBlock);
    {
      eventBlock === 1
        ? openConfirmationDeleteDialog(Block, "Block")
        : openConfirmationDeleteDialog(unBlock, "Unblock");
    }
  };

  const unBlock = async () => {
    // window.alert(bigBlock);
    let jsonData = {
      status: 1,
    };
    // console.log(id);
    console.log("handle block----------------------->");
    console.log(constants.geteventsURL + "/" + eventUser.events_id);
    let blockEventsData = await patchDataApi(
      constants.geteventsURL + "/" + eventUser.events_id,
      jsonData
    );
    if (blockEventsData.status === 200 || blockEventsData.statusText === "OK") {
      // console.log("User Successfully Block");
      // setBigBlock(0);

      setEventBlock(0);
      // window.alert(bigBlock);
      getEventUser(props.location.state.eventsId);

      addSuccessMessage("Event UnBlocked");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      // console.log("User Not  successfully Deleted");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };
  const Block = async () => {
    // window.alert(bigBlock);
    let jsonData = {
      status: 0,
    };
    // console.log(id);
    console.log("handle block----------------------->");
    console.log(constants.geteventsURL + "/" + eventUser.events_id);
    let blockEventsData = await patchDataApi(
      constants.geteventsURL + "/" + eventUser.events_id,
      jsonData
    );
    if (blockEventsData.status === 200 || blockEventsData.statusText === "OK") {
      // console.log("User Successfully Block");
      setEventBlock(1);
      // window.alert(bigBlock);
      getEventUser(props.location.state.eventsId);
      addSuccessMessage("Event Blocked");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      // console.log("User Not  successfully Deleted");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };

  const handleRemove = () => {
    openConfirmationDeleteDialog(remove, "Remove");
  };
  const remove = async () => {
    let jsonData = {
      isDeleted: 1,
    };
    let deleteData = await patchDataApi(
      constants.geteventsURL + "/" + props.location.state.eventsId,
      jsonData
    );
    // console.log(props)
    if (deleteData.status === 200 || deleteData.statusText === "OK") {
      addSuccessMessage("Event Delete");
      history.goBack()
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      addErrorMessage("Event Not Delete");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
    // let removeEvents = await deleteDataApi(
    //   constants.geteventsURL + "/" + props.location.state.id
    // );
    // if (removeEvents.status === 200 && removeEvents.statusText === "OK") {
    //   addSuccessMessage("Events Remove");
    //   history.push("events");
    //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    // } else {
    //   // console.log("User Not  successfully Deleted");
    //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    // }
  };

  const handleReport = (data) => {
    // window.alert("report");
    openReportDialog(data);
  };
  const handleView = (data) => {
    openViewDialog(data);
  };
  const handleComment = (data) => {
    openCommentDialog(data);
  };
  if (eventUser.length !== 0) {
    removeLoader();
    return (
      <div className="main-window">
        <Grid container spacing={2} >
          <Grid item md={12} sm={12}>
            <NavSearchBar title="Event" />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <div>
                  <img src={eventUser.events_urlImage === "" 
                  ? constants.musicURL
                  : imagelink + eventUser.events_urlImage}
                    alt="NotFound" style={{ width: "100%", borderRadius: "32px", height: "50vh" }}></img>
                </div>
                <div className="header-poppins-14  font-14" style={{ padding: "10px 0px" }}>
                  {eventUser.events_name}
                </div>

                <div className="header-type-3 lineheight-22">
                  {eventUser.events_description}
                  {/* Lorem Khaled Ipsum is a major key to success. They don’t want us to win. Always remember in the jungle there’s a lot of they in there, after you overcome they, you will make it to paradise. Major key, don’t fall for the trap, stay focused. It’s the ones closest to you that want to see you fail. Major key, don’t fall for the trap, stay focused. It’s the ones closest to you that want to see you fail. You see the hedges, how I got it shaped up? It’s important to shape up your hedges, it’s like getting a haircut, stay fresh. */}
                </div>

              </Grid>
              <Grid item md={4} xs={12}>
                <CardPublishDate publishDate={moment(eventUser.events_created).format(
                  "DD MMM yyyy")} />
              </Grid>
              <Grid item md={4} xs={12}>
                <div>
                  <div className="addBorder">
                    <p className="header-type-3 lineheight-28 ">Date & Time</p>
                    <p className=" header-poppins-12 lineheight-18  ">
                      {moment(eventUser.events_eventdate).format("DD-MM-yyyy hh:mm A")}
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item md={4} xs={12}>
                <CardStatus status={
                  eventUser.events_status === 1 ? "Active" : "Inactive"
                } />
              </Grid>
              <Grid item md={12} xs={12}>
                <div className="header-poppins-14  font-14" style={{ padding: "10px 0px" }}>
                  {"Booking Link"}
                </div>123
                <a target="_blank" href={eventUser.events_eventlink} className="header-type-3 lineheight-22" >
                  {/* <div className="header-type-3 lineheight-22"> */}
                    {eventUser.events_eventlink}

                  {/* </div> */}

                </a>

              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} >
                <UserInformation
                  urlImageUser={
                    Userimagelink + eventUser.user.urlImage
                  }
                  userEmail={eventUser.user.email}
                  userName={
                    eventUser.user.firstName +
                    " " +
                    eventUser.user.lastName
                  }
                  userAlbum={eventUser.user.albums.length}
                  userFollower={eventUser.user.followed.length}
                  userFollowing={eventUser.user.following.length}
                ></UserInformation>
              </Grid>

              <Grid item md={12} xs={12}>
                <div className="header-poppins-14  font-14" style={{ padding: "10px 0px" }}>
                  {"Event Location"}
                </div>
                <div>
                  <img src={map} alt="NotFound" style={{ width: "100%", borderRadius: "32px", height: "150px" }}></img>
                </div>
                <div className="header-poppins-14  font-12" >
                  {"Dream world Wide"}
                </div>

                <div className="header-type-3 lineheight-22">
                  {eventUser.events_address}
                  {/* Lorem Khaled Ipsum is a major key to success. They don’t want us to win. Always remember in the jungle there’s a lot of they in there, after you overcome they, you will make it to paradise. Major key, don’t fall for the trap, stay focused. It’s the ones closest to you that want to see you fail. Major key, don’t fall for the trap, stay focused. It’s the ones closest to you that want to see you fail. You see the hedges, how I got it shaped up? It’s important to shape up your hedges, it’s like getting a haircut, stay fresh. */}
                </div>


              </Grid>
              <Grid item xs={12} md={12} >
                <CardCategory name="Category(s)" isTransparent isGray categoriesItem={eventUser.categories}></CardCategory>
              </Grid>
              {/* <Grid item xs={12} md={12} >
                <CardCategory name="tag" isTransparent isGray></CardCategory>
              </Grid> */}
              {/* <Grid item xs={12} md={6}>
                <CardSwitch></CardSwitch>
              </Grid>
              <Grid item xs={12} md={6}>
                <CardMore></CardMore>
              </Grid> */}
              <Grid item xs={12} md={6}>
                <CustomButtonOutlined
                  onClick={() => {
                    handleRemove();
                  }}
                  text="remove"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomButtonContained
                  onClick={() => {
                    handleBlock();
                  }}
                  text={eventBlock === 1 ? "Block" : "UnBlock"}
                />
              </Grid>

            </Grid>
          </Grid>
        </Grid>

      </div >
    );
  } else {
    return <div className="main-window"><LoaderforEvent /></div>;
  }
}