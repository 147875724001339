import React, { useEffect, useState } from "react";
import CustomTableNew from "../../components/CustomTable/CustomTableNew";
import SearchBar from "../../components/SearchBar/SearchBar";
import { Container, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import StreetviewIcon from "@material-ui/icons/Streetview";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from '@material-ui/icons/Edit';
import IconArrowBottom from "../../assets/images/IconArrowBottom.svg";
import { Menu, MenuItem, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import {
  CustomButtonContained,
  CustomButtonOutlined,
} from "../../components/Controls/CustomButton";
import {
  BlueCard,
  GreenCard,
  OrangeCard,
  PinkCard,
} from "../../components/CardNew/CommonCards";
import NavSearchBar from "../../components/SearchBar/NavSearchBar";
import { constants } from "../../utility/constants";
import { getdata, patchDataApi } from "../../utility/apiCalling";
import { NoMoreDataFound } from "../../components/NoDataFound/NoDataFound";
import { openConfirmationDeleteDialog } from "../../utility/dialogAppear";
import { addErrorMessage, addSuccessMessage } from "../../utility/LoadingContent";
import reactDom from "react-dom";


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  console.log(props);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          p={2}
          className="bg-black"
          style={{ padding: "10px 0px 10px 0px" }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    minWidth: "0px !important",
  },
}));
export default function Artist({ location }) {
  const [posts, setPosts] = useState([]);
  const [postsdet, setPostsdet] = useState(0);
  const [posts1, setPosts1] = useState([]);
  const [posts1det, setPosts1det] = useState(0);
  // const [stopLoader, setStopLoader] = useState(true)
  // const [stopLoader1, setStopLoader1] = useState(true)
  const [userCount, setuserCount] = useState([]);
  const [serc, setserc] = useState(null);
  var [page, setPage] = useState(1);
  var [page1, setPage1] = useState(1);
  const [showPageNumberButton, setshowPageNumberButton] = useState(false);
  const [showPageNumberButton1, setshowPageNumberButton1] = useState(false);
  const [nextPageNotFound, setnextPageNotFound] = useState(false);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  console.log(posts)
  const handleChange = (event, newValue) => {
    // if(history.location.state !== undefined && history.location.state !== null && history.location.state.activeTab !== undefined){
    //   history.location.state.activeTab = newValue;
    // }
    setValue(newValue);
    setserc(null)
    if (newValue == 0) {
      setPage(1)
      getNextPage(1)
    } else {
      getNextPage1(1)
      setPage1(1)

    }
    // setPage(1)
    // setPage1(1)
    // getNextPage(1)
    // getNextPage1(1)
  };

  const callextra = async () => {
    await getCount();

    await getArtist(page, serc);
  }
  const callextra1 = async () => {
    await getArtist1(page1, serc);
    await getCount()
  }

  useEffect(() => {
    console.log(location)
    if (location?.state?.activeTab == 1) {
      setValue(1);

      callextra1()
    } else {
      callextra()
    }
    // 

  }, []);
  let history = useHistory();

  // ------------------------show User Details

  const showUserDetails = (key) => {
    console.log("view-------------------------------");
    // window.alert(key)

    history.push({
      pathname: `user-details/${key}`,
      state: {
        ArtistId: key,
        activeTab: value,
      },
    });
  };
  const showUserDetails1 = (key) => {
    console.log("view-------------------------------");
    // window.alert(key)

    history.push({
      pathname: `/addartist/${key}`,
      state: {
        ArtistId: key,
        activeTab: value,
      },
    });
  };
  const showUserDetails10 = () => {
    console.log("view-------------------------------");
    // window.alert(key)

    history.push({
      pathname: `/addartist`,
    });
  };

  // --------------------------------------get Count
  async function getCount() {
    console.log(constants.artistCount);
    let response = await getdata(constants.artistCount);
    if (
      response.request.status === 200
    ) {
      // console.log(response.data)

      return setuserCount(response.data);
    }
  }

  // ---------------------------------------------------------------getArtist

  async function getArtist(page, ser) {
    // console.log(
    //   constants.user +
    //   "?filter=isDeleted||$eq||0&page=" +
    //   page +
    //   "&limit=10&filter=usertype||$eq||Artist"
    // );

    // http://45.80.152.23:4527/user/adminSearchUser?page=1&limit=10&searchText=Deepika&usertype=Artist&isAdminManaged=0
    let response
    if (ser == undefined || ser == "undefined" || ser == null || ser == "") {
      response = await getdata(
        constants.user +
        "/adminSearchUser?page=" +
        page +
        "&limit=10&searchText=" +
        "&usertype=Artist&isAdminManaged=0"
      );
    } else {
      response = await getdata(
        constants.user +
        "/adminSearchUser?page=" +
        page +
        "&limit=10&searchText=" +
        ser +
        "&usertype=Artist&isAdminManaged=0"
      );
    }


    if (
      response.request.status === 200
    ) {
      console.log(response.data);
      if (response.data.data.length !== 0) {
        if ((response.data.limit * response.data.page) < response.data.totalCount) {
          setshowPageNumberButton(true);
        } else {
          setshowPageNumberButton(false);
        }
        setPostsdet(response.data.total)
        return setPosts(posts.concat(response.data.data))


      } else {
        setshowPageNumberButton(false);
        // return setPosts(posts)
        // return setStopLoader(false)
      }
    }
  }
  async function getArtist1(page, ser) {
    // console.log(
    //   constants.user +
    //   "?filter=isDeleted||$eq||0&page=" +
    //   page +
    //   "&limit=10&filter=usertype||$eq||Artist"
    // );
    let response
    if (ser == undefined || ser == "undefined" || ser == null || ser == "") {
      response = await getdata(
        constants.user +
        "/adminSearchUser?page=" +
        page +
        "&limit=10&searchText=" +
        "&usertype=Artist&isAdminManaged=1"
      );
    } else {
      response = await getdata(
        constants.user +
        "/adminSearchUser?page=" +
        page +
        "&limit=10&searchText=" +
        ser +
        "&usertype=Artist&isAdminManaged=1"
      );
    }
    if (
      response.request.status === 200
    ) {
      console.log(response.data);
      if (response.data.data.length !== 0) {
        if ((response.data.limit * response.data.page) < response.data.totalCount) {
          setshowPageNumberButton(true);
        } else {
          setshowPageNumberButton(false);
        }
        console.log(response.data)
        // setTimeout(() => {
        setPosts1det(response.data.total)
        return setPosts1(posts1.concat(response.data.data))

        // }, 100);


      } else {
        setshowPageNumberButton1(false);
        // setPosts1(posts1)
        // return setStopLoader1(false)
      }
    }
  }

  // ---------------------------------------------------------------------Next Page

  const nextPage = () => {
    setPage(page + 1);
    getArtist(page + 1, serc)
  };
  const nextPage1 = () => {
    setPage1(page1 + 1);
    getArtist1(page1 + 1, serc)
  };

  console.log("--------------------page" + page);
  async function getNextPage(page, ser) {
    // console.log(
    //   constants.user +
    //   "?page=" +
    //   ty +
    //   "&limit=10&filter=usertype||$eq||Artist"
    // );
    let response
    if (ser == undefined || ser == "undefined" || ser == null || ser == "") {
      response = await getdata(
        constants.user +
        "/adminSearchUser?page=" +
        page +
        "&limit=10&searchText=" +
        "&usertype=Artist&isAdminManaged=0"
      );
    } else {
      response = await getdata(
        constants.user +
        "/adminSearchUser?page=" +
        page +
        "&limit=10&searchText=" +
        ser +
        "&usertype=Artist&isAdminManaged=0"
      );
    }
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      console.log("--------------------> next page");
      console.log(response);
      if (response.data.data.length !== 0) {
        if ((response.data.limit * response.data.page) < response.data.totalCount) {
          setshowPageNumberButton(true);
        } else {
          setshowPageNumberButton(false);
        }
        setPosts(response.data.data);
        setPostsdet(response.data.total)
      } else {
        if (ser !== undefined && ser !== "undefined" && ser !== null && ser !== "") {
          console.log("jjjjjjj")
          setPosts([]);
        }
        return setshowPageNumberButton(false);
      }
    }
  }
  async function getNextPage1(page, ser) {
    // console.log(
    //   constants.user +
    //   "?page=" +
    //   ty +
    //   "&limit=10&filter=usertype||$eq||Artist"
    // );
    let response
    if (ser == undefined || ser == "undefined" || ser == null || ser == "") {
      response = await getdata(
        constants.user +
        "/adminSearchUser?page=" +
        page +
        "&limit=10&searchText=" +
        "&usertype=Artist&isAdminManaged=1"
      );
    } else {
      response = await getdata(
        constants.user +
        "/adminSearchUser?page=" +
        page +
        "&limit=10&searchText=" +
        ser +
        "&usertype=Artist&isAdminManaged=1"
      );
    }
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      console.log("--------------------> next page");
      console.log(response);
      if (response.data.data.length !== 0) {
        if ((response.data.limit * response.data.page) < response.data.totalCount) {
          setshowPageNumberButton(true);
        } else {
          setshowPageNumberButton(false);
        }
        setPosts1(response.data.data);
        setPosts1det(response.data.total)
      } else {
        if (ser !== undefined && ser !== "undefined" && ser !== null && ser !== "") {
          setPosts1([]);
        }
        return setshowPageNumberButton1(false);
      }
    }
  }

  // -----------------------------------------------------------------handle Delete
  // ---------------------------------------delete handle
  const handleDelete = (id) => {
    // window.alert(id)
    openConfirmationDeleteDialog(() => deleteArtist(id), "Delete");
  };
  const sendvalue = (i) => {
    console.log(i)
    setserc(i)

    if (value == 0) {
      setPage(1)
      getNextPage(1, i)
    } else {
      getNextPage1(1, i)
      setPage1(1)

    }

  }
  const deleteArtist = async (id) => {

    let jsonData = {
      isDeleted: 1,
    };
    let deleteData = await patchDataApi(
      //constants.user + "/" + id,
      constants.user + "/deleteUser/" + id, //Hitendra
      jsonData
    );
    if (deleteData.status === 200 || deleteData.statusText === "OK") {
      addSuccessMessage("Artist Delete");
      setPage(1)
      setPage1(1)
      getNextPage(1)
      getNextPage1(1)
      getCount();

      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      addErrorMessage("Artist Not Deleted")
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }

    // let deleteUser1 = await deleteDataApi(constants.user + "/" + id);
    // if (deleteUser1.status === 200 && deleteUser1.statusText === "OK") {
    //   // console.log("User Successfully Deleted");
    //   addSuccessMessage("User Delete ");
    //   getUser();
    //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    // } else {
    //   console.log("User Not Delete");
    //   // reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    // }
  };

  // ----------------------------------------------------------------data loading

  const data = (posts) => {
    console.log(posts);
    // console.log(stopLoader)
    if (posts !== undefined && posts.length !== 0) {
      var formatted = posts.map((item, key) => {
        console.log(item);
        // console.log(item.id)
        var imagelink = constants.profileURL;//constants.baseURL + "profilefiles";
        return [
          <div style={{ display: "flex" }} key={item.id.toString()}>
            <img
              src={item.urlImage !== undefined && item.urlImage !== null && item.urlImage !== "" && item.urlImage !== "undefined" ? imagelink + item.urlImage : constants.userImageURL}
              alt={item.firstName + " " + item.lastName}
              className="table-image"
            />
            <div>{" " + item.firstName + " " + item.lastName}</div>
          </div>,
          item.email,
          // item.fullAddress === undefined || item.fullAddress === "undefined" || item.fullAddress === null ? "" : item.fullAddress,
          // item.subscribeYouTubeChannel === true ? <p>Yes</p> : <p>No</p>,
          item.showOnHome === true ? (
            <span style={{ color: "rgb(76, 196, 124)" }}>Yes</span>
          ) : (
            <span style={{ color: "rgb(214, 88, 122)" }}>No</span>
          ),
          item.showMainArtist === true ? (
            <span style={{ color: "rgb(76, 196, 124)" }}>Yes</span>
          ) : (
            <span style={{ color: "rgb(214, 88, 122)" }}>No</span>
          ),
          item.status === true ? (
            <span style={{ color: "rgb(76, 196, 124)" }}>Active</span>
          ) : (
            <span style={{ color: "rgb(214, 88, 122)" }}>Inactive</span>
          ),

          item.makeFeatured === false ? (
            <p style={{ color: "#376BC5" }}>{"standard"}</p>
          ) : (
            <p style={{ color: "#BF8643" }}>{"Featured"}</p>
          ),

          <div>
            <span>
              <StreetviewIcon
                fontSize="small"
                titleAccess="view"
                style={{
                  cursor: "pointer",
                  color: "#376BC5",
                  marginRight: "20px",
                }}
                onClick={() => showUserDetails(item.id)}
              />
            </span>
            <span>
              <DeleteIcon
                fontSize="small"
                style={{ cursor: "pointer", color: "#D6587A" }}
                titleAccess="Delete"
                onClick={() => handleDelete(item.id)}
              />
            </span>
          </div>,
        ];
      });
      return formatted;
    } else return [];
  };
  const data1 = (posts) => {
    console.log(posts);
    if (posts !== undefined && posts.length !== 0) {
      var formatted = posts.map((item, key) => {
        console.log(item);
        // console.log(item.id)
        var imagelink = constants.profileURL;//constants.baseURL + "profilefiles";
        return [
          <div style={{ display: "flex" }} key={item.id.toString()}>
            <img
              src={item.urlImage !== undefined && item.urlImage !== null && item.urlImage !== "" && item.urlImage !== "undefined" ? imagelink + item.urlImage : constants.userImageURL}
              alt={item.firstName + " " + item.lastName}
              className="table-image"
            />
            <div>{" " + item.firstName + " " + item.lastName}</div>
          </div>,
          item.email,
          // item.fullAddress === undefined || item.fullAddress === "undefined" || item.fullAddress === null ? "" : item.fullAddress,
          // item.subscribeYouTubeChannel === true ? <p>Yes</p> : <p>No</p>,
          item.showOnHome === true ? (
            <span style={{ color: "rgb(76, 196, 124)" }}>Yes</span>
          ) : (
            <span style={{ color: "rgb(214, 88, 122)" }}>No</span>
          ),
          item.showMainArtist === true ? (
            <span style={{ color: "rgb(76, 196, 124)" }}>Yes</span>
          ) : (
            <span style={{ color: "rgb(214, 88, 122)" }}>No</span>
          ),
          item.status === true ? (
            <span style={{ color: "rgb(76, 196, 124)" }}>Active</span>
          ) : (
            <span style={{ color: "rgb(214, 88, 122)" }}>Inactive</span>
          ),

          item.makeFeatured === false ? (
            <p style={{ color: "#376BC5" }}>{"standard"}</p>
          ) : (
            <p style={{ color: "#BF8643" }}>{"Featured"}</p>
          ),

          <div>
            <span>
              <StreetviewIcon
                fontSize="small"
                titleAccess="view"
                style={{
                  cursor: "pointer",
                  color: "#376BC5",
                  marginRight: "10px",
                }}
                onClick={() => showUserDetails(item.id)}
              />
            </span>
            <span>
              <DeleteIcon
                fontSize="small"
                style={{ cursor: "pointer", color: "#D6587A", marginRight: "10px", }}
                titleAccess="Delete"
                onClick={() => handleDelete(item.id)}
              />
            </span>
            <span>
              <EditIcon
                fontSize="small"
                style={{ cursor: "pointer", color: "green" }}
                titleAccess="Edit"
                onClick={() => showUserDetails1(item.id)}
              />
            </span>
          </div>,
        ];
      });
      return formatted;
    } else return [];
  };
  return (
    <>
      <div className="main-window artist-load">
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>

            <NavSearchBar title="Artist" />
          </Grid>
          <Grid item xs={6} md={3}>
            <GreenCard
              totalGreen={userCount.totalFeaturedArtists}
              title="Featured Artist"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <BlueCard
              totalBlue={userCount.totalStandardArtists}
              title="Standard Artist"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <PinkCard
              totalPink={userCount.totalReportedArtists}
              title="Reported Artist"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <OrangeCard
              totalBlocked={userCount.totalBlockedArtists}
              title="Blocked Artist"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={value}
                  onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Manage By Self" {...a11yProps(0)} />
                  <Tab label="Manage By Admin" {...a11yProps(1)} />

                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <SearchBar
                      title={
                        postsdet === 0
                          ? "0 Artist(s)"
                          : postsdet + " Artist(s)"
                      }
                      sendvalue={sendvalue}
                    ></SearchBar>
                  </Grid>
                  {/* <Grid item md={1} xs={3} style={{ alignSelf: "center" }}>
            <CustomButtonOutlined
              onClick={() => history.push("/addartist")}
              text="Add Artist"
            />
          </Grid> */}
                  {/* </Grid> */}
                  <Grid item md={12} xs={12}>
                    {posts.length !== 0 ? <CustomTableNew
                      tableHead={[
                        "Name",
                        "Email",
                        // "Address",
                        "Show On Home",
                        "Main Artist",
                        "Status",
                        "Type",
                        "Action",
                      ]}
                      tableData={data(posts)}
                    /> : <p style={{ paddingTop: "150px", textAlign: "center" }}>
                      No data found
                    </p>}


                  </Grid>
                  <Grid item md={12} xs={12}>
                    <div style={{ textAlign: "-webkit-center" }}>
                      {showPageNumberButton && (
                        <div>
                          <Grid item md={12} style={{ width: "20%" }}>
                            <CustomButtonOutlined
                              text={"view More "}
                              onClick={() => nextPage()}
                            />
                          </Grid>
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid container spacing={2}>
                  <Grid item xs={9} md={11}>
                    <SearchBar
                      title={
                        posts1det === 0
                          ? "0 Artist(s)"
                          : posts1det + " Artist(s)"
                      }
                      sendvalue={sendvalue}
                    ></SearchBar>{console.log(posts1)}
                  </Grid>
                  <Grid item md={1} xs={3} style={{ alignSelf: "center" }}>
                    <CustomButtonOutlined
                      onClick={showUserDetails10}
                      text="Add Artist"
                    />
                  </Grid>
                  {/* </Grid> */}
                  <Grid item md={12} xs={12} >
                    {posts1.length !== 0 ?
                      <CustomTableNew
                        tableHead={[
                          "Name",
                          "Email",
                          // "Address",
                          "Show On Home",
                          "Main Artist",
                          "Status",
                          "Type",
                          "Action",
                        ]}
                        tableData={data1(posts1)}
                      />
                      : <p style={{ paddingTop: "150px", textAlign: "center" }}>
                        No data found
                      </p>}


                  </Grid>
                  <Grid item md={12} xs={12}>
                    <div style={{ textAlign: "-webkit-center" }}>
                      {showPageNumberButton1 && (
                        <div>
                          <Grid item md={12} style={{ width: "20%" }}>
                            <CustomButtonOutlined
                              text={"view More "}
                              onClick={() => nextPage1()}
                            />
                          </Grid>
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </TabPanel>

            </Box>
          </Grid>


        </Grid>
      </div>
    </>
  );
}
