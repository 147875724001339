import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";

import CloseIcon from "@material-ui/icons/Close";
import { DialogContent, DialogTitle, Grid } from "@material-ui/core";
import {
  CustomButtonContained,
  CustomButtonOutlined,
} from "../Controls/CustomButton";

import reactDom from "react-dom";
import { deleteDataApi, postDataApi } from "../../utility/apiCalling";
import { constants } from "../../utility/constants";

export default function VedioUpload(props) {
  const { handleSubmit, renderElement, text } = props;
console.log(props)
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    reactDom.unmountComponentAtNode(renderElement);
    setOpen(false);
  };


  return (
    <div>
      <Dialog
        maxWidth="sm"
        onClose={handleClose}
        // aria-labelledby="customized-dialog-title"
        open={open}
        style={{ borderRadius: "32px" }}
        // className={classes.dialog}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          //   className={classes.dialogtitle}
        >
          {/* {open ? (
            <IconButton
              aria-label="close"
              //   className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null} */}
          {/* Please Confirm */}
        </DialogTitle>
        <DialogContent
        // className={classes.DialogContent}
        >
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>

            {text==="Yes"?<div>Would you like to Change ?</div>:<div>Are you sure you want to {text} ?</div>}
              
            </Grid>
            <Grid item md={12}>
              <Grid container spacing={2} style={{ margin: "0px" }}>
                <Grid item xs={6} md={6}>
                  <CustomButtonOutlined text={text==="Yes"?"No":"Cancel" }  onClick={handleClose} />
                </Grid>

                <Grid item xs={6} md={6}>
                  <CustomButtonContained text={text==="Yes"?"Yes":text } onClick={handleSubmit} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
