import { Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import Search from "../../assets/images/Search.png";
import Sort from "../../assets/images/Sort.png";
import PropTypes from "prop-types";
// import VedioUpload from "../VedioUpload/VedioUpload";
import { CustomButtonOutlined } from "../Controls/CustomButton";
// import { openVedioDialog } from "../../utility/dialogAppear";

export default function TabSearchBar(props) {
  //   const [openVedio, setopenVedio] = useState(true);
  const { title, track, showBackButton, onClick,notshowsearch } = props;

  const backHandle = () => {
    // openVedioDialog();
  };
  return (
    <div className="dis-flex">
      <Grid container spacing={2}>
        <Grid item md={9} xs={5}>
          <Typography variant="h6" className="header-poppins-12">
            {title}
          </Typography>
        </Grid>
        {!notshowsearch?<><Grid item md={1} xs={3} style={{ alignSelf: "center", textAlign: "center" }}>
          <div className="header-type-3">{track}</div>
        </Grid>
        <Grid item md={1} xs={2}>
          <div className="dis-flex-around">
            <img src={Search} alt="Logo" style={{ width: " 36px" }} />
            {/* <img src={Sort} alt="Logo" style={{ width: " 36px" }} />
            <img src={Filter} alt="Logo" style={{ width: " 36px" }} /> */}
          </div>
        </Grid></>:null}
        <Grid item md={1} xs={2 }>
          <CustomButtonOutlined onClick={onClick} text="Back" />
        </Grid>
      </Grid>
      {/* <Grid container spacing={2}>
        <Grid item lg={10} md={9} xs={9}>
          {showBackButton ? (
            <div>
              <Grid container>
                <Grid item md={10} xs={10}>
                  <Typography variant="h6" className="header-poppins-12">
                    {title}
                  </Typography>
                </Grid>
                <Grid item md={1} xs={2} style={{ alignSelf: "center" }}>
                  <CustomButtonOutlined onClick={onClick} text="Back" />
                </Grid>
                <Grid
                  item
                  md={1}
                  xs={2}
                  style={{ alignSelf: "center", textAlign: "center" }}
                >
                  <div className="header-type-3">{track}</div>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div>
              <Grid container>
                <Grid
                  item
                  lg={11}
                  md={10}
                  xs={9}
                  style={{ alignSelf: "center" }}
                >
                  <Typography variant="h6" className="header-poppins-12">
                    {title}
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={1}
                  md={1}
                  xs={9}
                  style={{ alignSelf: "center", textAlign: "center" }}
                >
                  <div className="header-type-3">{track}</div>
                </Grid>
              </Grid>
            </div>
          )}
        </Grid>

        <Grid item lg={2} md={3} xs={3}>
          <div className="dis-flex-around">
            <img src={Search} alt="Logo" style={{ width: " 36px" }} />
            <img src={Sort} alt="Logo" style={{ width: " 36px" }} />
            <img src={Filter} alt="Logo" style={{ width: " 36px" }} />
          </div>
        </Grid>
      </Grid> */}
    </div>
  );
}
TabSearchBar.propTypes = {
  showBackButton: PropTypes.bool,
  title: PropTypes.string,
  track: PropTypes.string,
};
