import { Card, CardContent } from "@material-ui/core";
import React from "react";
import { IMAGES, ICONS } from "../../utility/ImagesAndLogo";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#121216",
  },
}));

export function ViewCard() {
  const classes = useStyles();
  return (
    <div>
      {" "}
      <Card
        className={classes.root}
        style={{
          // backgroundImage: `url(${PinkMask})`,
          backgroundImage: `url(${IMAGES.OrangeMask})`,
          borderRadius: "15px",
          backgroundSize: "cover",
        }}
      >
        <CardContent>
          <div className="dis-flex" style={{ justifyContent: "space-evenly" }}>
            <img
              src={ICONS.ViewIcon}
              alt="notFound"
              style={{ width: "40px", marginRight: "20px" }}
            ></img>

            <div>
              <div className="header-poppins-14" style={{ fontSize: "16px" }}>
                0
              </div>
              <div
                className="header-type-3"
                style={{ fontSize: "12px" }}
                // style={{ fontSize: "8px" }}
              >
                Total Views
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
export function CommentCard(props) {
  const { totalComment } = props;
  const classes = useStyles();
  return (
    <div>
      <Card
        className={classes.root}
        style={{
          // backgroundImage: `url(${PinkMask})`,
          backgroundImage: `url(${IMAGES.BlueMask})`,
          borderRadius: "10px",
          backgroundSize: "cover",
        }}
      >
        <CardContent>
          <div className="dis-flex" style={{ justifyContent: "space-evenly" }}>
            <img
              src={ICONS.CommentIcon}
              alt="notFound"
              style={{ width: "40px", marginRight: "20px" }}
            ></img>

            <div>
              <div className="header-poppins-14" style={{ fontSize: "16px" }}>
                {totalComment}
              </div>
              <div className="header-type-3" style={{ fontSize: "12px" }}>
                Total Comments
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
export function LikeCard(props) {
  const { totalLike ,extra} = props;
  const classes = useStyles();
  return (
    <div>
      <Card
        className={classes.root}
        style={{
          // backgroundImage: `url(${PinkMask})`,
          backgroundImage: `url(${IMAGES.BlueMask})`,
          borderRadius: "10px",
          backgroundSize: "cover",
          height: extra?"89px":"none"
        }}
      >
        <CardContent>
          <div className="dis-flex" style={{ justifyContent: "space-evenly" }}>
            <img
              src={ICONS.CommentIcon}
              alt="notFound"
              style={{ width: "40px", marginRight: "20px" }}
            ></img>

            <div>
              <div className="header-poppins-14" style={{ fontSize: "16px" }}>
                {totalLike}
              </div>
              <div className="header-type-3" style={{ fontSize: "12px" }}>
                Total Likes
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
export function ReportedCard(props) {
  const { totalReport } = props;
  const classes = useStyles();
  return (
    <div>
      <Card
        className={classes.root}
        style={{
          // backgroundImage: `url(${PinkMask})`,
          backgroundImage: `url(${IMAGES.PinkMask})`,
          borderRadius: "10px",
          backgroundSize: "cover",
        }}
      >
        <CardContent>
          <div className="dis-flex" style={{ justifyContent: "space-evenly" }}>
            <img
              src={ICONS.ReportedIcon}
              alt="notFound"
              style={{ width: "40px", marginRight: "20px" }}
            ></img>

            <div>
              <div className="header-poppins-14" style={{ fontSize: "16px" }}>
                {totalReport}
              </div>
              <div className="header-type-3" style={{ fontSize: "12px" }}>
                Total Reported
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
export function OrangeCard(props) {
  const { totalBlocked, title } = props;

  const classes = useStyles();
  return (
    <div>
      <Card
        className={classes.root}
        style={{
          // backgroundImage: `url(${PinkMask})`,
          backgroundImage: `url(${IMAGES.OrangeMask})`,
          borderRadius: "10px",
          backgroundSize: "cover",
        }}
      >
        <CardContent>
          <div className="dis-flex" style={{ justifyContent: "space-evenly" }}>
            <img
              src={ICONS.BlockedIcon}
              alt="notFound"
              style={{ width: "40px", marginRight: "20px" }}
            ></img>

            <div>
              <div className="header-poppins-14" style={{ fontSize: "20px" }}>
                {totalBlocked}
              </div>
              <div className="header-type-3" style={{ fontSize: "14px" }}>
                {title}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
export function PinkCard(props) {
  const { totalPink, title } = props;
  const classes = useStyles();
  return (
    <div>
      <Card
        className={classes.root}
        style={{
          // backgroundImage: `url(${PinkMask})`,
          backgroundImage: `url(${IMAGES.PinkMask})`,
          borderRadius: "10px",
          backgroundSize: "cover",
        }}
      >
        <CardContent>
          <div className="dis-flex" style={{ justifyContent: "space-evenly" }}>
            <img
              src={ICONS.ReportedIcon}
              alt="notFound"
              style={{ width: "40px", marginRight: "20px" }}
            ></img>

            <div>
              <div className="header-poppins-14" style={{ fontSize: "20px" }}>
                {totalPink}
              </div>
              <div className="header-type-3" style={{ fontSize: "14px" }}>
                {title}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
export function GreenCard(props) {
  const { totalGreen, title } = props;
  const classes = useStyles();
  return (
    <div>
      <Card
        className={classes.root}
        style={{
          // backgroundImage: `url(${PinkMask})`,
          backgroundImage: `url(${IMAGES.GreenMask})`,
          borderRadius: "10px",
          backgroundSize: "cover",
        }}
      >
        <CardContent>
          <div className="dis-flex" style={{ justifyContent: "space-evenly" }}>
            <img
              src={ICONS.FeaturedIcon}
              alt="notFound"
              style={{ width: "40px", marginRight: "20px" }}
            ></img>

            <div>
              <div className="header-poppins-14" style={{ fontSize: "20px" }}>
                {totalGreen}
              </div>
              <div className="header-type-3" style={{ fontSize: "14px" }}>
                {title}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
export function BlueCard(props) {
  const { totalBlue, title } = props;
  const classes = useStyles();
  return (
    <div>
      <Card
        className={classes.root}
        style={{
          // backgroundImage: `url(${PinkMask})`,
          backgroundImage: `url(${IMAGES.BlueMask})`,
          borderRadius: "10px",
          backgroundSize: "cover",
        }}
      >
        <CardContent>
          <div className="dis-flex" style={{ justifyContent: "space-evenly" }}>
            <img
              src={ICONS.StandardIcon}
              alt="notFound"
              style={{ width: "40px", marginRight: "20px" }}
            ></img>

            <div>
              <div className="header-poppins-14" style={{ fontSize: "20px" }}>
                {totalBlue}
              </div>
              <div className="header-type-3" style={{ fontSize: "14px" }}>
                {title}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
