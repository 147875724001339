import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Avatar from "../../assets/CardIcon/Avatar.png";
// import "./ViewComponent.scss";
import styles from "./ReportedComponentStyle";
import reactDom from "react-dom";
import moment from "moment";
import { constants } from "../../utility/constants";
import { keys } from "@material-ui/core/styles/createBreakpoints";
const useStyles = makeStyles(styles);

export default function ReportedComponent(props) {
  const classes = useStyles();
  const { data, renderElement } = props;

  const [open, setOpen] = React.useState(true);
  var Userimagelink = constants.profileURL; //constants.baseURL + "profilefiles";
  console.log("------------commnet section");
  console.log(data);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const handleClose = () => {
    reactDom.unmountComponentAtNode(renderElement);
    setOpen(false);
  };
  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <Dialog
        // maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className={classes.dialog}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          className={classes.dialogtitle}
        >
          {open ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
          Reported By User
        </DialogTitle>
        {/* <div onClose={handleClose}> view</div> */}
        <DialogContent className={classes.DialogContent}>
          {data === undefined || data.length === 0 ? (
            <div style={{ padding: "50px 200px" }}>No Data Found</div>
          ) : (
            <div>
              {data.map((reportItem, key) => {
                return (
                  <div className="dis-flex" key={key}>
                    {reportItem.map((item, key) => {
                      return (
                        <div className={classes.DataSet}>
                          <div className="dis-flex">
                            <img
                              src={Userimagelink + item.userImage}
                              alt="image_not_Found"
                              className={classes.ViewImage}
                            />
                            <div className="mar-left-10">
                              <div className={classes.CommentData}>
                                <p
                                  style={{ fontSize: "15px" }}
                                  className="opacity-90"
                                >
                                  {item.userName}
                                </p>
                                <p className="font-10 opacity-60 colorwhite">
                                  {/* 12-feb-2019 */}
                                  {moment(item.reportDate).format(
                                    "DD MMM yyyy"
                                  )}
                                </p>
                              </div>
                              <p
                                style={{
                                  fontSize: "10px",
                                  opacity: "0.6",
                                  paddingTop: "3px",
                                  paddingBottom: "9px",
                                }}
                              >
                                +91-{item.userPhone}
                              </p>
                              <p className="font-10 opacity-60 colorwhite">
                                {item.reportMessage}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
