const ViewComponentStyle = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    // top: theme.spacing(1),
    // color: theme.palette.grey[500],
    backgroundColor: "rgba(255, 216, 116, 1)",
    padding: "0px",
    color: "black",
  },
  dialog: {
    borderRadius: "32px",
    borderBottomColor: "red",
  },
  dialogtitle: {
    backgroundColor: "rgba(18, 18, 22, 0.8)",
    // padding: "(20px 0px 0px 12px)",
    color: "rgba(255, 255, 255, 0.9)",
    paddingBottom: "0px",
  },
  DialogContent: {
    backgroundColor: "rgba(18, 18, 22, 0.8)",
    padding: "0px",
    // opacity: "0.5",
  },
});
export default ViewComponentStyle;
