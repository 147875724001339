import React, { useEffect, useState } from 'react'
import { Col, Row, Container, Tab, Tabs, Modal, Button, Form } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { DialogContent, DialogTitle, Grid } from "@material-ui/core";
import upload from "../../assets/images/uploadImage1.png";
import ImageUploader from "react-images-upload";
import CustomTextField from "../../components/Controls/CustomTextField";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import close from "../../assets/images/close.png";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { CustomButtonContained } from "../../components/Controls/CustomButton";
import uploadMusic2 from "../../assets/images/uploadMusic2.png";
import musicImg from "../../assets/images/music.png";
import videoImg from "../../assets/images/video.png";
import musiccover from "../../assets/images/ArtistImage1.png";
import closebtn from "../../assets/images/close.png";
import Dialog from "@material-ui/core/Dialog";
import { FileDrop } from "react-file-drop";
import { addErrorMessage, addLoader, addSuccessMessage, removeLoader } from '../../utility/LoadingContent';
import { constants } from '../../utility/constants';
import { CircularProgressbar } from "react-circular-progressbar";
import { Multiselect } from "multiselect-react-dropdown";
import { AiFillCloseSquare } from "react-icons/ai";
import { useHistory } from 'react-router-dom';
import { deleteDataApi, getdata, patchDataApi, postDataApi } from '../../utility/apiCalling';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
const UploadMusic = ({ location }) => {
  console.log(location)
  let history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [showAlbumSection, setShowAlbumSection] = useState(true);
  const [coverImg, setCoverImg] = useState(null);
  const [albumCoverImg, setAlbumCoverImg] = useState("");
  const [isMusicImgeErrShow, setisMusicImgeErrShow] = useState(false);
  const [isUploadSong, setUploadSong] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isAlbumNameErrShow, setAlbumNameErr] = useState(false);
  const [disableImgDelete, setDisableImgDelete] = useState(false);
  const [removeSongsList, setRemoveSongsList] = useState([]);
  const [isUploadSongsSectionShow, setUploadSongsSection] = useState(false);
  const [dragNDropText, setDragNDropText] = useState(
    "Drag & Drop your image here or browse"
  );
  const [isAddCategoryErrShow, setAddCategoryErr] = useState(false);
  const [albumDetails, setAlbumDetails] = useState({
    albumName:
      location.state !== undefined && location.state !== null && location.state.item !== undefined
        ? location.state.item.name
        : "",
    albumType:
      location.state !== undefined && location.state !== null && location.state.item !== undefined
        ? location.state.item.albumType
        : location.state.type,
    albumurlImage:
      location.state !== undefined && location.state !== null && location.state.item !== undefined
        ? location.state.item.urlImage
        : "",
    categoryList: [],
    selectedCategoryList: [],
    existingCategoryList: [],
    id:
      location.state !== undefined && location.state !== null && location.state.id !== undefined
        ? location.state.id
        : 0,
    existingMusicList: [],
  });
  const [songsList, setSongsList] = useState([]);
  const [songsListIDCount, setSongsListIDCount] = useState(0);
  const [musicCategories, setMusicCategories] = useState([]);
  const multiSelectStyle = {
    multiselectContainer: {
      color: "white",
      fontSize: 14,
    },
    option: {
      // To change css for dropdown options
      // color: "#121216",
    },
    chips: {
      // background: "#121216",
      fontSize: 15,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 8,
      paddingBottom: 8,
      borderRadius: 8,
    },
    searchBox: {
      border: "1px solid #8186AF",
      borderRadius: "2px",
      //fontFamily: 'Mr Eaves Mod OT',
      fontSize: 15,
      minHeight: 40,

      //width: 395,
    },
  };
  let audioReg = /\.(?:mp3|ogg|acc|wav|m4p)$/i;
  let videoReg = /\.(?:webm|mkv|flv|vob|mp4)$/i;
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  const onDrop = (file) => fileValidation(file[0]);
  const onUploadImg = (file) => fileValidation(file[0]);
  const onSelectCategory = (selectedList) => {
    setAlbumDetails((prevState) => {
      return { ...prevState, selectedCategoryList: selectedList };
    });
    setAddCategoryErr(false);
    console.log(
      "albumDetails.selectedCategoryList: ",
      albumDetails.selectedCategoryList
    );
  };
  const onRemoveSelectCategory = (selectedList) => {
    setAlbumDetails((prevState) => {
      return { ...prevState, selectedCategoryList: selectedList };
    });
  };
  const handleClose = () => {
    // window.location.reload();
    // reactDom.unmountComponentAtNode(renderElement);
    setOpen(false);
  };
  const fileValidation = (file) => {
    if (file !== undefined) {
      // Allowing file type
      let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      if (!allowedExtensions.exec(file.name)) {
        addErrorMessage("Please upload a image file!");
        //enqueueSnackbar("Please upload a image file!", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right', } });
        file = "";
        setDragNDropText("Drop your image here or browse");
        return false;
      } else {
        let imageSrc = URL.createObjectURL(file);
        setDragNDropText("Drop your image here or browse");
        setCoverImg(imageSrc);
        setAlbumCoverImg(file);
      }
    }
  };
  const onNext = () => {
    const { albumName, selectedCategoryList } = albumDetails;
    if (!albumName && selectedCategoryList.length === 0 && coverImg === null) {
      setAlbumNameErr(true);
      setAddCategoryErr(true);
      addErrorMessage("Upload a cover image!");
    } else if (!albumName) {
      setAlbumNameErr(true);
    } else if (
      //albumCoverImg.length === 0
      albumDetails.id === 0
        ? albumCoverImg.length === 0
        : albumDetails.albumurlImage.length === 0
    ) {
      // console.log(albumCoverImg);
      // console.log("error in Music");
      setisMusicImgeErrShow(true);
    } else if (selectedCategoryList.length === 0) {
      setAddCategoryErr(true);
    }
    else {
      setShowAlbumSection(false);
      // setShowMusicSection(true);
      if (albumDetails.id !== 0) {
        getMusicsByAlbum();
      } else {
        setSongsList([]);
      }
      setDragNDropText("Drag & Drop your file here or browse");
    }
  };

  const getMusicsByAlbum = async () => {
    let resMusics = await getdata(
      constants.baseURL + "musics?filter=album.id||$eq||" + albumDetails.id
    ); //location.state.id
    console.log("resMusics: ", resMusics);
    if (resMusics != undefined && resMusics.data.length > 0) {
      let itms = [];
      resMusics.data.map((m, i) => {
        itms.push({
          id: i + 1,
          file: {
            name: m.title,
            size:
              m.size !== undefined && m.size !== null && m.size !== ""
                ? m.size
                : 0,
          },
          isExists: true,
          itemId: m.id,
        });
      });
      setTimeout(() => {
        setSongsListIDCount(resMusics.data.length + 1);
      });
      setSongsList([...itms]);
      setAlbumDetails((prevState) => {
        return { ...prevState, existingMusicList: resMusics.data };
      });
    }
  };
  const getSubcategories = async () => {
    let resSubcategories = await getdata(
      constants.baseURL + "subcategories?filter=category.id||$eq||1&filter=isDeleted||$eq||0"
    );
    console.log("resSubcategories: ", resSubcategories);
    if (resSubcategories != undefined && resSubcategories.data.length > 0) {
      setMusicCategories([...resSubcategories.data]);
      setAlbumDetails((prevState) => {
        return {
          ...prevState,
          categoryList: [...resSubcategories.data],
        };
      });
      if (albumDetails.id !== 0) {
        console.log("JK")
        //location.state.id != undefined) {
        getAlbumCategories(resSubcategories.data);
      }
    }
  };
  const onDeleteSongList = (id) => {
    if (songsList.length !== 0) {
      let newArr = songsList.filter((song) => song.id !== id);
      setSongsList(newArr);

      let fltDelSong = songsList.filter((song) => song.id === id);
      console.log("Remvoed Existing Song: ", fltDelSong);
      if (
        fltDelSong.length > 0 &&
        fltDelSong[0].isExists != undefined &&
        fltDelSong[0].isExists == true
      ) {
        let dSong = albumDetails.existingMusicList.filter(
          (m) => m.id == fltDelSong[0].itemId
        );
        setRemoveSongsList([...removeSongsList, ...dSong]);
      }
    }
  };
  const getAlbumCategories = async (categories) => {
    let resSubcategories = await getdata(
      constants.baseURL +
      "selectedalbumcategories?filter=album.id||$eq||" +
      albumDetails.id
    ); //location.state.id
    console.log("getAlbumCategories: ", resSubcategories);
    console.log("JPBRO")
    if (resSubcategories != undefined && resSubcategories.data.length > 0) {
      let sItems = [];
      resSubcategories.data.map((p) => {
        let fltCategories = categories.filter((c) => c.id == p.subcategory.id);
        sItems.push(fltCategories[0]);
      });

      setAlbumDetails((prevState) => {
        return {
          ...prevState,
          selectedCategoryList: [...sItems],
          existingCategoryList: [...resSubcategories.data],
        };
      });
    }
  };
  const onSelectMusicOrVideoFileValidate = (file, fromFun) => {
    console.log("jay3")
    if (songsList.length === 0) {
      console.log("jay4")
      console.log("Empty list...");
      return loadFileDuration(file).then((resp) => {
        console.log("resp: ", resp);
        console.log("jay6")
        addSongFileToSongList(resp, fromFun);
      });
    } else {
      let newFiles = [];
      return loadFileDuration(file).then((resp) => {
        console.log("resp: ", resp);
        for (let i = 0; i < resp.length; i++) {
          let fileExits = songsList.filter((f) => f.file.name == file[i].name);
          if (fileExits.length == 0) {
            newFiles.push(file[i]);
          } else {
            addErrorMessage(file[i].name + "already added");
          }
        }
        console.log("newFiles: ", newFiles);
        addSongFileToSongList(newFiles, fromFun);
      });
    }
  };
  const loadFileDuration = (uplFiles) => {
    let promises_array = [];
    //uplFiles.map((file) => {
    for (let i = 0; i < uplFiles.length; i++) {
      promises_array.push(
        new Promise((resolve, reject) => {
          // Create instance of FileReader
          let reader = new FileReader();
          // When the file has been succesfully read
          reader.onload = (event) => {
            // Create an instance of AudioContext
            let audioContext = new (window.AudioContext ||
              window.webkitAudioContext)();
            // Asynchronously decode audio file data contained in an ArrayBuffer.
            audioContext.decodeAudioData(event.target.result, (buffer) => {
              // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
              uplFiles[i]["duration"] = buffer.duration;
              resolve(uplFiles[i]);
            });
          };
          // In case that the file couldn't be read
          reader.onerror = (event) => {
            console.error("An error ocurred reading the file: ", event);
            reject(false);
          };
          console.log("jay5")
          // Read file as an ArrayBuffer, important !
          reader.readAsArrayBuffer(uplFiles[i]);
        })
      );
    }
    return Promise.all(promises_array);
  };
  const addSongFileToSongList = (file, fromFun) => {
    console.log("jayraj", file)
    console.log("jay7")
    let song_upload_input = document.getElementById("song_upload_input");
    let count = songsListIDCount;
    let songsitems = [];
    if (albumDetails.albumType === "music") {
      console.log("jay8")
      for (let i = 0; i < file.length; i++) {
        if (!audioReg.exec(file[i].name)) {
          addErrorMessage("Please upload a audio file!");
          if (fromFun === "fromDragNDropFun") {
            setDragNDropText("Drag & Drop your file here or browse");
          }
          return false;
        } else {
          console.log("jay9")
          count = count + 1;
          songsitems.push({ id: count, file: file[i] });
        }
      }
      setSongsListIDCount(count);
      setTimeout(() => {
        console.log("jay10")
        console.log("file: ", songsListIDCount, "--", file);
        setSongsList([...songsList, ...songsitems]);
        song_upload_input.value = "";
      });
      console.log("jay11")
    } else if (albumDetails.albumType === "video") {
      for (let i = 0; i < file.length; i++) {
        if (!videoReg.exec(file[i].name)) {
          addErrorMessage("Please upload a video file!");
          if (fromFun === "fromDragNDropFun") {
            setDragNDropText("Drag & Drop your file here or browse");
          }
          return false;
        } else {
          count = count + 1;
          songsitems.push({ id: count, file: file[i] });
        }
      }
      setSongsListIDCount(count);
      setTimeout(() => {
        console.log("file: ", songsListIDCount, "--", file);
        setSongsList([...songsList, ...songsitems]);
        song_upload_input.value = "";
      });
    }
    console.log("jay12")
  };
  const onSelectMusicOrVideoFile = (event) => {
    if (event) {
      if (albumDetails.albumType === "music") {
        console.log("jay1")
        for (let i = 0; i < event.target.files.length; i++) {
          if (!audioReg.exec(event.target.files[i].name)) {
            addErrorMessage("Please upload a audio file!");
            return false;
          }
        }

      } else if (albumDetails.albumType === "video") {
        for (let i = 0; i < event.target.files.length; i++) {
          if (!videoReg.exec(event.target.files[i].name)) {
            addErrorMessage("Please upload a video file!");
            return false;
          }
        }
      }
      console.log("jay2")
      onSelectMusicOrVideoFileValidate(event.target.files);
    }
  };
  const renderSongsList = () => {
    console.log("songsList: ", songsList);
    if (songsList.length !== 0) {
      return songsList.map((song) => {
        let fileName = song.file.name.split(".")[0];
        let fileSizeInMB = song.file.size / 1024 / 1024;
        return (
          <div key={song.id}>

            {/* <Grid item md={12} className="mt-20" > */}
            {/* <div className='upload-song-list'> */}
            <div className='upload-song-admin '>
              <Grid container spacing={1} className='uploaded d-flex-space-between '>
                <Grid item md={"auto"}>
                  <img src={
                    albumDetails.albumurlImage != undefined &&
                      albumDetails.albumurlImage != ""
                      ? constants.albumFileURL + albumDetails.albumurlImage
                      : coverImg === null
                        ? albumDetails.albumType === "music"
                          ? musicImg
                          : videoImg
                        : coverImg
                  } className="music-cover" alt=""></img>
                </Grid>
                <Grid item md={"10"} lg={"9"}>
                  <div className='song-details'>{fileName}</div>
                  <div className='song-time'>{fileSizeInMB.toFixed(1)}MB</div>
                </Grid>
                <Grid item md={"auto"}>
                  {disableImgDelete == false && (<img src={closebtn} className="close-btn" alt="hello" onClick={() => onDeleteSongList(song.id)}></img>)}
                </Grid>
              </Grid>
            </div>

            {/* </div> */}
            {/* <Grid container spacing={3}>
                  <Grid item md={6} xs={12} className='add-artist-btn'>
                    <CustomButtonContained text="Back" />
                  </Grid>
                  <Grid item md={6} xs={12} className='add-artist-btn'>
                    <CustomButtonContained text="Save" />
                  </Grid>
                </Grid> */}
            {/* </Grid> */}


            {/* <div className="col-12">
              <div className="download-music-box">
                <div className="row">
                  <div className="col-3 col-md-2">
                    <img
                      className="episode-img"
                      src={
                        albumDetails.albumurlImage != undefined &&
                          albumDetails.albumurlImage != ""
                          ? constants.albumFileURL + albumDetails.albumurlImage
                          : coverImg === null
                            ? albumDetails.albumType === "music"
                              ? musicImg
                              : videoImg
                            : coverImg
                      }
                      alt=""
                    />
                  </div>
                  <div className="col-9 col-md-10">
                    <div className="row justify-content-between">
                      <div className="col-auto">
                        <div className="download-title">{fileName}</div>
                      </div>
                      <div className="col-auto">
                        {disableImgDelete == false && (
                          <img
                            className="close-btn"
                            onClick={() => onDeleteSongList(song.id)}
                            src={close}
                            alt="cover-image"
                          />
                        )}
                      </div>
                    </div>
                    <div className="row justify-content-around align-items-center">
                      <div className="col-12">
                        <div className="download-size">
                          {fileSizeInMB.toFixed(1)}MB
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        );
      });
    } else {
      return (
        <div className='errorText' style={{ fontSize: 13 }}>
          Song list is empty! Please upload a song.
        </div>
      );
    }
  };
  const onBack = () => {
    setShowAlbumSection(true);
    // setShowMusicSection(false);

    setUploadSongsSection(false);
    setDragNDropText("Drag & Drop your image here or browse");
  };
  const onUploadSonglist = async () => {
    if (songsList.length === 0) {
      addErrorMessage("Upload atleast one music file!");
    } else {
      //Check if album with same exists or not
      let respName = await getAlbumByName();
      console.log("respName: ", respName.data, albumDetails);
      if (albumDetails.id === 0 && respName.data.length > 0) {
        addErrorMessage("Album with the same name already exists!");
        return;
      } else if (albumDetails.id !== 0 && respName.data.length > 0 && albumDetails.id !== respName.data[0].id) {
        addErrorMessage("Album with the same name already exists!");
        return;
      }
      //Check if album with same exists or not
      setUploadSong(true);
      setDisableImgDelete(true);
      uploadCoverImage();
    }
  };
  const uploadCoverImage = async () => {
    addLoader();
    if (albumCoverImg != "") {
      console.log("raj1")
      let customHeaders = {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer ",
      };
      let data = new FormData();
      data.append(constants.uplFileParameter, albumCoverImg);
      let res = await postDataApi(
        //constants.baseURL + "albumfiles",
        constants.albumFileUploadURL,
        data,
        customHeaders
      );
      console.log(res)
      if (
        res != undefined &&
        res.data != undefined &&
        res.data.status != undefined &&
        res.data.status === 200
      ) {
        console.log("raj2")
        removeLoader();
        setAlbumCoverImg("");
        setUploadProgress(25);
        createAlbum(res.data.data.filename);
      }
    } else {
      removeLoader();
      setUploadProgress(25);
      createAlbum(albumDetails.id !== 0 ? albumDetails.albumurlImage : ""); //createAlbum(location.state.id != undefined ? albumDetails.albumurlImage : "");
    }
  };
  const getAlbumByName = async () => {
    let resAlbum = await getdata(
      // constant.baseURL + "albums?filter=name||$eq||" + albumDetails.albumName
      constants.baseURL + "albums?filter=name||$eq||" + albumDetails.albumName + "&filter=isDeleted||$eq||0"
    );
    return resAlbum;
  }
  const createAlbum = async (coverImageName) => {
    if (currentUserInfo !== undefined) {
      console.log("raj3")
      addLoader();
      let jsonData = {
        name: albumDetails.albumName,
        user: location.state.ArtistId,
        albumType: albumDetails.albumType,
        urlImage: coverImageName,
      };
      console.log(jsonData);
      var formBody = [];
      for (var property in jsonData) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(jsonData[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let respAlbum;
      let sStatus = "";
      if (albumDetails.id !== 0) {
        respAlbum = await patchDataApi(
          constants.baseURL + "albums/" + albumDetails.id,
          formBody,
          authHeader
        );
        if (
          respAlbum != undefined &&
          respAlbum.status != undefined &&
          respAlbum.status == 200
        ) {
          sStatus = "Success";
        }
      } else {

        console.log("raj4")
        respAlbum = await postDataApi(
          constants.baseURL + "albums",
          formBody,
          authHeader
        );
        if (
          respAlbum != undefined &&
          respAlbum.status != undefined &&
          respAlbum.status == 201
        ) {
          sStatus = "Success";
        }
      }
      //if (respAlbum != undefined && respAlbum.status != undefined && respAlbum.status == 201) {
      if (sStatus == "Success") {
        console.log("raj5")
        setUploadProgress(50);
        uploadMusicFiles(respAlbum.data.id);
      }
    }
  };
  const uploadMusicFiles = async (albumId) => {
    console.log("raj6")
    addLoader();
    let customHeaders = {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer ",
    };
    let data = new FormData();
    songsList.map((m) => {
      if (m.isExists == undefined || m.isExists == false) {
        //data.append(process.env.REACT_APP_NODE_ENV === "development"?"file":"image", m.file);
        data.append(constants.uplFileParameter, m.file);
      }
    });
    let res = await postDataApi(
      constants.musicFileUploadURL, //process.env.REACT_APP_NODE_ENV === "development" ? constants.baseURL + "musicfiles/uploadMultipleFiles" : constants.baseURL + "musicfiles",
      data,
      customHeaders
    );
    console.log("Upload Music Files: ", res.data);
    if (
      res != undefined &&
      res.status != undefined &&
      res.status === 201 &&
      res.data != undefined &&
      res.data.data != undefined
    ) {
      console.log("raj7")
      setUploadProgress(75);
      return saveAllMusics(albumId, res.data.data.length>=0 ? res.data.data : [res.data.data]).then((response) => {
        console.log("FFFF", response, location)
        if (
          (response != undefined &&
            response.length > 0 &&
            response[0] == true) ||
          ((location.state !== undefined && location.state.id !== undefined) || (location.state !== undefined && location.state.ArtistId !== undefined))
        ) {
          console.log("PPPPPPPPPPPP")
          return saveAlbumCategories(albumId).then((response) => {
            if (
              response != undefined &&
              response.length > 0 &&
              response[0] == true
            ) {
              console.log("qqqqqqqqqq")
              removeLoader();
              setUploadProgress(100);
              addSuccessMessage(
                location?.state?.id != undefined
                  ? "Album Updated"
                  : "Album Created"
              );
              console.log("raj12")
              if (albumDetails.id !== 0) {
                history.push({
                  pathname: `user-details/${location.state.ArtistId}`,
                  state: {
                    ArtistId: location.state.ArtistId,
                  },
                });
              } else {
                removeLoader();
                history.push({
                  pathname: `user-details/${location.state.ArtistId}`,
                  state: {
                    ArtistId: location.state.ArtistId,
                  },
                });
              }
            } else {
              resetUploadMusic(0);
            }
          });
        } else {
          resetUploadMusic(0);
        }
      });
    } else {
      resetUploadMusic(0);
      addErrorMessage("Error in uploading music in album");
    }
  };
  const saveAllMusics = async (albumId, uploadedMusicFiles) => {

    console.log("raj8")
    let promises_array = [];
    uploadedMusicFiles.map(async(item) => {
      console.log("raj9")
      await promises_array.push(
        await new Promise(async(resolve, reject) => {
          //get file size
          let fItem = songsList.filter(
            (f) => f.file.name === item.originalname
          );
          console.log(fItem)
          //prepare json
          let jsonData = {
            title: item.originalname.split(".")[0],
            album: albumId,
            musicType: albumDetails.albumType,
            urlImage: item.filename,
            size: fItem.length > 0 ? fItem[0].file.size : 0,
            duration: fItem.length > 0 ? fItem[0].file.duration : 0,
          };
          return await createAlbumMusic(jsonData)
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              reject(false);
            });
        })
      );
    });
    //Delete existing song if removed by user
    if (albumDetails.id !== 0 && removeSongsList.length > 0) {
      //if (location.state.id != undefined && removeSongsList.length > 0) {
      removeSongsList.map(async(item) => {
        await promises_array.push(
          await new Promise(async(resolve, reject) => {
            return await deleteAlbumMusic(item.id)
              .then(() => {
                resolve(true);
              })
              .catch((error) => {
                reject(false);
              });
          })
        );
      });
    }

    console.log("DDDDDDD", promises_array)
    return Promise.all(promises_array);
  };
  const saveAlbumCategories = async (albumId) => {
    let promises_array = [];
    albumDetails.selectedCategoryList.map((item) => {
      promises_array.push(
        new Promise((resolve, reject) => {
          let jsonData = { album: albumId, subcategory: item.id };
          return createAlbumCategories(jsonData)
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              reject(false);
            });
        })
      );
    });
    //
    if (albumDetails.id !== 0 && albumDetails.existingCategoryList.length > 0) {
      // if (location.state.id != undefined && albumDetails.existingCategoryList.length > 0) {
      albumDetails.existingCategoryList.map((item) => {
        promises_array.push(
          new Promise((resolve, reject) => {
            let fExists = albumDetails.selectedCategoryList.filter(
              (x) => x.id == item.subcategory.id
            );
            if (fExists.length == 0) {
              return deleteAlbumCategories(item.id)
                .then(() => {
                  resolve(true);
                })
                .catch((error) => {
                  reject(false);
                });
            } else {
              resolve(true);
            }
          })
        );
      });
    }
    return Promise.all(promises_array);
  };
  const resetUploadMusic = (progressValue) => {
    setDisableImgDelete(false);
    setUploadProgress(progressValue);
    setUploadSongsSection(false);
    setCoverImg(null);
    setAlbumCoverImg("");
    setUploadSong(false);
    console.log("musicCategories: ", musicCategories);
    removeLoader();
    setAlbumDetails({
      albumName: "",
      albumType: "music",
      //categoryList: musicCategories,
      selectedCategoryList: [],
    });
    if (albumDetails.id !== 0) {
      //if (location.state.id != undefined) {
        history.push({
          pathname: `user-details/${location.state.ArtistId}`,
          state: {
            ArtistId: location.state.ArtistId,
          },
        });
    } else {
      removeLoader();
      history.push({
        pathname: `user-details/${location.state.ArtistId}`,
        state: {
          ArtistId: location.state.ArtistId,
        },
      });
    }
  };
  const createAlbumMusic = async (jsonData) => {
    console.log("raj10")
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let resExits = await getdata(
      constants.baseURL +
      "musics?filter=album.id||$eq||" +
      jsonData.album +
      "&filter=title||$eq||" +
      jsonData.title
    );
    // let fItem = songsList.filter(
    //   (f) => f.file.name === resExits.data[0].
    // );
    console.log("resExits: ", resExits);
    if (resExits.data.length >= 0) {
      return await Promise.resolve(
        postDataApi(constants.baseURL + "musics", formBody, authHeader)
      )
        .then((response) => {
          if (
            response != undefined &&
            response.status != undefined &&
            response.status == 201
          ) {
            console.log("raj11")
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          return false;
        });
    } else {
      return false;
    }
  };
  const deleteAlbumMusic = async (id) => {
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return await Promise.resolve(
      await deleteDataApi(constants.baseURL + "musics/" + id, authHeader)
    )
      .then((resDel) => {
        console.log("resDel: ", resDel);
        if (resDel != undefined && resDel.status == 200) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        return false;
      });
  };
  const createAlbumCategories = async (jsonData) => {
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let resExits = await getdata(
      constants.baseURL +
      "selectedalbumcategories?filter=album.id||$eq||" +
      jsonData.album +
      "&filter=subcategory.id||$eq||" +
      jsonData.subcategory
    );
    console.log("resExits: ", resExits);
    if (resExits.data.length == 0) {
      return await Promise.resolve(
        postDataApi(
          constants.baseURL + "selectedalbumcategories",
          formBody,
          authHeader
        )
      )
        .then((response) => {
          if (
            response != undefined &&
            response.status != undefined &&
            response.status == 201
          ) {
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          return false;
        });
    } else {
      return false;
    }
  };
  const deleteAlbumCategories = async (id) => {
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return await Promise.resolve(
      await deleteDataApi(
        constants.baseURL + "selectedalbumcategories/" + id,
        authHeader
      )
    )
      .then((resDel) => {
        console.log("resDel: ", resDel);
        if (resDel != undefined && resDel.status == 200) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        return false;
      });
  };
  useEffect(() => {
    getSubcategories();
  }, []);
  return (
    <div className={classes.root}>
      {showAlbumSection == true ? (
        <div className='upload-song add-artist-main'>

          <Grid container spacing={3}>

            <Grid item xs={12} lg={6}>
              <div className='upload-music-main  '>
                {coverImg === null ? (

                  <FileDrop
                    onDragOver={() =>
                      setDragNDropText("Drop your image here!!")
                    }
                    onDragLeave={() =>
                      setDragNDropText("Drop your file here")
                    }
                    onDrop={onDrop}
                  >
                    <div className='upload-background'>
                      <img src={
                        albumDetails.albumurlImage != undefined &&
                          albumDetails.albumurlImage != ""
                          ? constants.albumFileURL +
                          albumDetails.albumurlImage
                          : upload
                      }
                        className="user-profile" alt="upload picture"></img>
                      <div className="upload-text mt-20">Upload Cover Image</div>
                      <div className="upload-subtitle mt-10">Drop your file here or browse</div>
                      <div style={{ textAlign: 'center' }}>
                        <ImageUploader
                          className="imageUploaderNew"
                          withIcon={false}
                          buttonText="Browse"
                          label=""
                          onChange={(file) => onUploadImg(file)}
                          imgExtension={constants.supportedImageFiles}
                          singleImage={true}
                        />
                      </div>
                    </div>
                  </FileDrop>
                ) : (
                  <FileDrop
                    onDragOver={() =>
                      setDragNDropText("Drop your image here!!")
                    }
                    onDragLeave={() =>
                      setDragNDropText("Drop your file here")
                    }
                    onDrop={onDrop}
                  >
                    <div className='upload-background'>
                      <img src={coverImg} id="uploaded_cover_img" className="user-profile" alt="cover-image" onClick={() => setCoverImg(null)}></img>

                      <ImageUploader
                        className="imageUploaderNew"
                        withIcon={false}
                        buttonText="Browse"
                        label=""
                        onChange={(file) => onUploadImg(file)}
                        imgExtension={constants.supportedImageFiles}
                        singleImage={true}
                      />

                    </div>
                  </FileDrop>
                )}
                {isMusicImgeErrShow && (
                  <div className="errorText" style={{ marginTop: "0px" }}>
                    Please Upload Image File
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div className='upload-music-main  h-100 '>
                <div className="upload-text  ">Album Details</div>
                {/* {isUploadSong ? (
                  <div className="col-2 progress_bar">
                    <CircularProgressbar
                      value={uploadProgress}
                      maxValue={100}
                      minValue={0}
                      text={`${uploadProgress}%`}
                    />
                  </div>
                ) : null} */}
                <Grid item md={12} className="mt-20" >
                  <CustomTextField
                    name="userName"
                    label="User Name"
                    onChange={(e) => {
                      setAlbumDetails({
                        ...albumDetails,
                        albumName: e.target.value,
                      });
                      setAlbumNameErr(false);
                    }}
                    value={albumDetails.albumName}
                    required={true}
                    fullWidth
                  ></CustomTextField>
                  {isAlbumNameErrShow && (
                    <div
                      className="errorText"
                      style={{ marginTop: "0px" }}
                    >
                      Enter Name
                    </div>
                  )}
                </Grid>
                <div className="login-sub-title mt-10 mb-10">Join us and Listen your favourite music</div>
                <div className='upload-control custom-upload'>
                  <Grid container spacing={3}>
                    <Grid item md={6} className="mt-20 w-sm-100" >
                      <div className='selection-page'>
                        Video <span><FormControlLabel value="female" control={<Radio checked={albumDetails.albumType === "video"}
                          disabled={
                            // albumDetails.id !== 0
                            //   ? albumDetails.albumType !== "video" &&
                            true
                            // : false
                          }
                          onChange={() => {
                            setAlbumDetails((prevState) => {
                              return {
                                ...prevState,
                                albumType: "video",
                              };
                            });
                          }} />} /></span>
                      </div>
                    </Grid>
                    <Grid item md={6} className="mt-20 w-sm-100"  >
                      <div className='selection-page'>
                        Audio <span><FormControlLabel value="female" control={<Radio disabled={
                          // albumDetails.id !== 0
                          //   ? albumDetails.albumType !== "music" &&
                          true
                          // : false
                        }
                          checked={albumDetails.albumType === "music"}
                          onChange={() => {
                            setAlbumDetails((prevState) => {
                              return {
                                ...prevState,
                                albumType: "music",
                              };
                            });
                          }} />} /></span>
                      </div>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      {/* <div className="complete-profile">
                        <div className="floating-label "> */}
                      <Multiselect
                        options={albumDetails.categoryList}
                        selectedValues={albumDetails.selectedCategoryList}
                        onSelect={onSelectCategory}
                        onRemove={onRemoveSelectCategory}
                        displayValue="name"
                        placeholder="Add Category"
                        style={multiSelectStyle}
                        // ClearIcon
                        // closeIcon="circle"
                        customCloseIcon={
                          <AiFillCloseSquare className="multiselect-close-icon" />
                        }
                      />
                      {isAddCategoryErrShow && (
                        <div
                          className="errorText"
                          style={{ marginTop: "0px" }}
                        >
                          Add Category
                        </div>
                      )}
                      {/* </div>
                      </div> */}
                    </Grid>
                    {/* <Grid item md={12} xs={12} className='add-artist-btn'> */}
                    {/* {albumDetails.id !== 0 ? <> */}
                    <Grid item md={6} xs={6} className='add-artist-btn'>
                      <CustomButtonContained text="Cancel" onClick={() => {
                        history.push({
                          pathname: `user-details/${location.state.ArtistId}`,
                          state: {
                            ArtistId: location.state.ArtistId,
                          },
                        });
                      }} />
                    </Grid>
                    <Grid item md={6} xs={6} className='add-artist-btn'>
                      <CustomButtonContained text="Next" onClick={() => onNext()} />
                    </Grid>
                    {/* </> :   */}
                    {/* <CustomButtonContained text="Next"   onClick={() => onNext()} />
                 } */}
                    {/* </Grid> */}
                  </Grid>
                </div>
              </div>
            </Grid>

          </Grid>
        </div>) : (
        <div className='upload-song'>

          <Grid container spacing={3}>
            <Grid item xs={12} lg={6} >
              <div className='upload-music-main text-center '>
                <FileDrop
                  onDragOver={() =>
                    setDragNDropText("Drop your file here!!")
                  }
                  onDragLeave={() =>
                    setDragNDropText("Drag & Drop your file here or browse")
                  }
                  onDrop={(e) =>
                    onSelectMusicOrVideoFileValidate(e, "fromDragNDropFun")
                  }
                >
                  <div className="centerTextInDiv h-100" style={{ height: 350 }}>
                    <label for="song_upload_input">
                      <img
                        src={uploadMusic2}
                        style={{ marginTop: 55 }}
                        alt="upload file"
                        id={0}
                      />
                    </label>
                    <input
                      onChange={onSelectMusicOrVideoFile}
                      className="custom-file-input"
                      type="file"
                      style={{ display: "none" }}
                      name="song_upload_input"
                      id="song_upload_input"
                      accept={
                        albumDetails.albumType === "music"
                          ? constants.acceptedAudio
                          : constants.acceptedVideo
                      }
                    />
                  </div>
                </FileDrop>
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div className='upload-music-main  h-100 '>
                <div className="upload-text  ">Album Songs</div>
                {/* {isUploadSong ? (
                  <div className="col-2 progress_bar">
                    <CircularProgressbar
                      value={uploadProgress}
                      maxValue={100}
                      minValue={0}
                      text={`${uploadProgress}%`}
                    />
                  </div>
                ) : null} */}
                <Grid item md={12} className="mt-20" >
                  <div className='upload-song-list'>
                    {/* <div className='upload-song-admin'> */}
                    <div
                      className={
                        songsList.length === 0 ? "centerTextInDiv" : ""
                      }
                      style={{
                        height: 350,
                        overflowX: "hidden",
                        overflowY: "auto",
                      }}
                    >
                      {renderSongsList()}
                    </div>
                    {/* </div> */}

                  </div>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12} className='add-artist-btn'>
                      <CustomButtonContained text="Back" onClick={() => onBack()} />
                    </Grid>
                    <Grid item md={6} xs={12} className='add-artist-btn'>
                      <CustomButtonContained text={albumDetails.id !== 0 ? "Update" : "Save"} onClick={onUploadSonglist} />
                    </Grid>
                  </Grid>
                </Grid>


              </div>
            </Grid>

          </Grid>
        </div>
      )}
    </div >

  )
}

export default UploadMusic