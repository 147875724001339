import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Button } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  contained: {
    backgroundColor: "#FFD874",
    color: "black",
    fontSize: "10px",
    borderRadius: "6px",
    padding: "8px 0px",
    textTransform: "capitalize",
  },
  outlined: {
    borderColor: "#FFD874",
    color: "#FFD874",
    fontSize: "10px",
    borderRadius: "6px",
    padding: "8px 0px",
    textTransform: "capitalize",
  },
  text: {
    // borderColor: "#FFD874",
    color: "#FFD874",
    fontSize: "10px",
    // borderRadius: "6px",
    padding: "8px 0px",
    textTransform: "capitalize",
  },
}));

export function CustomButtonContained(props) {
  const { variant, onClick, color, text, ...other } = props;
  const classes = useStyles();
  return (
    <Button
      fullWidth
      // color={color || "primary"}
      variant="contained"
      onClick={onClick}
      {...other}
      className={classes.contained}
    >
      {text}
    </Button>
  );
}
export function CustomButtonOutlined(props) {
  const { variant, onClick, color, text, ...other } = props;
  const classes = useStyles();
 
  return (
    <Button
      fullWidth
      // color={color || "primary"}
      variant={"outlined" || variant}
      onClick={onClick}
      {...other}
      className={classes.outlined}
    >
      {text}
    </Button>
  );
}
export function CustomButtonText(props) {
  const { variant, onClick, color, text, ...other } = props;
  const classes = useStyles();
  return (
    <Button
      fullWidth
      // color={color || "primary"}
      // variant={"outlined" || variant}
      onClick={onClick}
      {...other}
      className={classes.outlined}
    >
      {text}
    </Button>
  );
}
