import React from "react";
import { Switch } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
// const styledBy = (property, mapping) => (props) => mapping[props[property]];
// const useStyles = makeStyles((theme) => ({
//   toggle: {
//     "& .Mui-checked": {
//       color: "#FFD874",

//       // transform: "translateX(25px) !important",
//     },
//     "& .MuiSwitch-thumb": {
//       color: "#FFD874",
//     },
//     "&.Mui-checked + .MuiSwitch-track": {
//       backgroundColor: "#008000e0",
//     },
//   },
//   switch_primary: {
//     "&.Mui-checked": {
//       color: "#4CAF50",
//     },
//     "&.Mui-checked + .MuiSwitch-track": {
//       backgroundColor: "#4CAF50",
//     },
//   },
// }));

export default function CustomSwitch(props) {
  console.log("--customswitch--",props);
  const { checked, onChange, disabled, ...other } = props;
  // const classes = useStyles();
  return (
    <div>
      <Switch
        // className={classes.toggle + " " + classes.switch_primary}
        checked={checked}
        edge="start"
        color="secondary"
        disabled={disabled}
        onChange={onChange}
        {...other}
      />
    </div>
  );
}
