import { Grid } from "@material-ui/core";
import React from "react";
import CardCategory from "../../components/CardNew/CardCategory";
import CardSmallUserInformation from "../../components/CardNew/CardSmallUserInformation";
import CustomTabSecond from "../../components/customTab/CustomTabSecond";
import { constants } from "../../utility/constants";
import IconMoreCircle from "../../components/ArtistDetails/Icon-More-Circle.png";
import CustomSwitch from "../../components/Controls/CustomSwitch";
export default function ArtistUserAlbumDetails(props) {
  const { ArtistData, MakeFeatured, onChange1 } = props;
  var Userimagelink = constants.profileURL; //constants.baseURL + "profilefiles";

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <CardSmallUserInformation
            isGray
            isTransparentprofile
            userName={ArtistData.firstName + " " + ArtistData.lastName}
            userEmail={ArtistData.email}
            urlImageUser={Userimagelink + ArtistData.urlImage}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <CardCategory
            name="Type(s)"
            isGray
            isTransparent
            categoriesItem={ArtistData.selCategories}
          ></CardCategory>
        </Grid>
        <Grid item md={4}>
          <Grid container spacing={2}>
            <Grid item md={4} xs={4}>
              <div
                className="addBorder lineheight-15"
                style={{ textAlign: "center" }}
              >
                <span className="header-type-3">
                  Make <br></br>Featured Artist
                </span>
                <CustomSwitch checked={MakeFeatured} onChange={onChange1} />
              </div>
            </Grid>
            <Grid item md={4} xs={4}>
              <div className="addBorder" style={{ textAlign: "center" }}>
                <p className="header-poppins-12 lineheight-28">
                  {ArtistData.length === 0 ? "0" : ArtistData.reported.length}
                </p>
                <p className="header-type-3 lineheight-22">
                  People Reported Artist
                </p>
              </div>
            </Grid>
            <Grid item md={4} xs={4}>
              <div
                className="addBorder"
                style={{ padding: "24px 8px", display: "flex" }}
              >
                <img
                  src={IconMoreCircle}
                  alt="not available"
                // className={classes.Switcher}
                />
                <span className="header-type-3 pad-left-10">More Option</span>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* <CustomTabSecond /> */}
    </div>
  );
}
