import React, { useEffect, useState } from 'react'
import { Col, Row, Container, Tab, Tabs, Modal, Button, Form } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import uploadImage1 from "../../assets/images/uploadImage1.png";
import ImageUploader from "react-images-upload";
import CustomTextField from "../../components/Controls/CustomTextField";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { CustomButtonContained } from "../../components/Controls/CustomButton";
import uploadMusic2 from "../../assets/images/uploadMusic2.png";
import musiccover from "../../assets/images/ArtistImage1.png";
import closebtn from "../../assets/images/close.png";
import { FileDrop } from "react-file-drop";
import { addErrorMessage, addLoader, addSuccessMessage, removeLoader } from '../../utility/LoadingContent';
import { constants } from '../../utility/constants';
import { CircularProgressbar } from "react-circular-progressbar";
import { Multiselect } from "multiselect-react-dropdown";
import { AiFillCloseSquare } from "react-icons/ai";
import { deleteDataApi, getdata, patchDataApi, postDataApi } from '../../utility/apiCalling';
import musicImg from "../../assets/images/music.png";
import videoImg from "../../assets/images/video.png";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const Uploadpodcaste = (props) => {
  let history = useHistory();
  console.log(props)
  const classes = useStyles();
  const { id, item, ArtistId } = props.location.state;
  const [coverImg, setCoverImg] = useState(null);
  const [dragNDropText, setDragNDropText] = useState("Drop your file here");
  const [podcastCategories, setPodCastCategories] = useState([]);
  const [podFiles1, setPodFiles1] = useState([]);
  const [isUploadPodcast, setUploadPodcast] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isPodcastTitleErrShow, setPodcastTitleErr] = useState(false);
  const [isPodcastDescErrShow, setPodcastDescErr] = useState(false);
  const [isAddCategoryErrShow, setAddCategoryErr] = useState(false);
  const [podFiles, setPodFiles] = useState([]);
  const [showSelectedPodcastFile, setShowSelectedPodcastFile] = useState(
    id !== undefined && id !== 0 ? true : false
  );
  console.log(id)
  const [podcastDetails, setPodcastDetails] = useState({
    podcastTitle: item !== undefined ? item.name : "",
    podcasturlPodcast: item !== undefined ? item.urlPodcast : "",
    podcastDescription: item !== undefined ? item.description : "",
    podcastType: item !== undefined ? item.podcastType : "music",
    podcasturlImage: item !== undefined ? item.urlImage : "",
    categoryList: [],
    selectedCategoryList: [],
    uploadedPodcast: null,
    existingCategoryList: [],
    id: id !== undefined ? id : 0,
    size: item !== undefined ? item.size : 0,
  });
  let audioReg = /\.(?:opus|flac|webm|weba|wav|ogg|m4a|mp3|oga|mid|amr|aiff|wma|au|aac|mp4|avi|mov)$/i;
  let videoReg = /\.(?:ogm|wmv|mpg|webm|ogv|mov|asx|mpeg|mp4|m4v|avi)$/i;
  const multiSelectStyle = {
    multiselectContainer: {
      color: "white",
      fontSize: 14,
    },
    option: {
      // To change css for dropdown options
      //   color: colors.colors13,
    },
    chips: {
      //   background: colors.colors13,
      fontSize: 15,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 8,
      paddingBottom: 8,
      borderRadius: 8,
    },
    searchBox: {
      border: "1px solid #8186AF",
      borderRadius: "2px",
      //fontFamily: 'Mr Eaves Mod OT',
      fontSize: 15,
      minHeight: 40,

      //width: 395,
    },
  };
  const onDrop = (file) => fileValidation(file[0]);
  const onUploadImg = (file) => fileValidation(file[0]);
  const fileValidation = (file) => {
    // Allowing file type
    let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    if (!allowedExtensions.exec(file.name)) {
      addErrorMessage("Please upload a valid image file");
      file = "";
      setDragNDropText("Drop your image here or browse");
      return false;
    } else {
      let imageSrc = URL.createObjectURL(file);
      setDragNDropText("Drop your image here or browse");
      setCoverImg(imageSrc);
      let fileItem = [];
      fileItem.push(file);
      //jayraj mehta
      setPodFiles1([...fileItem]);
      //jayraj mehta
    }
  };
  const onDeleteUploadedPodcast = () => {
    setPodcastDetails((prevState) => {
      return { ...prevState, uploadedPodcast: null };
    });
    return false;
  };
  const onSelectCategory = (selectedList) => {
    console.log("Selected Category: ", selectedList);
    setPodcastDetails((prevState) => {
      return { ...prevState, selectedCategoryList: selectedList };
    });
    setAddCategoryErr(false);
  };

  const onRemoveSelectCategory = (selectedList) => {
    setPodcastDetails((prevState) => {
      return { ...prevState, selectedCategoryList: selectedList };
    });
  };
  const getSubcategories = async () => {
    let resSubcategories0 = await getdata(
      constants.baseURL + "subcategories?filter=category.id||$eq||2&filter=isDeleted||$eq||0"
    );
    console.log("resSubcategories: ", resSubcategories0);
    if (resSubcategories0 != undefined && resSubcategories0.data.length > 0) {
      setPodCastCategories([...resSubcategories0.data]);
      setPodcastDetails((prevState) => {
        return {
          ...prevState,
          categoryList: [...resSubcategories0.data],
        };
      });
      if (id !== undefined && id !== 0) {
        getPodcastSubcategories(resSubcategories0.data);
      }
    }
  };
  const cancelPostcast = () => {
    if (id !== undefined && id !== 0) {
      history.goBack();
    } else {
      if (!isUploadPodcast && props != undefined) {
        history.goBack();
      }
    }
  };
  const getPodcastSubcategories = async (categories) => {
    let resSubcategories = await getdata(
      constants.baseURL +
      "selectedPodcastcategories?filter=podcasts.id||$eq||" +
      id
    );
    console.log("getPodcastSubcategories: ", resSubcategories);
    if (resSubcategories != undefined && resSubcategories.data.length > 0) {
      let sItems = [];
      resSubcategories.data.map((p) => {
        let fltCategories = categories.filter((c) => c.id == p.subcategory.id);
        if (fltCategories[0] !== undefined) {
          sItems.push(fltCategories[0]);
        }
      });
      console.log("sItems: ", sItems);
      setPodcastDetails((prevState) => {
        return {
          ...prevState,
          selectedCategoryList: [...sItems],
          existingCategoryList: [...resSubcategories.data],
        };
      });
    }
  };
  const onSelectPodcast = (event) => {
    console.log(event)
    if (event) {
      if (podcastDetails.podcastType === "music") {
        console.log(event.target.files[0])
        if (!audioReg.exec(event.target.files[0].name)) {
          addErrorMessage("Please upload a valid audio file");
          return;
        }
      } else if (podcastDetails.podcastType === "video") {
        if (!videoReg.exec(event.target.files[0].name)) {
          addErrorMessage("Please upload a valid video file");
          return;
        }
      }
      setPodcastDetails((prevState) => {
        return { ...prevState, uploadedPodcast: event.target.files[0] };
      });
      setShowSelectedPodcastFile(false);

      setPodFiles([...event.target.files]);
      console.log(podFiles)
      console.log(podcastDetails, podFiles)
      return false;
    }
  };
  const renderUploadedPodcast = () => {
    const { podcastType, uploadedPodcast } = podcastDetails;
    let fileName = uploadedPodcast.name.split(".")[0];
    let fileSizeInMB = uploadedPodcast.size / 1024 / 1024;
    console.log("showSelectedPodcastFile: ", showSelectedPodcastFile);
    return (
      <>
        {showSelectedPodcastFile === false && (
          <div className='upload-song-admin '>
            <Grid container spacing={1} className='uploaded d-flex-space-between'>
              <Grid item md={"auto"}>
                <img src={
                  coverImg === null
                    ? podcastType === "music"
                      ? musicImg
                      : videoImg
                    : coverImg
                } className="music-cover" alt=""></img>
              </Grid>
              <Grid item md={"10"} lg={"9"}>
                <div className='song-details'>{fileName}</div>
                <div className='song-time'>{fileSizeInMB.toFixed(1)}MB</div>
              </Grid>
              <Grid item md={"auto"}>
                <img style={{ cursor: "pointer" }} src={closebtn} className="close-btn" alt="hello" onClick={() => onDeleteUploadedPodcast()}></img>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  };
  const renderSelectedUploadedPodcast = () => {
    return (
      <>
        {showSelectedPodcastFile === true && (
          <div className='upload-song-admin'>
            <Grid container spacing={1} className='uploaded'>
              <Grid item md={"auto"}>
                <img src={
                  podcastDetails.podcasturlImage != ""
                    ? constants.podcastFileURL + podcastDetails.podcasturlImage
                    : podcastDetails.podcastType == "video"
                      ? constants.videoURL
                      : constants.musicURL} className="music-cover" alt=""></img>
              </Grid>
              <Grid itemmd={"10"} lg={"9"}>
                <div className='song-details'>{podcastDetails.podcasturlPodcast}</div>
                <div className='song-time'>{(podcastDetails.size / 1024 / 1024).toFixed(1)}MB</div>
              </Grid>
              <Grid item md={"auto"}>
                <img src={closebtn} className="close-btn" alt="hello" onClick={() => {
                  setShowSelectedPodcastFile(false);
                }}
                  style={{ cursor: "pointer" }}></img>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  };

  const onUpload = async () => {
    const {
      podcastTitle,
      selectedCategoryList,
      podcastDescription,
      uploadedPodcast,
    } = podcastDetails;
    if (
      !podcastTitle &&
      !podcastDescription &&
      selectedCategoryList.length === 0 &&
      // uploadedPodcast === null &&
      id === undefined
    ) {
      setPodcastTitleErr(true);
      setAddCategoryErr(true);
      setPodcastDescErr(true);
      addErrorMessage("Upload a podcast file!");
      //enqueueSnackbar("Upload a podcast file!", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right', } });
    } else if (!podcastTitle) {
      setPodcastTitleErr(true);
    } else if (!podcastDescription) {
      setPodcastDescErr(true);
    } else if (selectedCategoryList.length === 0) {
      setAddCategoryErr(true);
    // } else if (uploadedPodcast === null && id === 0) {
    //   addErrorMessage("Upload a podcast file!");
      //enqueueSnackbar("Upload a podcast file!", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right', } });
    } else {
      console.log("DONE");
      setUploadPodcast(true);
      setDragNDropText("Drop your file here");
      //Check if podcast with same name exists or not
      let respName = await getPodcastByName();
      console.log("respName: ", respName);
      if (id === 0 && respName.data.length > 0) {
        addErrorMessage("Podcast with the same name already exists!");
        return;
      } else if (id !== undefined && id !== 0 && respName.data.length > 0 && id !== respName.data[0].id) {
        addErrorMessage("Podcast with the same name already exists!");
        return;
      }
      //
      // if (podFiles.length > 0) {

      //   uploadPodcastFiles();

      // } else 
      if (podFiles1.length > 0) {

        uploadPodcastFiles1()

      } else {
        if (id !== undefined && id !== 0) {
          setUploadProgress(25);
          createPodcast(
            podcastDetails.podcasturlImage,
            // podcastDetails.podcasturlPodcast
          );
        } else {
          resetUploadPodcast(0);
        }
      }
    }
  };
  const getPodcastByName = async () => {
    let resPodcast = await getdata(
      // constant.baseURL + "podcasts?filter=name||$eq||"+podcastDetails.podcastTitle
      constants.baseURL + "podcasts?filter=name||$eq||" + podcastDetails.podcastTitle + "&filter=isDeleted||$eq||0"
    );
    return resPodcast;
  }
  const uploadPodcastFiles = async () => {
    addLoader();
    if (podFiles1.length > 0) {
      let customHeaders = {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer ",
      };
      let data = new FormData();

      let extraprodFiles = [...podFiles1, ...podFiles]
      extraprodFiles.map((m) => {
        data.append(constants.uplFileParameter, m);
      });
      let res = await postDataApi(
        constants.podcastFileUploadURL, //process.env.REACT_APP_NODE_ENV === "development" ? constants.baseURL + "podcastfiles/uploadMultipleFiles" : constants.baseURL + "podcastfiles",
        data,
        customHeaders
      );
      //console.log("Upload Podcast Files: ", res.data);
      if (
        res != undefined &&
        res.status != undefined &&
        res.status === 201 &&
        res.data != undefined &&
        res.data.data != undefined
      ) {
        console.log("Uploaded Podcast file: ", res.data.data);
        let coverFileName = "";
        coverFileName = res.data.data[0].filename
        // coverImg != null
        //   ? res.data.data[0].filename
        //   : id !== 0
        //   ? podcastDetails.podcasturlImage
        //   : "";
        setUploadProgress(25);
        console.log("jjjjj")
        createPodcast(
          coverFileName,
          res.data.data[1].filename
          // coverImg != null
          //   ? id !== 0
          //     ? podcastDetails.podcasturlPodcast
          //     : res.data.data[1].filename
          //   : id !== 0
          //   ? podcastDetails.podcasturlPodcast
          //   : res.data.data[0].filename
          ,
          res.data.data[1].originalname
          // coverImg != null
          // ? id !== 0
          //   ? podcastDetails.podcasturlPodcast
          //   : res.data.data[1].originalname
          // : id !== 0
          //   ? podcastDetails.podcasturlPodcast
          //   : res.data.data[0].originalname
        );
      } else {
        removeLoader();
        addErrorMessage("Error in uploading podcast files");
      }
    } else {
      let customHeaders = {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer ",
      };
      let data = new FormData();

      let extraprodFiles = [...podFiles]
      extraprodFiles.map((m) => {
        data.append(constants.uplFileParameter, m);
      });
      let res = await postDataApi(
        constants.podcastFileUploadURL,//process.env.REACT_APP_NODE_ENV === "development" ? constants.baseURL + "podcastfiles/uploadMultipleFiles" : constants.baseURL + "podcastfiles",
        data,
        customHeaders
      );
      //console.log("Upload Podcast Files: ", res.data);
      if (
        res != undefined &&
        res.status != undefined &&
        res.status === 201 &&
        res.data != undefined &&
        res.data.data != undefined
      ) {
        console.log("Uploaded Podcast file: ", res.data.data);
        let coverFileName = "";
        coverFileName = podcastDetails.podcasturlImage;
        // coverImg != null
        //   ? res.data.data[0].filename
        //   : id !== 0
        //   ? podcastDetails.podcasturlImage
        //   : "";
        setUploadProgress(25);
        createPodcast(
          coverFileName,
          // coverImg != null
          //   ? id !== 0
          //     ? podcastDetails.podcasturlPodcast
          //     : res.data.data[1].filename
          //   : id !== 0
          //   ? podcastDetails.podcasturlPodcast
          //   : 
          res.data.data[0].filename,
          // coverImg != null
          // ? id !== 0
          //   ? podcastDetails.podcasturlPodcast
          //   : res.data.data[1].originalname
          // : id !== 0
          //   ? podcastDetails.podcasturlPodcast
          //   :
          res.data.data[0].originalname
        );
      } else {
        removeLoader();
        addErrorMessage("Error in uploading podcast files");
      }
      // setUploadProgress(25);
      // createPodcast(
      //   podcastDetails.podcasturlImage,
      //   podcastDetails.podcasturlPodcast
      // );
    }
  };
  const resetUploadPodcast = (progressValue) => {
    setUploadProgress(progressValue);
    setCoverImg(null);
    setPodFiles([]);
    setPodcastDetails({
      albumName: "",
      podcastTypeType: "music",
      podcastDescription: "",
      podcastTitle: "",
      //categoryList: musicCategories,
      selectedCategoryList: [],
      uploadedPodcast: null,
    });
    history.push({
      pathname: `user-details/${ArtistId}`,
      state: {
        ArtistId: ArtistId,
      },
    });
  };
  const uploadPodcastFiles1 = async () => {
    addLoader();
    // if (podFiles.length > 0) {
    //   let customHeaders = {
    //     Accept: "application/json",
    //     "Content-Type": "multipart/form-data",
    //     Authorization: "Bearer ",
    //   };
    //   let data = new FormData();

    //   let extraprodFiles = [...podFiles1, ...podFiles]
    //   extraprodFiles.map((m) => {
    //     data.append("image", m);
    //   });
    //   let res = await postDataApi(
    //     constants.podcastFileUploadURL, //process.env.REACT_APP_NODE_ENV === "development" ? constants.baseURL + "podcastfiles/uploadMultipleFiles" : constants.baseURL + "podcastfiles",
    //     data,
    //     customHeaders
    //   );
    //   //console.log("Upload Podcast Files: ", res.data);
    //   if (
    //     res != undefined &&
    //     res.status != undefined &&
    //     res.status === 201 &&
    //     res.data != undefined &&
    //     res.data.data != undefined
    //   ) {
    //     console.log("Uploaded Podcast file: ", res.data.data);
    //     let coverFileName = "";
    //     coverFileName =
    //       coverImg != null
    //         ? res.data.data[0].filename
    //         : id !== undefined && id !== 0
    //           ? podcastDetails.podcasturlImage
    //           : "";
    //     setUploadProgress(25);
    //     createPodcast(
    //       coverFileName,
    //       coverImg != null
    //         ? id !== undefined && id !== 0
    //           ? podcastDetails.podcasturlPodcast
    //           : res.data.data[1].filename
    //         : id !== undefined && id !== 0
    //           ? podcastDetails.podcasturlPodcast
    //           : res.data.data[0].filename,
    //       coverImg != null
    //         ? id !== undefined && id !== 0
    //           ? podcastDetails.podcasturlPodcast
    //           : res.data.data[1].originalname
    //         : id !== undefined && id !== 0
    //           ? podcastDetails.podcasturlPodcast
    //           : res.data.data[0].originalname
    //     );
    //   } else {
    //     removeLoader();
    //     addErrorMessage("Error in uploading podcast files");
    //   }
    // } else {
      let customHeaders = {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer ",
      };
      let data = new FormData();

      let extraprodFiles = [...podFiles1]
      extraprodFiles.map((m) => {
        data.append(constants.uplFileParameter, m);
      });
      let res = await postDataApi(
        constants.podcastFileUploadURL,//process.env.REACT_APP_NODE_ENV === "development" ? constants.baseURL + "podcastfiles/uploadMultipleFiles" : constants.baseURL + "podcastfiles",
        data,
        customHeaders
      );
      //console.log("Upload Podcast Files: ", res.data);
      if (
        res != undefined &&
        res.status != undefined &&
        res.status === 201 &&
        res.data != undefined &&
        res.data.data != undefined
      ) {
        console.log("Uploaded Podcast file: ", res.data.data);
        let extra = []
        if (res.data.data.length >= 0) {
          extra = res.data.data
        } else {
          extra = [res.data.data]
        }
        // let coverFileName = "";
        // coverFileName = podcastDetails.podcasturlImage;
        // coverImg != null
        //   ? res.data.data[0].filename
        //   : id !== 0
        //   ? podcastDetails.podcasturlImage
        //   : "";
        setUploadProgress(25);
        createPodcast(
          extra[0].filename,
          // coverImg != null
          //   ? id !== 0
          //     ? podcastDetails.podcasturlPodcast
          //     : res.data.data[1].filename
          //   : id !== 0
          //   ? podcastDetails.podcasturlPodcast
          //   : 
          // podcastDetails.podcasturlPodcast
          // ,
          // coverImg != null
          // ? id !== 0
          //   ? podcastDetails.podcasturlPodcast
          //   : res.data.data[1].originalname
          // : id !== 0
          //   ? podcastDetails.podcasturlPodcast
          //   :
          //  res.data.data[0].originalname
        );
      } else {
        removeLoader();
        addErrorMessage("Error in uploading podcast files");
      }
      // setUploadProgress(25);
      // createPodcast(
      //   podcastDetails.podcasturlImage,
      //   podcastDetails.podcasturlPodcast
      // );
    // }
  };
  const createPodcast = async (coverImageName, podcastFileName, originalAudioVideoFileName) => {

    console.log(coverImageName, podcastFileName, originalAudioVideoFileName)
    if (ArtistId !== undefined) {
      addLoader();
      let fItem = podFiles.filter(f => f.name === originalAudioVideoFileName);
      let jsonData = {
        name: podcastDetails.podcastTitle,
        description: podcastDetails.podcastDescription,
        artist: ArtistId,
        podcastType: podcastDetails.podcastType,
        urlImage: coverImageName,
        // urlPodcast: podcastFileName,
        // size: fItem.length > 0 ? fItem[0].size : 0,
      };
      console.log(jsonData);
      var formBody = [];
      for (var property in jsonData) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(jsonData[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let respPodcast;
      let sStatus = "";
      if (id !== undefined && id !== 0) {
        respPodcast = await patchDataApi(
          constants.baseURL + "podcasts/" + id,
          formBody,
          authHeader
        );
        if (
          respPodcast != undefined &&
          respPodcast.status != undefined &&
          respPodcast.status == 200
        ) {
          sStatus = "Success";
        }
      } else {
        respPodcast = await postDataApi(
          constants.baseURL + "podcasts",
          formBody,
          authHeader
        );
        if (
          respPodcast != undefined &&
          respPodcast.status != undefined &&
          respPodcast.status == 201
        ) {
          sStatus = "Success";
        }
      }
      //console.log("respPodcast: ", respPodcast);
      if (sStatus == "Success") {
        setUploadProgress(75);
        return savePodcastCategories(respPodcast.data.id).then((response) => {
          if (
            response != undefined &&
            response.length > 0 &&
            response[0] == true
          ) {
            removeLoader();
            setUploadProgress(100);
            addSuccessMessage(
              id !== undefined && id !== 0 ? "Podcast Updated" : "Podcast Created"
            );
            resetUploadPodcast(0);
          } else {
            removeLoader();
            resetUploadPodcast(0);
          }
        });
      } else {
        removeLoader();
        addErrorMessage("Error encountered while creating podcast!");
        resetUploadPodcast(0);
      }
    }
  };
  const savePodcastCategories = async (podcastId) => {
    let promises_array = [];
    podcastDetails.selectedCategoryList.map((item) => {
      promises_array.push(
        new Promise((resolve, reject) => {
          let jsonData = { podcasts: podcastId, subcategory: item.id };
          return createPodcastCategories(jsonData)
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              reject(false);
            });
        })
      );
    });
    if (id !== undefined && id !== 0 && podcastDetails.existingCategoryList.length > 0) {
      podcastDetails.existingCategoryList.map((item) => {
        promises_array.push(
          new Promise((resolve, reject) => {
            let fExists = podcastDetails.selectedCategoryList.filter(
              (x) => x.id == item.subcategory.id
            );
            if (fExists.length == 0) {
              return deletePodcastCategory(item.id)
                .then(() => {
                  resolve(true);
                })
                .catch((error) => {
                  reject(false);
                });
            } else {
              resolve(true);
            }
          })
        );
      });
    }
    return Promise.all(promises_array);
  };
  const createPodcastCategories = async (jsonData) => {
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let resExits = await getdata(
      constants.baseURL +
      "selectedpodcastcategories?filter=podcasts.id||$eq||" +
      jsonData.podcasts +
      "&filter=subcategory.id||$eq||" +
      jsonData.subcategory
    );
    console.log("resExits: ", resExits);
    if (resExits.data.length == 0) {
      return await Promise.resolve(
        postDataApi(
          constants.baseURL + "selectedpodcastcategories",
          formBody,
          authHeader
        )
      )
        .then((response) => {
          if (
            response != undefined &&
            response.status != undefined &&
            response.status == 201
          ) {
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          return false;
        });
    } else {
      return false;
    }
  };

  const deletePodcastCategory = async (id) => {
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return await Promise.resolve(
      await deleteDataApi(
        constants.baseURL + "selectedPodcastcategories/" + id,
        authHeader
      )
    )
      .then((resDel) => {
        console.log("resDel: ", resDel);
        if (resDel != undefined && resDel.status == 200) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        return false;
      });
  };
  useEffect(() => {
    getSubcategories();
  }, []);
  return (
    <div className={classes.root}>
      <div className='upload-song add-artist-main'>
        <Grid container spacing={3}>

          <Grid item xs={12} lg={6}>
            <div className='upload-music-main upload-poocaste h-100  '>
              {coverImg === null ? (
                <FileDrop
                  onDragOver={() =>
                    setDragNDropText("Drop your image here!!")
                  }
                  onDragLeave={() =>
                    setDragNDropText("Drop your file here")
                  }
                  onDrop={onDrop}
                >
                  <div className='upload-background'>
                    <img src={
                      podcastDetails.podcasturlImage != ""
                        ? constants.podcastFileURL +
                        podcastDetails.podcasturlImage
                        : uploadImage1
                    } className="user-profile" alt="hello"></img>
                    <div className="upload-text mt-20">Upload Cover Image</div>
                    <div className="upload-subtitle mt-10">Drop your file here or browse</div>
                    <div style={{ textAlign: 'center' }}>
                      <ImageUploader
                        className="imageUploaderNew"
                        withIcon={false}
                        buttonText="Browse"
                        label=""
                        onChange={(file) => onUploadImg(file)}
                        imgExtension={constants.supportedImageFiles}
                        singleImage={true}
                      />
                    </div>
                  </div>
                </FileDrop>
              ) : (
                <FileDrop
                  onDragOver={() =>
                    setDragNDropText("Drop your image here!!")
                  }
                  onDragLeave={() =>
                    setDragNDropText("Drop your file here")
                  }
                  onDrop={onDrop}
                >
                  <div className='upload-background'>
                    <img onClick={() => setCoverImg(null)}
                      id="uploaded_cover_img"
                      src={coverImg} className="user-profile" alt="hello"></img>
                    <div className="upload-text mt-20">Upload Cover Image</div>
                    <div className="upload-subtitle mt-10">Drop your file here or browse</div>
                    <div style={{ textAlign: 'center' }}>
                      <ImageUploader
                        className="imageUploaderNew"
                        withIcon={false}
                        buttonText="Browse"
                        label=""
                        onChange={(file) => onUploadImg(file)}
                        imgExtension={constants.supportedImageFiles}
                        singleImage={true}
                      />
                    </div>
                  </div>
                </FileDrop>
              )}
            </div>
          </Grid>
          <Grid item xs={12} lg={6}>
            <div className='upload-music-main  h-100 '>
              <div className="upload-text  ">Podcast Details</div>
              {/* {isUploadPodcast ? (
                <div className="col-2 progress_bar">
                  <CircularProgressbar
                    value={uploadProgress}
                    maxValue={100}
                    minValue={0}
                    text={`${uploadProgress}%`}
                  />
                </div>
              ) : null} */}
              <div className=''>
                <Grid item md={12} className="mt-20" >
                  <CustomTextField
                    name="title"
                    label="Title"
                    maxLength={255}
                    placeholder=" "
                    onChange={(e) => {
                      setPodcastDetails({
                        ...podcastDetails,
                        podcastTitle: e.target.value,
                      });
                      setPodcastTitleErr(false);
                    }}
                    value={podcastDetails.podcastTitle}
                    fullWidth
                  ></CustomTextField>
                  {isPodcastTitleErrShow === true && (
                    <div
                      className="errorText"
                      style={{ marginTop: "0px" }}
                    >
                      Enter title
                    </div>
                  )}
                </Grid>
                <Grid item md={12} className="mt-20" >
                  <CustomTextField
                    name="Description"
                    label="Description"
                    multiline
                    rows={3}
                    placeholder=" "
                    onChange={(e) => {
                      setPodcastDetails({
                        ...podcastDetails,
                        podcastDescription: e.target.value,
                      });
                      setPodcastDescErr(false);
                    }}
                    value={podcastDetails.podcastDescription}
                    fullWidth
                  ></CustomTextField>
                  {isPodcastDescErrShow === true && (
                    <div
                      className="errorText"
                      style={{ marginTop: "0px" }}
                    >
                      Enter description
                    </div>
                  )}
                </Grid>
                <Grid item md={12} xs={12} className="mt-20">
                  {/* <div className="complete-profile">
                    <div className="floating-label "> */}
                  <Multiselect
                    options={podcastDetails.categoryList}
                    selectedValues={
                      podcastDetails.selectedCategoryList
                    }
                    onSelect={onSelectCategory}
                    onRemove={onRemoveSelectCategory}
                    displayValue="name"
                    placeholder="Add Category"
                    style={multiSelectStyle}
                    // showCheckbox={true}
                    // closeIcon="circle"
                    customCloseIcon={
                      <AiFillCloseSquare className="multiselect-close-icon" />
                    }
                  />
                  {isAddCategoryErrShow && (
                    <div
                      className="errorText"
                      style={{ marginTop: "0px" }}
                    >
                      Add category
                    </div>
                  )}
                  {/* </div>
                  </div> */}
                </Grid>
                <div className="login-sub-title mt-10">Select Type</div>
                <Grid container spacing={1}>
                  <Grid item md={6} className="mt-20 w-sm-100" >
                    <div className='selection-page'>
                      Video <span><FormControlLabel value="video" control={<Radio disabled={
                        id !== undefined && id !== 0
                          ? podcastDetails.podcastType !==
                          "video" && true
                          : false
                      }
                        checked={
                          podcastDetails.podcastType === "video"
                        }
                        onChange={() => {
                          if (
                            podcastDetails.uploadedPodcast ===
                            null
                          ) {
                            setPodcastDetails((prevState) => {
                              return {
                                ...prevState,
                                podcastType: "video",
                              };
                            });
                          }
                        }} />} /></span>
                    </div>
                  </Grid>
                  <Grid item md={6} className="mt-20 w-sm-100" >
                    <div className='selection-page'>
                      Audio <span><FormControlLabel value="music" control={<Radio disabled={
                        id !== undefined && id !== 0
                          ? podcastDetails.podcastType !==
                          "music" && true
                          : false
                      }
                        checked={
                          podcastDetails.podcastType === "music"
                        }
                        onChange={() => {
                          if (
                            podcastDetails.uploadedPodcast ===
                            null
                          ) {
                            setPodcastDetails((prevState) => {
                              return {
                                ...prevState,
                                podcastType: "music",
                              };
                            });
                          }
                        }} />} /></span>
                    </div>
                  </Grid>
                </Grid>
                {/* <div className='browse-sec'>
                  <Grid container spacing={1} className="d-flex-space-between">
                    <Grid item md={9} xl={10} className="" >
                      <div className="login-sub-title mt-10">Select File</div>
                    </Grid>
                    <Grid item md={"auto"} className="" >
                      <div className='browse-btn' >
                        <input
                          onChange={onSelectPodcast}
                          className="custom-file-input"
                          type="file"
                          style={{ display: "none" }}
                          id="song_upload_input"
                          accept={
                            podcastDetails.podcastType === "music"
                              ? constants.acceptedAudio
                              : constants.acceptedVideo
                          }
                        />
                        <label for="song_upload_input">Browse</label>
                      </div>
                    </Grid>
                    <Grid item md={12} className="" >
                      {podcastDetails.uploadedPodcast !== null &&
                        renderUploadedPodcast()}
                      {podcastDetails.uploadedPodcast == null &&
                        renderSelectedUploadedPodcast()}
                    </Grid>
                  </Grid>
                </div> */}
              </div>
              <div className='upload-control'>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={6} className='add-artist-btn'>
                    <CustomButtonContained text="Cancel" onClick={cancelPostcast} />
                  </Grid>
                  <Grid item md={6} xs={6} className='add-artist-btn'>
                    <CustomButtonContained text={id !== undefined && id !== 0 ? "Update" : "Upload"} onClick={onUpload} />
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>

        </Grid>
      </div>

    </div >
  )
}

export default Uploadpodcaste