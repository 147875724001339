import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Avatar from "../../assets/CardIcon/Avatar.png";
import "./ViewComponent.scss";
import styles from "./ViewComponentStyle";
import reactDom from "react-dom";
import { constants } from "../../utility/constants";
const useStyles = makeStyles(styles);

// const DialogTitle = () => {
//   return (
//     <MuiDialogTitle disableTypography className={classes.root}>
//       <Typography variant="h6">{children}</Typography>
//       {onClose ? (
//         <IconButton
//           aria-label="close"
//           className={classes.closeButton}
//           onClick={onClose}
//         >
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </MuiDialogTitle>
//   );
// };

export default function LikeComponent(props) {
  const classes = useStyles();
  const { data, renderElement } = props;
  const [open, setOpen] = React.useState(true);
  // console.log(data);
  var Userimagelink = constants.profileURL; //constants.baseURL + "profilefiles";

  const handleClose = () => {
    reactDom.unmountComponentAtNode(renderElement);
    setOpen(false);
  };
  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <Dialog
        maxWidth="md"
        onClose={handleClose}
        // aria-labelledby="customized-dialog-title"
        open={open}
        style={{ borderRadius: "32px" }}
        className={classes.dialog}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          className={classes.dialogtitle}
        >
          {open ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
          Like
        </DialogTitle>
        {/* <div onClose={handleClose}> view</div> */}
        <DialogContent className={classes.DialogContent}>
          {data === undefined || data.length === 0 ? (
            <div style={{ padding: "50px 200px" }}>No Data Found</div>
          ) : (
            <div style={{ display: "flex" }}>
              {data.map((item, key) => {
                return (
                  <div className="Data-Set" key={key}>
                    <div style={{ display: "flex" }}>
                      <img
                        src={Userimagelink + item?.user?.urlImage}
                        alt="image_not_Found"
                        className="ViewImage"
                      />
                      <div className="ViewData">
                        <p style={{ fontSize: "15px", opacity: "0.9" }}>
                          {item?.user?.firstName + " " + item?.user?.lastName}
                        </p>
                        <p style={{ fontSize: "10px", opacity: "0.6" }}>
                          +91-{item?.user?.phone}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
