import React, { useEffect, useState } from "react";
import ArtistDetails from "../../components/ArtistDetails/ArtistDetails";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import ArtistTypeCard from "../../components/ArtistTypeCard/ArtistTypeCard";
import { Tabs ,Typography} from "@material-ui/core";
import { deleteDataApi, getdata, patchDataApi, postDataApi } from "../../utility/apiCalling";
import Song from "../../components/Song/Song";
import { constants } from "../../utility/constants";
import UserDetails from "../../components/UserDetails/UserDetails";
import ArtistUserAlbumDetails from "./ArtistUserAlbumDetails";
import PodcastPageDetails from "../PodcastPage/PodcastPageDetails";
import ArtistTabPodcastDetails from "./ArtistTabPodcastDetails";
import ArtistTabNewsDetails from "./ArtistTabNewsDetails";
import ArtistTabEventsDetails from "./ArtistTabEventsDetails";
import add from "../../assets/images/download.png";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import UploadMusic from './UploadMusic'
import UploadNews from './UploadNews'
import CustomTextField from "../../components/Controls/CustomTextField";


import {
  openCommentDialog,
  openLikeDialog,
  openConfirmationDeleteDialog,
} from "../../utility/dialogAppear";
import NavSearchBar from "../../components/SearchBar/NavSearchBar";
import {
  addSuccessMessage,
  addErrorMessage,
  addLoader,
  removeLoader,
} from "../../utility/LoadingContent";
import Search from "../../assets/images/Search.png";
import { CustomButtonOutlined } from "../../components/Controls/CustomButton";
import reactDom from "react-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Modal from 'react-bootstrap/Modal'

export default function ArtistUserDetails(props) {
  const { ArtistId } = props.location.state;

  console.log("--------------------------------------------User Artist");
  console.log(ArtistId);
  let history = useHistory()
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  var [page, setPage] = useState(1);
  const [album, setalbum] = useState([]);
  const [vedioAlbum, setvedioAlbum] = useState([]);
  const [flag, setFlag] = useState(false);
  // const [music, setmusic] = useState([]);

  const [podcastByArtist, setpodcastByArtist] = useState([]);
  const [NewsByArtist, setNewsByArtist] = useState([]);
  const [eventsByArtist, seteventsByArtist] = useState([]);
  const [userDetails, setuserDetails] = useState([]);
  const [podcastGetAll, setpodcastGetAll] = useState([]);

  const [makeHome, setmakeHome] = useState(null);
  const [makeFeature, setmakeFeature] = useState(null);
  const [MainArtist, setMainArtist] = useState(null);
  const [MakeTopTen, setMakeTopTen] = useState(null);
  const [MakeTopTwenty, setMakeTopTwenty] = useState(null);
  const [inputVal, setInputVal] = useState({})
  const getInputVal = (e) => {
    console.log(e.target)
    let { name, value } = e.target;
    setInputVal((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  const [stopLoader, setStopLoader] = useState(
    {
      albumLoader: true,
      podcastLoader: true,
      newsLoader: true,
      eventLoader: true,
    }
  );
  const [commonId, setcommonId] = useState("");
  useEffect(() => {
    getArtist();
    getAlbums(1,null);
  }, []);

  const [selectedTab, setSelectedTab] = useState(0);
  const handleChange = (event, newValue) => {

    setFlag(false);
    setPage(1);
    if (newValue === 2) {
      getPodcast(1);
    }
    if (newValue === 3) {
      getNews(1);
    }
    if (newValue === 4) {
      getEvents(1);
    }else{
      getAlbums(1)
    }
    setSelectedTab(newValue);
    setInputVal((prevState) => ({
      ...prevState,
      ["search"]: "",
    }));
    window.scrollTo(0, 0);
  };

  const handleComment = (key) => {
    let data = podcastData.data[key].comments;
    openCommentDialog(data);
  };
  const handleDelete = (id) => {
    // window.alert(id);
    openConfirmationDeleteDialog(() => {
      deleteBig(id);
    }, "Delete");
  };
  const handleEventDelete = (id) => {
    // window.alert(id);
    openConfirmationDeleteDialog(() => {
      deleteEventBig(id);
    }, "Delete");
  };
  const callPage = () => {
    if(selectedTab === 0){
      history.push({
        pathname: `/uploadmusic`,
        state: {
          ArtistId: ArtistId,
          type: "music"
        },
      })
    }
    if(selectedTab === 1){
      history.push({
        pathname: `/uploadmusic`,
        state: {
          ArtistId: ArtistId,
          type: "video"

        },
      })
    }
    if(selectedTab === 2){
      history.push({
        pathname: `/uploadpodcaste`,
        state: {
          ArtistId: ArtistId,
        },
      })
    }
    if(selectedTab === 3){
      history.push({
        pathname: `/uploadnews`,
        state: {
          ArtistId: ArtistId,
        },
      })
    }
    if(selectedTab === 4){
      history.push({
        pathname: `/uploadevent`,
        state: {
          ArtistId: ArtistId,
        },
      })
    }

  }
  const sendvalue = (i) =>{
    console.log(i)
    // setserc(i)

    setPage(1)
    if (selectedTab === 2) {
      getPodcast(1,i);
    }
    if (selectedTab === 3) {
      getNews(1,i);
    }
    if (selectedTab === 4) {
      getEvents(1,i);
    }else{
      getAlbums(1,i)

    }
    
    // setPage1(1)
    // getNextPage(1,i)
    // getNextPage1(1,i)
  }
  const deleteBig = async (id) => {
    let jsonData = {
      isDeleted: 1,
    };
    let deleteData = await patchDataApi(
      constants.deleteNews + "/" + id,
      jsonData
    );
    if (deleteData.status === 200 ) {
      addSuccessMessage("News Delete");
      setPage(1);
      getNews(1)
      // getBigStage(1);
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      addErrorMessage("News Not Delete");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
    // let deleteBigStage = await deleteDataApi(constants.getBigStage + "/" + id);
    // if (deleteBigStage.status === 200 ) {
    //   // console.log("User Successfully Deleted");
    //   addSuccessMessage("Big Stage Delete Successfully");
    //   getBigStage(1);
    //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    // } else {
    //   console.log("User Not  successfully Deleted");
    //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    // }
  };
  const deleteEventBig = async (id) => {
    let jsonData = {
      isDeleted: 1,
    };
    let deleteData = await patchDataApi(
      constants.deleteEvent+ "/" + id,
      jsonData
    );
    if (deleteData.status === 200 ) {
      addSuccessMessage("Event Delete");
      setPage(1);
      getEvents(1)
      // getBigStage(1);
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      addErrorMessage("Event Not Delete");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
    // let deleteBigStage = await deleteDataApi(constants.getBigStage + "/" + id);
    // if (deleteBigStage.status === 200 ) {
    //   // console.log("User Successfully Deleted");
    //   addSuccessMessage("Big Stage Delete Successfully");
    //   getBigStage(1);
    //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    // } else {
    //   console.log("User Not  successfully Deleted");
    //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    // }
  };

  // handleDel: () => handleAlubmDelete(items.album_id)
  const handleAlubmDelete = (id) => {
    // window.alert(id);
    openConfirmationDeleteDialog(() => {
      deleteAlubmBig(id);
    }, "Delete");
  };
  const handleAlubmEdit = (id) => {
    console.log(id)
    let dfltURL =
      id.album_albumType == "video" ? constants.videoURL : constants.musicURL;
    history.push({
      pathname: `/uploadmusic`,
      state: {
        ArtistId: ArtistId,
        id: id.album_id,
        item: {
          id: id.album_id,
          name: id.album_name,
          img:
            id.album_urlImage != undefined &&
              id.album_urlImage != null &&
              id.album_urlImage != ""
              ? constants.albumFileURL + id.album_urlImage
              : dfltURL,
          title: id.album_name,
          albumType: id.album_albumType,
          urlImage: id.album_urlImage,
          artistName: id.user.firstName + " " + id.user.lastName,
        }
      },
    })
  };
  const handlePodcastEdit = (id) => {
    console.log(id)
    let dfltURL =
      id.podcasts_podcastType == "video" ? constants.videoURL : constants.musicURL;
    history.push({
      pathname: `/uploadpodcaste`,
      state: {
        ArtistId: ArtistId,
        id: id.podcasts_id,
        item: {
          id: id.podcasts_id,
          name: id.podcasts_name,
          img:
            id.podcasts_urlImage != undefined &&
              id.podcasts_urlImage != null &&
              id.podcasts_urlImage != ""
              ? constants.podcastFileURL + id.podcasts_urlImage
              : dfltURL,
          title: id.podcasts_name,
          description: id.podcasts_description,
          podcastType: id.podcasts_podcastType,
          urlPodcast: id.podcasts_urlPodcast,
          urlImage: id.podcasts_urlImage,
          artistName: id.user.firstName + " " + id.user.lastName,
          size:
            id.podcasts_size !== undefined && id.podcasts_size !== null && id.podcasts_size !== ""
              ? id.podcasts_size
              : 0,
        }
      },
    })
  }
  const handleNewsEdit = (id) => {
    console.log(id)
    // let dfltURL =
    // id.podcasts_podcastType == "video" ? constants.videoURL : constants.musicURL;
    history.push({
      pathname: `/uploadnews`,
      state: {
        ArtistId: ArtistId,
        id: id.news_id,
        item: {
          id: id.news_id,
          name: id.news_title,
          img:
            id.news_urlImage != undefined &&
              id.news_urlImage != null &&
              id.news_urlImage != ""
              ? constants.newsFileURL + id.news_urlImage
              : constants.musicURL,
          title: id.news_title,
          subtitle: id.news_subtitle,
          description: id.news_description,
          urlImage: id.news_urlImage,
          artistName:
            id.user != undefined
              ? id.user.firstName + " " + id.user.lastName
              : ""
        }

      },
    })
  }
  
  const handleEventEdit = (id) => {
    console.log(id)
    // let dfltURL =
    // id.podcasts_podcastType == "video" ? constants.videoURL : constants.musicURL;
    history.push({
      pathname: `/uploadevent`,
      state: {
        ArtistId: ArtistId,
        id: id.events_id,
        item: {
          id: id.events_id,
          name: id.events_name,
          title: id.events_name,
          img:
            id.events_urlImage != undefined &&
              id.events_urlImage != null &&
              id.events_urlImage != ""
              ? constants.eventsFileURL + id.events_urlImage
              : constants.musicURL,
          eventLink: id.events_eventlink,
          address: id.events_address,
          description: id.events_description,
          urlImage: id.events_urlImage,
          eventdate: id.events_eventdate,
          artistName:
            id.user != undefined
              ? id.user.firstName + " " + id.user.lastName
              : "",
        }

      },
    })
  }
  const handlePodcastDelete = (id) => {
    // window.alert(id);
    openConfirmationDeleteDialog(() => {
      deletePodcastBig(id);
    }, "Delete");
  };
  const deleteAlubmBig = async (id) => {
    let jsonData = {
      isDeleted: 1,
    };
    let deleteData = await patchDataApi(
      constants.deleteAlbum + "/" + id,
      jsonData
    );
    if (deleteData.status === 200 ) {
      addSuccessMessage("Album Delete");
      setPage(1);
      getAlbums(1,null,"status")
      // getBigStage(1);
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      addErrorMessage("Album Not Delete");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };
  const deletePodcastBig = async (id) => {
    let jsonData = {
      isDeleted: 1,
    };
    let deleteData = await patchDataApi(
      constants.deletePodcast + "/" + id,
      jsonData
    );
    if (deleteData.status === 200 ) {
      addSuccessMessage("Podcast Delete");
      setPage(1);
      getPodcast(1)
      // getBigStage(1);
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      addErrorMessage("Podcast Not Delete");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };
  const handleLike = (key) => {
    let data = podcastData.data[key].likes;
    openLikeDialog(data)
    // openViewDialog(data);
  };
  // -------------------------------------------------------------->get Artst
  async function getArtist() {
    console.log(constants.user + "/getById/" + ArtistId);

    let response = await getdata(constants.user + "/adminGetById/" + ArtistId);
    console.log(response);
    if (
      response.request.status === 200 
    ) {
      setmakeHome(response.data.showOnHome);
      setmakeFeature(response.data.makeFeatured);
      setMakeTopTen(response.data.showOnTopTen);
      setMakeTopTwenty(response.data.showOnTopTwenty);
      setMainArtist(response.data.showMainArtist);

      return setuserDetails(response.data);
    }
  }

  // --------------------------------------------------------------get POdcast
  async function getPodcast(p,ser) {
    let apiURL = constants.adminpodcastsByArtist +
      "/" +
      ArtistId +
      "?page=" +
      p +
      "&limit=100"
      if (ser) {
        apiURL += "&searchText=" + ser
      }
    let response = await getdata(
      apiURL
    );
    if (
      response.request.status === 200
    ) {
      console.log("podcast Response", response)
      if (response.data.data.length !== 0) {
        // return setPosts(response.data);
        console.log("podcast----------------------------------------------------> yha hai")
        return setpodcastByArtist(response.data);
      } else {
        console.log("podcast===========================================================> yha hai")
        setpodcastByArtist(response.data);
        return setStopLoader({ ...stopLoader, podcastLoader: false });
      }
      // console.log(response);
    }
  }
  // --------------------------------------------------------------get News
  async function getNews(p,ser) {
    let apiURL = constants.adminnewsByArtist + "/" + ArtistId + "?page=" + p + "&limit=100"
    if (ser) {
      apiURL += "&searchText=" + ser
    }

    let response = await getdata(
      apiURL
    );
    if (
      response.request.status === 200 
    ) {
      console.log("News Response", response)
      if (response.data.data.length !== 0) {
        // return setPosts(response.data);


        return setNewsByArtist(response.data);
      } else {
        setNewsByArtist(response.data);
        return setStopLoader({ ...stopLoader, newsLoader: false });
      }
    }
  }
  // --------------------------------------------------------------get events
  async function getEvents(p,ser) {
    let apiURL = constants.admineventsByArtist + "/" + ArtistId + "?page=" + p + "&limit=100"
    if (ser) {
      apiURL += "&searchText=" + ser
    }
    let response = await getdata(
      apiURL
    );
    
    if (
      response.request.status === 200 
    ) {
      // console.log(response);
      if (response.data.data.length !== 0) {
        // return setPosts(response.data);
        return seteventsByArtist(response.data);
      } else {

        return setStopLoader({ ...stopLoader, eventLoader: false });
      }
    }
  }
  // ------------------------------------------------------------------->getMusic

  async function getAlbums(p,ser,i) {

    if (i) {
      setalbum([])
      setvedioAlbum([])
    }
    // console.log(constants.user + "?page=" + page + "&limit=10&filter=usertype||$eq||Artist")
    
     let apiURL = constants.adminartistAlbum + "/" + ArtistId + "?page=" + p + "&limit=100" 
  
    if (ser) {
      apiURL += "&searchText=" + ser
    }
    let response = await getdata(
      apiURL
    );
    
    if (
      response.request.status === 200 
    ) {
      // console.log(response.data)
      console.log("album data---------------------------------------------->")
      console.log(response.data.data);
      console.log("album data---------------------------------------------->")
      // if (response.data.data.length == 0) {
      // return setPosts(response.data);
      if(p==1){
        setalbum([])
        setvedioAlbum([])
      }
      {
        response.data.data.map((item) => {
          console.log(item);
          if (item.album_albumType === "music") {
           
              setalbum((prevState) => [...prevState, item]);
            
            // ...prevState,
            // totalCount: prevState.totalCount + response.data.totalCount,
            // data: [...prevState.data, ...response.data.data],
          } else {
           
            setvedioAlbum((prevState) => [...prevState, item]);
          }
        });
      }
    }

    // else {
    //   console.log("----------------------------------------------------loder samapt")
    //   return setStopLoader({ albumLoader: false });
    // }



    // return setalbum(response.data.data);

  }

  const musicData = (album) => {
    console.log(album);
    console.log(album);

    console.log(album !== undefined && album.length !== 0);
    if (album !== undefined && album.length !== 0) {
      console.log("hello----------------------andar");
      var formatted = album.map((items, key) => {
        console.log(key);

        var imagelink = constants.albumFileURL; //constants.baseURL + "albumfiles/";
        console.log(imagelink + items.album_urlImage);
        return [
          {
            id: items.album_id,
            image: [
              {
                itemImage:
                  items.album_urlImage === ""
                    ? constants.musicURL
                    : imagelink + items.album_urlImage,
                handleImage: () => handleMusicView(items.album_id),
              },
            ],

            vedioTitle: items.album_name,
            // imageTagName: "Active",
            vedioTrack: items.musics.length + " Tracks",
            view: "0",
            like: [
              {
                // totalLike: items.likes.length,
                totalLike: items?.likes?.length,
                handleLike: () => handleMusicLike(key),
              },
            ],
            comment: [
              {
                // totalComment: items.comments.length,
                totalComment: items?.comments?.length,
                handleComment: () => handleMusiccomment(key),
              },
            ],
            handleDel: () => handleAlubmDelete(items.album_id),
            handleEdit: () => handleAlubmEdit(items),
            isAdminManaged: userDetails.isAdminManaged
          },
        ];
      });
      return formatted;
    } else return [];
  };
  // -------------------------------------------------------------------------------vedioData

  const vedioData = (vedioAlbum) => {
    console.log(vedioAlbum);
    console.log(vedioAlbum);

    console.log(vedioAlbum !== undefined && vedioAlbum.length !== 0);
    if (vedioAlbum !== undefined && vedioAlbum.length !== 0) {
      console.log("hello----------------------andar");
      var formatted = vedioAlbum.map((items, key) => {
        console.log(key);

        var imagelink = constants.albumFileURL; //constants.baseURL + "albumfiles/";
        console.log(imagelink + items.album_urlImage);
        return [
          {
            id: items.album_id,
            image: [
              {
                itemImage:
                  items.album_urlImage === ""
                    ? constants.musicURL
                    : imagelink + items.album_urlImage,
                handleImage: () => handleVideoView(items.album_id),
              },
            ],

            vedioTitle: items.album_name,
            // imageTagName: "Active",
            vedioTrack: items.musics.length + " Tracks",
            view: "0",
            like: [
              {
                // totalLike: items.likes.length,
                totalLike: items?.likes?.length,
                handleLike: () => handleVideoLike(key),
              },
            ],
            comment: [
              {
                // totalComment: items.comments.length,
                totalComment: items?.comments?.length,
                handleComment: () => handleVideocomment(key),
                // handleComment: () => handleComment(key),
              },
            ],
            handleDel: () => handleAlubmDelete(items.album_id),
            handleEdit: () => handleAlubmEdit(items),
            isAdminManaged: userDetails.isAdminManaged
          },
        ];
      });
      return formatted;
    } else return [];
  };

  // -----------------------------------------------------------------------podcastByArtist
  const podcastData = (podcastByArtist) => {
    console.log("--------------------------------------PodcastByArtist");
    console.log(podcastByArtist);
    console.log(podcastByArtist);
    console.log(podcastByArtist !== undefined && podcastByArtist.length !== 0);
    if (podcastByArtist !== undefined && podcastByArtist.length !== 0) {
      console.log("hello----------------------andar");
      var formatted = podcastByArtist.data.map((items, key) => {
        console.log(key);

        var imagelink = constants.podcastFileURL; //constants.baseURL + "podcastfiles/";
        return [
          {
            id: items.podcasts_id,
            image: [
              {
                itemImage:
                  items.podcasts_urlImage === ""
                    ? constants.musicURL
                    : imagelink + items.podcasts_urlImage,
                handleImage: () => handlePodcastView(items.podcasts_id),
              },
            ],
            // image: imagelink + items.podcasts_urlImage,
            vedioTitle: items.podcasts_name,
            imageTagName: "Active",
            vedioTrack: items.podcasts_description,
            view: "0",
            like: [
              {
                totalLike: items?.likes?.length,
                handleLike: () => handlePodcastLike(key),
              },
            ],
            comment: [
              {
                totalComment: items?.comments?.length,
                handleComment: () => handlePodcastComment(key),
              },
            ],
            // customHandle: "bigstage",
            // totalData: items,
            handleDel: () => handlePodcastDelete(items.podcasts_id),
            handleEdit: () => handlePodcastEdit(items),
            isAdminManaged: userDetails.isAdminManaged
            // design: (
            //     <div key={items.podcasts_id.toString()}>
            //         <DeleteIcon
            //             fontSize="small"
            //             style={{ cursor: "pointer" }}
            //             titleAccess="Delete" onClick={() => { handleDelete(items.podcasts_id) }} />

            //     </div>
            // ),
          },
        ];
      });
      return formatted;
    } else return [];
  };
  // ----------------------------------------------------------------------News By Artist
  const newsData = (NewsByArtist) => {
    console.log(NewsByArtist);
    console.log(NewsByArtist);
    console.log(NewsByArtist !== undefined && NewsByArtist.length !== 0);
    if (NewsByArtist !== undefined && NewsByArtist.length !== 0) {
      console.log("hello----------------------andar");
      var formatted = NewsByArtist.data.map((items, key) => {
        console.log(key);

        var imagelink = constants.newsFileURL; //constants.baseURL + "newsfiles/";
        return [
          {
            id: items.news_id,
            image: [
              {
                itemImage:
                  items.news_urlImage === ""
                    ? constants.musicURL
                    : imagelink + items.news_urlImage,
                handleImage: () => handleNewsView(items.news_id),
              },
            ],
            // image: imagelink + items.news_urlImage,
            vedioTitle: items.news_title,
            imageTagName: "Active",
            vedioTrack: items.news_description.substring(0, 40),
            view: "0",
            like: [
              {
                totalLike: items.likes.length,
                handleLike: () => handleNewsLike(key),
              },
            ],
            comment: [
              {
                totalComment: items.comments.length,
                handleComment: () => handleNewsComment(key),
              },
            ],
            handleDel: () => handleDelete(items.news_id),
            handleEdit: () => handleNewsEdit(items),
            isAdminManaged: userDetails.isAdminManaged
          },
        ];
      });
      return formatted;
    } else return [];
  };
  // --------------------------------------------------------Events By Artist

  const eventsData = (eventsByArtist) => {
    console.log(eventsByArtist);
    console.log(eventsByArtist);
    console.log(eventsByArtist !== undefined && eventsByArtist.length !== 0);
    if (eventsByArtist !== undefined && eventsByArtist.length !== 0) {
      console.log("hello----------------------andar");
      var formatted = eventsByArtist.data.map((items, key) => {
        console.log(key);

        var imagelink = constants.eventsFileURL; //constants.baseURL + "eventsfiles/";
        return [
          {
            id: items.events_id,
            image: [
              {
                itemImage:
                  items.events_urlImag === ""
                    ? constants.musicURL
                    : imagelink + items.events_urlImage,
                handleImage: () => handleEventsView(items.events_id),
              },
            ],
            vedioTitle: items.events_name,
            handleEdit: () => handleEventEdit(items),
            handleDel: () => handleEventDelete(items.events_id),
            like: [
              {
                totalLike: items.likes.length,
                handleLike: () => handleNewsLike(key),
              },
            ],
            comment: [
              {
                totalComment: items.comments.length,
                handleComment: () => handleNewsComment(key),
              },
            ],
            // imageTagName: "Active",
            vedioTrack: items.events_description.substring(0, 40),
            isAdminManaged: userDetails.isAdminManaged
          },
        ];
      });
      return formatted;
    } else return [];
  };

  // --------------------view  Music Album function
  const handleAlbumView = (key) => {
    setcommonId(key);
    setFlag(true);
    // getMusic(key);
  };
  // async function getMusic(key) {
  //     // console.log(constants.user + "?page=" + page + "&limit=10&filter=usertype||$eq||Artist")
  //     console.log(constants.musicByAlbum + "/" + key + "?page=1&limit=10");
  //     let response = await getdata(
  //         constants.musicByAlbum + "/" + key + "?page=1&limit=10"
  //     );
  //     if (
  //         response.request.status === 200 
  //     ) {
  //         // console.log(response.data)
  //         console.log(response);
  //         setFlag(true);
  //         return setmusic(response.data.data);
  //     }
  // }


  // -------------------------------------Music Album Comment View



  const handleMusicAlbumcomment = (key) => {
    const data = album.data[key].comment
    openCommentDialog(data);
  };
  const handleMusicAlbumLike = (key) => {

  }


  // ----------------------------News Handling
  const handleMusicView = (key) => {
    // getMusic(key)
    console.log("NewsKey---------->", key)
    setcommonId(key);
    setFlag(true);
  };
  const handleVideoView = (key) => {
    // getMusic(key)
    console.log("NewsKey---------->", key)
    setcommonId(key);
    setFlag(true);
  };
  const handleNewsView = (key) => {
    // getMusic(key)
    console.log("NewsKey---------->", key)
    setcommonId(key);
    setFlag(true);
  };

  const getNewsCommentData = (key) => {
    let data = NewsByArtist.data[key].comments;
    console.log(data);
    if (data.length !== 0 && data.length !== undefined) {
      let formattedData = data.map((commentData, key) => {
        return [
          {
            commentDate: commentData.newscomments_created,
            commentMessage: commentData.newscomments_comment,
            userImage: commentData?.user?.urlImage,
            userName:
              commentData?.user?.firstName + " " + commentData?.user?.lastName,
            userPhone: commentData?.user?.phone,
          },
        ];
      });
      return formattedData;
    } else {
      return [];
    }
    //  openCommentDialog(data);
  };
  const getMusicCommentData = (key) => {
    let data = album[key].comments;
    console.log(data);
    if (data?.length !== 0 && data?.length !== undefined) {
      let formattedData = data.map((commentData, key) => {
        return [
          {
            commentDate: commentData.newscomments_created,
            commentMessage: commentData.newscomments_comment,
            userImage: commentData?.user?.urlImage,
            userName:
              commentData?.user?.firstName + " " + commentData?.user?.lastName,
            userPhone: commentData?.user?.phone,
          },
        ];
      });
      return formattedData;
    } else {
      return [];
    }
    //  openCommentDialog(data);
  };
  const getVideoCommentData = (key) => {
    let data = vedioAlbum[key].comments;
    console.log(data);
    if (data?.length !== 0 && data?.length !== undefined) {
      let formattedData = data.map((commentData, key) => {
        return [
          {
            commentDate: commentData.newscomments_created,
            commentMessage: commentData.newscomments_comment,
            userImage: commentData?.user?.urlImage,
            userName:
              commentData?.user?.firstName + " " + commentData?.user?.lastName,
            userPhone: commentData?.user?.phone,
          },
        ];
      });
      return formattedData;
    } else {
      return [];
    }
    //  openCommentDialog(data);
  };

  const handleMusiccomment = (key) => {
    const data = getMusicCommentData(key);
    console.log(data)
    openCommentDialog(data);
  };
  const handleVideocomment = (key) => {
    const data = getVideoCommentData(key);
    console.log(data)
    openCommentDialog(data);
  };
  const handleNewsComment = (key) => {
    const data = getNewsCommentData(key);
    console.log(data)
    openCommentDialog(data);
  };

  const handleMusicLike = (key) => {
    console.log(album)
    let data = album[key].likes;
    console.log(data)
    openLikeDialog(data);
    // openViewDialog(data);
  };
  const handleVideoLike = (key) => {

    let data = vedioAlbum[key].likes;
    console.log(data)
    openLikeDialog(data);
    // openViewDialog(data);
  };
  const handleNewsLike = (key) => {
    console.log(NewsByArtist)
    let data = NewsByArtist.data[key].likes;
    console.log(data)
    openLikeDialog(data);
    // openViewDialog(data);
  };
  // --------------------------------Events Handling
  const handleEventsView = (key) => {
    // getMusic(key)
    // history.push("EventIdData")
    setcommonId(key);
    setFlag(true);
  };
  // ---------------------------------------------------------------------------podcast Handling
  const handlePodcastView = (key) => {
    // getMusic(key)
    console.log("Podcast key", key)
    setcommonId(key);
    setFlag(true);
  };

  const getPodcastCommentData = (key) => {
    let data = podcastByArtist.data[key].comments;
    console.log(data);
    if (data.length !== 0 && data.length !== undefined) {
      let formattedData = data.map((commentData, key) => {
        return [
          {
            commentDate: commentData.podcastcomments_created,
            commentMessage: commentData.podcastcomments_comment,
            userImage: commentData.user.urlImage,
            userName:
              commentData.user.firstName + " " + commentData.user.lastName,
            userPhone: commentData.user.phone,
          },
        ];
      });
      return formattedData;
    } else {
      return [];
    }
    //  openCommentDialog(data);
  };

  const handlePodcastComment = (key) => {
    const data = getPodcastCommentData(key);
    openCommentDialog(data);
  };
  // const handlePodcastComment = (key) => {
  //   let data = podcastByArtist[key].comments;
  //   openCommentDialog(data);
  // };
  const handlePodcastLike = (key) => {
    console.log(key)
    console.log(podcastByArtist.data[key])
    let data = podcastByArtist.data[key].likes;
    openLikeDialog(data);
    // openViewDialog(data);
  };

  // -----------------------------------------------------------------------------------------------setMakeHome
  const handleHomeArtist = () => {
    console.log();
    if (makeHome) {
      setNotShowHome();
    } else {
      setShowHome();
    }
  };

  async function setNotShowHome() {
    // window.alert(bigBlock);
    let jsonData = {
      showOnHome: 0,
    };
    // console.log(id);
    console.log("handle Not Show----------------------->");
    console.log(constants.user + "/" + userDetails.id);
    let NotShowHome = await patchDataApi(
      constants.user + "/" + userDetails.id,
      jsonData
    );
    console.log(NotShowHome);
    if (NotShowHome.status === 200 ) {
      setmakeHome(false);
      getArtist();
      addSuccessMessage("Show On Home Disabled");
    } else {
      // console.log("User Not  successfully Deleted");
    }
  }
  async function setShowHome() {
    // window.alert(bigBlock);
    let jsonData = {
      showOnHome: 1,
    };
    // console.log(id);
    console.log("home show----------------------->");
    console.log(constants.user + "/" + userDetails.id);
    let showHome = await patchDataApi(
      constants.user + "/" + userDetails.id,
      jsonData
    );
    console.log(showHome);
    if (showHome.status === 200 ) {
      addSuccessMessage("Show On Home Enabled");
      getArtist();
      setmakeHome(true);
    } else {
    }
  }
  //   ----------------------------------------------------------------makeFeatured
  const handleFeaturedArtist = () => {
    // window.alert(makeFeature);
    console.log();
    if (userDetails.makeFeatured) {
      setUnFeatured();
    } else {
      setFeatured();
    }
  };
  async function setUnFeatured() {
    let jsonData = {
      makeFeatured: 0,
    };

    console.log("handle Un Featured----------------------->");
    console.log(constants.user + "/" + userDetails.id);
    let unFeatured = await patchDataApi(
      constants.user + "/" + userDetails.id,
      jsonData
    );
    console.log(unFeatured);
    if (unFeatured.status === 200 ) {
      setmakeFeature(false);
      getArtist();
      addSuccessMessage("Artist Standard");
    } else {
    }
  }
  async function setFeatured() {
    let jsonData = {
      makeFeatured: 1,
    };

    console.log("handle Featured----------------------->");
    console.log(constants.user + "/" + userDetails.id);
    let FeaturedArtist = await patchDataApi(
      constants.user + "/" + userDetails.id,
      jsonData
    );
    console.log(FeaturedArtist);
    if (FeaturedArtist.status === 200 ) {
      addSuccessMessage("Featured Artist");
      getArtist();
      setmakeFeature(true);
    } else {
    }
  }

  // -----------------------------------------make top 10

  const handleShowOnTopTen = async () => {
    console.log("artist.showOnTopTen: ", userDetails.showOnTopTen);
    console.log("userDetails: ", userDetails.showOnTopTwenty == 1);
    let respArtist = await getdata(
      constants.getBigStage + "?filter=showOnTopTen||$eq||1"
    );
    if (respArtist.data.length !== 20) {
      if (userDetails.showOnTopTwenty == 1) {
        //   addSuccessMessage("The BigStage is already in top twenty list");
        openConfirmationDeleteDialog(confirmToMakeTopTen, "Yes");
        return;
      }

      if (userDetails.showOnTopTen == 1 || userDetails.showOnTopTen == 0) {
        // addSuccessMessage("hello")
        checkAndUncheckShowTen();
      }
    } else {
      addErrorMessage("You crosses the limit !");
    }
  };

  const checkAndUncheckShowTen = async () => {
    console.log("userDetails.showOnTopTen: ", userDetails.showOnTopTen);

    let jsonData = {
      showOnTopTen: userDetails.showOnTopTen == 0 ? 1 : 0,
      // showOnTopTwenty:0,
    };
    let respArtist = await patchDataApi(
      constants.user + "/" + userDetails.id,
      jsonData
    );
    console.log("respBigStage: ", respArtist);
    if (respArtist.status === 200 ) {
      //   getBigstageUser(props.location.state.id);
      getArtist();
      addSuccessMessage(
        userDetails.showOnTopTen == 1
          ? "Show On Top Ten Disabled"
          : "Show On Top Ten Enabled"
      );
    } else {
      console.log("Error:");
      addErrorMessage("Error while updating show on top twenty");
    }
  };
  const confirmToMakeTopTen = async () => {
    let jsonData = {
      showOnTopTen: userDetails.showOnTopTen == 0 ? 1 : 0,
      showOnTopTwenty: 0,
    };
    let respArtist = await patchDataApi(
      constants.user + "/" + userDetails.id,
      jsonData
    );
    console.log("respBigStage: ", respArtist);
    if (respArtist.status === 200 ) {
      //   getBigstageUser(props.location.state.id);
      getArtist();
      addSuccessMessage(
        respArtist == 1 ? "Show On Top Ten Enabled" : "Show On Top Ten Disabled"
      );
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      console.log("Error:");
      addErrorMessage("Error while updating show on top ten");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };

  // -------------------------------------------------make Top twenty

  const handleShowOnTopTwenty = async () => {
    console.log("userDetails.showOnTopTwenty: ", userDetails.showOnTopTwenty);

    let respArtist = await getdata(
      constants.getBigStage + "?filter=showOnTopTwenty||$eq||1"
    );
    if (respArtist.data.length !== 20) {
      if (userDetails.showOnTopTen == 1) {
        // addErrorMessage("The BigStage is already in top ten list");
        openConfirmationDeleteDialog(confirmToMakeTopTwenty, "Yes");
        return;
      }
      if (
        userDetails.showOnTopTwenty == 1 ||
        userDetails.showOnTopTwenty == 0
      ) {
        // addSuccessMessage("hello")
        checkAndUncheckShowTwenty();
      }
    } else {
      addErrorMessage("You crosses the limit !");
    }

    // if(bigStageUser.bigstage_showOnTopTwenty == 0){

    // }
  };
  const checkAndUncheckShowTwenty = async () => {
    let jsonData = {
      showOnTopTwenty: userDetails.showOnTopTwenty == 0 ? 1 : 0,
      // showOnTopTwenty:0,
    };
    let respArtist = await patchDataApi(
      constants.user + "/" + userDetails.id,
      jsonData
    );
    console.log("respArtist: ", respArtist);
    if (respArtist.status === 200 ) {
      //   getBigstageUser(props.location.state.id);
      getArtist();
      addSuccessMessage(
        userDetails.showOnTopTwenty == 0
          ? "Show On Top Twenty Enabled"
          : "Show On Top Twenty Disabled"
      );
    } else {
      console.log("Error:");
      addErrorMessage("Error while updating show on top twenty");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };

  const confirmToMakeTopTwenty = async () => {
    let jsonData = {
      showOnTopTwenty: userDetails.showOnTopTwenty == 0 ? 1 : 0,
      showOnTopTen: 0,
    };
    let respArtist = await patchDataApi(
      constants.user + "/" + userDetails.id,
      jsonData
    );
    console.log("respArtist: ", respArtist);
    if (respArtist.status === 200 ) {
      getArtist();
      addSuccessMessage(
        userDetails.showOnTopTwenty == 0
          ? "Show On Top Twenty Enabled"
          : "Show On Top Twenty Disabled"
      );
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      console.log("Error:");
      addErrorMessage("Error while updating show on top twenty");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };
  // --------------------------------------------Main Artist
  const handleMainArtist = async () => {
    if(album.length>0 || vedioAlbum.length>0){
      console.log(constants.user + "?filter=showMainArtist||$eq||1");
      let respArtist = await getdata(
        constants.user + "?filter=showMainArtist||$eq||1"
      );
      console.log(respArtist);
      if (respArtist.data.length === 0) {
        setOnMainArtist();
      } else {
        console.log(respArtist.data[0].id);
        if (respArtist.data[0].id === userDetails.id) {
          setOnMainArtist();
        } else {
          // confirmchanging Message
          openConfirmationDeleteDialog(confirmToMakeAnotherMainArtist, "Yes");
          setPreviousMainArtistDisable(respArtist.data[0].id);
        }
      }
    }else{
      addErrorMessage("Artist need minimum one album");
    }
   
  };
  const setPreviousMainArtistDisable = async (PreviousMainArtistId) => {
    console.log(PreviousMainArtistId, " : PreviousMainArtistId ");

    let jsonData = {
      showMainArtist: 0,
    };
    let respArtist = await patchDataApi(
      constants.user + "/" + PreviousMainArtistId,
      jsonData
    );
    console.log("respArtist: ", respArtist);
    if (respArtist.status === 200 ) {
      // getArtist()
      //   addSuccessMessage("Main Artist Enabled");
      //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      console.log("Error:");
      addErrorMessage("Error while updating show on top twenty");
      //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };

  const confirmToMakeAnotherMainArtist = async () => {
    let jsonData = {
      showMainArtist: 1,
    };
    let respArtist = await patchDataApi(
      constants.user + "/" + userDetails.id,
      jsonData
    );
    console.log("respArtist: ", respArtist);
    if (respArtist.status === 200 ) {
      getArtist();
      addSuccessMessage("Main Artist Enabled");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      console.log("Error:");
      addErrorMessage("Error while updating show on top twenty");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };

  const setOnMainArtist = async () => {
    console.log("ShowOnMian Artist", userDetails.showMainArtist);

    let jsonData = {
      //   showOnTopTwenty: userDetails.showOnTopTwenty == 0 ? 1 : 0,
      showMainArtist: userDetails.showMainArtist == 0 ? 1 : 0,
    };
    let respArtist = await patchDataApi(
      constants.user + "/" + userDetails.id,
      jsonData
    );
    console.log("respArtist: ", respArtist);
    if (respArtist.status === 200 ) {
      getArtist();
      addSuccessMessage(
        userDetails.showMainArtist == 0
          ? "Main Artist Enabled"
          : "Main Artist Disabled"
      );
    } else {
      console.log("Error:");
      addErrorMessage("Error In Main Artist");
    }
  };
  // ----------------------------------------------handleBack
  const handleBack = (BackStatus) => {
    console.log("BackStatus", BackStatus)
    if (BackStatus === "NewsBack") {
      setFlag(false);
      getNews(1);
    } else if (BackStatus === "EventBack") {
      setFlag(false);
      getEvents(1);
    } else if (BackStatus === "PodcastBack") {
      setFlag(false);
      getPodcast(1)
    } else {
      setFlag(false);
    }
  };
  // -------------------------------------------------------NextPage Podcast Handle
  const nextPodcastPage = () => {
    setPage(page + 1);
    getNextPodcastPage(page + 1);
  };

  console.log("--------------------page" + page);
  async function getNextPodcastPage() {
    console.log(
      constants.podcastsByArtist +
      "/" +
      ArtistId +
      "?page=" +
      page +
      "&limit=10"
    );
    let response = await getdata(
      constants.podcastsByArtist +
      "/" +
      ArtistId +
      "?page=" +
      page +
      "&limit=10"
    );
    if (
      response.request.status === 200 
    ) {
      console.log("--------------------> next page");
      console.log(response);
      if (response.data.data.length !== 0) {
        setpodcastByArtist((prevState) => {
          return {
            ...prevState,
            totalCount: prevState.totalCount + response.data.totalCount,
            data: [...prevState.data, ...response.data.data],
          };
        });
      } else {
        return <div>No more Record</div>;
      }
      // return setBigStageData(response.data);
    }
  }
  // -------------------------------------------------------NextPage News Handle
  const nextNewsPage = () => {
    setPage(page + 1);
    getNextNewsPage(page + 1);
  };

  console.log("--------------------page" + page);
  async function getNextNewsPage() {
    console.log(
      constants.newsByArtist + "/" + ArtistId + "?page=" + page + "&limit=10"
    );
    let response = await getdata(
      constants.newsByArtist + "/" + ArtistId + "?page=" + page + "&limit=10"
    );
    if (
      response.request.status === 200 
    ) {
      console.log("--------------------> next page");
      console.log(response);
      if (response.data.data.length !== 0) {
        setNewsByArtist((prevState) => {
          return {
            ...prevState,
            totalCount: prevState.totalCount + response.data.totalCount,
            data: [...prevState.data, ...response.data.data],
          };
        });
      } else {
        return <div>No more Record</div>;
      }
      // return setBigStageData(response.data);
    }
  }
  // -------------------------------------------------------NextPage Events Handle
  const nextEventsPage = () => {
    setPage(page + 1);
    getNextEventsPage(page + 1);
  };

  console.log("--------------------page" + page);
  async function getNextEventsPage() {
    console.log(
      constants.eventsByArtist + "/" + ArtistId + "?page=" + page + "&limit=10"
    );
    let response = await getdata(
      constants.eventsByArtist + "/" + ArtistId + "?page=" + page + "&limit=10"
    );
    if (
      response.request.status === 200 
    ) {
      console.log("--------------------> next page");
      console.log(response);
      if (response.data.data.length !== 0) {
        seteventsByArtist((prevState) => {
          return {
            ...prevState,
            totalCount: prevState.totalCount + response.data.totalCount,
            data: [...prevState.data, ...response.data.data],
          };
        });
      } else {
        return <div>No more Record</div>;
      }
      // return setBigStageData(response.data);
    }
  }
  const onChangeImage = async (e) => {
    let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    if (!allowedExtensions.exec(e.target.files[0].name)) {
      addErrorMessage("Please upload a valid image file");
      return false;
    }
    addLoader();
    console.log("aa", e.target.files)
    // imgSrc = URL.createObjectURL(e.target.files[0]);
    let customHeaders = {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer ",
    };
    let data = new FormData();
    data.append(constants.uplFileParameter, e.target.files[0]);
    let res = await postDataApi(
      //constants.baseURL + "profilefiles",
      constants.profileFileUploadURL,
      data,
      customHeaders
    );
    console.log("res", res);
    if (res.data.status === 200) {
      // setPersonalInfoData((prevState) => {
      //   return { ...prevState, profilePicture: "" };
      // });
      // setSelectedUserPicture([]);
      updateUserProfilePicture(res.data.data.filename);

    } else {
      removeLoader();
    }
  }
  const updateUserProfilePicture = async (profileImageName) => {
    let jsonData;
    jsonData = {
      urlImage: profileImageName,
    };
    console.log(jsonData);
    if (jsonData != undefined) {
      var formBody = [];
      for (var property in jsonData) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(jsonData[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let apiName = "user/updateUserProfilePicture/";

      let respUpdate = await patchDataApi(
        constants.baseURL + apiName + ArtistId,
        formBody,
        authHeader
      );
      console.log("respUpdate: ", respUpdate);
      if (
        respUpdate != undefined &&
        respUpdate.status != undefined &&
        respUpdate.status == 200
      ) {
        // refreshUserInfoLocalStorage(respUpdate.data);

        addSuccessMessage("Update Image");
        getArtist()
        removeLoader();
        // setShowConfirmModal(false);
      } else {
        removeLoader();

      }
    }
  };



  const handleClose = () => {
    setOpen(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  return (
    <div className="main-window">
      <Grid container spacing={2}>
        <Grid item md={12}>
          <NavSearchBar
            title={
              userDetails.firstName === undefined
                ? ""
                : userDetails.firstName + " " + userDetails.lastName
            }
          />
        </Grid>
        <Grid item md={12}>
          <div style={{ alignItems: "center", alignContent: "center" }}>
            {flag === false ? (
              <ArtistDetails
                MakeHome={makeHome}
                onChange={() => handleHomeArtist()}
                MakeFeatured={makeFeature}
                onChange1={() => handleFeaturedArtist()}
                MakeTopTen={MakeTopTen}
                onChangeShowOnTopTen={() => handleShowOnTopTen()}
                MakeTopTwenty={MakeTopTwenty}
                onChangeShowOnTopTwenty={() => handleShowOnTopTwenty()}
                MainArtist={MainArtist}
                onChangeMainArtist={() => handleMainArtist()}
                ArtistData={userDetails}
                onChangeImage={(e) =>onChangeImage(e)}
              ></ArtistDetails>
            ) : (
              <ArtistUserAlbumDetails
                MakeFeatured={makeFeature}
                onChange1={() => handleFeaturedArtist()}
                ArtistData={userDetails}
              />
            )}
          </div>
        </Grid>
        <Grid item md={12}>
          {/* <CustomTab ></CustomTab> */}
          {userDetails.status?<Grid container spacing={2}>
            <Grid item md={8} xl={10} xs={12}>
              <Tabs value={selectedTab} onChange={handleChange}>
                <Tab label="Music" />
                <Tab label="Videos" />
                <Tab label="Podcast" />
                <Tab label="News" />
                <Tab label="Events" />
              </Tabs>
            </Grid>
            <Grid className="create-btn"
              item
              md={3}
              xl={2}
              xs={12}
              style={{ alignSelf: "center", textAlign: "center" }}
            >
              {flag === false ? (
                <div className="dis-flex-around">
                  {selectedTab === 0 && (
                    <div className="header-type-3">
                      {" "}
                      {album.length + " Music (s)"}
                    </div>
                  )}
                  {selectedTab === 1 && (
                    <div className="header-type-3">
                      {vedioAlbum.length + " Video(s)"}
                    </div>
                  )}
                  {selectedTab === 2 && (
                    <div className="header-type-3">
                      {podcastByArtist.totalCount === undefined ? "0  Podcast(s)" : podcastByArtist.totalCount + " Podcast(s)"}
                    </div>
                  )}
                  {selectedTab === 3 && (
                    <div className="header-type-3">
                      {NewsByArtist.totalCount === undefined ? "0 News(s)" : NewsByArtist.totalCount + " News(s)"}
                    </div>
                  )}
                  {selectedTab === 4 && (
                    <div className="header-type-3">
                      {eventsByArtist.totalCount === undefined ? "0 Events(s)" : eventsByArtist.totalCount + " Event(s)"}
                    </div>
                  )}
                  <Grid item md={5} xs={5}>
        <CustomTextField
                  label="Search"
                  onChange={getInputVal}
                  name="search"
                  value={inputVal.search}
                ></CustomTextField>
        </Grid>
        <Grid item md={1} xs={2}>
          <div className="dis-flex-around">
            <img src={Search} alt="Logo" style={{ width: " 36px" }}
             onClick={()=>sendvalue(inputVal.search)}
             />
           
          </div>
        </Grid>
                  {userDetails.isAdminManaged ?<><img src={Search} alt="Logo" style={{ width: " 36px" }} />
                  <CustomButtonOutlined
                    onClick={() => callPage()}
                    text="Create New"
                  /></>:""}
                </div>
              ) : (
                ""
              )}
            </Grid>
            {/* <Grid item md={1}>
                            {flag === false ?
                                <div className="dis-flex-around">
                                    <img src={Search} alt="Logo" style={{ width: " 36px" }} />

                                    <img src={Avatar} alt="Logo" style={{ width: " 36px" }} />
                                </div> : ""}
                        </Grid> */}
          </Grid>:null}

          {selectedTab === 0 && userDetails.status && (

            <div>
              
               
                {album.length !== 0 ? (
                  <div>
                    {flag === false ? (
                      <ArtistTypeCard
                        sourceData={musicData(album)}
                        variant="Music"
                      ></ArtistTypeCard>
                    ) : (
                      <Song
                        // musicData={music}

                        albumId={commonId}
                        backButton={() => handleBack()}
                      ></Song>
                    )}
                  </div>) : (
                  <p style={{ paddingTop: "150px", textAlign: "center" }}>
                    No data found
                  </p>
                )}
            </div>
          )}
          {selectedTab === 1 && userDetails.status &&(
            <>
              <div>
               



                  {vedioAlbum.length !== 0 ? (
                    <div>
                      {flag === false ? (
                        <ArtistTypeCard
                          sourceData={vedioData(vedioAlbum)}
                          variant="Vedio"
                        ></ArtistTypeCard>
                      ) : (
                        <Song
                          // musicData={music}
                          albumId={commonId}
                          backButton={() => handleBack()}
                        ></Song>
                      )}
                    </div>) : (
                    <p style={{ paddingTop: "150px", textAlign: "center" }}>
                      No data found
                    </p>
                  )}
              </div>
            </>
          )}
          {selectedTab === 2 && userDetails.status &&(
            <div>
            
                {stopLoader.podcastLoader === true ? (
                  <div>
                    {flag === false ? (
                      <ArtistTypeCard
                        sourceData={podcastData(podcastByArtist)}
                        variant="PodCast"
                      ></ArtistTypeCard>
                    ) : (
                      <ArtistTabPodcastDetails
                        podcastId={commonId}
                        backButton={() => handleBack("PodcastBack")}
                      />
                    )}
                  </div>) : (
                  <p style={{ paddingTop: "150px", textAlign: "center" }}>
                    No data found
                  </p>
                )}
            </div>
          )}
          {selectedTab === 3 && userDetails.status &&(
            <div>
              

                {stopLoader.newsLoader === true ? (
                  <div>
                    {flag === false ? (
                      <ArtistTypeCard
                        sourceData={newsData(NewsByArtist)}
                        variant="News"
                      ></ArtistTypeCard>
                    ) : (
                      <ArtistTabNewsDetails
                        newsId={commonId}
                        backButton={() => handleBack("NewsBack")}
                      />
                    )}
                  </div>) : (
                  <p style={{ paddingTop: "150px", textAlign: "center" }}>
                    No data found
                  </p>
                )}

            </div>
          )}
          {selectedTab === 4 && userDetails.status &&(
            <div>

                {stopLoader.eventLoader === true ? (
                  <div>
                    {flag === false ? (
                      <ArtistTypeCard
                        sourceData={eventsData(eventsByArtist)}
                        variant="Event"
                      ></ArtistTypeCard>
                    ) : (
                      <ArtistTabEventsDetails
                        eventsId={commonId}
                        backButton={() => handleBack("EventBack")}
                      />
                    )}
                  </div>) : (
                  <p style={{ paddingTop: "150px", textAlign: "center" }}>
                    No data found
                  </p>
                )}
            </div>
          )}
        </Grid>
        {/* --------------------------------------------View More Button */}
        <Grid item md={12} xs={12}>
          <div style={{ textAlign: "-webkit-center" }}>
            {selectedTab === 0 && <div className="header-type-3"></div>}
            {selectedTab === 1 && <div className="header-type-3"></div>}
            {selectedTab === 2 && (
              <div className="header-type-3">
                {flag === false ? (
                  <div>
                    {podcastByArtist.length !== 0 ? (
                      <div>
                        {podcastByArtist.data.length === 10 ? (
                          <div>
                            <Grid item md={12} style={{ width: "20%" }}>
                              <CustomButtonOutlined
                                text={"view More "}
                                onClick={() => nextPodcastPage()}
                              />
                            </Grid>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
            {selectedTab === 3 && (
              <div className="header-type-3">
                {flag === false ? (
                  <div>
                    {NewsByArtist.length !== 0 ? (
                      <div>
                        {NewsByArtist.data.length === 10 ? (
                          <div>
                            <Grid item md={12} style={{ width: "20%" }}>
                              <CustomButtonOutlined
                                text={"view More "}
                                onClick={() => nextNewsPage()}
                              />
                            </Grid>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
            {selectedTab === 4 && (
              <div className="header-type-3">
                {flag === false ? (
                  <div>
                    {eventsByArtist.length !== 0 ? (
                      <div>
                        {eventsByArtist.data.length === 10 ? (
                          <div>
                            <Grid item md={12} style={{ width: "20%" }}>
                              <CustomButtonOutlined
                                text={"view More "}
                                onClick={() => nextEventsPage()}
                              />
                            </Grid>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      {/* <Dialog open={open} onClose={handleClose} aria-labelledby="customized-dialog-title" fullWidth="true" 
        maxWidth={"xl"} >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Modal title
        </DialogTitle>
        <DialogContent>
         <UploadMusic/>
         
        </DialogContent>
        <DialogActions>
          
        </DialogActions>
      </Dialog> */}

      {/* <Dialog open={open1} onClose={handleClose1} aria-labelledby="customized-dialog-title" fullWidth="true" 
        maxWidth={"xl"} >
      <DialogTitle id="customized-dialog-title" onClose={handleClose1}>
          Modal title
        </DialogTitle>
        <DialogContent>
         <UploadNews/>
         
        </DialogContent>
        <DialogActions>
          
        </DialogActions>
      </Dialog> */}

      {/* <UserDetails></UserDetails> */}
      {/* <Song></Song> */}
      {/* <SongDetails></SongDetails> */}
    </div>
  );
}
