import React from "react";
import { Grid } from "@material-ui/core";
import CardCategory from "../../components/CardNew/CardCategory";
import CardSmallUserInformation from "../../components/CardNew/CardSmallUserInformation";
import { LikeCard, CommentCard } from "../../components/CardNew/CommonCards";
import { constants } from "../../utility/constants";

export default function ReportedSongsDialogContent(props) {
  const { data } = props;

  console.log(data);
  var Userimagelink = constants.profileURL; //constants.baseURL + "profilefiles";
  return (
    <div>
      <Grid container spacing={2} style={{ margin: "0px" }}>
        <Grid item xs={12} md={12}>
          <CardSmallUserInformation
            // isTransparent
            urlImageUser={constants.musicURL}
            userName={data.album.album_name}
            // userEmail={data.email}
            font_size="font-12"
          ></CardSmallUserInformation>
        </Grid>
        <Grid item xs={12} md={12}>
          <CardSmallUserInformation
            isBlack
            isTransparentprofile
            showButton
            urlImageUser={Userimagelink + data.album.user.urlImage}
            userName={
              data.album.user.firstName + " " + data.album.user.lastName
            }
            userEmail={data.album.user.email}
            userid={data.album.user.id}

            // font_size="font-12"
          ></CardSmallUserInformation>
        </Grid>
        {/* <Grid item xs={12} md={12}> */}

        <Grid item xs={6} md={6}>
          <LikeCard totalLike={data.likes.length} extra={true} />
        </Grid>
        <Grid item xs={6} md={6}>
          <CommentCard totalComment={data.comments.length} />
        </Grid>
        {/* </Grid > */}

        <Grid item xs={12} md={12}>
          <CardCategory
            isTransparent
            isBlack
            name="Category(s)"
            type="songs"
            categoriesItem={data.categories}
          />
        </Grid>
        {/* <Grid item md={12}>
                            <div className="addBorder">
                                <p className="header-poppins-12 lineheight-28 ">{data.reported.length}</p>
                                <p className=" header-type-3 lineheight-18">People Reported Podcast</p>
                            </div>
                        </Grid> */}
      </Grid>
    </div>
  );
}
