import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import StreetviewIcon from "@material-ui/icons/Streetview";
import DeleteIcon from "@material-ui/icons/Delete";
import CustomTableNew from "../../components/CustomTable/CustomTableNew";
// import { CustomButtonContained, CustomButtonOutlined } from "../../components/Controls/CustomButton"
// import IconArrowBottom from "../../assets/images/IconArrowBottom.svg";
import "./Users.scss";
import { deleteDataApi, getdata, patchDataApi } from "../../utility/apiCalling";
import {
  openConfirmationDeleteDialog,
  UserInformation,
} from "../../utility/dialogAppear";
import { BlueCard, OrangeCard } from "../../components/CardNew/CommonCards";
import SearchBar from "../../components/SearchBar/SearchBar";
import NavSearchBar from "../../components/SearchBar/NavSearchBar";
import { constants } from "../../utility/constants";
import reactDom from "react-dom";
import { addErrorMessage, addSuccessMessage } from "../../utility/LoadingContent";
import LoaderforTable from "../../components/SkeletonLoader/LoaderforTable";
import { CustomButtonOutlined } from "../../components/Controls/CustomButton";
// import { useRef } from "react";

export default function Users() {
  const [posts, setPosts] = useState([]);
  const [stopLoader, setStopLoader] = useState(true)
  const [showPageNumberButton, setshowPageNumberButton] = useState(false);
  const [serc, setserc] = useState(null);
  var [page, setPage] = useState(1);
  useEffect(() => {
    getUser(page,serc);
  }, []);
  const sendvalue = (i) =>{
    console.log(i)
    setserc(i)
    setPage(1)
    getNextPage(1,i)
  }
  const nextPage = () => {
    setPage(page + 1);
    getUser(page + 1,serc)
  };
  // ---------------------------------------standard User
  var totalStandard = (posts) => {
    var count = 0;
    posts.map((data) => {
      if (data.status === true) count++;
    });
    return count;
  };
  // --------------------------------------blocked User
  var totalBlocked = (posts) => {
    var count = 0;
    posts.map((data) => {
      if (data.status === false) count++;
    });
    return count;
  };

  // ---------------------------------------delete handle
  const handleDelete = (id) => {
    // window.alert(id)
    openConfirmationDeleteDialog(() => deleteUser(id), "Delete");
  };
  const deleteUser = async (id) => {

    let jsonData = {
      isDeleted: 1,
    };
    let deleteData = await patchDataApi(
      constants.user + "/" + id,
      jsonData
    );
    if (deleteData.status === 200 || deleteData.statusText === "OK") {
      addSuccessMessage("User Delete");
      setPage(1)
    getNextPage(1)
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      addErrorMessage("User Not Deleted")
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }

    // let deleteUser1 = await deleteDataApi(constants.user + "/" + id);
    // if (deleteUser1.status === 200 && deleteUser1.statusText === "OK") {
    //   // console.log("User Successfully Deleted");
    //   addSuccessMessage("User Delete ");
    //   getUser();
    //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    // } else {
    //   console.log("User Not Delete");
    //   // reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    // }
  };

  const showUserDetails = (value) => {
    console.log(value);
    let data = posts[value];
    // console.log(posts[value])
    UserInformation(data);
  };
  // --------------------------------------------------------------------

  async function getUser(page,ser) {
    let response
if(ser==undefined||ser=="undefined"||ser==null||ser==""){
  response = await getdata(
    constants.user +
    "/adminSearchUser?page=" +
    page +
    "&limit=10&searchText="+
    "&usertype=User"
  );
}else{
  response = await getdata(
    constants.user +
    "/adminSearchUser?page=" +
    page +
    "&limit=10&searchText="+
    ser+
    "&usertype=User"
  );
}
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      console.log(response.data)
      if (response.data.data.length !== 0) {
        if (response.data.data.length >= 10) {
          setshowPageNumberButton(true);
        }
        return setPosts(posts.concat(response.data.data))
       

      } else {
        setshowPageNumberButton(false);
        // return setPosts(posts)
        // return setStopLoader(false)
      }
      // if (response.data.length !== 0) {
      //   return setPosts(response.data);

      // } else {
      //   return setStopLoader(false)
      // }
      // return setPosts(response.data);
    }
  }
  async function getNextPage(page,ser) {
    // console.log(
    //   constants.user +
    //   "?page=" +
    //   ty +
    //   "&limit=10&filter=usertype||$eq||Artist"
    // );
    let response
    if(ser==undefined||ser=="undefined"||ser==null||ser==""){
      response = await getdata(
        constants.user +
        "/adminSearchUser?page=" +
        page +
        "&limit=10&searchText="+
        "&usertype=User"
      );
    }else{
      response = await getdata(
        constants.user +
        "/adminSearchUser?page=" +
        page +
        "&limit=10&searchText="+
        ser+
        "&usertype=User"
      );
    }
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      console.log("--------------------> next page");
      console.log(response);
      if (response.data.data.length !== 0) {
        if (response.data.data.length !== 10) {
          setshowPageNumberButton(false);
        }else{
          setshowPageNumberButton(true);
        }
        setPosts(response.data.data);
      } else {
        if(ser!==undefined&&ser!=="undefined"&&ser!==null&&ser!==""){
          console.log("jjjjjjj")
          setPosts([]);
        }
        return setshowPageNumberButton(false);
      }
    }
  }
  const data = (posts) => {
    console.log("----------->users posts");
    console.log(posts);
    var formatted = posts.map((item, key) => {
      console.log(item);
      // console.log(item.id)
      var imagelink = constants.profileURL; //constants.baseURL + "profilefiles";
      return [
        <div style={{ display: "flex" }} key={item?.id?.toString()}>
          <img
            src={item.urlImage !== undefined && item.urlImage !== null && item.urlImage !== "" && item.urlImage !== "undefined" ? imagelink + item.urlImage : constants.userImageURL}
            alt={item.firstName + " " + item.lastName}
            className="table-image"
          />
          <div>{" " + item.firstName + " " + item.lastName}</div>
        </div>,
        item.email,
        item.subscribeYouTubeChannel === true ? <p>Yes</p> : <p>No</p>,
        item.status === true ? (
          <span style={{ color: "rgb(76, 196, 124)" }}>Active</span>
        ) : (
          <span style={{ color: "rgb(214, 88, 122)" }}>Inactive</span>
        ),
        item.usertype === "Artist" ? (
          <p style={{ color: "#376BC5" }}>{item.usertype}</p>
        ) : (
          <p style={{ color: "#BF8643" }}>{item.usertype}</p>
        ),
        <div>
          <span>
            <StreetviewIcon
              fontSize="small"
              titleAccess="view"
              style={{
                cursor: "pointer",
                color: "#376BC5",
                marginRight: "20px",
              }}
              onClick={() => showUserDetails(key)}
            />
          </span>
          <span>
            <DeleteIcon
              fontSize="small"
              style={{ cursor: "pointer", color: "#D6587A" }}
              titleAccess="Delete"
              onClick={() => handleDelete(item.id)}
            />
          </span>
        </div>,
      ];
    });
    return formatted;
  };
  return (
    <>
      <div className="main-window">
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <NavSearchBar title="Users" />
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={3} xs={6}>
              <BlueCard
                totalBlue={totalStandard(posts)}
                title="Standard User"
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <OrangeCard
                totalBlocked={totalBlocked(posts)}
                title="Blocked User"
              />
            </Grid>
          </Grid>
          <Grid item md={12} xs={12}>
            <SearchBar title={posts.length + " User(s)"} sendvalue={sendvalue} />
          </Grid>
          <Grid item md={12} xs={12}>

            {/* <LoaderforTable /> */}
            {stopLoader === true ? <CustomTableNew
              tableHead={[
                "Name",
                "Email",
                "Subscribed",
                "status",
                "Type",
                "Action",
              ]}
              tableData={data(posts)}
            /> : <p style={{ paddingTop: "150px", textAlign: "center" }}>
              No data found
            </p>}
          </Grid>
          <Grid item md={12} xs={12}>
                    <div style={{ textAlign: "-webkit-center" }}>
                      {showPageNumberButton && (
                        <div>
                          <Grid item md={12} style={{ width: "20%" }}>
                            <CustomButtonOutlined
                              text={"view More "}
                              onClick={() => nextPage()}
                            />
                          </Grid>
                        </div>
                      )}
                    </div>
                  </Grid>
        </Grid>
      </div>
    </>
  );
}
