import React, { useEffect, useState } from "react";
import { Container, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { CustomButtonContained } from "../../components/Controls/CustomButton";
import NavSearchBar from "../../components/SearchBar/NavSearchBar";
import CustomTextField from "../../components/Controls/CustomTextField";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { deleteDataApi, getdata, patchDataApi, postDataApi } from "../../utility/apiCalling";
import { constants } from "../../utility/constants";
import { Multiselect } from "multiselect-react-dropdown";
import { AiFillCloseSquare } from "react-icons/ai";
import VisibilityIcon from '@material-ui/icons/Visibility';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Checkbox from '@material-ui/core/Checkbox';
import { addErrorMessage, addLoader, addSuccessMessage, removeLoader } from "../../utility/LoadingContent";
const multiSelectStyle = {
  multiselectContainer: {
    color: "white",
    fontSize: 14,
  },
  option: {
    // To change css for dropdown options
    // color: colors.colors13,
  },
  chips: {
    // background: colors.colors13,
    fontSize: 15,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 8,
  },
  searchBox: {
    border: "1px solid #8186AF",
    borderRadius: "2px",
    fontSize: 15,
    minHeight: 40,
  },
};

export default function Artist(props) {
  const [showpage, setshowpage] = useState(false)
  const [inputVal, setInputVal] = useState({ firstName: "", lastName: "", userName: "", phone: "" })
  const [inputVal1, setInputVal1] = useState({ biography: "", youtubeURL: "" })
  const [subscribe, setSubscribe] = useState(false);
  const [errors, setError] = useState({});
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedTypes, setTypes] = useState(false);
  const [typesErr, setTypesErr] = useState(false);
  const [categoryErr, setCategoryErr] = useState(false);
  const [selectedCategory, setCategory] = useState([]);
  const [status, setstatus] = useState(false);
  const [count, setCount] = useState(1);

  const [passwordShow, setPasswordShow] = useState({
    isCreatePasswordShow: true,
    isRepeatPasswordShow: true,
  });
  const emailReg =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const passwordReg =
    /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,20}$/;
  const textRegex =
    /^[/\a-zA-Z0-9\s\[\]\{\}\.\`\~\!\@\$\^\&\(\)\_\+\-\=\;\,]*$/;
  // let phonenoReg = /^[789]\d{9}$/;
  let phonenoReg = /^[0-9]/;
  console.log(subscribe)
  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    if (!inputVal.firstName) {
      formIsValid = false;
      errors["firstName"] = "Firstname is required";
    }
    else if (!textRegex.test(inputVal.firstName)) {
      formIsValid = false;
      errors["firstName"] = "Invalid FirstName";
    }
    // if (!inputVal.lastName) {
    //   formIsValid = false;
    //   errors["lastName"] = "*Please Enter LastName";
    // }
    // else if (!textRegex.test(inputVal.lastName)) {
    //   formIsValid = false;
    //   errors["lastName"] = "*Invalid LastName";
    // }
    // if (!inputVal.userName) {
    //   formIsValid = false;
    //   errors["userName"] = "*Please Enter userName";
    // }
    // else if (!textRegex.test(inputVal.userName)) {
    //   formIsValid = false;
    //   errors["userName"] = "*Invalid userName";
    // }
    // if (!inputVal.phone) {
    //   formIsValid = false;
    //   errors["phone"] = "*Please Enter phone";
    // }
    // else if (!phonenoReg.test(inputVal.phone)) {
    //   formIsValid = false;
    //   errors["phone"] = "*Invalid phone";
    // }
    // else if (inputVal.phone.length !== 10) {
    //   formIsValid = false;
    //   errors["phone"] = "*Invalid phone length";
    // }
    if (inputVal.phone) {

      if (!phonenoReg.test(inputVal.phone)) {
        formIsValid = false;
        errors["phone"] = "Invalid Mobile Number";
      }
      if (inputVal.phone.length !== 10) {
        formIsValid = false;
        errors["phone"] = "Mobile Number should be 10 digits";//"Invalid Mobile Number length";
      }
    }
    if (!inputVal.email) {
      formIsValid = false;
      errors["email"] = "Email is required";
    }
    if (inputVal.email && !emailReg.test(inputVal.email)) {
      formIsValid = false;
      errors["email"] = "Invalid email";
    }
    // if (!inputVal.lastName) {
    //   formIsValid = false;
    //   errors["lastName"] = "Last Name required";
    // }
    // else if (!textRegex.test(inputVal.lastName)) {
    //   formIsValid = false;
    //   errors["lastName"] = "Invalid Last Name";
    // }
    if (!inputVal.userName) {
      formIsValid = false;
      errors["userName"] = "Artist Name required";
    }
    else if (!textRegex.test(inputVal.userName)) {
      formIsValid = false;
      errors["userName"] = "Invalid Artist Name";
    }
    if(inputVal.createPassword || inputVal.repeatPassword)
    {if (!inputVal.createPassword) {
      formIsValid = false;
      errors["createPassword"] = "*Please Enter createPassword";
    }
    else if (!passwordReg.test(inputVal.createPassword)) {
      formIsValid = false;
      errors["createPassword"] = "Password should be min-6, max-20 characters long including(digits, special characters)";
    }
    if (!inputVal.repeatPassword) {
      formIsValid = false;
      errors["repeatPassword"] = "*Please Enter repeatPassword";
    }
    else if (inputVal.createPassword !== inputVal.repeatPassword) {
      formIsValid = false;
      errors["repeatPassword"] = "Password should be same";
    }}

    // if (!accountData.name) {
    //   formIsValid = false;
    //   errors["name"] = "*Please Enter name";
    // }
    // if (!accountData.categoryId) {
    //   formIsValid = false;
    //   errors["categoryId"] = "*Please Select category";
    // }

    setError(errors);
    return formIsValid;
  };
  const validate1Form = () => {
    let errors = {};
    let formIsValid = true;

    if (!inputVal.firstName) {
      formIsValid = false;
      errors["firstName"] = "Firstname is required";
    }
    else if (!textRegex.test(inputVal.firstName)) {
      formIsValid = false;
      errors["firstName"] = "Invalid FirstName";
    }
    if (inputVal.phone) {

      if (!phonenoReg.test(inputVal.phone)) {
        formIsValid = false;
        errors["phone"] = "Invalid Mobile Number";
      }
      if (inputVal.phone.length !== 10) {
        formIsValid = false;
        errors["phone"] = "Mobile Number should be 10 digits";//"Invalid Mobile Number length";
      }
    }
    if (!inputVal.email) {
      formIsValid = false;
      errors["email"] = "Email is required";
    }
    if (!emailReg.test(inputVal.email)) {
      formIsValid = false;
      errors["email"] = "Invalid email";
    }
    // if (!inputVal.lastName) {
    //   formIsValid = false;
    //   errors["lastName"] = "Last Name required";
    // }
    // else if (!textRegex.test(inputVal.lastName)) {
    //   formIsValid = false;
    //   errors["lastName"] = "Invalid Last Name";
    // }
    if (!inputVal.userName) {
      formIsValid = false;
      errors["userName"] = "Artist Name required";
    }
    else if (!textRegex.test(inputVal.userName)) {
      formIsValid = false;
      errors["userName"] = "Invalid Artist Name";
    }
    // if (!inputVal.phone) {
    //   formIsValid = false;
    //   errors["phone"] = "*Please Enter phone";
    // }
    // else if (!phonenoReg.test(inputVal.phone)) {
    //   formIsValid = false;
    //   errors["phone"] = "*Invalid phone";
    // }
    // else if (inputVal.phone.length !== 10) {
    //   formIsValid = false;
    //   errors["phone"] = "*Invalid phone length";
    // }


    setError(errors);
    return formIsValid;
  };
  let history = useHistory();
  console.log(subcategories)
  const validateForm1 = () => {
    let errors = {};
    let formIsValid = true;

    // if (!inputVal1.biography) {
    //   formIsValid = false;
    //   errors["biography"] = "*Please Enter biography";
    // }
    // else if (!textRegex.test(inputVal1.biography)) {
    //   formIsValid = false;
    //   errors["biography"] = "*Invalid biography";
    // }
    // if(!selectedTypes || selectedTypes.length === 0){
    //   formIsValid = false;
    //   errors["cat"] = "*Please Select Type";
    // }
    // if(!selectedCategory || selectedCategory.length === 0){
    //   formIsValid = false;
    //   errors["sub"] = "*Please Select Category";
    // }
    if (selectedCity === "") {
      formIsValid = false;
      errors["city"] = "*Please Select City";
    }
    if (selectedState === "") {
      formIsValid = false;
      errors["state"] = "*Please Select State";
    }
    if (selectedCountry === "") {
      formIsValid = false;
      errors["country"] = "*Please Select Country";
    }



    // if (!accountData.name) {
    //   formIsValid = false;
    //   errors["name"] = "*Please Enter name";
    // }
    // if (!accountData.categoryId) {
    //   formIsValid = false;
    //   errors["categoryId"] = "*Please Select category";
    // }

    setError(errors);
    return formIsValid;
  };
  console.log(selectedCountry)
  const getInputVal = (e) => {
    console.log(e.target)
    let { name, value } = e.target;
   if(name=== "phone"){
    if(value.length<11){
      setInputVal((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
   
   }else{
    setInputVal((prevState) => ({
      ...prevState,
      [name]: value,
    }));
   }
      
    
    
  }
  const getInputVal1 = (e) => {
    console.log(e.target)
    let { name, value } = e.target;

    if (name === "country") {
      setSelectedCountry(value);
      let sCountry = countries.filter((f) => f.name === value);
      if (sCountry.length > 0) {
        getStates(sCountry[0].id);
        setCities([]);
      }
    }
    else if (name === "state") {
      setSelectedState(value);
      let sState = states.filter((f) => f.name === value);
      if (sState.length > 0) {
        getCities(sState[0].id);
      }
    }
    else if (name === "city") {
      setSelectedCity(value);
    }
    else {
      setInputVal1((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const callfirstpage = async () => {
    addLoader()
    if (validateForm()) {
      let respUser = await getdata(
        constants.baseURL + "user?filter=email||$eq||" + inputVal.email
      );
      console.log(respUser)
      if (respUser !== undefined && respUser.data.length > 0) {
        // allowToCreateUser = false;
        // setEmailErr(true);
        removeLoader();
        // setEmailErrMsg(constant.emailRegistered);
        addErrorMessage("email alerady registered");
      } else {
        setshowpage(true)
        setInputVal1({})
        getCountries()
        getCategories()
        removeLoader();
      }

    }
    removeLoader();
  }
  const call1firstpage = async () => {
    addLoader()
    if (validate1Form()) {


      // getCountries()
      // getCategories()
      let arrCategories = [];
      if (inputVal.selCategories.length > 0) {
        console.log(inputVal.selCategories)
        inputVal.selCategories.map((c) => {
          arrCategories.push(c.category);
        });
      }
      const flagList = new Set()
      let ij = arrCategories.filter(function (item) {
        if (!flagList.has(item["name"])) {
          flagList.add(item["name"])
          return true
        }
      })
      console.log(ij)
      setTypes(ij)
      let arrCategories1 = [];
      if (inputVal.selCategories.length > 0) {
        inputVal.selCategories.map((c) => {
          console.log(c)
          let i = {}
          i = c.subcategory
          if(i !== undefined){
            i.category = c?.category
            console.log(i)
            arrCategories1.push(i);
          }
          
        });
      }
      setCategory(arrCategories1)
      // setSelectedCountry(value);
      let sCountry = countries.filter((f) => f.id === inputVal.countryId);
      let sState = states.filter((f) => f.id === inputVal.stateId);
      let sCity = cities.filter((f) => f.id === inputVal.cityId);
      console.log(countries)
      console.log(sCountry, sState, sCity)
      setSelectedState(sState.length > 0 ? sState[0].name : "");
      setSelectedCountry(sCountry.length > 0 ? sCountry[0].name : "");
      setSelectedCity(sCity.length > 0 ? sCity[0].name : "");
      setshowpage(true)
      setCount(count + 1)
      removeLoader();
    }
    removeLoader();
  }
  console.log(inputVal1)
  const callfirstpage1 = async () => {
    
    if (validateForm1()) {
      addLoader();
      let jsonData0 = {
        firstName: inputVal.firstName,
        lastName: inputVal.lastName,
        userName: inputVal.userName,
        email: inputVal.email !== undefined ? inputVal.email : "adminManaged@mmm.com",
        phone:
          inputVal.phone !== undefined ? inputVal.phone.toString() : "",

        password: inputVal.createPassword !== undefined ? inputVal.createPassword : "Mmm@123",
        usertype: "Artist",
        isAdminManaged: 1,
        subscribeYouTubeChannel: subscribe == true ? 1 : 0,
      };
      var formBody = [];
      for (var property in jsonData0) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(jsonData0[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded", //"none",
      };
      let responseUser = await postDataApi(
        constants.baseURL + "auth/signup",
        formBody,
        authHeader
      );
      console.log("responseUser: ", responseUser);
      if (
        responseUser !== undefined &&
        responseUser.status !== undefined &&
        responseUser.status === 201
      ) {
        let fltCountries = countries.filter(
          (c) => c.name === selectedCountry
        );
        let fltStates = states.filter((c) => c.name === selectedState);
        let fltCities = cities.filter((c) => c.name === selectedCity);
        let jsonData = {
          fullAddress: !inputVal1.street  ?  "" : inputVal1.street ,
          countryId: fltCountries.length > 0 ? parseInt(fltCountries[0].id) : null,
          stateId: fltStates.length > 0 ? parseInt(fltStates[0].id) : null,
          cityId: fltCities.length > 0 ? parseInt(fltCities[0].id) : null,
          biography: !inputVal1.biography  ? "" : inputVal1.biography,
          selectedCategory: selectedCategory,
          subcategories: subcategories,
          instagramURL: !inputVal1.instagramURL ? "" :inputVal1.instagramURL, 
          youtubeURL: !inputVal1.youtubeURL  ?  "" :inputVal1.youtubeURL ,
          iTunesURL: !inputVal1.iTunesURL  ?  "" : inputVal1.iTunesURL ,
          spotifyURL: !inputVal1.spotifyURL  ?  "" :inputVal1.spotifyURL, 
          tikTokURL: !inputVal1.tikTokURL  ?  "": inputVal1.tikTokURL,
          appleMusicURL: !inputVal1.appleMusicURL  ? "" :inputVal1.appleMusicURL ,
          facebookURL: !inputVal1.facebookURL  ? "" :inputVal1.facebookURL ,
        };

        console.log(jsonData)
        var formBody1 = [];
        for (var property in jsonData) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(jsonData[property]);
          formBody1.push(encodedKey + "=" + encodedValue);
        }
        formBody1 = formBody1.join("&");
        var authHeader1 = {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        };
        console.log(
          constants.baseURL + "user/completeProfile/" + responseUser.data.id
        );
        let responseUser1 = await patchDataApi(
          constants.baseURL + "user/completeProfile/" + responseUser.data.id,
          formBody1,
          authHeader1
        );
        console.log(responseUser1);
        if (
          responseUser1 !== undefined &&
          responseUser1.status !== undefined &&
          responseUser1.status !== 201
        ) {
          console.log("New User: ", responseUser1);
          if (
            responseUser1.data !== undefined &&
            responseUser1.data.id !== undefined
          ) {
            return setSelectedTypesAndCategoriesForUser(
              responseUser1.data
            ).then(async(response2) => {
              // if (
              //   response2 !== undefined &&
              //   response2.length > 0 &&
              //   response2[0] === true
              // ) {
                addSuccessMessage("Account Created");
               
                await history.push("/artist")
                // history.push({
                //   pathname: `/artist`,
                //   state: {
                //     activeTab: 1,
                //   },
                // });
                removeLoader()
              // }else{
                
              // }
            })
          }
        }else{

        }

      }else{
        removeLoader()
        addErrorMessage("Your Password is too weak!")
      }
    }
    // removeLoader()
    // setshowpage(true)
    // getCountries()
    // getCategories()

    // removeLoader()

  }
  const call1firstpage1 = async () => {
   
    if (validateForm1()) {
      addLoader();
      let fltCountries = countries.filter(
        (c) => c.name === selectedCountry
      );
      let fltStates = states.filter((c) => c.name === selectedState);
      let fltCities = cities.filter((c) => c.name === selectedCity);
      let jsonData0 = {
        firstName: inputVal.firstName,
        lastName: inputVal.lastName,
        userName: inputVal.userName,
        email: inputVal.email,
        phone:
          inputVal.phone !== undefined ? inputVal.phone.toString() : "",

        // password: inputVal.createPassword,
        // usertype: "Artist",
        // isAdminManaged:1,
        subscribeYouTubeChannel: subscribe == true ? 1 : 0,
        // fullAddress: inputVal1.street,
        countryId: fltCountries[0].id,
        stateId: fltStates[0].id,
        cityId: fltCities[0].id,
        biography: inputVal1.biography !== undefined ? inputVal1.biography : "",
        // selectedCategory: selectedCategory,
        // subcategories: subcategories,
        urlImage: inputVal1.urlImage,
        instagramURL: inputVal1.instagramUrl !== undefined ? inputVal1.instagramUrl : "",
        youtubeURL: inputVal1.youtubeURL !== undefined ? inputVal1.youtubeURL : "",
        iTunesURL: inputVal1.iTunesURL !== undefined ? inputVal1.iTunesURL : "",
        spotifyURL: inputVal1.spotifyURL !== undefined ? inputVal1.spotifyURL : "",
        tikTokURL: inputVal1.tikTokURL !== undefined ? inputVal1.tikTokURL : "",
        appleMusicURL: inputVal1.appleMusicURL !== undefined ? inputVal1.appleMusicURL : "",
        facebookURL: inputVal1.facebookURL !== undefined ? inputVal1.facebookURL : "",
      };
      var formBody = [];
      for (var property in jsonData0) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(jsonData0[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded", //"none",
      };
      let responseUser = await patchDataApi(
        constants.baseURL + "user/updatePersonalProfileInfo/" + inputVal1.id,
        formBody,
        authHeader
      );
      console.log("responseUser: ", responseUser);
      if (
        responseUser != undefined &&
        responseUser.status != undefined &&
        responseUser.status == 200
      ) {
        deleteSetSelectedTypesAndCategoriesForUser().then((res) => {
          if (res == true) {
            return setSelectedTypesAndCategoriesForUser1().then(async (response) => {
              // if (
              //   response != undefined &&
              //   response.length > 0 &&
              //   response[0] == true
              // ) {
                
                addSuccessMessage("update profile");
                await history.push("/artist")
                // history.push({
                //   pathname: `/artist`,
                //   state: {
                //     activeTab: props.location.state.activeTab,
                //   },
                // });
                removeLoader();

              // } else {

              // }
            });
          } else {
            addErrorMessage()
          }
        });
      }

    }
    // removeLoader()


  }
  const deleteSetSelectedTypesAndCategoriesForUser = async (id) => {
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return await Promise.resolve(
      deleteDataApi(
        constants.baseURL +
        "selectedcategories/deleteMultipleByUser/" +
        inputVal1.id,
        authHeader
      )
    )
      .then((respDelete) => {
        console.log("Delete Selected Category for User: ", respDelete);
        if (
          respDelete != undefined &&
          respDelete.status != undefined &&
          respDelete.status == 200
        ) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        return false;
      });
  };
  const setSelectedTypesAndCategoriesForUser1 = async () => {
    let promises_array = [];
    //find main categogy which is not present in subcategory selection
    let citems = [];
    selectedTypes.map((c) => {
      let fltCategory = subcategories.filter(
        (f) => f.category !== undefined && f.category.id === c.id
      );
      if (fltCategory.length > 0) {
        let fltTypes = selectedCategory.filter(
          (f) => f.id === fltCategory[0].id
        );
        if (fltTypes.length == 0) {
          citems.push(c);
        }
      }
    });
    console.log("citems:", citems);
    selectedCategory.map((catItem) => {
      promises_array.push(
        new Promise((resolve, reject) => {
          let fltCategory = subcategories.filter((f) => f.id == catItem.id);
          if (fltCategory.length > 0) {
            let jsonData = {
              user: inputVal1.id,
              category: fltCategory[0].category.id,
              subcategory: fltCategory[0].id,
            };
            return callSetSelectedTypesAndCategoriesForUser(jsonData)
              .then(() => {
                resolve(true);
              })
              .catch((error) => {
                reject(false);
              });
          } else {
            resolve(true);
          }
        })
      );
    });
    //add only selected categories
    citems.map((catItem) => {
      promises_array.push(
        new Promise((resolve, reject) => {
          //let fltCategory = subcategories.filter((f) => f.id == catItem.id);
          //if (fltCategory.length > 0) {
          let jsonData = {
            user: inputVal1.id,
            category: catItem.id,
          };
          return callSetSelectedTypesAndCategoriesForUser(jsonData)
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              reject(false);
            });
          // } else {
          //   resolve(true);
          // }
        })
      );
    });

    //remove unselected categories that are present in database
    if (inputVal1.selCategories.length > 0) {
      inputVal1.selCategories.map((item) => {
        promises_array.push(
          new Promise((resolve, reject) => {
            let fExists = selectedTypes.filter(
              (x) => item.subcategory !== undefined && item.subcategory !== null && x.id == item.subcategory.id
            );
            if (fExists.length == 0) {
              return deleteUserCategories(item.id)
                .then(() => {
                  resolve(true);
                })
                .catch((error) => {
                  reject(false);
                });
            } else {
              resolve(true);
            }
          })
        );
      });
    }
    return Promise.all(promises_array);
  };
  const deleteUserCategories = async (id) => {
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return await Promise.resolve(
      await deleteDataApi(
        constants.baseURL + "selectedcategories/" + id,
        authHeader
      )
    )
      .then((resDel) => {
        console.log("resDel: ", resDel);
        if (resDel != undefined && resDel.status == 200) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        return false;
      });
  };
  const setSelectedTypesAndCategoriesForUser = async (objUser) => {
    let promises_array = [];
    selectedCategory.map((catItem) => {
      promises_array.push(
        new Promise((resolve, reject) => {
          let fltCategory = subcategories.filter((f) => f.id == catItem.id);
          console.log(fltCategory)
          if (fltCategory.length > 0) {
            let jsonData = {
              user: objUser.id,
              category: fltCategory[0].category.id,
              subcategory: fltCategory[0].id,
            };
            console.log(jsonData)
            return callSetSelectedTypesAndCategoriesForUser(jsonData)
              .then(() => {
                resolve(true);
              })
              .catch((error) => {
                reject(false);
              });
          } else {
            resolve(true);
          }
        })
      );
    });
    return Promise.all(promises_array);
  };
  const callSetSelectedTypesAndCategoriesForUser = async (jsonData) => {
    console.log(jsonData);
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let resExits = await getdata(
      constants.baseURL +
      "selectedcategories?filter=category.id||$eq||" +
      jsonData.category +
      "&filter=subcategory.id||$eq||" +
      jsonData.subcategory +
      "&filter=user.id||$eq||" +
      jsonData.user
    );
    console.log("resExits: ", resExits);
    if (resExits.data.length == 0) {
      return await Promise.resolve(
        postDataApi(
          constants.baseURL + "selectedcategories",
          formBody,
          authHeader
        )
      )

        .then((response) => {

          console.log(response)
          if (
            response !== undefined &&
            response.status !== undefined &&
            response.status === 201
          ) {

            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          console.log(error)
          return false;
        });
    } else {
      return false;
    }
  };
  const loadSubcategoriesByCategory = (arrCategories) => {
    if (arrCategories.length > 0) {
      getSubcategories(arrCategories);
    } else {
      setSubcategories([]);
    }
  };
  const onRemoveSelectedType = (removedValArr) => {
    setTypes(removedValArr);
    loadSubcategoriesByCategory(removedValArr);
    //remove category if type is removed
    console.log(selectedCategory, removedValArr)
    for (var i = 0; i < selectedCategory.length; i++) {
      let flt = removedValArr.filter(
        (f) => f.id === selectedCategory[i]?.category?.id
      );
      if (flt.length === 0) {
        selectedCategory.splice(i, 1);
        i--;
      }
    }
  };
  const onRemoveSelectedCat = (removedValArr) => {
    setCategory(removedValArr);
  };
  const onSelectType = (selectValArr) => {
    console.log(selectValArr)
    setTypes(selectValArr);
    setTypesErr(false);
    loadSubcategoriesByCategory(selectValArr);
  };
  const onSelectCat = (selectValArr) => {
    console.log(selectValArr)
    setCategory(selectValArr);
    setCategoryErr(false);
  };
  const getCategories = async () => {
    let resCategories = await getdata(
      constants.baseURL + "categories?filter=isDeleted||$eq||0"
    );
    console.log(resCategories.data);
    if (resCategories !== undefined && resCategories.data.length > 0) {
      setCategories(resCategories.data);
    }
  };
  const getSubcategories = async (categories) => {
    let arrCategories = [];
    if (categories?.length > 0) {
      categories.map((c) => {
        arrCategories.push(c.id);
      });
    }
    let resSubcategories = await getdata(
      constants.baseURL +
      "subcategories?filter=isDeleted||$eq||0&filter=category.id||$in||" +
      arrCategories
    );
    if (resSubcategories !== undefined && resSubcategories.data?.length > 0) {
      setSubcategories([...resSubcategories.data]);
    }
  };
  const getCountries = async () => {
    let resCountries = await getdata(constants.baseURL + "countries/getActiveCountries");
    console.log(resCountries)
    if (resCountries !== undefined && resCountries.data.length > 0) {
      // setSelectedCountry(resCountries[0].name);
      setCountries(resCountries.data);
      // if(status==false){getStates(resCountries.data[0].id);}
    }
  };
  const getStates = async (countryId) => {
    setStates([]);
    let resStates = await getdata(
      constants.baseURL + "states/getActiveStatesByCountry/" + countryId
    );
    console.log(resStates)
    if (resStates !== undefined && resStates.data !== undefined) {
      // setSelectedState(resStates[0].name);
      setStates([...resStates.data]);
      // if(status==false){
      //   console.log("jjjjjj")
      //   getCities(resStates.data[0].id)
      // }
    }
  };
  const getCities = async (stateId) => {
    setCities([]);
    let resCities = await getdata(
      constants.baseURL + "cities/getActiveCitiesByState/" + stateId
    );
    console.log(resCities)
    if (resCities !== undefined && resCities.data !== undefined) { // && resCities.data.length > 0
      // setSelectedCity(resCities[0].name);
      setCities([...resCities.data]);
    }
  };
  async function callartist(i) {
    console.log(i);

    let response = await getdata(constants.user + "/adminGetById/" + i.ArtistId);
    console.log(response);
    if (
      response.request.status === 200 
      ||
      response.request.statusText === "OK"
    ) {
      setInputVal(response.data)
      setInputVal1(response.data)
      setSubscribe(response.data?.subscribeYouTubeChannel)
      getCountries()
      getStates(response.data.countryId)
      getCities(response.data.stateId)

      getCategories()
      console.log(response.data.selCategories)
      let arrCategories = [];
      if (response.data.selCategories.length > 0) {
        response.data.selCategories.map((c) => {
          arrCategories.push(c.category);
        });
      }
      console.log(arrCategories)
      // setTypes(arrCategories)
      getSubcategories(arrCategories)
      // setSubscribe(true)
      // setmakeHome(response.data.showOnHome);
      // setmakeFeature(response.data.makeFeatured);
      // setMakeTopTen(response.data.showOnTopTen);
      // setMakeTopTwenty(response.data.showOnTopTwenty);
      // setMainArtist(response.data.showMainArtist);

      // return setuserDetails(response.data);
    }
  }
  useEffect(() => {
    console.log("props.location.state: ", props.location.state);
    if (props.location.state) {
      setstatus(true)
      callartist(props.location.state)
    }
  }, [])

  console.log(inputVal)
  return (
    <>
      <div className="main-window add-artist-main">
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>

            <NavSearchBar title={props.location.state ? "Update Artist" : "Create Artist"} />
          </Grid>
          {!showpage ?
            <>
              <Grid item md={6} xs={12}>

                <CustomTextField
                  name="firstName"
                  label="First Name"
                  value={inputVal.firstName}
                  onChange={getInputVal}
                  // style={{ transform: `${"translate"('-50%','-50%')}`}}
                  // className="MuiInputLabel-shrink"
                  fullWidth

                  required={true}
                ></CustomTextField>
                <span
                  style={{
                    color: "red",

                    top: "5px",
                    fontSize: "10px",
                  }}
                >
                  {errors["firstName"]}
                </span>
              </Grid>
              <Grid item md={6} xs={12}>
                <CustomTextField
                  label="Last Name"
                  onChange={getInputVal}
                  name="lastName"
                  value={inputVal.lastName}
                // required={true}
                // maxLength={250}
                // fullWidth
                ></CustomTextField>
                <span
                  style={{
                    color: "red",

                    top: "5px",
                    fontSize: "10px",
                  }}
                >
                  {errors["lastName"]}
                </span>
              </Grid>
              <Grid item md={6} xs={12}>
                <CustomTextField
                  label="Artist Name"
                  onChange={getInputVal}
                  name="userName"
                  value={inputVal.userName}
                  // required={true}
                  maxLength={250}
                  type="text"

                  fullWidth
                ></CustomTextField>
                <span
                  style={{
                    color: "red",

                    top: "5px",
                    fontSize: "10px",
                  }}
                >
                  {errors["userName"]}
                </span>
              </Grid>
              <Grid item md={6} xs={12}>
                <CustomTextField
                  type="text"
                  label="Mobile Number"
                  onChange={getInputVal}
                  name="phone"
                  maxLength={10}
                  value={inputVal.phone}
                  // required={true}

                  fullWidth
                ></CustomTextField>
                <span
                  style={{
                    color: "red",

                    top: "5px",
                    fontSize: "10px",
                  }}
                >
                  {errors["phone"]}
                </span>
              </Grid>
              {!status ? <><Grid item md={12} xs={12}>
                <CustomTextField
                  type="text"
                  label="Email"
                  onChange={getInputVal}
                  name="email"
                  value={inputVal.email}
                  // required={true}
                  maxLength={250}

                  fullWidth
                ></CustomTextField>
                <span
                  style={{
                    color: "red",

                    top: "5px",
                    fontSize: "10px",
                  }}
                >
                  {errors["email"]}
                </span>
              </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    type={!passwordShow.isCreatePasswordShow
                      ? "text"
                      : "password"}
                    label="Password"
                    onChange={getInputVal}
                    name="createPassword"
                    value={inputVal.createPassword}
                    // required={true}
                    minLength={6}
                    maxLength={20}

                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"

                        // onMouseDown={handleMouseDownPassword}
                        >
                          {!passwordShow.isCreatePasswordShow ? <VisibilityIcon style={{ color: "#FFD874" }} onClick={() => {
                            setPasswordShow((prevState) => {
                              return {
                                ...prevState,
                                isCreatePasswordShow: true,
                              };
                            });
                          }} /> : <VisibilityOff style={{ color: "#FFD874" }} onClick={() => {
                            setPasswordShow((prevState) => {
                              return {
                                ...prevState,
                                isCreatePasswordShow: false,
                              };
                            });
                          }} />}
                        </IconButton>
                      </InputAdornment>,
                    }}
                  ></CustomTextField>
                  {/* {!passwordShow.isCreatePasswordShow ? (
                    <div
                      className="eye-icon-show-hide"
                      onClick={() => {
                        setPasswordShow((prevState) => {
                          return {
                            ...prevState,
                            isCreatePasswordShow: true,
                          };
                        });
                      }}
                    >
                      <BsEyeFill />
                    </div>
                  ) : (
                    <div
                      className="eye-icon-show-hide"
                      onClick={() => {
                        setPasswordShow((prevState) => {
                          return {
                            ...prevState,
                            isCreatePasswordShow: false,
                          };
                        });
                      }}
                    >
                      <BsEyeSlashFill />
                    </div>
                  )} */}
                  <span
                    style={{
                      color: "red",

                      top: "5px",
                      fontSize: "10px",
                    }}
                  >
                    {errors["createPassword"]}
                  </span>
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                    type={
                      !passwordShow.isRepeatPasswordShow
                        ? "text"
                        : "password"
                    }
                    label="Confirm Password"
                    onChange={getInputVal}
                    name="repeatPassword"
                    value={inputVal.repeatPassword}
                    // required={true}
                    minLength={6}
                    maxLength={20}

                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"

                        // onMouseDown={handleMouseDownPassword}
                        >
                          {!passwordShow.isRepeatPasswordShow ? <VisibilityIcon style={{ color: "#FFD874" }} onClick={() => {
                            setPasswordShow((prevState) => {
                              return {
                                ...prevState,
                                isRepeatPasswordShow: true,
                              };
                            });
                          }} /> : <VisibilityOff style={{ color: "#FFD874" }} onClick={() => {
                            setPasswordShow((prevState) => {
                              return {
                                ...prevState,
                                isRepeatPasswordShow: false,
                              };
                            });
                          }} />}
                        </IconButton>
                      </InputAdornment>,
                    }}
                  ></CustomTextField>
                  {/* {!passwordShow.isRepeatPasswordShow ? (
                    <div
                      className="eye-icon-show-hide"
                      onClick={() => {
                        setPasswordShow((prevState) => {
                          return {
                            ...prevState,
                            isRepeatPasswordShow: true,
                          };
                        });
                      }}
                    >
                      <BsEyeFill />
                    </div>
                  ) : (
                    <div
                      className="eye-icon-show-hide"
                      onClick={() => {
                        setPasswordShow((prevState) => {
                          return {
                            ...prevState,
                            isRepeatPasswordShow: false,
                          };
                        });
                      }}
                    >
                      <BsEyeSlashFill />
                    </div>
                  )} */}
                  <span
                    style={{
                      color: "red",

                      top: "5px",
                      fontSize: "10px",
                    }}
                  >
                    {errors["repeatPassword"]}
                  </span>
                </Grid></> : null}
              <Grid item md={"auto"} xs={1} className='add-form-checkbox'>
                <Checkbox
                  onClick={() => setSubscribe(!subscribe)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  checked={subscribe}
                />

              </Grid>
              <Grid item md={11} xs={11}>
                Subscribe Our YouTube Channel
              </Grid>

              <Grid item md={6} xs={12} className='add-artist-btn'>
                <CustomButtonContained text="Back" 
                onClick={()=>
                  {
                    history.push({
                      pathname: `/artist`,
                      state: {
                        activeTab: 1,
                      },
                    })
                  }}
                 />
              </Grid>
              <Grid item md={6} xs={12} className='add-artist-btn'  >
                {!status ? <CustomButtonContained text="Next" onClick={() => callfirstpage()} /> : <CustomButtonContained text="Next" onClick={() => call1firstpage()} />}
              </Grid> </> : <>
              
              <div className="add-artist-overflow">
              <Grid container spacing={2}>
              <Grid item md={12} xs={12} style={{paddingTop:"21px"}} >
                <CustomTextField
                  name="biography"
                  label="Bio"
                  value={inputVal1.biography}
                  onChange={getInputVal1}
                  type="text"
                  fullWidth
                  maxLength={250}
                  multiline
                      rows={3}
                // required={true}
                ></CustomTextField>
                <span
                  style={{
                    color: "red",

                    top: "5px",
                    fontSize: "10px",
                  }}
                >
                  {errors["biography"]}
                </span>
              </Grid>
              <Grid item md={12} xs={12}>
                <Multiselect
                  options={categories}
                  onSelect={onSelectType}
                  onRemove={onRemoveSelectedType}
                  selectedValues={selectedTypes}
                  displayValue="name"
                  placeholder="Select Type"
                  style={multiSelectStyle}
                  customCloseIcon={
                    <AiFillCloseSquare className="multiselect-close-icon" />
                  }
                />
                <span
                  style={{
                    color: "red",

                    top: "5px",
                    fontSize: "10px",
                  }}
                >
                  {errors["cat"]}
                </span>
              </Grid>
              <Grid item md={12} xs={12} className="mb-10">
                <Multiselect
                  options={subcategories}
                  onSelect={onSelectCat}
                  selectedValues={selectedCategory}
                  onRemove={onRemoveSelectedCat}
                  displayValue="name"
                  placeholder="Select Category"
                  style={multiSelectStyle}
                  customCloseIcon={
                    <AiFillCloseSquare className="multiselect-close-icon" />
                  }
                />
                <span
                  style={{
                    color: "red",

                    top: "5px",
                    fontSize: "10px",
                  }}
                >
                  {errors["sub"]}
                </span>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className="complete-profile">
                  <div className="floating-label mt-20">
                    <select
                      className="floating-input floating-select1"
                      placeholder="Select Country"
                      name="country"
                      value={selectedCountry}
                      onChange={getInputVal1}
                    >
                      <option selected value="Select Country" >Select Country</option>
                      {countries.map((option) => (<>

                        <option key={option.name} value={option.name}>
                          {option.name}
                        </option>
                      </>
                      ))}

                    </select>
                  </div>
                  <span
                    style={{
                      color: "red",

                      top: "5px",
                      fontSize: "10px",
                    }}
                  >
                    {errors["country"]}
                  </span>
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <select
                  className="floating-input floating-select1"
                  placeholder=" "
                  name="state"
                  value={selectedState}
                  onChange={getInputVal1}
                >
                  <option selected>Select State</option>
                  {states.map((option) => (
                    <option key={option.name} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </select>
                <span
                  style={{
                    color: "red",

                    top: "5px",
                    fontSize: "10px",
                  }}
                >
                  {errors["state"]}
                </span>
              </Grid>
              <Grid item md={6} xs={12}>
                <select
                  className="floating-input floating-select1 mt-9"
                  placeholder=" "
                  name="city"
                  value={selectedCity}
                  onChange={getInputVal1}
                >
                  <option selected>Select City</option>
                  {cities.map((option) => (
                    <option key={option.name} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </select>
                <span
                  style={{
                    color: "red",

                    top: "5px",
                    fontSize: "10px",
                  }}
                >
                  {errors["city"]}
                </span>
              </Grid>
              {/* <Grid item md={6} xs={12}>
            <CustomTextField
              name="userName"
              label="Zip Code"
              value=""
              onChange=""

              fullWidth
            ></CustomTextField>
          </Grid> */}
              <Grid item md={6} xs={12}>
                <CustomTextField
                  name="youtubeURL"
                  label="Youtube"
                  value={inputVal1.youtubeURL}
                  onChange={getInputVal1}
                  type="text"
                  //  placeholder="Bio"
                  fullWidth
                  maxLength={250}
                // required={true}
                ></CustomTextField>
              </Grid>

              <Grid item md={6} xs={12}>
                <CustomTextField
                  name="instagramURL"
                  label="Instagram"
                  value={inputVal1.instagramURL}
                  onChange={getInputVal1}
                  type="text"
                  fullWidth
                  maxLength={250}
                // required={true}
                ></CustomTextField>
              </Grid>
              <Grid item md={6} xs={12}>
                <CustomTextField
                  name="facebookURL"
                  label="Facebook"
                  value={inputVal1.facebookURL}
                  onChange={getInputVal1}
                  type="text"
                  fullWidth
                  maxLength={250}
                // required={true}
                ></CustomTextField>
              </Grid>
              <Grid item md={6} xs={12}>
                <CustomTextField
                  name="iTunesURL"
                  label="itune Music"
                  value={inputVal1.iTunesURL}
                  onChange={getInputVal1}
                  type="text"
                  fullWidth
                  maxLength={250}
                // required={true}
                ></CustomTextField>
              </Grid>
              <Grid item md={6} xs={12}>
                <CustomTextField
                  name="appleMusicURL"
                  label="Apple Music"
                  value={inputVal1.appleMusicURL}
                  onChange={getInputVal1}
                  type="text"
                  fullWidth
                  maxLength={250}
                // required={true}
                ></CustomTextField>
              </Grid>
              <Grid item md={6} xs={12}>
                <CustomTextField
                  name="spotifyURL"
                  label="Spotify"
                  value={inputVal1.spotifyURL}
                  onChange={getInputVal1}
                  type="text"
                  fullWidth
                  maxLength={250}
                // required={true}
                ></CustomTextField>
              </Grid>
              <Grid item md={6} xs={12}>
                <CustomTextField
                  name="tikTokURL"
                  label="Tiktok"
                  value={inputVal1.tikTokURL}
                  onChange={getInputVal1}
                  type="text"
                  fullWidth
                  maxLength={250}
                // required={true}
                ></CustomTextField>
              </Grid>
              {/* <Grid item md={6} xs={12}>
            <CustomTextField
              name="userName"
              label="Twitter"
              value=""
              onChange=""

              fullWidth
            ></CustomTextField>
          </Grid> */}
              <Grid item md={6} xs={12} className='add-artist-btn'>
                <CustomButtonContained text="Back" onClick={() => setshowpage(false)} />
              </Grid>
              <Grid item md={6} xs={12} className='add-artist-btn' >
                {!status ? <CustomButtonContained text="Save" onClick={() => callfirstpage1()} /> : <CustomButtonContained text="Update" onClick={() => call1firstpage1()} />}
              </Grid>
              </Grid>
              </div>
            </>}

          {/* /////////////second-page//////// */}


        </Grid>
      </div>
    </>
  );
}
