import React, { useEffect, useState } from 'react'
import { Col, Row, Container, Tab, Tabs, Modal, Button, Form } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import uploadImage1 from "../../assets/images/uploadImage1.png";
import ImageUploader from "react-images-upload";
import CustomTextField from "../../components/Controls/CustomTextField";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { CustomButtonContained } from "../../components/Controls/CustomButton";
import uploadMusic2 from "../../assets/images/uploadMusic2.png";
import musiccover from "../../assets/images/ArtistImage1.png";
import closebtn from "../../assets/images/close.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FileDrop } from "react-file-drop";


import "react-datepicker/dist/react-datepicker.css";
import { addErrorMessage, addLoader, addSuccessMessage, removeLoader } from '../../utility/LoadingContent';
import { constants } from '../../utility/constants';
import { Multiselect } from "multiselect-react-dropdown";
import { AiOutlineHeart, AiFillHeart, AiFillCloseSquare } from "react-icons/ai";
import { useHistory } from 'react-router-dom';
import { deleteDataApi, getdata, patchDataApi, postDataApi } from '../../utility/apiCalling';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const Uploadevent = (props) => {
  const classes = useStyles();
  let history = useHistory();
  const { id, item, ArtistId } = props.location.state;
  const [coverImg, setCoverImg] = useState(null);
  const [podFiles, setPodFiles] = useState([]);
  const [dragNDropText, setDragNDropText] = useState("Drop your file here");
  const [isUploadEvents, setUploadEvents] = useState(false);
  const [isEventNameErrShow, setEventNameErr] = useState(false);
  const [isEventAddressErrShow, setEventAddressErr] = useState(false);
  const [isEventDateErrShow, setEventDateErr] = useState(false);
  const [isEventLinkErrShow, setEventLinkErr] = useState(false);
  const [isEventDescErrShow, setEventDescErr] = useState(false);
  const [isAddCategoryErrShow, setAddCategoryErr] = useState(false);
  const [eventCategories, setEventCategories] = useState([]);
  const [isEventImgeErrShow, setisEventImgeErrShow] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [selectedDate, handleDateChange] = useState(
    item !== undefined && item !== null && item !== "" && item.eventdate !== undefined && item.eventdate !== null && item.eventdate !== ""
      ? new Date(item.eventdate)
      : new Date()
  );
  const [eventDetails, setEventDetails] = useState({
    eventName: item !== undefined ? item.title : "",
    eventLink: item !== undefined ? item.eventLink : "",
    eventDescription: item !== undefined ? item.description : "",
    eventurlImage: item !== undefined ? item.img : "",
    urlImage: item !== undefined ? item.urlImage : "",
    eventDate: selectedDate,
    eventAddress: item !== undefined ? item.address : "",
    categoryList: [],
    selectedCategoryList: [],
    existingCategoryList: [],
    id: id,
  });
  const onDrop = (file) => fileValidation(file[0]);
  const onUploadImg = (file) => fileValidation(file[0]);
  const multiSelectStyle = {
    multiselectContainer: {
      color: "white",
      fontSize: 14,
    },
    option: {
      // To change css for dropdown options
      //   color: colors.colors13,
    },
    chips: {
      //   background: colors.colors13,
      fontSize: 15,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 8,
      paddingBottom: 8,
      borderRadius: 8,
    },
    searchBox: {
      border: "1px solid #8186AF",
      borderRadius: "2px",
      //fontFamily: 'Mr Eaves Mod OT',
      fontSize: 15,
      minHeight: 40,
      //width: 395,
    },
  };
  const fileValidation = (file) => {
    // Allowing file type
    let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    if (!allowedExtensions.exec(file.name)) {
      addErrorMessage("Please upload a image file!");
      //enqueueSnackbar("Please upload a image file!", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right', } });
      file = "";
      setDragNDropText("Drop your image here or browse");
      return false;
    } else {
      let imageSrc = URL.createObjectURL(file);
      setDragNDropText("Drop your image here or browse");
      setCoverImg(imageSrc);
      let fileItem = [];
      fileItem.push(file);
      setPodFiles([...fileItem, ...podFiles]);
    }
  };
  const onSelectCategory = (selectedList) => {
    console.log("Selected Category: ", selectedList);
    setEventDetails((prevState) => {
      return { ...prevState, selectedCategoryList: selectedList };
    });
    setAddCategoryErr(false);
  };

  const onRemoveSelectCategory = (selectedList) => {
    setEventDetails((prevState) => {
      return { ...prevState, selectedCategoryList: selectedList };
    });
  };
  const cancelEvents = () => {
    history.goBack();
  };
  const onUpload = async () => {
    const {
      eventName,
      eventLink,
      selectedCategoryList,
      eventDescription,
      eventDate,
      eventAddress,
    } = eventDetails;
    setisEventImgeErrShow(false);
    if (
      !eventName &&
      !eventLink &&
      !eventDescription &&
      !eventDate &&
      !eventAddress &&
      selectedCategoryList.length === 0 &&
      (id === undefined || id === 0)
    ) {
      setEventNameErr(true);
      setEventLinkErr(true);
      setEventDateErr(true);
      setEventAddressErr(true);
      setAddCategoryErr(true);
      setEventDescErr(true);
      addErrorMessage("Upload a event file!");
      //enqueueSnackbar("Upload a event file!", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right', } });
    } else if (!eventName) {
      setEventNameErr(true);
    } else if (!eventLink) {
      setEventLinkErr(true);
    } else if (!eventDescription) {
      setEventDescErr(true);
    } else if (selectedCategoryList.length === 0) {
      setAddCategoryErr(true);
    } else if (!eventDate) {
      setEventDateErr(true);
    } else if (!eventAddress) {
      setEventAddressErr(true);
    } else if (
      id === 0 || id === undefined ? podFiles.length === 0 : eventDetails.eventurlImage.length === 0
    ) {
      //console.log("error in Events");
      setisEventImgeErrShow(true);
    } else {
      //Check if event with same name exists or not
      let respName = await getEventsByName();
      console.log("respName: ", respName);
      if ((id === 0 || id === undefined) && respName.data.length > 0) {
        addErrorMessage("Event with the same name already exists!");
        return;
      } else if (id !== 0 && id !== undefined && respName.data.length > 0 && id !== respName.data[0].id) {
        addErrorMessage("Event with the same name already exists!");
        return;
      }
      //Check if event with same name exists or not
      if (ArtistId !== undefined) {
        setUploadEvents(true);
        setDragNDropText("Drop your file here");
        if (podFiles.length > 0) {
          addLoader();
          uploadEventsFiles();
        } else {
          if (id !== 0 && id !== undefined) {
            addLoader();
            setUploadProgress(25);
            createEvents(eventDetails.urlImage);
          } else {
            removeLoader();
            resetEvents(0);
          }
        }
      }
    }
  };
  const getEventsByName = async () => {
    let resEvent = await getdata(
      constants.baseURL + "events?filter=name||$eq||" + eventDetails.eventName
    );
    return resEvent;
  }
  const resetEvents = (progressValue) => {
    setUploadProgress(progressValue);
    setCoverImg(null);
    setPodFiles([]);
    setEventDetails({
      albumName: "",
      eventDescription: "",
      eventName: "",
      eventLink: "",
      //categoryList: musicCategories,
      selectedCategoryList: [],
    });
  };
  const uploadEventsFiles = async () => {
    if (podFiles.length > 0) {
      let customHeaders = {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer ",
      };
      let data = new FormData();
      podFiles.map((m) => {
        data.append(constants.uplFileParameter, m);
      });
      let res = await postDataApi(
        //constants.baseURL + "eventsfiles",
        constants.eventsFileUploadURL,
        data,
        customHeaders
      );
      console.log("Upload events Files: ", res.data);
      if (
        res != undefined &&
        res.status != undefined &&
        res.status === 201 &&
        res.data != undefined &&
        res.data.data != undefined
      ) {
        console.log("Uploaded events file: ", res.data.data);
        let coverFileName = "";
        coverFileName =
          coverImg != null
            ? res.data.data.filename
            : (id !== 0 && id !== undefined)
              ? eventDetails.eventurlImage
              : "";
        setUploadProgress(25);
        createEvents(coverFileName);
      } else {
        removeLoader();
        addErrorMessage("Error in uploading event files");
        // enqueueSnackbar('Error in uploading event files', {
        //     variant: 'error', anchorOrigin: {
        //     vertical: 'top',
        //     horizontal: 'right',
        //     },
        // });
      }
    } else {
      setUploadProgress(25);
      createEvents(eventDetails.eventurlImage);
    }
  };
  const createEvents = async (coverImageName) => {
    let jsonData = {
      name: eventDetails.eventName,
      eventlink: eventDetails.eventLink,
      description: eventDetails.eventDescription,
      artist: ArtistId,
      urlImage: coverImageName,
      eventdate: selectedDate, //eventDetails.eventDate,
      address: eventDetails.eventAddress,
    };
    console.log(jsonData);
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let respEvents;
    let sStatus = "";
    if (id !== 0 && id !== undefined) {
      respEvents = await patchDataApi(
        constants.baseURL + "events/" + id,
        formBody,
        authHeader
      );
      if (
        respEvents != undefined &&
        respEvents.status != undefined &&
        respEvents.status == 200
      ) {
        sStatus = "Success";
      }
    } else {
      respEvents = await postDataApi(
        constants.baseURL + "events",
        formBody,
        authHeader
      );
      if (
        respEvents != undefined &&
        respEvents.status != undefined &&
        respEvents.status == 201
      ) {
        sStatus = "Success";
      }
    }
    console.log("respEvents: ", respEvents);
    //if (respEvents != undefined && respEvents.status != undefined && respEvents.status == 201) {
    if (sStatus == "Success") {
      setUploadProgress(75);
      return saveEventsCategories(respEvents.data.id).then((response) => {
        if (
          response != undefined &&
          response.length > 0 &&
          response[0] == true
        ) {
          removeLoader();
          setUploadProgress(100);
          addSuccessMessage(id !== 0 && id !== undefined ? "Event Updated" : "Event Created");
          resetEvents(0);
          history.goBack();
        } else {
          removeLoader();
          resetEvents(0);
          history.goBack();
        }
      });
    } else {
      removeLoader();
      addErrorMessage("Error encountered while creating event!");
      resetEvents(0);
      if (id !== 0 && id !== undefined) {
        history.goBack();
      }
    }
  };
  const saveEventsCategories = async (eventId) => {
    let promises_array = [];
    eventDetails.selectedCategoryList.map((item) => {
      promises_array.push(
        new Promise((resolve, reject) => {
          let jsonData = { events: eventId, subcategory: item.id };
          return createEventsCategories(jsonData)
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              reject(false);
            });
        })
      );
    });
    if (id !== 0 && id !== undefined && eventDetails.existingCategoryList.length > 0) {
      eventDetails.existingCategoryList.map((item) => {
        promises_array.push(
          new Promise((resolve, reject) => {
            let fExists = eventDetails.selectedCategoryList.filter(
              (x) => x.id == item.subcategory.id
            );
            if (fExists.length == 0) {
              return deleteEventCategory(item.id)
                .then(() => {
                  resolve(true);
                })
                .catch((error) => {
                  reject(false);
                });
            } else {
              resolve(true);
            }
          })
        );
      });
    }
    return Promise.all(promises_array);
  };
  const createEventsCategories = async (jsonData) => {
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let resExits = await getdata(
      constants.baseURL +
      "selectedeventcategories?filter=events.id||$eq||" +
      jsonData.events +
      "&filter=subcategory.id||$eq||" +
      jsonData.subcategory
    );
    console.log("resExits: ", resExits);
    if (resExits.data.length == 0) {
      return await Promise.resolve(
        postDataApi(
          constants.baseURL + "selectedeventcategories",
          formBody,
          authHeader
        )
      )
        .then((response) => {
          if (
            response != undefined &&
            response.status != undefined &&
            response.status == 201
          ) {
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          return false;
        });
    } else {
      return false;
    }
  };
  const deleteEventCategory = async (id) => {
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return await Promise.resolve(
      await deleteDataApi(
        constants.baseURL + "selectedEventcategories/" + id,
        authHeader
      )
    )
      .then((resDel) => {
        console.log("resDel: ", resDel);
        if (resDel != undefined && resDel.status == 200) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        return false;
      });
  };
  const getSubcategories = async () => {
    let resSubcategories = await getdata(
      constants.baseURL + "subcategories?filter=category.id||$eq||3&filter=isDeleted||$eq||0"
    );
    console.log("resSubcategories: ", resSubcategories);
    if (resSubcategories != undefined && resSubcategories.data.length > 0) {
      setEventCategories([...resSubcategories.data]);
      setEventDetails((prevState) => {
        return {
          ...prevState,
          categoryList: [...resSubcategories.data],
        };
      });
      if (id !== 0) {
        getEventsSubcategories(resSubcategories.data);
      }
    }
  };
  const getEventsSubcategories = async (categories) => {
    let resSubcategories = await getdata(
      constants.baseURL + "selectedeventcategories?filter=events.id||$eq||" + id
    );
    console.log("getEventsSubcategories: ", resSubcategories);
    if (resSubcategories != undefined && resSubcategories.data.length > 0) {
      let sItems = [];
      resSubcategories.data.map((p) => {
        let fltCategories = categories.filter((c) => c.id == p.subcategory.id);
        sItems.push(fltCategories[0]);
      });
      setEventDetails((prevState) => {
        return {
          ...prevState,
          selectedCategoryList: [...sItems],
          existingCategoryList: [...resSubcategories.data],
        };
      });
    }
  };
  useEffect(() => {
    getSubcategories();
  }, []);
  return (
    <div className={classes.root}>
      <div className='upload-song add-artist-main h-100'>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className='upload-music-main  '>
              {coverImg === null ? (
                <FileDrop
                  onDragOver={() =>
                    setDragNDropText("Drop your image here!!")
                  }
                  onDragLeave={() =>
                    setDragNDropText("Drop your file here")
                  }
                  onDrop={onDrop}
                >
                  <div className='upload-background'>
                    <img src={
                      eventDetails.eventurlImage != undefined &&
                        eventDetails.eventurlImage != ""
                        ? eventDetails.eventurlImage
                        : uploadImage1
                    } className="user-profile" alt="hello"></img>
                    <div className="upload-text mt-20">Upload Cover Image</div>
                    <div className="upload-subtitle mt-10">Drop your file here or browse</div>
                    <div className="upload-subtitle mt-10">Or</div>
                    <div style={{ textAlign: 'center' }}>
                      <ImageUploader
                        className="imageUploaderNew"
                        withIcon={false}
                        buttonText="Browse"
                        label=""
                        onChange={(file) => onUploadImg(file)}
                        imgExtension={constants.supportedImageFiles}
                        singleImage={true}
                      />
                    </div>
                  </div>
                </FileDrop>
              ) : (
                <FileDrop
                  onDragOver={() =>
                    setDragNDropText("Drop your image here!!")
                  }
                  onDragLeave={() =>
                    setDragNDropText("Drop your file here")
                  }
                  onDrop={onDrop}
                >
                  <div className='upload-background'>
                    <img onClick={() => setCoverImg(null)}
                      id="uploaded_cover_img"
                      src={coverImg} className="user-profile" alt="hello"></img>
                    <div className="upload-text mt-20">Upload Cover Image</div>
                    <div className="upload-subtitle mt-10">Drop your file here or browse</div>
                    <div className="upload-subtitle mt-10">Or</div>
                    <div style={{ textAlign: 'center' }}>
                      <ImageUploader
                        className="imageUploaderNew"
                        withIcon={false}
                        buttonText="Browse"
                        label=""
                        onChange={(file) => onUploadImg(file)}
                        imgExtension={constants.supportedImageFiles}
                        singleImage={true}
                      />
                    </div>
                  </div>
                </FileDrop>
              )}{isEventImgeErrShow && (
                <div className="errorText" style={{ marginTop: "0px" }}>
                  Please Upload Image File
                </div>
              )}
              <div className="upload-text mt-20  ">Event Details</div>
              <Grid item md={12} className="mt-20" >
                <CustomTextField
                  name="eventname"
                  label="Event Name"
                  placeholder=" "
                  maxLength={255}
                  onChange={(e) => {
                    setEventDetails({
                      ...eventDetails,
                      eventName: e.target.value,
                    });
                    setEventNameErr(false);
                  }}
                  value={eventDetails.eventName}
                  fullWidth
                ></CustomTextField>
                {isEventNameErrShow && (
                  <div className="errorText" style={{ marginTop: "0px" }}>
                    Enter name
                  </div>
                )}
              </Grid>
              <Grid item md={12} className="mt-20" >
                <CustomTextField
                  name="address"
                  label="Address"
                  maxLength={255}
                  multiline
                  rows={2}
                  placeholder=" "
                  onChange={(e) => {
                    setEventDetails({
                      ...eventDetails,
                      eventAddress: e.target.value,
                    });
                    setEventAddressErr(false);
                  }}
                  value={eventDetails.eventAddress}
                  fullWidth
                ></CustomTextField>
                {isEventAddressErrShow && (
                  <div className="errorText" style={{ marginTop: "0px" }}>
                    Enter event address
                  </div>
                )}
              </Grid>
              <Grid item md={12} className="mt-20" >
                <CustomTextField
                  name="title"
                  label="Description"
                  minWidth={300}
                  placeholder=" "
                  onChange={(e) => {
                    setEventDetails({
                      ...eventDetails,
                      eventDescription: e.target.value,
                    });
                    setEventDescErr(false);
                  }}
                  multiline
                  rows={3}
                  value={eventDetails.eventDescription}
                  fullWidth
                ></CustomTextField>
                {isEventDescErrShow && (
                  <div className="errorText" style={{ marginTop: "0px" }}>
                    Enter event descrition
                  </div>
                )}
              </Grid>
              <Grid item md={12} className="mt-20" >
                <CustomTextField
                  name="booking"
                  label="Booking Link"
                  minWidth={300}
                  maxLength={255}
                  onChange={(e) => {
                    setEventDetails({
                      ...eventDetails,
                      eventLink: e.target.value,
                    });
                    setEventLinkErr(false);
                  }}
                  value={eventDetails.eventLink}
                  fullWidth
                ></CustomTextField>
                {isEventLinkErrShow && (
                  <div className="errorText" style={{ marginTop: "0px" }}>
                    Enter event link
                  </div>
                )}
              </Grid>
              <Grid item md={12} className="mt-20" >
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  showTimeSelect
                  minDate={new Date()}
                  dateFormat="MMMM d, yyyy h:mm aa"
                />

              </Grid>

              <div className='upload-control mt-20'>
                <Grid container spacing={3}>

                  <Grid item md={12} xs={12}>
                    {/* <div className="complete-profile">
                                            <div className="floating-label "> */}
                    <Multiselect
                      options={eventDetails.categoryList}
                      selectedValues={eventDetails.selectedCategoryList}
                      onSelect={onSelectCategory}
                      onRemove={onRemoveSelectCategory}
                      displayValue="name"
                      placeholder="Add Category"
                      style={multiSelectStyle}
                      // showCheckbox={true}
                      // closeIcon="circle"
                      customCloseIcon={
                        <AiFillCloseSquare className="multiselect-close-icon" />
                      }
                    />
                    {isAddCategoryErrShow && (
                      <div className="errorText" style={{ marginTop: "0px" }}>
                        Add Category
                      </div>
                    )}
                    {/* </div>
                                        </div> */}
                  </Grid>
                  <Grid item md={6} xs={12} className='add-artist-btn'>
                    <CustomButtonContained text="Cancel" onClick={cancelEvents} />
                  </Grid>
                  <Grid item md={6} xs={12} className='add-artist-btn'>
                    <CustomButtonContained text={id !== 0 && id !== undefined ? "Update" : "Create"} onClick={onUpload} />
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div >
  )
}

export default Uploadevent