import { Container, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Menu, MenuItem } from "@material-ui/core";
import ArtistTypeCard from '../../components/ArtistTypeCard/ArtistTypeCard'
import SearchBar from '../../components/SearchBar/SearchBar'
import { BlueCard, OrangeCard, GreenCard } from '../../components/CardNew/CommonCards';
import NavSearchBar from '../../components/SearchBar/NavSearchBar';
import { deleteDataApi, getdata, patchDataApi } from "../../utility/apiCalling";
import { constants } from "../../utility/constants";
import {
    CustomButtonContained,
    CustomButtonOutlined,
} from "../../components/Controls/CustomButton";
import {
    openCommentDialog,
    openConfirmationDeleteDialog,
    openVedioUpdateDialog,
    openViewDialog,
} from "../../utility/dialogAppear";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { addErrorMessage, addSuccessMessage } from "../../utility/LoadingContent";
import reactDom from "react-dom";
import { NoMoreDataFound } from '../../components/NoDataFound/NoDataFound';

export default function EventPage() {
    const [eventsData, setEventsData] = useState([]);
    const [stopLoader, setStopLoader] = useState(true);
    const [nextPageNotFound, setnextPageNotFound] = useState(false)
    const [showPageNumberButton, setshowPageNumberButton] = useState(false);
    var [page, setPage] = useState(1);
  const [serc, setserc] = useState(null);

    useEffect(() => {
        getEvents(page);
    }, []);
    let history = useHistory();

    // -------------------------menu button

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    // ----------------------------menu Button

    // --------------------view Button function
    const handleView = (key) => {
        let data = eventsData.data[key];
        history.push({
            pathname: `events-details/${data.events_id}`,
            state: {
                eventsId: data.events_id,
            },
        });
    };
    //---------------------------------handle Edit
    const handleEdit = (id) => {
        window.alert(id);
        openVedioUpdateDialog(id);
    };
    //--------------------Delete Button Function
    const handleDelete = (id) => {
        openConfirmationDeleteDialog(deleteBig(id), "Delete");
    };
    const deleteBig = async (id) => {
        let jsonData = {
            isDeleted: 1,
        };
        let deleteData = await patchDataApi(
            constants.getBigStage + "/" + id,
            jsonData
        );
        if (deleteData.status === 200 || deleteData.statusText === "OK") {
            addSuccessMessage("Event Delete");
            setPage(1);
            getEvents(1);
            reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
        } else {
            addErrorMessage("Event Delete");
            reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
        }
        // let deleteBigStage = await deleteDataApi(constants.getBigStage + "/" + id);
        // if (deleteBigStage.status === 200 && deleteBigStage.statusText === "OK") {
        //     // console.log("User Successfully Deleted");
        //     addSuccessMessage("Big Stage Delete ");
        //     setPage(1);
        //     reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
        // } else {
        //     console.log("User Not Delete");
        //     reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
        // }
    };

    async function getEvents(page) {
        console.log("------------------podcast page")
        console.log(constants.admingeteventsURL + "/getAll?page=" + page + "&limit=10")
        let response = await getdata(
            constants.admingeteventsURL + "/getAll?page=" + page + "&limit=10"
        );
        if (
            response.request.status === 200 ||
            response.request.statusText === "OK"
        ) {
            if (response.data.data.length !== 0) {

                if (response.data.data.length === 10) {
                    setshowPageNumberButton(true);
                }
                return setEventsData(response.data);
            } else {
                setEventsData(response.data);
                return setStopLoader(false);
            }
            // console.log("hello");
            // console.log(response.data)
        }
    }
    const handleComment = (key) => {
        let data = eventsData.data[key].comments;
        openCommentDialog(data);
    };
    const handleLike = (key) => {
        let data = eventsData.data[key].likes;
        openViewDialog(data);
    };

    const sendvalue = (i) =>{
        console.log(i)
        setserc(i)
        setPage(1)
        getNextPage(1,i);
    
      }
    const nextPage = () => {
        setPage(page + 1);
        getNextPage(page + 1,serc);
    };

    console.log("--------------------page" + page);
    async function getNextPage(p,ser) {

        let apiURL = constants.admingeteventsURL + "/getAll?page=" + p + "&limit=10"
        if (ser) {
            apiURL += "&searchText=" + ser
          }
          let response = await getdata(
            apiURL
          );
        if (
            response.request.status === 200 ||
            response.request.statusText === "OK"
        ) {
            console.log("--------------------> next page");
            console.log(response);
            if (response.data.data.length !== 0) {
                if ((response.data.limit*response.data.page)<response.data.totalCount) {
                    setshowPageNumberButton(true);
                  }else{
                    setshowPageNumberButton(false);
                  }
                  if(p==1){
                    setEventsData(response.data);
                  }else{
                setEventsData((prevState) => {
                    return {
                        ...prevState,
                        // totalCount: prevState.totalCount + response.data.totalCount,
                        data: [...prevState.data, ...response.data.data],
                    };
                });
            }
            } else {
                return setshowPageNumberButton(false);
            }
            // return setBigStageData(response.data);
        }
    }
    const data = (eventsData) => {
        console.log(eventsData)
        console.log(eventsData);
        console.log(eventsData !== undefined && eventsData.length !== 0)
        if (eventsData !== undefined && eventsData.length !== 0) {
            console.log("hello----------------------andar")
            var formatted = eventsData.data.map((items, key) => {
                console.log(key);

                var imagelink = constants.eventsFileURL;//constants.baseURL + "eventsfiles/";
                return [
                    {
                        id: items.events_id,
                        image: [
                            {
                                itemImage: items.events_urlImag === "" 
                                ? constants.musicURL
                                : imagelink + items.events_urlImage,
                                handleImage: () => handleView(key),
                            },
                        ],
                        vedioTitle: items.events_name,
                        // imageTagName: "Active",
                        vedioTrack: items.events_description.substring(0, 40),
                    },
                ];
            });
            return formatted;
        } else return [];
    };

    return (
        <div className="main-window">
            <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                    <NavSearchBar title="Events" />
                </Grid>
                <Grid item xs={6} md={3}>
                    <GreenCard totalGreen={eventsData.activeCount}
                        title="Active Events" />
                </Grid>
                <Grid item xs={6} md={3}>
                    <BlueCard totalBlue={eventsData.reportedCount}
                        title="Reported Events" />
                </Grid>
                <Grid item xs={6} md={3}>
                    <OrangeCard totalBlocked={eventsData.inactiveCount}
                        title="Blocked Events" />
                </Grid>
                <Grid item xs={12} md={12}>
                    <SearchBar title={
                        eventsData.totalCount === undefined
                            ? "0 Event(s)"
                            : eventsData.totalCount + " Events(s)"
                    }
            sendvalue={sendvalue}
            ></SearchBar>
                </Grid>
                <Grid item xs={12} md={12}>
                    {stopLoader === true ? (
                        <ArtistTypeCard sourceData={data(eventsData)} variant="Event"></ArtistTypeCard>
                    ) : (
                        <p style={{ paddingTop: "150px", textAlign: "center" }}>
                            No data found
                        </p>
                    )}

                </Grid>
                <Grid item md={12} xs={12}>
                    <div style={{ textAlign: "-webkit-center" }}>
                        {showPageNumberButton && (
                            <div>
                                <Grid item md={12} style={{ width: "20%" }}>
                                    <CustomButtonOutlined
                                        text={"view More "}
                                        onClick={() => nextPage()}
                                    />
                                </Grid>
                            </div>
                        )}
                    </div>
                </Grid>
                {/* {nextPageNotFound === true ? <NoMoreDataFound /> :
                    <Grid item md={12} xs={12}>
                        <div style={{ textAlign: "-webkit-center" }}>
                            {eventsData.length !== 0 ? (
                                <div>
                                    {eventsData.data.length === 10 ? (
                                        <div>
                                            <Grid item md={12} style={{ width: "20%" }}>
                                                <CustomButtonOutlined
                                                    text={"view More "}
                                                    onClick={() => nextPage()}
                                                />
                                            </Grid>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            ) : (
                                ""
                            )}

                        </div>
                    </Grid>
                } */}

            </Grid>

        </div>
    )
}
