import { TextField } from "@material-ui/core";
// import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import {
  createStyles,
  alpha,
  Theme,
  ThemeProvider,
  createMuiTheme,
  withStyles,
  makeStyles,
  createTheme,
} from "@material-ui/core/styles";

import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "red",
    "& label.Mui-focused": {
      color: "#8186AF",
    },

    // "& .MuiInput-underline:after": {
    //   borderBottomColor: "#8186AF",
    // },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#757575",
      },
      "&:hover fieldset": {
        borderColor: "#757575",
      },
      // "&.Mui-focused fieldset": {
      //   borderColor: "green",
      // },
    },
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#8186AF",
    },
  },
  overrides: {
    // Style sheet name 
    MuiFormLabel: {
      // Name of the rule
      root: {
        // Some CSS
        color: "whitesmoke",
      },
    },
    MuiFormLabel: {
      // Name of the rule
      root: {
        // Some CSS
        color: "whitesmoke",
      },
    },
    MuiInputBase: {
      // Name of the rule
      input: {
        // Some CSS
        color: "whitesmoke",
      },
    },
  },
});

export default function CustomTextField(props) {

  const classes = useStyles();
  const {
    name,
    label,
    type,
    error = null,
    defaultValue,
    value,
    onChange,
    endAdornment,
    ...other
  } = props;
  return (
    <ThemeProvider theme={theme}>
      <TextField
        className={classes.root}
        variant="outlined"
        margin="dense"
        fullWidth
        color="primary"
        label={label}
        type={type || "text"}
        name={name}
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        endAdornment={endAdornment}
        // error
        // helperText="some validation error"
        {...(error && { error: true, helperText: error })}
        {...other}
      />
    </ThemeProvider>
  );
}
