import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import "./UserDetails.scss";
import { CardEmail, CardSubscribe } from "../CardNew/CardLocation";
import reactDom from "react-dom";
import { getdata, patchDataApi } from "../../utility/apiCalling";
import { constants } from "../../utility/constants";
import {
  CustomButtonContained,
  CustomButtonOutlined,
} from "../../components/Controls/CustomButton";
import { openConfirmationDeleteDialog } from "../../utility/dialogAppear";
import { addSuccessMessage } from "../../utility/LoadingContent";
const useStyles = makeStyles((theme) => ({
  paperWidthXs: {
    maxWidth: "314px",
    margin: "32px",
    position: "relative",
    // overflow-y: auto;
  },
  contained: {
    backgroundColor: "#FFD874",
    color: "black",
    fontSize: "10px",
    borderRadius: "6px",
    padding: "8px 0px",
    textTransform: "capitalize",
  },
  outlined: {
    borderColor: "#FFD874",
    color: "#FFD874",
    fontSize: "10px",
    borderRadius: "6px",
    padding: "8px 0px",
    textTransform: "capitalize",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function UserDetails(props) {
  var image = constants.profileURL; //constants.baseURL + "profilefiles";
  // console.log("-------------------------sjsknckscnkdsn");
  var [userBlock, setUserBlock] = useState(props.data.status);
  const [follow, setfollow] = useState(null);
  //added by Hitendra - 05Mar2022
  const [artistfollowers, setArtistfollowers] = useState(null);
  //added by Hitendra - 05Mar2022
  console.log(props.data);
  console.log(props.biography);
  // console.log("------------------kksdnckndkcnkd");
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const handleClose = () => {
    reactDom.unmountComponentAtNode(props.renderElement);
    setOpen(false);
  };

  // -------------------------------------handle block
  const handleBlock = () => {
    // window.alert("hande block");
    // window.alert(bigBlock);
    {
      userBlock === true
        ? openConfirmationDeleteDialog(Block, "Block")
        : openConfirmationDeleteDialog(unBlock, "Unblock");
    }
  };

  const unBlock = async () => {
    // window.alert(bigBlock);
    let jsonData = {
      // id: data.id,
      status: 1,
    };
    // console.log(id);
    console.log("handle block----------------------->");
    console.log(constants.user + "/" + props.data.id);
    let blockUserData = await patchDataApi(
      constants.user + "/" + props.data.id,
      jsonData
    );
    if (blockUserData.status === 200 || blockUserData.statusText === "OK") {
      setUserBlock(0);
      addSuccessMessage("User Unblock");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };
  const Block = async () => {
    let jsonData = {
      status: 0,
    };
    console.log("handle block----------------------->");
    console.log(constants.user + "/" + props.data.id);
    let blockUserData = await patchDataApi(
      constants.user + "/" + props.data.id,
      jsonData
    );
    if (blockUserData.status === 200 || blockUserData.statusText === "OK") {
      setUserBlock(1);
      addSuccessMessage("User Block");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };
  const callfollower = async(i) =>{
    let resSubcategories =  await getdata(
      constants.baseURL + "follows?filter=user.id||$eq||" + i
    );
    setfollow(resSubcategories.data.length)
  }
  //added by Hitendra - 05Mar2022
  const callArtistfollower = async(i) =>{
    let resSubcategories =  await getdata(
      constants.baseURL + "follows?filter=follower.id||$eq||" + i
    );
    setArtistfollowers(resSubcategories.data.length)
  }
  //added by Hitendra - 05Mar2022
  useEffect(() => {
   console.log(props.data.id);
   callfollower(props.data.id);
   //added by Hitendra
   callArtistfollower(props.data.id);
   //added by Hitendra - 05Mar2022
   
  // if (
  //   resSubcategories.request.status === 200 &&
  //   resSubcategories.request.statusText === "OK"
  // ) {
  //   console.log(resSubcategories)
  // }
  }, [])
  

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <Dialog
        maxWidth="xs"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        style={{ borderRadius: "32px" }}
        // className={classes.paperWidthXs}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          User Details
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <div className="custom-image-email">
                <div className="Header">
                  <img
                    src={image + props.data.urlImage}
                    alt="image_not_Found"
                    className="image-setting"
                  />

                  <div className="HeaderSet">
                    <p className="header-poppins-14 font-18 lineheight-28">
                      {props.data.firstName + " " + props.data.lastName}
                    </p>
                    <p className="header-type-3 lineheight-28">
                      {props.data.email}
                    </p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item md={12} xs={12}>
              <div className="follower custom_foll">
                <div
                  // style={{ borderRight: "1px solid rgb(52, 49, 60)" }}
                  className="followerOption"
                >
                  <p className="header-poppins-12 font-14">{follow !== null ? follow : 0}</p>
                  <p className="header-type-3 font-12">Following</p>
                </div>
                {
                  props.data.usertype === "Artist" &&
                  <div className="followerOption">
                    <p className="header-poppins-12 font-14">{artistfollowers !== null ? artistfollowers : 0}</p>
                    <p className="header-type-3 font-12">Follower</p>
                  </div>
                }
                
              </div>
            </Grid>
            <Grid item md={12} xs={12}>
              <CardEmail email={props.data.email}></CardEmail>
            </Grid>
            <Grid item md={12} xs={12}>
              <CardSubscribe subscribe={props.data.subscribeYouTubeChannel} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item md={6} xs={6}>
              <CustomButtonOutlined
                text="Cancel"
                onClick={() => {
                  handleClose();
                }}
              ></CustomButtonOutlined>
            </Grid>
            <Grid item md={6} xs={6}>
              <CustomButtonContained
                onClick={() => {
                  handleBlock();
                }}
                text={userBlock === true ? "Block" : "UnBlock"}
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
