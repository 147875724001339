import React, { useEffect, useState } from "react";
import CustomTableNew from "../../components/CustomTable/CustomTableNew";
import SearchBar from "../../components/SearchBar/SearchBar";
import { Grid } from "@material-ui/core";
// import IconArrowBottom from "../../assets/images/IconArrowBottom.svg";
// import { Menu, MenuItem } from "@material-ui/core";
import { CustomButtonOutlined } from "../../components/Controls/CustomButton";
import {
  openConfirmationDeleteDialog,
  openReportedPodcastDialog,
} from "../../utility/dialogAppear";
import NavSearchBar from "../../components/SearchBar/NavSearchBar";
import StreetviewIcon from "@material-ui/icons/Streetview";
import { getdata, patchDataApi } from "../../utility/apiCalling";
import { constants } from "../../utility/constants";
import reactDom from "react-dom";
import { addSuccessMessage } from "../../utility/LoadingContent";
import Popup from "../../components/PopUp/Popup";
import ReportedPodcastDialogContent from "./ReportedPodcastDialogContent";
// import { addLoader, removeLoader } from "../../utility/LoadingContent";

export default function ReportedPodcast() {
  const [posts, setPosts] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [responseById, setresponseById] = useState([]);
  const [stopLoader, setStopLoader] = useState(true)
  const [showPageNumberButton, setshowPageNumberButton] = useState(false);
  var [podcastBlock, setPodcastBlock] = useState(0);
  var [page, setPage] = useState(1);
  const [serc, setserc] = useState(null);

  useEffect(() => {
    // document.title = "Artist";
    getReportedPodcast(page);
  }, []);

  // ------------------------------------------------------------Podcast Info
  async function getReportedPodcast(page) {
    let response = await getdata(
      constants.adminreportedPodcast + "/getAll?page=" + page + "&limit=10"
    );
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      if (response.data.data.length !== 0) {
        if (response.data.data.length === 10) {
          setshowPageNumberButton(true);
        }
        return setPosts(response.data);

      } else {
        setPosts(response.data);
        return setStopLoader(false);
      }
      // if (response.data.data.length !== 0) {
      //   return setPosts(response.data);

      // } else {
      //   setPosts(response.data);
      //   return setStopLoader(false)
      // }
      // return setPosts(response.data);
    }
  }

  // -----------------------------------------------------------onClick get Podcast Info
  async function getPodcastInfo(id) {
    // addLoader()
    let response = await getdata(constants.getPodcastURL + "/getById/" + id);
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      // removeLoader()
      console.log(response)
      setresponseById(response.data.data);
      setPodcastBlock(response.data.data.podcasts_status);
      setOpenPopup(true);
      // return openReportedPodcastDialog(response.data.data);
    }
  }

  // ----------------------------handle Block
  const handleBlock = () => {
    {
      podcastBlock === 1
        ? openConfirmationDeleteDialog(Block, "Block")
        : openConfirmationDeleteDialog(unBlock, "Unblock");
    }
  };

  const unBlock = async () => {
    let jsonData = {
      status: 1,
    };

    console.log("handle block----------------------->");
    console.log(constants.getPodcastURL + "/" + responseById.podcasts_id);
    let blockPodcastData = await patchDataApi(
      constants.getPodcastURL + "/" + responseById.podcasts_id,
      jsonData
    );
    if (
      blockPodcastData.status === 200 ||
      blockPodcastData.statusText === "OK"
    ) {
      setOpenPopup(false);

      getReportedPodcast(1);
      addSuccessMessage("Podcast UnBlocked");

      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };
  const Block = async () => {
    // window.alert(bigBlock);
    let jsonData = {
      status: 0,
    };
    // console.log(id);
    console.log("handle block----------------------->");
    console.log(constants.getPodcastURL + "/" + responseById.podcasts_id);
    let blockPodcastData = await patchDataApi(
      constants.getPodcastURL + "/" + responseById.podcasts_id,
      jsonData
    );
    if (
      blockPodcastData.status === 200 ||
      blockPodcastData.statusText === "OK"
    ) {
      setOpenPopup(false);
      // setPage()
      getReportedPodcast(1);
      addSuccessMessage("Podcast Blocked");

      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };

  // --------------------------------------------------------------------------onNext page podcast Info
  const sendvalue = (i) =>{
    console.log(i)
    setserc(i)
    setPage(1)
    getNextPage(1,i);

  }
  const nextPage = () => {
    setPage(page + 1);
    getNextPage(page + 1,serc);
  };

  console.log("--------------------page" + page);
  async function getNextPage(p,ser) {
    let apiURL = constants.adminreportedPodcast + "/getAll?page=" + p + "&limit=10"
    if (ser) {
      apiURL += "&searchText=" + ser
    }
    let response = await getdata(
      apiURL
    );
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      console.log("--------------------> next page");
      console.log(response);
      if (response.data.data.length !== 0) {
        if ((response.data.limit*response.data.page)<response.data.totalCount) {
          setshowPageNumberButton(true);
        }else{
          setshowPageNumberButton(false);
        }
        if(p==1){
          setPosts(response.data);
        }else{
        setPosts((prevState) => {
          console.log(
            "---------------------------------------------------------andar aa gya"
          );
          return {
            ...prevState,
            // totalCount: prevState.totalCount + response.data.totalCount,
            data: [...prevState.data, ...response.data.data],
          };
        });
      }
      } else {
        return setshowPageNumberButton(false);
      }
      // return setBigStageData(response.data);
    }
  }

  const data = () => {
    console.log("----------->users posts");
    console.log(posts);

    if (posts !== undefined && posts.length !== 0) {
      var formatted = posts.data.map((item) => {
        var imagelink = constants.profileURL; //constants.baseURL + "profilefiles";
        return [
          item.podcasts.podcasts_name,
          <div
            style={{ display: "flex" }}
            key={item.reportpodcasts_id.toString()}
          >
            <img
              src={item.podcasts.artist.urlImage !== undefined 
                && item.podcasts.artist.urlImage !== null 
                && item.podcasts.artist.urlImage !== "" 
                && item.podcasts.artist.urlImage !== "undefined" 
                ? imagelink + item.podcasts.artist.urlImage 
                : constants.userImageURL}
              alt={item.podcasts.artist.firstName +
              " " +
              item.podcasts.artist.lastName}
              className="table-image"
            />
            <div>
              {" " +
                item.podcasts.artist.firstName +
                " " +
                item.podcasts.artist.lastName}
            </div>
          </div>,
          item.reportpodcasts_comment,
          item.reportedUser.firstName + " " + item.reportedUser.lastName,
          item.podcasts.podcasts_status === 1 ? (
            <span style={{ color: "rgb(76, 196, 124)" }}>Active</span>
          ) : (
            <span style={{ color: "rgb(214, 88, 122)" }}>Inactive</span>
          ),
          <div>
            <span>
              <StreetviewIcon
                fontSize="small"
                titleAccess="view"
                style={{
                  cursor: "pointer",
                  color: "#376BC5",
                  marginRight: "10px",
                }}
                onClick={() => {
                  getPodcastInfo(item.podcasts.podcasts_id);
                }}
              />
            </span>
          </div>,
        ];
      });
      return formatted;
    } else return [];
  };
  return (
    <>
      <div className="main-window">
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <NavSearchBar title="Reported Podcast" />
          </Grid>
          <Grid item md={12} xs={12}>
            <SearchBar
              title={
                posts.totalCount === undefined
                  ? "0 Podcast(s)"
                  : posts.totalCount + " Podcast(s)"
              }
            sendvalue={sendvalue}

            ></SearchBar>
          </Grid>
          <Grid item md={12} xs={12}>

            {stopLoader === true ? <CustomTableNew
              tableHead={[
                "Podcast Name",
                "Artist",
                "Comments",
                "Reported By",
                // "Type",
                "Status",
                "Action",
              ]}
              tableData={data(posts)}
            /> : <p style={{ paddingTop: "150px", textAlign: "center" }}>
              No data found
            </p>}
          </Grid>
          <Grid item md={12} xs={12}>
            <div style={{ textAlign: "-webkit-center" }}>
              {showPageNumberButton && (
                <div>
                  <Grid item md={12} style={{ width: "20%" }}>
                    <CustomButtonOutlined
                      text={"view More "}
                      onClick={() => nextPage()}
                    />
                  </Grid>
                </div>
              )}
            </div>
          </Grid>
          {/* <Grid item md={12} xs={12}>
            <div style={{ textAlign: "-webkit-center" }}>
              {posts !== undefined && posts.length !== 0 ? (
                <div>
                 
                  {posts.data.length === 10 ? (
                    <div>
                      
                      <Grid item md={12} style={{ width: "20%" }}>
                        <CustomButtonOutlined
                          text={"view More "}
                          onClick={() => nextPage()}
                        />
                      </Grid>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </Grid> */}
        </Grid>
        <Popup
          openPopup={openPopup}
          buttonText={podcastBlock === 1 ? "Block" : "UnBlock"}
          title="Podcast Info"
          setOpenPopup={setOpenPopup}
          buttonClick={() => handleBlock()}
        >
          {/* <ReportedArtistDialog data={responseById} /> */}
          <ReportedPodcastDialogContent data={responseById} />
        </Popup>
      </div>
    </>
  );
}
