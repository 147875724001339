// import React, { Component } from "react";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import Navbar1 from "./components/Navbar/Navbar1";
// import Home from "./pages/Home/Home";
// import Login from "./pages/Login/Login";

// export default class RouteControl extends Component {
//   render() {
//     return (
//       <div>
//         <Router>
//           {/* <Navbar1></Navbar1> */}
//           <Switch>
//             <Route path="/login" component={Login}></Route>
//           </Switch>
//         </Router>
//       </div>
//     );
//   }
// }

import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Navbar1 from "./components/Navbar/Navbar1";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import { getdata } from "./utility/apiCalling";

export default function RouteControl() {
  // useEffect(() => {
  //   getVerifyTOken()
  // }, []);
  // async function getVerifyToken(){
  //   var verifyToken=await getdata(constants.)
  // }

  return (
    <div>
      <Router>
        <ScrollToTop />
        {/* <Route exact path="/login" component={Login} /> */}
        {localStorage.getItem("UserToken") !== undefined &&
          localStorage.getItem("UserToken") !== null &&
          localStorage.getItem("UserToken") !== "" && (
            <>
              <Navbar1></Navbar1>
              {/* <Redirect to="/dashboard" component={Home} /> */}
              <Switch>
                {/*  <Route path="/dashboard" component={Home}></Route> */}
              </Switch>
            </>
          )}
        {(localStorage.getItem("UserToken") === undefined ||
          localStorage.getItem("UserToken") === null ||
          localStorage.getItem("UserToken") === "") && (
            <>
          {/* <Redirect to="/login" component={Login} />
          <Login></Login> */}
          <Route exact path={["/","/admin/","/admin/login"]} component={Login} />
          {/* <Route exact path="/login" component={Login} /> */}
          </> 
        )}
      </Router>
    </div>
  );
}
