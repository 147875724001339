import React from "react";
import { CardDuration, CardPublishDate } from "../CardNew/CardLocation";
import { Grid } from "@material-ui/core";
import PodcastSong from "../Song/PodcastSong";

export default function ListPodcast(props) {
  const {
    podcastName,
    podcastImage,
    podcastUserName,
    podcastDate,
    podcastDescription,
  } = props;
  console.log(podcastImage);
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <div
            style={{
              display: "flex",
              borderRadius: "4px",
              padding: "8px 8px",
            }}
            className="backLightGray"
          >
            <div>
              <img
                src={podcastImage}
                alt="Imgloaderror"
                className="song-image image-40"
              />
            </div>
            <div className="dis-flex-column  lineheight-15">
              <span className="opacity-90">{podcastName}</span>
              <span className="font-12 opacity-60">{podcastUserName}</span>
            </div>
          </div>
        </Grid>
        <Grid item md={12} xs={12}>
          <CardPublishDate publishDate={podcastDate} />
        </Grid>
        {/* <Grid item md={6}>
          <CardDuration />
        </Grid> */}
        <Grid item md={12} xs={12}>
          <div className="dis-flex-column  lineheight-15">
            <span className="font-14 opacity-90 lineheight-28">
              {podcastName}
              {/* Podcast Name */}
            </span>
            <span
              className="font-12 opacity-60 "
              style={{ textAlign: "justify" }}
            >
              {podcastDescription}
              {/* Lorem Khaled Ipsum is a major key to success. They don’t want us
              to win. Always remember in the jungle there’s a lot of they in
              there, after you overcome they, you will make it to paradise.
              Major key, don’t fall for the trap, stay focused. It’s the ones
              closest to you that want to see you fail. Major key, don’t fall
              for the trap, stay focused. It’s the ones closest to you that want
              to see you fail. */}
            </span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
