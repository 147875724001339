import React from "react";
import CustomSkeleton from "./CustomSkeleton";
import { Grid } from "@material-ui/core";

export default function LoaderforTable() {
  return (
    <div style={{ overflow: "hidden" }}>
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <CustomSkeleton height="30px" />
        </Grid>
        <Grid item md={12} xs={12}>
          <CustomSkeleton height="30px" />
        </Grid>
        <Grid item md={12} xs={12}>
          <CustomSkeleton height="30px" />
        </Grid>
        <Grid item md={12} xs={12}>
          <CustomSkeleton height="30px" />
        </Grid>
        <Grid item md={12} xs={12}>
          <CustomSkeleton height="30px" />
        </Grid>
        <Grid item md={12} xs={12}>
          <CustomSkeleton height="30px" />
        </Grid>
        <Grid item md={12} xs={12}>
          <CustomSkeleton height="30px" />
        </Grid>
        <Grid item md={12} xs={12}>
          <CustomSkeleton height="30px" />
        </Grid>
        <Grid item md={12} xs={12}>
          <CustomSkeleton height="30px" />
        </Grid>
        <Grid item md={12} xs={12}>
          <CustomSkeleton height="30px" />
        </Grid>
      </Grid>
    </div>
  );
}
