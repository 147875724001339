import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  ListItemAvatar,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomTableNew from "../../components/CustomTable/CustomTableNew";
import {
  OrangeCard,
  BlueCard,
  LikeCard,
} from "../../components/CardNew/CommonCards";
import Avatar from "../../assets/CardIcon/Avatar.png";
import { CustomButtonText } from "../../components/Controls/CustomButton";
import Skeleton from "@material-ui/lab/Skeleton";
import moment from "moment";
import "./Home.scss";
import NavSearchBar from "../../components/SearchBar/NavSearchBar";
import { constants } from "../../utility/constants";
import { getdata } from "../../utility/apiCalling";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomSkeleton from "../../components/SkeletonLoader/CustomSkeleton";

export default function Home() {
  const [FeaturedArtist, setfeaturedArtist] = useState([]);
  const [featuredLoader, setfeaturedLoader] = useState(true)
  const [bigStage, setBigStage] = useState([]);
  const [newEvent, setNewEvent] = useState([]);
  const [latestNews, setLatestNews] = useState([]);
  const [dashCount, setDashCount] = useState([]);
  var Userimagelink = constants.profileURL; //constants.baseURL + "profilefiles";
  var bigstageImageLink = constants.bigStageThumbnail; //constants.baseURL + "bigstagethumbnailfiles/";
  var EventImageLink = constants.eventsFileURL; //constants.baseURL + "eventsfiles/";
  var NewsImagelink = constants.newsFileURL; //constants.baseURL + "newsfiles/";
  let history = useHistory();
  useEffect(() => {
    // alert("hi")
    // document.title = "Users";
    getFeaturedArtist();
    getBigStage();
    getEvents();
    getNews();
    getDashboardCount();
  }, []);
  async function getFeaturedArtist() {
    let response = await getdata(
      constants.user + "?filter=makeFeatured||$eq||1&usertype||$eq||Artist&filter=isDeleted||$eq||0&sort=id,DESC"
      // user
    );
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      // console.log(response.data)
      if (response.data.length !== 0) return setfeaturedArtist(response.data);
      else
        setfeaturedLoader(true)

    }
  }
  async function getBigStage() {
    let response = await getdata(
      constants.admingetBigStage + "/getAll?page=1&limit=1"
    );
    if (

      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      // console.log("hello");
      // console.log(response.data)
      return setBigStage(response.data.data);
    }
  }
  async function getEvents() {
    // console.log("------------------Event page")
    // console.log(constants.geteventsURL + "/getAll?page=1&limit=5")
    let response = await getdata(
      constants.admingeteventsURL + "/getAll?page=1&limit=5"
    );
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      // console.log("hello");
      // console.log(response.data)
      return setNewEvent(response.data.data);
    }
  }

  async function getNews() {
    // console.log("------------------Home News page")
    // console.log(constants.getnewsURL + "/getAll?page=1&limit=2")
    let response = await getdata(
      constants.admingetnewsURL + "/getAll?page=1&limit=2"
    );
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      // console.log("hello");
      // console.log(response.data)
      return setLatestNews(response.data.data);
    }
  }
  async function getDashboardCount() {
    let response = await getdata(constants.dashboardCount);
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      // console.log(response.data)

      return setDashCount(response.data);
    }
  }
  const data = () => {
    // console.log("----------->users posts")
    // console.log(newEvent)
    var formatted = newEvent.map((item) => {
      // console.log(item)
      // console.log(item.id)
      // var imagelink = constants.baseURL + "profilefiles"
      return [
        // item.news.news_title,

        <img
          src={EventImageLink + item.events_urlImage}
          className="table-image image-30"
        />,
        item.events_name,
        item.user !== undefined ? item.user.firstName + " " + item.user.lastName : "",
        item.events_address,
        moment(item.events_eventdate).format("DD MMM yyyy"),
        moment(item.events_eventdate).format("h:mm:ss A"),
      ];
    });
    return formatted;
  };

  return (
    <div className="main-window">
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <NavSearchBar title="Dashboard" />
        </Grid>

        <Grid item md={5} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={6}>

              <OrangeCard totalBlocked={dashCount.totalUsers} title="Users" />
            </Grid>
            <Grid item md={6} xs={6}>

              <BlueCard totalBlue={dashCount.totalArtists} title="Artist" />
            </Grid>
            <Grid item md={12} xs={12}>

              <Card className="background-black-light-imp">
                <CardContent>
                  <div className="dis-flex-between">
                    <span className="header-poppins-14">Featured Artist</span>
                    <span>
                      {FeaturedArtist.length === 0?null:<CustomButtonText
                        text="view All"
                        onClick={() => {
                          history.push("artist");
                        }}
                      />}
                    </span>
                  </div>
                  <div className="wrapper mobileWidth" style={FeaturedArtist.length === 0?{justifyContent:"center"}:null}>
                    <div className="item">

                      {FeaturedArtist.length === 0 ? (
                        // <Skeleton variant="rect" width={340} height={118} />
                        <div>

                          {featuredLoader === false ? <CustomSkeleton height="118" /> : <div className="NoDataFound NoDataFound_center">No data found</div>}
                        </div>

                      ) : (
                        <div style={{ display: "flex" }}>
                          {FeaturedArtist.map((artist) => {

                            return [
                              <div
                                key={artist.id}
                                style={{ marginRight: "10px" }}
                              >

                                <img
                                  src={Userimagelink + artist.urlImage}
                                  alt="image-not-found"
                                  style={{
                                    width: "95px",
                                    height: "90px",
                                    borderRadius: "10px",
                                  }}
                                />

                                <div className="item-heading ">
                                  <div className="header-poppins-12 font-10">
                                    {artist.firstName + " " + artist.lastName}
                                  </div>
                                  <div className="header-type-3 font-8">
                                  {artist?.country?.name}
                                  </div>
                                </div>
                              </div>,
                            ];
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={3} xs={12}>
          <Card className="addBorder" style={{ background: "transparent" }}>
            <CardContent>
              <div className="dis-flex-between">
                <span className="header-poppins-14">The Big Stage</span>

                <span>
                 { bigStage.length === 0 ?null:<CustomButtonText
                    text="view All"
                    onClick={() => {
                      history.push("bigstage");
                    }}
                  />}
                </span>
              </div>

              {bigStage.length === 0 ? (
                featuredLoader === false ?<Skeleton variant="rect" width={210} height={118} />: <div className="NoDataFound NoDataFound_center">No data found</div>
              ) : (
                <div style={{ display: "flex" }}>
                  {bigStage.map((data) => {
                    return [
                      <div key={data.bigstage_id}>
                        <img
                          src={bigstageImageLink + data.bigstage_thumbnailURL}
                          alt="image-not-found"
                          style={{ height: "180px", width: "100%" }}
                        />

                        <div className="item-heading ">
                          <div className="header-poppins-12 font-10">
                            {data.bigstage_name}
                          </div>
                          <div className="header-type-3 font-8">USA</div>
                        </div>
                      </div>,
                    ];
                  })}
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={6}>
              <OrangeCard totalBlocked={dashCount.reportedArtists} title="Reported Artist" />
            </Grid>
            <Grid item md={6} xs={6}>
              <BlueCard totalBlue={dashCount.reportedSongs} title="Reported Songs" />
            </Grid>
            <Grid item md={6} xs={6}>
              <OrangeCard totalBlocked={dashCount.reportedNews} title="Reported News" />
            </Grid>
            <Grid item md={6} xs={6}>
              <BlueCard totalBlue={dashCount.reportedPodcasts} title="Reported Podcast" />
            </Grid>

          </Grid>
        </Grid>
        <Grid item md={7} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Card style={{ background: "transparent" }}>
                <CardContent>
                  <div className="dis-flex-between">
                    <span className="header-poppins-14">New Event(s)</span>
                    <span>
                     {newEvent.length==0?null: <CustomButtonText text="view All" onClick={() => { history.push("events") }} />}
                      </span>
                  </div>
                  {newEvent.length==0 ? <div className="NoDataFound NoDataFound_center">No data found</div> :<CustomTableNew
                    tableHead={["Image", "Event Name", "Artist Name", "Location", "Date", "Time"]}
                    tableData={data(newEvent)}
                  />}


                </CardContent>
              </Card>
            </Grid>
          </Grid>

        </Grid>
        <Grid item md={5} xs={12}>
          <Card className="background-black-light-imp" >
            <CardContent>
              <div className="dis-flex-between">
                <span className="header-poppins-14">Latest News</span>
                <span>
                {latestNews.length === 0 ?null:<CustomButtonText text="view All" onClick={() => { history.push("news") }} />}
                  </span>
              </div>

              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  {console.log(latestNews)}
                  <div >
                    {latestNews.length === 0 ? <div className="NoDataFound NoDataFound_center" >No data found</div> : <div style={{ display: "flex" }}>
                      {latestNews.map((item) => {
                        return [
                          <div style={{ width: "50%", marginLeft: "10px" }}>
                            <img src={NewsImagelink + item.news_urlImage} alt="image-not-found" style={{ width: "100%", height: "160px", borderRadius: "10px" }} />

                            <div className="item-heading ">
                              <div className="header-poppins-12 font-12 text-wrap" >{item.news_title}</div>
                              <div className="header-type-3 font-8 text-wrap" style={{ maxWidth: "40ch" }}>{item.news_description}</div>
                            </div>
                          </div>
                        ]


                      })}

                    </div>}
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
