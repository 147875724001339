import React, { useEffect, useState } from "react";
import CustomTableNew from "../../components/CustomTable/CustomTableNew";
import SearchBar from "../../components/SearchBar/SearchBar";
import { Grid } from "@material-ui/core";
// import IconArrowBottom from "../../assets/images/IconArrowBottom.svg";
// import { Menu, MenuItem } from "@material-ui/core";
import { CustomButtonOutlined } from "../../components/Controls/CustomButton";
import {
  openConfirmationDeleteDialog,
  openReportedSongDialog,
} from "../../utility/dialogAppear";
import NavSearchBar from "../../components/SearchBar/NavSearchBar";
// import { addLoader, removeLoader } from "../../utility/LoadingContent";
import { constants } from "../../utility/constants";
import { getdata, patchDataApi } from "../../utility/apiCalling";
import StreetviewIcon from "@material-ui/icons/Streetview";
import Popup from "../../components/PopUp/Popup";
import ReportedSongsDialogContent from "./ReportedSongsDialogContent";
import { addSuccessMessage } from "../../utility/LoadingContent";
import reactDom from "react-dom";

export default function ReportedSongs() {
  const [posts, setPosts] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [stopLoader, setStopLoader] = useState(true);
  const [responseById, setresponseById] = useState([]);
  const [showPageNumberButton, setshowPageNumberButton] = useState(false);
  var [musicBlock, setMusicBlock] = useState(0);
  var [page, setPage] = useState(1);
  const [serc, setserc] = useState(null);

  useEffect(() => {
    // document.title = "Artist";
    getReportedSongs(page);
  }, []);

  // ------------------------------------------------------------Music All Details
  async function getReportedSongs(page) {
    let response = await getdata(
      constants.adminreportedSongs + "/getAll?page=" + page + "&limit=10"
    );
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      if (response.data.data.length !== 0) {
        if (response.data.data.length === 10) {
          setshowPageNumberButton(true);
        }
        return setPosts(response.data);
      } else {
        setPosts(response.data);
        return setStopLoader(false);
      }
      // if (response.data.data.length !== 0) {
      //   return setPosts(response.data);
      // } else {
      //   setPosts(response.data);
      //   return setStopLoader(false);
      // }
      // return setPosts(response.data);
    }
  }
  // -----------------------------------------------------------onClick get Songs Info
  async function getSongsInfo(id) {
    // addLoader();
    console.log(constants.getMusic + "/getById/" + id);
    let response = await getdata(constants.getMusic + "/getById/" + id);
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      setresponseById(response.data);
      setMusicBlock(response.data.music_status);
      setOpenPopup(true);
      // removeLoader();
      // return openReportedSongDialog(response.data);
    }
  }

  // ------------------------------------------handle Music Block

  const handleBlock = () => {
    // window.alert("hande block");
    // window.alert(bigBlock);
    {
      musicBlock === 1
        ? openConfirmationDeleteDialog(Block, "Block")
        : openConfirmationDeleteDialog(unBlock, "Unblock");
    }
  };

  const unBlock = async () => {
    // window.alert(bigBlock);
    let jsonData = {
      status: 1,
    };
    // console.log(id);
    console.log("handle block----------------------->");
    console.log(constants.getMusic + "/" + responseById.music_id);
    let blockMusicData = await patchDataApi(
      constants.getMusic + "/" + responseById.music_id,
      jsonData
    );
    if (blockMusicData.status === 200 || blockMusicData.statusText === "OK") {
      setOpenPopup(false);
      addSuccessMessage("Music Unblock");
      getReportedSongs(1);

      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
      // return ReportedPodcast()
    } else {
      // console.log("User Not  successfully Deleted");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };
  const Block = async () => {
    // window.alert(bigBlock);
    let jsonData = {
      status: 0,
    };
    // console.log(id);
    console.log("handle block----------------------->");
    console.log(constants.getMusic + "/" + responseById.music_id);
    let blockMusicData = await patchDataApi(
      constants.getMusic + "/" + responseById.music_id,
      jsonData
    );
    if (blockMusicData.status === 200 || blockMusicData.statusText === "OK") {
      setOpenPopup(false);
      addSuccessMessage("Music block");
      getReportedSongs(1);
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      // console.log("User Not  successfully Deleted");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };

  // --------------------------------------------------------------------------onNext page podcast Info
  const nextPage = () => {
    setPage(page + 1);
    getNextPage(page + 1);
  };
  const sendvalue = (i) =>{
    console.log(i)
    setserc(i)
    setPage(1)
    getNextPage(1,i);

  }

  console.log("--------------------page" + page);
  async function getNextPage(p,ser) {
    let apiURL = constants.adminreportedSongs + "/getAll?page=" + p + "&limit=10"
    if (ser) {
      apiURL += "&searchText=" + ser
    }
    let response = await getdata(
      apiURL
    );
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      console.log("--------------------> next page");
      console.log(response);
      if (response.data.data.length !== 0) {
        if ((response.data.limit*response.data.page)<response.data.totalCount) {
          setshowPageNumberButton(true);
        }else{
          setshowPageNumberButton(false);
        }
        if(p==1){
          setPosts(response.data);
        }else{
        setPosts((prevState) => {
         
          return {
            ...prevState,
            // totalCount: prevState.totalCount + response.data.totalCount,
            data: [...prevState.data, ...response.data.data],
          };
        });
      }
      } else {
        return setshowPageNumberButton(false);
      }
      // return setBigStageData(response.data);
    }
  }

  const data = () => {
    console.log("----------->users posts");
    console.log(posts);
    if (posts !== undefined && posts.length !== 0) {
      var formatted = posts.data.map((item) => {
        console.log(item);
        // console.log(item.id)
        var imagelink = constants.profileURL; //constants.baseURL + "profilefiles";
        return [
          item.music.music_title,
          <div style={{ display: "flex" }}>
            <img
              src={item.music.album.artist.urlImage !== undefined 
                && item.music.album.artist.urlImage !== null 
                && item.music.album.artist.urlImage !== "" 
                && item.music.album.artist.urlImage !== "undefined" ? imagelink + item.music.album.artist.urlImage : constants.userImageURL}
              alt={item.music.album.artist.firstName +
              " " +
              item.music.album.artist.lastName}
              className="table-image"
            />
            <div>
              {" " +
                item.music.album.artist.firstName +
                " " +
                item.music.album.artist.lastName}
            </div>
          </div>,
          item.reportsongs_comment,
          item.reportedUser.firstName + " " + item.reportedUser.lastName,
          item.music.music_status === 1 ? (
            <span style={{ color: "rgb(76, 196, 124)" }}>Active</span>
          ) : (
            <span style={{ color: "rgb(214, 88, 122)" }}>Inactive</span>
          ),
          <div>
            <span>
              <StreetviewIcon
                fontSize="small"
                titleAccess="view"
                style={{
                  cursor: "pointer",
                  color: "#376BC5",
                  marginRight: "10px",
                }}
                onClick={() => {
                  getSongsInfo(item.music.music_id);
                }}
              />
            </span>
          </div>,
        ];
      });
      return formatted;
    } else return [];
  };
  return (
    <>
      <div className="main-window">
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <NavSearchBar title="Reported Songs" />
          </Grid>
          <Grid item md={12} xs={12}>
            <SearchBar
              title={
                posts.totalCount === undefined
                  ? "0 Song(s)"
                  : posts.totalCount + " Song(s)"
              }
            sendvalue={sendvalue}

            ></SearchBar>
          </Grid>
          <Grid item md={12} xs={12}>
            {stopLoader === true ? (
              <CustomTableNew
                tableHead={[
                  "Song Name",
                  "Artist",
                  "Comments",
                  "Reported By",
                  // "Type",
                  "Status",
                  "Action",
                ]}
                tableData={data(posts)}
              />
            ) : (
              <p style={{ paddingTop: "150px", textAlign: "center" }}>
                No data found
              </p>
            )}
          </Grid>
          {/* <Grid item md={12} xs={12}>
            <div style={{ textAlign: "-webkit-center" }}>
              {posts !== undefined && posts.length !== 0 ? (
                <div>
                  {posts.data.length === 10 ? (
                    <div>
                      <Grid item md={12} style={{ width: "20%" }}>
                        <CustomButtonOutlined
                          text={"view More "}
                          onClick={() => nextPage()}
                        />
                      </Grid>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </Grid> */}
          <Grid item md={12} xs={12}>
            <div style={{ textAlign: "-webkit-center" }}>
              {showPageNumberButton && (
                <div>
                  <Grid item md={12} style={{ width: "20%" }}>
                    <CustomButtonOutlined
                      text={"view More "}
                      onClick={() => nextPage()}
                    />
                  </Grid>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        <Popup
          openPopup={openPopup}
          buttonText={musicBlock === 1 ? "Block" : "UnBlock"}
          title="Songs Info"
          setOpenPopup={setOpenPopup}
          buttonClick={() => handleBlock()}
        >
          {/* <ReportedArtistDialog data={responseById} /> */}
          <ReportedSongsDialogContent data={responseById} />
        </Popup>
      </div>
    </>
  );
}
