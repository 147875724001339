import { Typography } from "@material-ui/core";
import { Grid, Container } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { CustomButtonContained } from "../../components/Controls/CustomButton";
import CustomTextField from "../../components/Controls/CustomTextField";
import { Form, useForm } from "../../components/Controls/useForm";
import CheckBox from "@material-ui/core/CheckBox";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import clsx from 'clsx';
import { postDataApi } from "../../utility/apiCalling";
import {
  addErrorMessage,
  addLoader,
  addSuccessMessage,
  addWarningMessage,
  removeLoader,
} from "../../utility/LoadingContent";
import { constants } from "../../utility/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import logo from "../../assets/logo.png";
import VisibilityIcon from '@material-ui/icons/Visibility';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },

  },
  icon: {
    borderRadius: 3,
    width: 14,
    height: 14,
    backgroundColor: '#ebf1f5',
  },
  iconButton: {
    color: "#FFD874",
  },
  checkedIcon: {
    backgroundColor: '#FFD874',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      color: "white",
      width: 14,
      height: 14,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: 'FFD874',

    },
    'input': {
      backgroundColor: 'FFD874',
      color: "red",

    },
  },
});;

const initialFValues = {
  id: 0,
  userName: "",
  password: "",
};

export default function Login() {
  const [selectedValue, setSelectedValue] = useState(null);
  const [showPassword, setshowPassword] = useState(false);

  const classes = useStyles();
  var history = useHistory();
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("userName" in fieldValues) {
      temp.userName = fieldValues.userName ? "" : "This field is required.";
    }
    if ("password" in fieldValues)
      temp.password = fieldValues.password ? "" : "This field is required.";

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const handleSubmit = () => {
    if (validate()) {
      signIn();
      addLoader();
    }
  };
  const signIn = async () => {
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let jsonData = {
      email: values.userName,
      password: values.password,
    };
    console.log(jsonData);
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    let responseData = await postDataApi(
      constants.baseURL + "auth/signin",
      jsonData,
      authHeader
    );
    console.log("responseData: ", responseData);
    if (
      responseData != undefined &&
      responseData.data != undefined &&
      responseData.data.accessToken
    ) {
      localStorage.setItem("UserInfo", JSON.stringify(responseData.data.user));
      localStorage.setItem("UserToken", responseData.data.accessToken);
      removeLoader();

      addSuccessMessage("Welcome To MMM ");
      history.push("/dashboard");
      window.location.reload();
      // dispatch(showLoggedInView(true));
      //setProgressLoader(true);
    } else {
      removeLoader();
      addErrorMessage("Invalid Credentials!");
      // enqueueSnackbar('Invalid Credentials!', { variant: 'error', anchorOrigin: {
      //     vertical: 'top',
      //     horizontal: 'right',
      // }, });
    }
  };
  const handleClickShowPassword = () => {
    setValues(!showPassword);
  };
  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );
  return (
    <div
      className="main-window1 background-black-light-imp"
      style={{ paddingTop: "8%" }}
    >
      <Container maxWidth="xs">
        <div
          className="addBorder background-black-imp"
          style={{ padding: "40px 20px" }}
        >
          <div style={{ textAlign: "center" }}>
            <img
              src={logo}
              alt="not found"
              style={{ height: "70px", width: "70px" }}
            />
          </div>

          <Form>
            <Grid conatiner spacing={2}>
              <Grid item md={12}>
                <Typography
                  gutterBottom
                  align="center"
                  style={{ fontSize: "24px", fontWeight: "bold" }}
                  className="header-poppins-14"
                >
                  Login
                </Typography>
              </Grid>
              <Grid item md={12}>
                <Typography
                  paragraph
                  gutterBottom
                  align="center"
                  style={{ fontSize: "14px" }}
                  className="header-type-3 "
                >
                  Enter your username and password to play your favourite music
                </Typography>
              </Grid>
              <Grid item md={12} >
                <CustomTextField
                  name="userName"
                  label="User Name"
                  value={values.userName}
                  onChange={handleInputChange}
                  error={errors.userName}
                  fullWidth
                ></CustomTextField>
              </Grid>
              <Grid item md={12}>
                <CustomTextField
                  name="password"
                  label="Password"
                  type={showPassword === true ? "text" : "password"}
                  value={values.password}
                  onChange={handleInputChange}
                  error={errors.password}
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          console.log("hi---------------------------------->")
                          setshowPassword(!showPassword)
                        }}
                      // onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityIcon style={{ color: "#FFD874" }} /> : <VisibilityOff style={{ color: "#FFD874" }} />}
                      </IconButton>
                    </InputAdornment>,
                  }}
                // endAdornment={

                // }
                ></CustomTextField>
                <div>

                  {/* <VisibilityIcon /> */}
                </div>
              </Grid>
              <Grid item md={12}>
                <div className="dis-flex-between">
                  <div>
                    <CheckBox
                      className={classes.root}
                      // checked={selectedValue === "remember me"}
                      // onChange={handleChange}
                      icon={<span className={classes.icon} />}
                      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon, classes.iconButton)} style={{ color: "red" }} />}
                      value="remember me"
                      name="Remenber me"
                    />{" "}
                    <span className="header-type-3">Remember me</span>
                  </div>
                  {/* <div className="header-type-3">Forgot Password?</div> */}
                </div>
              </Grid>
              <Grid item md={12}>
                <CustomButtonContained text="Login" onClick={handleSubmit} />
              </Grid>
            </Grid>
          </Form>
        </div>
      </Container>
    </div>
  );
}
