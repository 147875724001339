import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#181a20",
    // opacity: "0.2",
  },
}));

export default function CustomSkeleton(props) {
  const { variant, height, width } = props;
  const classes = useStyles();
  return (
    <Skeleton
      className={classes.root}
      animation="wave"
      variant={variant || "rect"}
      height={height}
      width={width || "100%"}
    />
  );
}
