import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Avatar from "../../assets/CardIcon/Avatar.png";
// import "./ViewComponent.scss";
import styles from "./CommentComponentStyle";
import reactDom from "react-dom";
import moment from "moment";
import { constants } from "../../utility/constants";
const useStyles = makeStyles(styles);

export default function CommentComponent(props) {
  const classes = useStyles();
  const { data, renderElement,variant } = props;
  var Userimagelink = constants.profileURL; //constants.baseURL + "profilefiles";
  const [open, setOpen] = React.useState(true);
  console.log("------------commnet section");
  console.log(data);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const handleClose = () => {
    reactDom.unmountComponentAtNode(renderElement);
    setOpen(false);
  };
  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <Dialog
        // maxWidth="md"
        // minWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className={classes.dialog}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          className={classes.dialogtitle}
        >
          {open ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
          Comments
        </DialogTitle>
        {/* <div onClose={handleClose}> view</div> */}
        <DialogContent className={classes.DialogContent}>
          {data === undefined || data.length === 0 ? (
            <div style={{ padding: "50px 200px" }}>No Data Found</div>
          ) : (
            <div>
              {data.map((commentItem, key) => {
                return (
                  <div className="dis-flex" key={key}>
                     {variant=="episode"?

  <div className={classes.DataSet}>
    <div className="dis-flex">
      <img
        src={Userimagelink + commentItem?.user?.urlImage}
        alt="image_not_Found"
        className={classes.ViewImage}
      />
      <div className="mar-left-10">
        <div className={classes.CommentData}>
          <p
            style={{ fontSize: "15px" }}
            className="opacity-90"
          >
            {commentItem?.user?.userName}
          </p>
          <p className="font-10 opacity-60 colorwhite">
            {/* 12-feb-2019 */}
            {moment(commentItem.created).format(
              "DD MMM yyyy"
            )}
          </p>
        </div>
        <p
          style={{
            fontSize: "10px",
            opacity: "0.6",
            paddingTop: "3px",
            paddingBottom: "9px",
          }}
        >
          +91-{commentItem.user.phone}
        </p>
        <p className="font-10 opacity-60 colorwhite">
          {commentItem.comment}
          {/* {item.newscomments_comment}
          {item.podcastcomments_comment} */}
          {/* Lorem Ipsum is simply
      dummy text of the printing and typesetting industry.
      Lorem Ipsum has been the industry's standard dummy
      text ever since the 1500s, when an unknown printer
      took a galley of type and scrambled it to make a
      type specimen book. It has survived not only five
      centuries, but also the leap into electronic
      typesetting, remaining essentially unchanged. */}
        </p>
      </div>
    </div>
  </div>

:
  commentItem.map((item, keyValue) => {
    return (
      <div className={classes.DataSet}>
        <div className="dis-flex">
          <img
            src={Userimagelink + item.userImage}
            alt="image_not_Found"
            className={classes.ViewImage}
          />
          <div className="mar-left-10">
            <div className={classes.CommentData}>
              <p
                style={{ fontSize: "15px" }}
                className="opacity-90"
              >
                {item.userName}
              </p>
              <p className="font-10 opacity-60 colorwhite">
                {/* 12-feb-2019 */}
                {moment(item.commentDate).format(
                  "DD MMM yyyy"
                )}
              </p>
            </div>
            <p
              style={{
                fontSize: "10px",
                opacity: "0.6",
                paddingTop: "3px",
                paddingBottom: "9px",
              }}
            >
              +91-{item.userPhone}
            </p>
            <p className="font-10 opacity-60 colorwhite">
              {item.commentMessage}
              {/* {item.newscomments_comment}
              {item.podcastcomments_comment} */}
              {/* Lorem Ipsum is simply
          dummy text of the printing and typesetting industry.
          Lorem Ipsum has been the industry's standard dummy
          text ever since the 1500s, when an unknown printer
          took a galley of type and scrambled it to make a
          type specimen book. It has survived not only five
          centuries, but also the leap into electronic
          typesetting, remaining essentially unchanged. */}
            </p>
          </div>
        </div>
      </div>
    );
  })
}
                    
                  </div>
                );
              })}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
