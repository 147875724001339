const CommentComponentStyle = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    // top: theme.spacing(1),
    // color: theme.palette.grey[500],
    backgroundColor: "rgba(255, 216, 116, 1)",
    padding: "0px",
    color: "black",
  },
  dialog: {
    borderRadius: "32px",
    borderBottomColor: "red",
  },
  dialogtitle: {
    backgroundColor: "rgba(18, 18, 22, 0.8)",
    // padding: "(20px 0px 0px 12px)",
    color: "rgba(255, 255, 255, 0.9)",
    paddingBottom: "0px",
  },
  DialogContent: {
    backgroundColor: "rgba(18, 18, 22, 0.8)",
    padding: "0px",
    // opacity: "0.5",
  },
  DataSet: {
    border: "1px solid #34313c",
    padding: "8px",
    borderRadius: "16px",
    margin: "4px 12px",
    backgroundColor: "#121216",
    paddingRight: "1rem",
  },
  ViewImage: {
    height: "40px",
    width: "40px",
    margin: "0px",
    borderRadius: "8px",
  },
  CommentData: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "0px",
    color: "white",
    width: "500px",
  },
});
export default CommentComponentStyle;
