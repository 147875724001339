import React, { useEffect, useState } from 'react'
import { Col, Row, Container, Tab, Tabs, Modal, Button, Form } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import upload from "../../assets/images/uploadImage1.png";
import ImageUploader from "react-images-upload";
import CustomTextField from "../../components/Controls/CustomTextField";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { CustomButtonContained } from "../../components/Controls/CustomButton";
import uploadMusic2 from "../../assets/images/uploadMusic2.png";
import musiccover from "../../assets/images/ArtistImage1.png";
import closebtn from "../../assets/images/close.png";
import { FileDrop } from "react-file-drop";
import { addErrorMessage, addLoader, addSuccessMessage, removeLoader } from '../../utility/LoadingContent';
import { constants } from '../../utility/constants';
import uploadImage1 from "../../assets/images/uploadImage1.png";
import { Multiselect } from "multiselect-react-dropdown";
import { AiOutlineHeart, AiFillHeart, AiFillCloseSquare } from "react-icons/ai";
import { useHistory } from 'react-router-dom';
import { deleteDataApi, getdata, patchDataApi, postDataApi } from '../../utility/apiCalling';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
const UploadNews = (props) => {
  const classes = useStyles();
  let history = useHistory();
  const { id, item, ArtistId } = props.location.state;
  const [coverImg, setCoverImg] = useState(null);
  const [newsFiles, setNewsFiles] = useState([]);
  const [isNewsImgeErrShow, setisNewsImgeErrShow] = useState(false);
  const [isNewsTitleErrShow, setNewsTitleErr] = useState(false);
  const [isNewsSubTitleErrShow, setNewsSubTitleErr] = useState(false);
  const [isNewsDescErrShow, setNewsDescErr] = useState(false);
  const [isAddCategoryErrShow, setAddCategoryErr] = useState(false);
  const [newsCategories, setNewsCategories] = useState([]);
  const [isUploadNews, setUploadNews] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [newsDetails, setNewsDetails] = useState({
    newsTitle: item !== undefined ? item.title : "",
    newsSubtitle: item !== undefined ? item.subtitle : "",
    newsDescription: item !== undefined ? item.description : "",
    newsurlImage: item !== undefined ? item.urlImage : "",
    categoryList: [],
    selectedCategoryList: [],
    existingCategoryList: [],
    id: id !== undefined && id !== 0 ? id : 0,
  });
  const [dragNDropText, setDragNDropText] = useState(
    "Drop your file here or Browse"
  );
  const multiSelectStyle = {
    multiselectContainer: {
      color: "white",
      fontSize: 14,
    },
    option: {
      // To change css for dropdown options
      // color: colors.colors13,
    },
    chips: {
      // background: colors.colors13,
      fontSize: 15,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 8,
      paddingBottom: 8,
      borderRadius: 8,
    },
    searchBox: {
      border: "1px solid #8186AF",
      borderRadius: "2px",
      //fontFamily: 'Mr Eaves Mod OT',
      fontSize: 15,
      minHeight: 40,

      //width: 395,
    },
  };
  const onDrop = (file) => fileValidation(file[0]);
  const onUploadImg = (file) => fileValidation(file[0]);
  const fileValidation = (file) => {
    if (file !== undefined) {
      // Allowing file type
      let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      if (!allowedExtensions.exec(file.name)) {
        addErrorMessage("Please upload a image file!");
        //enqueueSnackbar("Please upload a image file!", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right', } });
        file = "";
        setDragNDropText("Drop your image here or browse");
        return false;
      } else {
        let imageSrc = URL.createObjectURL(file);
        setDragNDropText("Drop your image here or browse");
        setCoverImg(imageSrc);
        let fileItem = [];
        fileItem.push(file);
        setNewsFiles([...fileItem, ...newsFiles]);
      }
    }
  };
  const onSelectCategory = (selectedList) => {
    console.log("Selected Category: ", selectedList);
    setNewsDetails((prevState) => {
      return { ...prevState, selectedCategoryList: selectedList };
    });
    setAddCategoryErr(false);
  };

  const onRemoveSelectCategory = (selectedList) => {
    setNewsDetails((prevState) => {
      return { ...prevState, selectedCategoryList: selectedList };
    });
  };
  const onClickCancel = () => {
    history.goBack();
  };
  const onUpload = async () => {
    const { newsTitle, newsSubtitle, selectedCategoryList, newsDescription } =
      newsDetails;
    setisNewsImgeErrShow(false);
    if (
      !newsTitle &&
      !newsSubtitle &&
      !newsDescription &&
      selectedCategoryList.length === 0 &&
      (id == undefined || id == 0)
    ) {
      setNewsTitleErr(true);
      setNewsSubTitleErr(true);
      setAddCategoryErr(true);
      setNewsDescErr(true);
      addErrorMessage("Upload a news file!");
      //enqueueSnackbar("Upload a news file!", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right', } });
    } else if (!newsTitle) {
      setNewsTitleErr(true);
    } else if (!newsSubtitle) {
      setNewsSubTitleErr(true);
    } else if (!newsDescription) {
      setNewsDescErr(true);
    } else if (selectedCategoryList.length === 0) {
      setAddCategoryErr(true);
    } else if (
      id === 0 || id === undefined ? newsFiles.length === 0 : newsDetails.newsurlImage.length === 0
    ) {
      console.log(newsFiles);
      console.log("error in News");
      setisNewsImgeErrShow(true);
    } else {
      console.log("DONE");
      setUploadNews(true);
      setDragNDropText("Drop your file here or Browse");
      if (ArtistId !== undefined) {
        let respName = await getNewsByName();
        console.log("respName: ", respName);
        if ((id === 0 || id === undefined) && respName.data.length > 0) {
          addErrorMessage("News with the same title already exists!");
          return;
        } else if (id !== 0 && id !== undefined && respName.data.length > 0 && id !== respName.data[0].id) {
          addErrorMessage("News with the same title already exists!");
          return;
        }
        if (newsFiles.length > 0) {
          addLoader();
          uploadNewsFiles();
        } else {
          if (id !== 0 && id !== undefined) {
            setUploadProgress(25);
            saveNews(newsDetails.newsurlImage);
          } else {
            resetNews(0);
            history.goBack();
          }
        }
      } else {
        removeLoader();
        resetNews(0);
        history.goBack();
      }
    }
  };
  const getNewsByName = async () => {
    let resNews = await getdata(
      constants.baseURL + "news?filter=title||$eq||" + newsDetails.newsTitle
    );
    return resNews;
  }
  const uploadNewsFiles = async () => {
    if (newsFiles.length > 0) {
      let customHeaders = {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer ",
      };
      let data = new FormData();
      newsFiles.map((m) => {
        data.append(constants.uplFileParameter, m);
      });
      let res = await postDataApi(
        //constants.baseURL + "newsfiles",
        constants.newsFileUplodaURL,
        data,
        customHeaders
      );
      //console.log("Upload news Files: ", res.data);
      if (
        res !== undefined &&
        res.status !== undefined &&
        res.status === 201 &&
        res.data !== undefined &&
        res.data.data !== undefined
      ) {
        console.log("Uploaded news file: ", res.data.data);
        let coverFileName = "";
        coverFileName =
          coverImg !== null
            ? res.data.data.filename
            : id !== 0 && id !== undefined
              ? newsDetails.newsurlImage
              : "";
        setUploadProgress(25);
        saveNews(coverFileName);
      } else {
        removeLoader();
        addErrorMessage("Error in uploading podcast files");
      }
    } else {
      setUploadProgress(25);
      saveNews(newsDetails.newsurlImage);
    }
  };
  const saveNews = async (coverImageName) => {
    if (ArtistId != undefined) {
      addLoader();
      let jsonData = {
        title: newsDetails.newsTitle,
        subtitle: newsDetails.newsSubtitle,
        description: newsDetails.newsDescription,
        artist: ArtistId,
        urlImage: coverImageName,
      };
      console.log(jsonData);
      var formBody = [];
      for (var property in jsonData) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(jsonData[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let respNews;
      let sStatus = "";
      if (id !== 0 && id !== undefined) {
        respNews = await patchDataApi(
          constants.baseURL + "news/" + id,
          formBody,
          authHeader
        );
        if (
          respNews != undefined &&
          respNews.status !== undefined &&
          respNews.status == 200
        ) {
          sStatus = "Success";
        }
      } else {
        respNews = await postDataApi(
          constants.baseURL + "news",
          formBody,
          authHeader
        );
        if (
          respNews !== undefined &&
          respNews.status !== undefined &&
          respNews.status == 201
        ) {
          sStatus = "Success";
        }
      }
      console.log("respNews: ", respNews);
      //if (respNews !== undefined && respNews.status !== undefined && respNews.status == 201) {
      if (sStatus == "Success") {
        setUploadProgress(75);
        return saveNewsCategories(respNews.data.id).then((response) => {
          if (
            response !== undefined &&
            response.length > 0 &&
            response[0] == true
          ) {
            removeLoader();
            setUploadProgress(100);
            addSuccessMessage(
              id !== undefined && id !== 0 ? "News Updated" : "News Created"
            );
            resetNews(0);
            history.goBack();
          } else {
            removeLoader();
            resetNews(0);
            history.goBack();
          }
        });
      } else {
        removeLoader();
        addErrorMessage("Error encountered while creating news!");
        resetNews(0);
        history.goBack();
      }
    }
  };
  const resetNews = (progressValue) => {
    setUploadProgress(progressValue);
    setCoverImg(null);
    setNewsFiles([]);
    setNewsDetails({
      albumName: "",
      newsDescription: "",
      newsTitle: "",
      newsSubtitle: "",
      //categoryList: musicCategories,
      selectedCategoryList: [],
    });
  };
  const saveNewsCategories = async (newsId) => {
    let promises_array = [];
    newsDetails.selectedCategoryList.map((item) => {
      promises_array.push(
        new Promise((resolve, reject) => {
          let jsonData = { news: newsId, subcategory: item.id };
          return createNewsCategories(jsonData)
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              reject(false);
            });
        })
      );
    });
    if (id !== 0 && id !== undefined && newsDetails.existingCategoryList.length > 0) {
      console.log("existingCategoryList", newsDetails.existingCategoryList);
      newsDetails.existingCategoryList.map((item) => {
        promises_array.push(
          new Promise((resolve, reject) => {
            let fExists = newsDetails.selectedCategoryList.filter(
              (x) => x.id == item.subcategory.id
            );
            if (fExists.length == 0) {
              return deleteNewsCategory(item.id)
                .then(() => {
                  resolve(true);
                })
                .catch((error) => {
                  reject(false);
                });
            } else {
              resolve(true);
            }
          })
        );
      });
    }
    return Promise.all(promises_array);
  };
  const createNewsCategories = async (jsonData) => {
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let resExits = await getdata(
      constants.baseURL +
      "selectednewscategories?filter=news.id||$eq||" +
      jsonData.news +
      "&filter=subcategory.id||$eq||" +
      jsonData.subcategory
    );
    console.log("resExits: ", resExits);
    if (resExits.data.length == 0) {
      return await Promise.resolve(
        postDataApi(
          constants.baseURL + "selectednewscategories",
          formBody,
          authHeader
        )
      )
        .then((response) => {
          if (
            response != undefined &&
            response.status != undefined &&
            response.status == 201
          ) {
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          return false;
        });
    } else {
      return false;
    }
  };
  const deleteNewsCategory = async (id) => {
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return await Promise.resolve(
      await deleteDataApi(
        constants.baseURL + "selectedNewscategories/" + id,
        authHeader
      )
    )
      .then((resDel) => {
        console.log("resDel: ", resDel);
        if (resDel != undefined && resDel.status == 200) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        return false;
      });
  };
  const getSubcategories = async () => {
    let resSubcategories = await getdata(
      constants.baseURL + "subcategories?filter=category.id||$eq||4&filter=isDeleted||$eq||0"
    );
    console.log("resSubcategories: ", resSubcategories);
    if (resSubcategories != undefined && resSubcategories.data.length > 0) {
      setNewsCategories([...resSubcategories.data]);
      setNewsDetails((prevState) => {
        return {
          ...prevState,
          categoryList: [...resSubcategories.data],
        };
      });
      if (id !== 0 && id !== undefined) {
        getNewsSubcategories(resSubcategories.data);
      }
    }
  };
  const getNewsSubcategories = async (categories) => {
    console.log(id);
    let resSubcategories = await getdata(
      constants.baseURL + "selectednewscategories?filter=news.id||$eq||" + id
    );
    console.log("getNewsSubcategories: ", resSubcategories);
    if (resSubcategories != undefined && resSubcategories.data.length > 0) {
      let sItems = [];
      resSubcategories.data.map((p) => {
        let fltCategories = categories.filter((c) => c.id == p.subcategory.id);
        sItems.push(fltCategories[0]);
      });
      setNewsDetails((prevState) => {
        return {
          ...prevState,
          selectedCategoryList: [...sItems],
          existingCategoryList: [...resSubcategories.data],
        };
      });
    }
  };
  useEffect(() => {
    getSubcategories();
  }, []);
  return (
    <div className={classes.root}>
      <div className='upload-song add-artist-main h-100'>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className='upload-music-main  '>
              {coverImg === null ? (
                <FileDrop
                  onDragOver={() =>
                    setDragNDropText("Drop your image here!!")
                  }
                  onDragLeave={() =>
                    setDragNDropText("Drop your file here")
                  }
                  onDrop={onDrop}
                >
                  <div className='upload-background'>
                    <img src={
                      newsDetails.newsurlImage != ""
                        ? constants.newsFileURL +
                        newsDetails.newsurlImage
                        : uploadImage1
                    } className="user-profile" alt="hello"></img>
                    <div className="upload-text mt-20">Upload Cover Image</div>
                    <div className="upload-subtitle mt-10">Drop your file here or browse</div>
                    <div className="upload-subtitle mt-10">Or</div>
                    <div style={{ textAlign: 'center' }}>
                      <ImageUploader
                        className="imageUploaderNew"
                        withIcon={false}
                        buttonText="Browse"
                        label=""
                        onChange={(file) => onUploadImg(file)}
                        imgExtension={constants.supportedImageFiles}
                        singleImage={true}
                      />
                    </div>
                  </div>
                </FileDrop>
              ) : (
                <FileDrop
                  onDragOver={() =>
                    setDragNDropText("Drop your image here!!")
                  }
                  onDragLeave={() =>
                    setDragNDropText("Drop your file here")
                  }
                  onDrop={onDrop}
                >
                  <div className='upload-background'>
                    <img onClick={() => setCoverImg(null)}
                      id="uploaded_cover_img"
                      src={coverImg} className="user-profile" alt="hello"></img>
                    {/* <div className="upload-text mt-20">Upload Cover Image</div>
                <div className="upload-subtitle mt-10">Drop your file here or browse</div>
                <div className="upload-subtitle mt-10">Or</div> */}
                    <div style={{ textAlign: 'center' }}>
                      <ImageUploader
                        className="imageUploaderNew"
                        withIcon={false}
                        buttonText="Browse"
                        label=""
                        onChange={(file) => onUploadImg(file)}
                        imgExtension={constants.supportedImageFiles}
                        singleImage={true}
                      />
                    </div>
                  </div>
                </FileDrop>
              )}
              {isNewsImgeErrShow && (
                <div className="errorText" style={{ marginTop: "0px" }}>
                  Please Upload Image File
                </div>
              )}
              <div className="upload-text mt-20  ">{id !== 0 && id !== undefined ? "Update News Details" : "News Details"}</div>
              <Grid item md={12} className="mt-20" >
                <CustomTextField
                  name="title"
                  label="Title"
                  maxLength={255}
                  placeholder=" "
                  onChange={(e) => {
                    setNewsDetails({
                      ...newsDetails,
                      newsTitle: e.target.value,
                    });
                    setNewsTitleErr(false);
                  }}
                  value={newsDetails.newsTitle}
                  required={true}
                  fullWidth
                ></CustomTextField>
                {isNewsTitleErrShow === true && (
                  <div className="errorText" style={{ marginTop: "0px" }}>
                    Enter title
                  </div>
                )}
              </Grid>
              <Grid item md={12} className="mt-20" >
                <CustomTextField
                  name="title"
                  label="Sub title"
                  maxLength={255}
                  placeholder=" "
                  onChange={(e) => {
                    setNewsDetails({
                      ...newsDetails,
                      newsSubtitle: e.target.value,
                    });
                    setNewsSubTitleErr(false);
                  }}
                  value={newsDetails.newsSubtitle}
                  required={true}
                  fullWidth
                ></CustomTextField>
                {isNewsSubTitleErrShow === true && (
                  <div className="errorText" style={{ marginTop: "0px" }}>
                    Enter sub title
                  </div>
                )}
              </Grid>
              <Grid item md={12} className="mt-20" >
                <CustomTextField
                  name="title"
                  label="Description"
                  placeholder=" "
                  multiline
                  rows={3}

                  onChange={(e) => {
                    setNewsDetails({
                      ...newsDetails,
                      newsDescription: e.target.value,
                    });
                    setNewsDescErr(false);
                  }}
                  value={newsDetails.newsDescription}
                  required={true}
                  minWidth={300}
                  fullWidth
                ></CustomTextField>
                {isNewsDescErrShow === true && (
                  <div className="errorText" style={{ marginTop: "0px" }}>
                    Enter description
                  </div>
                )}
              </Grid>

              <div className='upload-control mt-20'>
                <Grid container spacing={3}>

                  <Grid item md={12} xs={12}>
                    {/* <div className="complete-profile">
                      <div className="floating-label "> */}
                    <Multiselect
                      options={newsDetails.categoryList}
                      selectedValues={newsDetails.selectedCategoryList}
                      onSelect={onSelectCategory}
                      onRemove={onRemoveSelectCategory}
                      displayValue="name"
                      placeholder="Add Category"
                      style={multiSelectStyle}
                      // showCheckbox={true}
                      // closeIcon="circle"
                      customCloseIcon={
                        <AiFillCloseSquare className="multiselect-close-icon" />
                      }
                    />
                    {isAddCategoryErrShow === true && (
                      <div
                        className="errorText"
                        style={{ marginTop: "0px" }}
                      >
                        Select category
                      </div>
                    )}
                    {/* </div>
                    </div> */}
                  </Grid>
                  <Grid item md={6} xs={12} className='add-artist-btn'>
                    <CustomButtonContained text="Cancel" onClick={() => onClickCancel()} />
                  </Grid>
                  <Grid item md={6} xs={12} className='add-artist-btn'>
                    <CustomButtonContained text={id === 0 || id === undefined ? "Save" : "Update"} onClick={onUpload} />
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div >
  )
}

export default UploadNews