import { Card, Grid } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MessageIcon from "@material-ui/icons/Message";
import PropTypes from "proptypes";
import React, { useEffect, useState } from "react";
import ArtistImage2 from "../../assets/images/ArtistImage/ArtistImage2.png";
import IconMoreCircle from "../ArtistDetails/Icon-More-Circle.png";
import "./Song.scss";
import SongDetails from "../SongDetails/SongDetails";
import ArtistPodcastDetails from "../../pages/Artist/ArtistPodcastDetails";
import TabSearchBar from "../SearchBar/TabSearchBar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { deleteDataApi, getdata, patchDataApi } from "../../utility/apiCalling";
import { constants } from "../../utility/constants";
import { openConfirmationDeleteDialog } from "../../utility/dialogAppear";
import {
  addErrorMessage,
  addSuccessMessage,
} from "../../utility/LoadingContent";
import reactDom from "react-dom";
import moment from "moment";
import {
  openCommentDialog,
  openLikeDialog
} from "../../utility/dialogAppear";
import Dropdown from 'react-bootstrap/Dropdown';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
export default function Episode(props) {
  const { podcast, name, backButton, albumId } = props;
  const [flag, setFlag] = useState(true);
  const [music, setmusic] = useState([]);
  const [songDetails, setsongDetails] = useState([]);
  const [podcastPage, setPodcastPage] = useState(false);
  const [songBlock, setsongBlock] = useState(null);
  const [editstate, seteditstate] = useState();
  const [albumName, setalbumName] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [blockstate, setblockstate] = useState();

  

  const handleClick = (event,i) => {
    seteditstate(i)
    let st = music[i]
    console.log(st)
    setblockstate(st.status)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  let history = useHistory();
  useEffect(() => {
    getMusic();
  }, []);

  async function getMusic() {
    // console.log(constants.musicByAlbum + "/" + albumId + "?page=1&limit=10");
    let response = await getdata(
        constants.baseURL + "episode/admin/getEpisodesByPodcastsWithUserLike?page=" + "1" + "&limit=50&podcastsId=" + albumId
    );
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
        console.log(response)
      setalbumName(response?.data?.data[0]?.podcasts?.name);

      return setmusic(response.data.data);
    }
  }

  const handleBlock = () => {
    {
      blockstate
        ? openConfirmationDeleteDialog(Block, "Block")
        : openConfirmationDeleteDialog(unBlock, "Unblock");
    }
  };
  const calllikes = (i) =>{
  console.log(i)
  openLikeDialog(i.likes)
  }
  const callcomment = (i) =>{
  console.log(i)
  openCommentDialog(i.comments,"episode");
  }
  const unBlock = async () => {
    let jsonData = {
      status: 1,
    };

    console.log("handle Music  Unblock----------------------->");
    console.log(constants.getMusic + "/" + songDetails.id);
    let unBlockSongData = await patchDataApi(
      constants.getEpisode + "/" + music[editstate].id,
      jsonData
    );
    if (unBlockSongData.status === 200 || unBlockSongData.statusText === "OK") {
      addSuccessMessage("Music UnBlocked");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
      handleClose()
      return getMusic();
    } else {
    }
  };
 
  const Block = async () => {
    let jsonData = {
      status: 0,
    };

    console.log("handle block----------------------->");
    // console.log(constants.getMusic + "/" + songDetails.id);
    let blockSongData = await patchDataApi(
      constants.getEpisode + "/" + music[editstate].id,
      jsonData
    );
    if (blockSongData.status === 200 || blockSongData.statusText === "OK") {
      // getMusicByKey(songDetails.id);

      addSuccessMessage("Music Blocked");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
      handleClose()
      return getMusic();
    } else {
    }
  };

  const handleRemove = () => {
    openConfirmationDeleteDialog(() => remove(), "Remove");
  };
  const remove = async () => {
    let i = music[editstate]
    let jsonData = {
      isDeleted: 1,
    };
    let deleteData = await patchDataApi(
      constants.deleteepisode + "/" + i.id,
      jsonData
    );
    if (deleteData.status === 200 || deleteData.statusText === "OK") {
      addSuccessMessage("Episode Delete");
      setFlag(true);
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
      return getMusic();
    } else {
      addErrorMessage("Episode Not Delete");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
    // let removeMusic = await deleteDataApi(
    //   constants.getMusic + "/" + songDetails.id
    // );
    // if (removeMusic.status === 200 && removeMusic.statusText === "OK") {
    //   addSuccessMessage("Music Remove");
    //   // history.push("events");
    //   setFlag(true);
    //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    //   return getMusic();
    // } else {
    //   // console.log("User Not  successfully Deleted");
    //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    // }
  };

  const handleChange = () => {
    
    console.log(editstate)
    let i = music[editstate]
    history.push({
      pathname: `/uploadepisode`,
      state: {
        ArtistId: i.artistId,
        id:i.id,
        type: i.episodeType,
        podcastId:i.podcasts.id,
        item:i
      },
    })
    // handleClose()
    // let data = music[key];
    // setsongDetails(data);
    // // getMusicByKey(data.id);
    // setsongBlock(data.status);
    // if (name === "podcast") {
    //   setFlag(false);
    //   setPodcastPage(true);
    // }
    // setFlag(false);
  };
  const StateChange = () => {
    setFlag(true);
  };
  const formatTime = (second) => {
    var i = 0;
    var h = 0;
    var s = parseInt(second);
    if (s >= 60) {
      i = parseInt(s / 60);
      s = parseInt(s % 60);

      if (i >= 60) {
        h = parseInt(i / 60);
        i = parseInt(i % 60);
      }
    }
    if (h > 0) return [zero(h), zero(i), zero(s)].join(":");
    return [zero(i), zero(s)].join(":");
    //console.log("formatTime- ",[zero(i), zero(s)].join(':'));
  };
  const zero = (v) => {
    return v >> 0 < 10 ? "0".concat(v) : v;
  };
  return (
    <div>
      {flag ? (
        <div>
          <Grid container spacing={3}>
            <Grid item md={12}>
              {/* <TabSearchBar
                title={albumName}
                track={music.length + " Episode(s)"}
                showBackButton
                onClick={backButton}
              /> */}
            </Grid>
          </Grid>

          {music !== 0 && music !== undefined ? (
            <div>
              {music.map((item, key) => {
                return (
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <Card
                          style={{
                            background: "rgba(24, 26, 32, 1)",
                            color: "white",
                            // opacity: "0.7",
                            borderRadius: "8px",
                            cursor: "pointer",
                          }}
                          // onClick={() => handleChange(key,item)}
                        >
                          <Grid
                            container
                            spacing={1}
                            style={{ padding: "12px" }}
                          >
                            <Grid item xs={12} sm={3}>
                              <div style={{ display: "flex" }}>
                                <div>
                                  <img
                                    src={constants.podcastepisodeFileURL + item.thumbnailUrlImage}
                                    alt="Imgloaderror"
                                    className="song-image"
                                  />
                                </div>
                                <div className="dis-flex-column  lineheight-15">
                                  <span className="font-12 opacity-90">
                                    {item.title}
                                  </span>
                                  <span className="font-10 opacity-40">
                                    {item.artist}
                                  </span>
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                              <div className="dis-flex-around ">
                                <div className="dis-flex-column  lineheight-15">
                                  <span className="font-10 opacity-40">
                                    Duration
                                  </span>
                                  <span className="font-12 opacity-90">
                                  {item.duration !== undefined &&
                      item.duration !== null &&
                      item.duration !== NaN
                      ? formatTime(item.duration)
                      : "0:00"}
                                  </span>
                                </div>
                                <div className="dis-flex-column  lineheight-15">
                                  <span className="font-10 opacity-40">
                                  {moment(item.created).format("DD-MMM-yyyy")}
                                  </span>
                                  {/* <span className="font-12 opacity-90">
                                    {item?.reported?.length}
                                  </span> */}
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <div className="dis-flex-between">
                                <div className="dis-inline-row opacity-50 ">
                                  <VisibilityIcon
                                    fontSize="small"
                                    titleAccess="View"
                                  />
                                  <span className="font-10 mar-left-4">
                                  {item.downloads.length}
                                  </span>
                                </div>
                                <div className="dis-inline-row opacity-50 ">
                                  <FavoriteIcon
                                    fontSize="small"
                                    titleAccess="Like"
                                    onClick={()=>calllikes(item)}
                                  />
                                  <span className="font-10 mar-left-4">
                                    {item.likes.length}
                                  </span>
                                </div>
                                <div className="dis-inline-row opacity-50 ">
                                  <MessageIcon
                                    fontSize="small"
                                    titleAccess={"comment"}
                                    onClick={()=>callcomment(item)}
                                  />
                                  <span className="font-10 mar-left-4">
                                    {item.comments.length}
                                  </span>
                                </div>
                                {item?.podcasts?.artist?.isAdminManaged ? <Button aria-controls={key}  aria-haspopup="true" onClick={(e)=>handleClick(e,key)}>
                                <img
                  src={IconMoreCircle}
                  alt="not available"
                  // className={classes.Switcher}
                />
                </Button>:null}
                <Menu
        id={key}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem  onClick={() => handleChange()}><span className="font-12 opacity-90" style={{color:"white"}}>
                                    Edit
                                  </span></MenuItem>
        <MenuItem onClick={handleRemove}><span className="font-12 opacity-90" style={{color:"white"}}>
                                    Remove
                                  </span></MenuItem>
        <MenuItem onClick={handleBlock}><span className="font-12 opacity-90" style={{color:"white"}}>
                                    {blockstate ? "Block" :"Unblock"}
                                  </span></MenuItem>
      </Menu>
              

                                {/* <MoreVertIcon
                                  fontSize="small"
                                  className="yellowcolor"
                                /> */}
                              </div>
                            </Grid>
                            {podcast ? (
                              <div>
                                <Grid item sm={12}>
                                  <div className="podcastdetail">
                                    Lorem ipsum dolor sit amet, consetetur
                                    sadipscing elitr, sed diam nonumy eirmod
                                    tempor invidunt ut labore et dolore magna
                                    aliquyam erat, sed diam voluptua. At vero
                                    eos et accusam et justo duo dolores et ea
                                    rebum. Stet clita kasd gubergren, no sea
                                    takimata sanctus est Lorem ip
                                  </div>
                                </Grid>
                              </div>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </Card>
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
              
            </div>
          ) : (
            <div>No data Found</div>
          )}

          {/* -------------------------------------2nd page------------------ */}
        </div>
      ) : (
        <div>
          {podcastPage ? (
            <div>
              <ArtistPodcastDetails />
            </div>
          ) : (
            <div>
              <Grid container spacing={3}>
                <Grid item md={12}>
                  <TabSearchBar
                    title={albumName}
                    showBackButton
                    onClick={() => StateChange()}
                  />
                </Grid>
              </Grid>
              {/* <SongDetails
                songItem={songDetails}
                musicBlockText={
                  songBlock === undefined
                    ? ""
                    : songBlock === 1
                    ? "Block"
                    : "UnBlock"
                }
                musicBlock={handleBlock}
                musicRemove={handleRemove}
              ></SongDetails> */}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
Episode.prototype = {
  podcast: PropTypes.bool,
};
