import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./ArtistDetailsStyle";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import IconMoreCircle from "./Icon-More-Circle.png";
import UserInformation from "../CardNew/UserInformation";
import CardCategory from "../CardNew/CardCategory";
import { CardLocation, CardSubscribe } from "../CardNew/CardLocation";

import { constants } from "../../utility/constants";

import CustomSwitch from "../Controls/CustomSwitch";
import "./ArtistDetails.scss";
import { CustomButtonContained } from "../Controls/CustomButton";
import { openConfirmationDeleteDialog } from "../../utility/dialogAppear";
import { patchDataApi } from "../../utility/apiCalling";
import { addSuccessMessage } from "../../utility/LoadingContent";

const useStyles = makeStyles(styles);
export default function ArtistDetails(props) {
  const {
    ArtistData,
    MakeHome,
    MakeFeatured,
    onChange,
    onChange1,
    MakeTopTen,
    MakeTopTwenty,
    MainArtist,
    onChangeShowOnTopTen,
    onChangeShowOnTopTwenty,
    onChangeMainArtist,
    onChangeImage,
  } = props;
  console.log("Make top 10 :", ArtistData.urlImage);
  const classes = useStyles();
  var Userimagelink = constants.profileURL; //constants.baseURL + "profilefiles";
console.log(ArtistData)
const handleBlock = () => {
  // window.alert("hande block");
  // window.alert(bigBlock);
  {
    ArtistData.status === true
      ? openConfirmationDeleteDialog(Block, "Block")
      : openConfirmationDeleteDialog(unBlock, "Unblock");
  }
};

const unBlock = async () => {
  // window.alert(bigBlock);
  let jsonData = {
    // id: data.id,
    status: true,
  };
  // console.log(id);
  console.log("handle block----------------------->");
  console.log(constants.user + "/" + ArtistData.id);
  let blockArtistData = await patchDataApi(
    constants.user + "/admin/blockUnblock/" + ArtistData.id, //Hitendra
    jsonData
  );
  if (blockArtistData.status === 200 || blockArtistData.statusText === "OK") {
    // setOpenPopup(false);
    addSuccessMessage("Artist Unblock");
    window.location.reload()
    // getReportedArtist();
    // reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
  } else {
  }
};
const Block = async () => {
  let jsonData = {
    status: false,
  };
  console.log("handle block----------------------->");
  console.log(constants.user + "/" + ArtistData.id);
  let blockArtistData = await patchDataApi(
    constants.user + "/admin/blockUnblock/" + ArtistData.id, //Hitendra
    jsonData
  );
  if (blockArtistData.status === 200 || blockArtistData.statusText === "OK") {
    // setArtistBlock(true);
    // setOpenPopup(false);

    addSuccessMessage("Artist Block");
    window.location.reload()
    // getReportedArtist();
    // reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
  } else {
    // reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
  }
};
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <UserInformation
            type="Playlist"
            urlImageUser={ArtistData.urlImage?Userimagelink + ArtistData.urlImage:constants.userImageURL }
            userEmail={ArtistData.email}
            userName={
              ArtistData.firstName === undefined
                ? ""
                : ArtistData.firstName + " " + ArtistData.lastName
            }
            userAlbum={
              ArtistData.length === 0 ? "0" : ArtistData.playlists.length
            }
            userFollower={
              ArtistData.length === 0 ? "0" : ArtistData.followed.length
            }
            userFollowing={
              ArtistData.length === 0 ? "0" : ArtistData.following.length
            }
            onChangeImage={(e)=>onChangeImage(e)}
          />
        </Grid>
        {/* -----------------------------------------------option */}

        <Grid item xs={6} sm={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Paper
                className={
                  classes.Add1 + " " + classes.paper + " " + classes.heightFix
                }
                style={{ textAlign: "center" }}
              >
                <span className="header-type-3">{"Show On Home Screen"}</span>
                <CustomSwitch checked={MakeHome} onChange={onChange} />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              {/* <Paper className={classes.Add1 + " " + classes.paper}>
                <div
                  style={{
                    display: "flex",
                    fontSize: "15px",
                    justifyContent: "space-around",
                  }}
                >
                  <div>
                    <p className="header-type-3 lineheight-28">Show On</p>
                    <p className="header-type-3">Home Screen</p>
                  </div>
                  <div>
                    <CustomSwitch checked={MakeHome} onChange={onChange} />
                  
                  </div>
                </div>
              </Paper> */}
              <Paper
                className={
                  classes.Add1 + " " + classes.paper + " " + classes.heightFix
                }
                style={{ textAlign: "center", minHeight: "123px" }}
              >
                <span className="header-type-3">{"Make Featured Artist"}</span>
                <CustomSwitch checked={MakeFeatured} onChange={onChange1} />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper
                className={
                  classes.Add1 + " " + classes.paper + " " + classes.heightFix
                }
                style={{ textAlign: "center", minHeight: "123px" }}
              >
                <span className="header-type-3">{"Main Artist"}</span>
                <CustomSwitch
                  checked={MainArtist}
                  onChange={onChangeMainArtist}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        {/* ----------------------------------------------------------------------------------extraa Row */}

        <Grid item xs={6} sm={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Paper
                className={
                  classes.Add1 + " " + classes.paper + " " + classes.heightFix
                }
                style={{ textAlign: "center" }}
              >
                <span className="header-type-3">{"Show On Top Ten"}</span>
                <CustomSwitch
                  checked={MakeTopTen}
                  onChange={onChangeShowOnTopTen}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper
                className={
                  classes.Add1 + " " + classes.paper + " " + classes.heightFix
                }
                style={{ textAlign: "center" }}
              >
                <span className="header-type-3">{"Show On Top Twenty"}</span>
                <CustomSwitch
                  checked={MakeTopTwenty}
                  onChange={onChangeShowOnTopTwenty}
                />
              </Paper>
              {/* <Paper
                className={classes.Add + " " + classes.paper}
                style={{ textAlign: "center" }}
              >
                <p className="header-poppins-12 lineheight-28">
                  {ArtistData.length === 0 ? "0" : ArtistData.reported.length}
                </p>
                <p className="header-type-3 lineheight-22">
                  People Reported Artist
                </p>
              </Paper> */}
            </Grid>
            {/* <Grid item xs={12} sm={4}>
              <Paper
                className={
                  classes.Add + " " + classes.paper + " " + classes.heightFix
                }
                style={{
                  textAlign: "center",
                  alignItems: "center",
                  padding: "19px 0px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <img
                  src={IconMoreCircle}
                  alt="not available"
                  className={classes.Switcher}
                />
                <span className="header-type-3">More Option</span>
              </Paper>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <div className="dis-flex-around addBorder ">
                <p className="header-poppins-12 lineheight-28 ">
                  Youtube Channel Subscribed
                </p>
                <p className="  header-type-3 lineheight-18  ">
                  <div>
                    <CustomSwitch
                      checked={
                        ArtistData.subscribeYouTubeChannel !== undefined
                          ? ArtistData.subscribeYouTubeChannel
                          : false
                      }
                    />
                  </div>
                  {/* 
                  {ArtistData.subscribeYouTubeChannel === true ? (
                    <div>
                      <span>Yes</span>

                      {
                        // <CustomSwitch
                        //   checked={ArtistData.subscribeYouTubeChannel && true}
                        // />
                      }
                    </div>
                  ) : (
                    <div>
                      <span>No</span>
                    </div>
                  )} */}
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper
                className={classes.Add + " " + classes.paper}
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <p className=" header-poppins-12">People Reported Artist</p>
                <p className="textRound">
                  {ArtistData.length === 0 ? "0" : ArtistData.reported.length}
                </p>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        {/* ------------------------------------------------------2nd Row Item */}
        <Grid item md={4} xs={12}>
          <div
            className={classes.heightFix + " addBorder"}
            style={{ minHeight: "80px" }}
          >
            <p className="header-poppins-12 lineheight-28 ">Location</p>
            <p className="header-type-3 lineheight-18  ">
              {ArtistData.fullAddress}
            </p>
          </div>
          {/* <CardLocation location={ArtistData.fullAddress} /> */}
        </Grid>
        <Grid item md={4} xs={12}>
          <CardCategory
            name="Category(s)"
            isTransparent
            categoriesItem={ArtistData.selCategories}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <CardCategory
            name="Type(s)"
            isTransparent
            categoriesItem={ArtistData.selCategories}
          />
        </Grid>
        <Grid item xs={12} md={4}>
                <CustomButtonContained
                  onClick={() => {
                    handleBlock();
                  }}
                  text={ArtistData?.status === true ? "Block" : "UnBlock"}
                />
              </Grid>
      </Grid>
    </div>
  );
}
