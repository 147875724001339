import React from "react";
import ArtistAvtar from "../../components/ArtistDetails/ArtistAvtar.png";
import classNames from "classnames";
import PropTypes from "prop-types";
import edit from "../../assets/images/editimage.png";

export default function UserInformation(props) {
  const {
    isBlack,
    isGray,
    userEmail,
    userName,
    urlImageUser,
    userAlbum,
    userFollower,
    userFollowing,
    type,
    onChangeImage,
    notshowpic
  } = props;
  const changeBackgroundToBlack = classNames("paper-main", {
    backBlack: isBlack === true,
  });
  const changeBackgroundToGray = classNames("Follower_details", {
    backLightGray: isGray === true,
  });
  return (
    <div className="user-edit">
      <div className={changeBackgroundToBlack}>
        {/* <div> */}
        <img
          src={urlImageUser}
          alt="not available"
          className="profile-details-image edit-image"
          style={{ borderRadius: "8px" }}
        />
       {notshowpic?null :<><input type="file" name="imageUpload" id="imageUpload" class="hide" onChange={(e) => onChangeImage(e)} />
        <label for="imageUpload" class="edit-user-btn"><img src={edit} alt="Logo" style={{ width: " 36px" }} /></label></>}
        {/* </div><input type="file" onChange={(e)=>onChangeImage(e)}/> */}
        <div style={{ padding: "10px" }}>
          <div>
            <p className="header-poppins-14 lineheight-28">{userName}</p>
            <p className="header-type-3">{userEmail}</p>
          </div>
          <div className={changeBackgroundToGray}>
            <div className="Follower_part">
              <p className="header-poppins-12">{userAlbum}</p>
              <p className="header-type-3">
                {type === "Playlist" ? "Playlist(s)" : "Album(s)"}
              </p>
            </div>
            <div className="Follower_part">
              <p className="header-poppins-12">{userFollower}</p>

              <p className="header-type-3">Follower</p>
            </div>
            <div className="Follower_part" style={{ border: "none" }}>
              <p className="header-poppins-12">{userFollowing}</p>
              <p className="header-type-3">Following</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
UserInformation.propTypes = {
  isGray: PropTypes.bool,
  isBlack: PropTypes.bool,
};
