import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import SearchBar from "../../components/SearchBar/SearchBar";
import Tab from "@material-ui/core/Tab";
import { Tabs } from "@material-ui/core";
import CustomTableNew from "../../components/CustomTable/CustomTableNew";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import NavSearchBar from "../../components/SearchBar/NavSearchBar";
import { deleteDataApi, getdata, patchDataApi } from "../../utility/apiCalling";
import { constants } from "../../utility/constants";
import moment from "moment";
import {
  openCategoryCreateDialog,
  openCategoryEditDialog,
  openConfirmationDeleteDialog,
} from "../../utility/dialogAppear";
import CategoryEditDialog from "../../components/CategoryEditDialog/CategoryEditDialog";
import reactDom from "react-dom";
import { addErrorMessage, addSuccessMessage } from "../../utility/LoadingContent";
import { CustomButtonContained, CustomButtonOutlined } from "../../components/Controls/CustomButton";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function Category() {
  const [privacy, setprivacy] = useState(null)
  const [privacy1, setprivacy1] = useState(null)
  const handleClick = async() =>{
  
  
      var jsonData = {
        title:privacy1,
      }
      // console.log(accountData1)
      let dddd = await patchDataApi(
        constants.baseURL + "privacypolicy/1" ,
        jsonData
      );
      if(dddd.status == 200){
        addSuccessMessage("Updated")
        callprivacy()
      }else{
        addSuccessMessage("Some Thing Wrong")
      }
      // console.log(dddd)
      
  
  }
  const callprivacy = async () => {
    let resCountries = await getdata(constants.baseURL + "privacypolicy");
    console.log(resCountries)
    setprivacy(resCountries.data[0].title)
    // console.log(resCountries,"http://45.80.152.23:4527/privacypolicy")
    // if (resCountries !== undefined && resCountries.data.length > 0) {
    //   // setSelectedCountry(resCountries[0].name);
    //   // setCountries(resCountries.data);
    //   // if(status==false){getStates(resCountries.data[0].id);}
    // }
  };
  const handleOnchange = (e,ed) => {
    console.log(e,ed.getData())
    let data = ed.getData()
    setprivacy1(data)
}
  useEffect(() => {
   callprivacy()
  }, [])
  
  return (
    <div className="main-window">
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <NavSearchBar title="Manage Privacy" />
        </Grid>
        <Grid item md={12} xs={12}>
        <CKEditor 
                        
                        editor={ ClassicEditor }
                        data={privacy}
                        style={{color:"black"}}
                        onChange={handleOnchange}
                    />
        </Grid>
        <Grid item md={12} xs={12}>
        <CustomButtonContained text="Save" onClick={handleClick} />
                        </Grid>
       
      </Grid>
      
    </div>
  );
}
