import { Container, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Menu, MenuItem } from "@material-ui/core";
import ArtistTypeCard from "../../components/ArtistTypeCard/ArtistTypeCard";
import SearchBar from "../../components/SearchBar/SearchBar";
import {
  BlueCard,
  OrangeCard,
  PinkCard,
} from "../../components/CardNew/CommonCards";
import NavSearchBar from "../../components/SearchBar/NavSearchBar";
import { deleteDataApi, getdata, patchDataApi } from "../../utility/apiCalling";
import { constants } from "../../utility/constants";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  CustomButtonContained,
  CustomButtonOutlined,
} from "../../components/Controls/CustomButton";
import {
  openCommentDialog,
  openConfirmationDeleteDialog,
  openLikeDialog,
  openVedioUpdateDialog,
  openViewDialog,
} from "../../utility/dialogAppear";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { addErrorMessage, addSuccessMessage } from "../../utility/LoadingContent";
import reactDom from "react-dom";
import { NoMoreDataFound } from "../../components/NoDataFound/NoDataFound";

export default function PodcastPage() {
  const [podcastData, setPodcastData] = useState([]);
  const [stopLoader, setStopLoader] = useState(true);
  const [nextPageNotFound, setnextPageNotFound] = useState(false)
  const [showPageNumberButton, setshowPageNumberButton] = useState(false);
  const [serc, setserc] = useState(null);
  
  var [page, setPage] = useState(1);
  useEffect(() => {
    getPodcast(page);
  }, []);
  let history = useHistory();

  // --------------------view Button function
  const handleView = (key) => {
    let data = podcastData.data[key];
    history.push({
      pathname: `podcast-details/${data.podcasts_id}`,
      state: {
        podcastId: data.podcasts_id,
      },
    });
  };
  //---------------------------------handle Edit
  const handleEdit = (id) => {
    window.alert(id);
    openVedioUpdateDialog(id);
  };
  //--------------------Delete Button Function
  const handleDelete = (id) => {
    // window.alert(id);
    openConfirmationDeleteDialog(() => {
      deletePod(id);
    }, "Delete");
  };
  const deletePod = async (id) => {
    let jsonData = {
      isDeleted: 1,
    };
    let deleteData = await patchDataApi(
      constants.getPodcastURL + "/" + id,
      jsonData
    );
    if (deleteData.status === 200 || deleteData.statusText === "OK") {
      addSuccessMessage("Podcast Delete");
      setPage(1);
      getPodcast(1);
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      addErrorMessage("Podcast Not Delete");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
    //   let deletePodcast = await deleteDataApi(constants.getPodcastURL + "/" + id);
    //   if (deletePodcast.status === 200 && deletePodcast.statusText === "OK") {
    //     // console.log("User Successfully Deleted");
    //     addSuccessMessage("Podcast Delete");
    //     reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    //   } else {
    //     console.log("podcast Not Delete");
    //     reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    //   }
  };

  async function getPodcast(page) {
    console.log("------------------podcast page");
    console.log(constants.admingetPodcastURL + "/getAll?page=" + page + "&limit=10");
    let response = await getdata(
      constants.admingetPodcastURL + "/getAll?page=" + page + "&limit=10"
    );
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      if (response.data.data.length !== 0) {
        if ((response.data.limit*response.data.page)<response.data.totalCount) {
          setshowPageNumberButton(true);
        }else{
          setshowPageNumberButton(false);
        }
        // return setPosts(response.data);
        return setPodcastData(response.data);
      } else {
        setPodcastData(response.data);
        return setStopLoader(false);
      }
      // console.log("hello");
      // console.log(response.data);
    }
  }
  // const handleComment = (key) => {
  //   let data = podcastData.data[key].comments;
  //   openCommentDialog(data);
  // };

  const getCommentData = (key) => {
    let data = podcastData.data[key].comments;
    console.log(data);
    if (data.length !== 0 && data.length !== undefined) {
      let formattedData = data.map((commentData, key) => {
        return [
          {
            commentDate: commentData.podcastcomments_created,
            commentMessage: commentData.podcastcomments_comment,
            userImage: commentData.user.urlImage,
            userName:
              commentData.user.firstName + " " + commentData.user.lastName,
            userPhone: commentData.user.phone,
          },
        ];
      });
      return formattedData;
    } else {
      return [];
    }
    //  openCommentDialog(data);
  };

  const handleComment = (key) => {
    const data = getCommentData(key);
    openCommentDialog(data);
  };

  const sendvalue = (i) =>{
    console.log(i)
    setserc(i)
    setPage(1)
    getNextPage(1,i);

  }







  const handleLike = (key) => {
    let data = podcastData.data[key].likes;
    openLikeDialog(data);
    // openViewDialog(data);
  };

  const nextPage = () => {
    setPage(page + 1);
    getNextPage(page + 1,serc);
  };

  console.log("--------------------page" + page);
  async function getNextPage(p,ser) {
    let apiURL = constants.admingetPodcastURL + "/getAll?page=" + p + "&limit=10"
    if (ser) {
      apiURL += "&searchText=" + ser
    }
    let response = await getdata(
      apiURL
    );
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      console.log("--------------------> next page");
      console.log(response);
      if (response.data.data.length !== 0) {
        if ((response.data.limit*response.data.page)<response.data.totalCount) {
          setshowPageNumberButton(true);
        }else{
          setshowPageNumberButton(false);
        }
        if(p==1){
          setPodcastData(response.data);
        }else{
          setPodcastData((prevState) => {
            return {
              ...prevState,
              // totalCount: prevState.totalCount + response.data.totalCount,
              data: [...prevState.data, ...response.data.data],
            };
          });
        }
        
      } else {
        return setshowPageNumberButton(false);
      }
      // return setBigStageData(response.data);
    }
  }
  const data = (podcastData) => {
    console.log(podcastData);
    console.log(podcastData);
    console.log(podcastData !== undefined && podcastData.length !== 0);
    if (podcastData !== undefined && podcastData.length !== 0) {
      console.log("hello----------------------andar");
      var formatted = podcastData.data.map((items, key) => {
        console.log(key);

        var imagelink = constants.podcastFileURL;//constants.baseURL + "podcastfiles/";
        return [
          {
            id: items.podcasts_id,
            image: [
              {
                itemImage:
                  items.podcasts_urlImage === ""
                    ? constants.musicURL
                    : imagelink + items.podcasts_urlImage,
                handleImage: () => handleView(key),
              },
            ],
            // image: imagelink + items.podcasts_urlImage,
            vedioTitle: items.podcasts_name,
            imageTagName: "Active",
            // vedioTrack: items.podcasts_description.substring(0, 32),
            vedioTrack: items.podcasts_description,
            view: "0",
            like: [
              {
                totalLike: items.likes.length,
                handleLike: () => handleLike(key),
              },
            ],
            comment: [
              {
                totalComment: items.comments.length,
                handleComment: () => handleComment(key),
              },
            ],
            // customHandle: "bigstage",
            // totalData: items,
            handleDel: () => handleDelete(items.podcasts_id),
            // design: (
            //     <div key={items.podcasts_id.toString()}>
            //         <DeleteIcon
            //             fontSize="small"
            //             style={{ cursor: "pointer" }}
            //             titleAccess="Delete" onClick={() => { handleDelete(items.podcasts_id) }} />

            //     </div>
            // ),
          },
        ];
      });
      return formatted;
    } else return [];
  };

  return (
    <div className="main-window">
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <NavSearchBar title="Podcast" />
        </Grid>
        <Grid item xs={6} md={3}>
          <BlueCard
            totalBlue={podcastData.activeCount}
            title="Active Podcast"
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <PinkCard
            totalPink={podcastData.reportedCount}
            title="Reported Podcast"
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <OrangeCard
            totalBlocked={podcastData.inactiveCount}
            title="Blocked Podcast"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <SearchBar
            title={
              podcastData.totalCount === undefined
                ? "0 Podcast(s)"
                : podcastData.totalCount + " Podcast(s)"
            }
            sendvalue={sendvalue}

          ></SearchBar>
        </Grid>
        <Grid item xs={12} md={12}>
          {stopLoader === true ? (
            <ArtistTypeCard
              sourceData={data(podcastData)}
              variant="PodCast"
            ></ArtistTypeCard>
          ) : (
            <p style={{ paddingTop: "150px", textAlign: "center" }}>
              No data found
            </p>
          )}

        </Grid>
        <Grid item md={12} xs={12}>
          <div style={{ textAlign: "-webkit-center" }}>
            {showPageNumberButton && (
              <div>
                <Grid item md={12} style={{ width: "20%" }}>
                  <CustomButtonOutlined
                    text={"view More "}
                    onClick={() => nextPage()}
                  />
                </Grid>
              </div>
            )}
          </div>
        </Grid>
        {/* {nextPageNotFound === true ? <NoMoreDataFound/>:
          <Grid item md={12} xs={12}>
            <div style={{ textAlign: "-webkit-center" }}>
              {podcastData.length !== 0 ? (
                <div>
                  {podcastData.data.length === 10 ? (
                    <div>
                      <Grid item md={12} style={{ width: "20%" }}>
                        <CustomButtonOutlined
                          text={"view More "}
                          onClick={() => nextPage()}
                        />
                      </Grid>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </Grid>

        } */}

      </Grid>
    </div>
  );
}
