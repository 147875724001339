import axios from "axios";

import { constants } from "./constants";

const axiosInstance = axios.create({ baseURL: constants.baseURL });
const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer tretgdfgdgdfgffdg",
};

export async function getdata(URL) {
  try {
    let response = await axios.get(URL);
    return response;
  } catch (error) {
    return error;
  }
}

// export async function getData(URL, data) {
//   console.log(URL);
//   console.log(data);
//   try {
//     let response = await axios
//       .get(URL, data)
//       .then((response) => {
//         console.error(response);
//         return response;
//       })
//       .catch((error) => error);
//     return response;
//   } catch (error) {
//     console.error(error);
//     return error;
//   }
// }
export async function postDataApi(URL, data, header) {
  console.log(URL);
  try {
    let response = await axios
      .post(URL, data, header)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => error);
    return response;
  } catch (error) {
    return error;
  }
}
export async function patchDataApi(URL, data, header) {
  console.log("-------------------api calling");
  console.log(URL, data, header);
  console.log("-------------------api calling end");
  try {
    let response = await axios
      .patch(URL, data, header)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => error);
    return response;
  } catch (error) {
    return error;
  }
}
export async function deleteDataApi(URL, data, header) {
  console.log("-------------------api calling");
  console.log(URL, data, header);
  console.log("-------------------api calling end");
  try {
    let response = await axios
      .delete(URL, data, header)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => error);
    return response;
  } catch (error) {
    return error;
  }
}
