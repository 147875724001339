import { Card, Grid } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MessageIcon from "@material-ui/icons/Message";
import PropTypes from "proptypes";
import React, { useEffect, useState } from "react";
import ArtistImage2 from "../../assets/images/ArtistImage/ArtistImage2.png";
import "./Song.scss";
import SongDetails from "../SongDetails/SongDetails";
import ArtistPodcastDetails from "../../pages/Artist/ArtistPodcastDetails";
import TabSearchBar from "../SearchBar/TabSearchBar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { deleteDataApi, getdata, patchDataApi } from "../../utility/apiCalling";
import { constants } from "../../utility/constants";
import { openConfirmationDeleteDialog } from "../../utility/dialogAppear";
import {
  addErrorMessage,
  addSuccessMessage,
} from "../../utility/LoadingContent";
import reactDom from "react-dom";
import CustomTextField from "../../components/Controls/CustomTextField";
import Search from "../../assets/images/Search.png";


export default function Song(props) {
  const { podcast, name, backButton, albumId } = props;
  const [flag, setFlag] = useState(true);
  const [music, setmusic] = useState([]);
  const [songDetails, setsongDetails] = useState([]);
  const [podcastPage, setPodcastPage] = useState(false);
  const [songBlock, setsongBlock] = useState(null);
  const [albumName, setalbumName] = useState("");
  const [inputVal, setInputVal] = useState({})
  const getInputVal = (e) => {
    console.log(e.target)
    let { name, value } = e.target;
    setInputVal((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  const sendvalue = (i) => {
    console.log(i)
    getMusic(i)
    // // setserc(i)

    // setPage(1)
    // if (selectedTab === 2) {
    //   getPodcast(1,i);
    // }
    // if (selectedTab === 3) {
    //   getNews(1,i);
    // }
    // if (selectedTab === 4) {
    //   getEvents(1,i);
    // }else{
    //   getAlbums(1,i)

    // }

    // // setPage1(1)
    // // getNextPage(1,i)
    // // getNextPage1(1,i)
  }
  let history = useHistory();
  useEffect(() => {
    getMusic();
  }, []);
  const zero = (v) => {
    return v >> 0 < 10 ? "0".concat(v) : v;
  };
  const formatTime = (second) => {
    var i = 0;
    var h = 0;
    var s = parseInt(second);
    if (s >= 60) {
      i = parseInt(s / 60);
      s = parseInt(s % 60);

      if (i >= 60) {
        h = parseInt(i / 60);
        i = parseInt(i % 60);
      }
    }
    if (h > 0) return [zero(h), zero(i), zero(s)].join(":");
    return [zero(i), zero(s)].join(":");
    //console.log("formatTime- ",[zero(i), zero(s)].join(':'));
  };
  async function getMusic(ser) {
    // console.log(constants.musicByAlbum + "/" + albumId + "?page=1&limit=10");
    let apiURL = constants.adminmusicByAlbum + "/" + albumId + "?page=1&limit=50"
    if (ser) {
      apiURL += "&searchText=" + ser
    }
    let response = await getdata(
      apiURL
    );
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      setalbumName(response.data.data[0].album.album_name);

      return setmusic(response.data.data);
    }
  }

  const handleBlock = () => {
    {
      songBlock === 1
        ? openConfirmationDeleteDialog(Block, "Block")
        : openConfirmationDeleteDialog(unBlock, "Unblock");
    }
  };

  const unBlock = async () => {
    let jsonData = {
      status: 1,
    };

    console.log("handle Music  Unblock----------------------->");
    console.log(constants.getMusic + "/" + songDetails.music_id);
    let unBlockSongData = await patchDataApi(
      constants.getMusic + "/" + songDetails.music_id,
      jsonData
    );
    if (unBlockSongData.status === 200 || unBlockSongData.statusText === "OK") {
      addSuccessMessage("Music UnBlocked");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
      return setsongBlock(1);
    } else {
    }
  };
  const Block = async () => {
    let jsonData = {
      status: 0,
    };

    console.log("handle block----------------------->");
    console.log(constants.getMusic + "/" + songDetails.music_id);
    let blockSongData = await patchDataApi(
      constants.getMusic + "/" + songDetails.music_id,
      jsonData
    );
    if (blockSongData.status === 200 || blockSongData.statusText === "OK") {
      // getMusicByKey(songDetails.music_id);

      addSuccessMessage("Music Blocked");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
      return setsongBlock(0);
    } else {
    }
  };

  const handleRemove = () => {
    openConfirmationDeleteDialog(() => remove(), "Remove");
  };
  const remove = async () => {
    let jsonData = {
      isDeleted: 1,
    };
    let deleteData = await patchDataApi(
      constants.getMusic + "/" + songDetails.music_id,
      jsonData
    );
    if (deleteData.status === 200 || deleteData.statusText === "OK") {
      addSuccessMessage("Music Delete");
      setFlag(true);
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
      return getMusic();
    } else {
      addErrorMessage("Music Not Delete");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
    // let removeMusic = await deleteDataApi(
    //   constants.getMusic + "/" + songDetails.music_id
    // );
    // if (removeMusic.status === 200 && removeMusic.statusText === "OK") {
    //   addSuccessMessage("Music Remove");
    //   // history.push("events");
    //   setFlag(true);
    //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    //   return getMusic();
    // } else {
    //   // console.log("User Not  successfully Deleted");
    //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    // }
  };

  const handleChange = (key) => {
    let data = music[key];
    setsongDetails(data);
    // getMusicByKey(data.music_id);
    setsongBlock(data.music_status);
    if (name === "podcast") {
      setFlag(false);
      setPodcastPage(true);
    }
    setFlag(false);
  };
  const StateChange = () => {
    setFlag(true);
  };

  return (
    <div>
      {flag ? (
        <div>
          <Grid container spacing={3}>
            <Grid item md={9}>
              <TabSearchBar
                title={albumName}
                track={music.length + " Track(s)"}
                showBackButton
                onClick={backButton}
              />
            </Grid>
            <Grid item md={2} xs={3}>
              <CustomTextField
                label="Search"
                onChange={getInputVal}
                name="search"
                value={inputVal.search}
              ></CustomTextField>
            </Grid>
            <Grid item md={1} xs={2}>
              <div className="dis-flex-around">
                <img src={Search} alt="Logo" style={{ width: " 36px" }}
                  onClick={() => sendvalue(inputVal.search)}
                />

              </div>
            </Grid>
          </Grid>

          {music !== 0 && music !== undefined ? (
            <div>
              {music.map((item, key) => {
                return (
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <Card
                          style={{
                            background: "rgba(24, 26, 32, 1)",
                            color: "white",
                            // opacity: "0.7",
                            borderRadius: "8px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleChange(key)}
                        >
                          <Grid
                            container
                            spacing={1}
                            style={{ padding: "12px" }}
                          >
                            <Grid item xs={12} sm={3}>
                              <div style={{ display: "flex" }}>
                                <div>
                                  <img
                                    src={ArtistImage2}
                                    alt="Imgloaderror"
                                    className="song-image"
                                  />
                                </div>
                                <div className="dis-flex-column  lineheight-15">
                                  <span className="font-12 opacity-90">
                                    {item.music_title}
                                  </span>
                                  <span className="font-10 opacity-40">
                                    {item.album.artist.firstName +
                                      " " +
                                      item.album.artist.lastName}
                                  </span>
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                              <div className="dis-flex-around ">
                                <div className="dis-flex-column  lineheight-15">
                                  <span className="font-10 opacity-40">
                                    Duration
                                  </span>
                                  <span className="font-12 opacity-90">
                                    {item?.music_duration !== undefined &&
                                      item?.music_duration !== null &&
                                      item?.music_duration !== NaN
                                      ? formatTime(item?.music_duration)
                                      : "0:00"}
                                  </span>
                                </div>
                                <div className="dis-flex-column  lineheight-15">
                                  <span className="font-10 opacity-40">
                                    Reported Song
                                  </span>
                                  <span className="font-12 opacity-90">
                                    {item.reported.length}
                                  </span>
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <div className="dis-flex-between">
                                <div className="dis-inline-row opacity-50 ">
                                  <VisibilityIcon
                                    fontSize="small"
                                    titleAccess="View"
                                  />
                                  <span className="font-10 mar-left-4">
                                    0
                                  </span>
                                </div>
                                <div className="dis-inline-row opacity-50 ">
                                  <FavoriteIcon
                                    fontSize="small"
                                    titleAccess="Like"
                                  />
                                  <span className="font-10 mar-left-4">
                                    {item.likes.length}
                                  </span>
                                </div>
                                <div className="dis-inline-row opacity-50 ">
                                  <MessageIcon
                                    fontSize="small"
                                    titleAccess={"comment"}
                                  />
                                  <span className="font-10 mar-left-4">
                                    {item.comments.length}
                                  </span>
                                </div>

                                {/* <MoreVertIcon
                                  fontSize="small"
                                  className="yellowcolor"
                                /> */}
                              </div>
                            </Grid>
                            {podcast ? (
                              <div>
                                <Grid item sm={12}>
                                  <div className="podcastdetail">
                                    Lorem ipsum dolor sit amet, consetetur
                                    sadipscing elitr, sed diam nonumy eirmod
                                    tempor invidunt ut labore et dolore magna
                                    aliquyam erat, sed diam voluptua. At vero
                                    eos et accusam et justo duo dolores et ea
                                    rebum. Stet clita kasd gubergren, no sea
                                    takimata sanctus est Lorem ip
                                  </div>
                                </Grid>
                              </div>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </Card>
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>No data Found</div>
          )}

          {/* -------------------------------------2nd page------------------ */}
        </div>
      ) : (
        <div>
          {podcastPage ? (
            <div>
              <ArtistPodcastDetails />
            </div>
          ) : (
            <div>
              <Grid container spacing={3}>
                <Grid item md={12}>
                  <TabSearchBar
                    title={albumName}
                    showBackButton
                    onClick={() => StateChange()}
                  />
                </Grid>
              </Grid>
              <SongDetails
                songItem={songDetails}
                musicBlockText={
                  songBlock === undefined
                    ? ""
                    : songBlock === 1
                      ? "Block"
                      : "UnBlock"
                }
                musicBlock={handleBlock}
                musicRemove={handleRemove}
              ></SongDetails>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
Song.prototype = {
  podcast: PropTypes.bool,
};
