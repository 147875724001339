import dotenv from  'dotenv'
//const prod = "https://api.multimusicmoguls.com/";
//const dev = "http://45.80.152.23:4527/";
const URL = process.env.REACT_APP_API_BASE_URL; //process.env.REACT_APP_NODE_ENV === "development" ? dev : prod;
const cdnURL = process.env.REACT_APP_CDN_URL; // "https://cdn.multimusicmoguls.com/";
const isAWS = process.env.REACT_APP_ISAWS === "Yes" ? true : false; //process.env.REACT_APP_NODE_ENV === "development" ? false : true;
console.log("constants - process.env.REACT_APP_ISAWS: ", process.env.REACT_APP_ISAWS)
console.log("constants - isAWS: ", isAWS)

export const constants = {
  baseURL: URL, //process.env.REACT_APP_NODE_ENV === "development" ? dev : prod,
  isAws: isAWS,

  user: URL + "user",
  
  // uplmultipleFileParameter: isAWS ? 'file' : 'image',
  uplFileParameter: 'file',

  musicFileUploadURL: isAWS ? URL + 'awsmusicfiles' : URL + 'musicfiles/uploadMultipleFiles',
  podcastFileUploadURL: isAWS ? URL + 'awspodcastfiles' : URL + 'podcastfiles/uploadMultipleFiles',
  podcastepisodeFileUploadURL: isAWS ? URL + 'awsepisodefiles' : URL + 'episodefiles/uploadMultipleFiles',
  newsFileUplodaURL: isAWS ? URL+'awsnewsfiles' : URL+'newsfiles',
  eventsFileUploadURL: isAWS ? URL+'awseventsfiles' : URL+'eventsfiles',
  profileFileUploadURL: isAWS ? URL+'awsprofilefiles' : URL+'profilefiles',
  albumFileUploadURL: isAWS ? URL+'awsalbumfiles' : URL+'albumfiles',
  bigstageVideoFileUploadURL: isAWS ? URL+'awsbigstagevideofiles' : URL+'bigstagevideofiles',
  bigstageThumbnailFileUploadURL: isAWS ? URL+'awsbigstagethumbnailfiles' : URL+'bigstagethumbnailfiles',
  subcategoryThumbnailFileUploadURL: isAWS ? URL+'awssubcategoryfiles' : URL+'subcategoryfiles',


  bigStageVideo: isAWS ? cdnURL + "uploads/bigstage/videos/" : URL + 'bigstagevideofiles/',//URL + "bigstagevideofiles",
  bigStageThumbnail: isAWS ? cdnURL + "uploads/bigstage/thumbnails/" : URL + 'bigstagethumbnailfiles/',//URL + "bigstagethumbnailfiles",
  albumFileURL: isAWS ? cdnURL + "uploads/album/" : URL + 'albumfiles/', //URL + "albumfiles/",
  profileURL: isAWS ? cdnURL + "uploads/profile/" : URL + 'profilefiles/',
  userImageURL: isAWS ? cdnURL + "uploads/profile/user.png" : URL + 'files/user.png',
  musicURL: isAWS ? cdnURL + "uploads/music.png" : URL + 'files/music.png',
  videoURL: isAWS ? cdnURL + "uploads/video.png" : URL + 'files/video.png',
  musicFileURL: isAWS ? cdnURL + "uploads/music/" : URL + 'musicfiles/',
  podcastFileURL: isAWS ? cdnURL + "uploads/podcast/" : URL + 'podcastfiles/',
  podcastthumbanailcategoryFileURL: isAWS ? cdnURL + "uploads/subcategory/" : URL + 'subcategoryfiles/',
  podcastepisodeFileURL: isAWS ? cdnURL + "uploads/episode/" : URL + 'episodefiles/',
  newsFileURL: isAWS ? cdnURL + "uploads/news/" : URL + 'newsfiles/',
  podcastCategoryURL: isAWS ? cdnURL + "uploads/podcastcategory.png" : URL + 'files/podcastcategory.png',
  eventsFileURL: isAWS ? cdnURL + "uploads/events/" : URL + 'eventsfiles/',
  


  createBigStage: URL + "bigstages",
  getBigStage: URL + "bigstages",
  admingetBigStage: URL + "bigstages/admin",
  getPodcastURL: URL + "podcasts",
  admingetPodcastURL: URL + "podcasts/admin",
  getnewsURL: URL + "news",
  admingetnewsURL: URL + "news/admin",
  geteventsURL: URL + "events",
  admingeteventsURL: URL + "events/admin",
  reportedArtist: URL + "reportartists",
  adminreportedArtist: URL + "reportartists/admin",
  reportedPodcast: URL + "reportpodcasts",
  adminreportedPodcast: URL + "reportpodcasts/admin",
  reportedEpisode: URL + "reportEpisode",
  adminreportedEpisode: URL + "reportEpisode/admin",
  reportedNews: URL + "reportnews",
  reportedSongs: URL + "reportsongs",
  adminreportedSongs: URL + "reportsongs/admin",
  getMusic: URL + "musics",
  getEpisode: URL + "episode",
  admingetEpisode: URL + "episode/admin",
  setbigstageorder: URL + "bigstages/changeBigstageOrder",
  dashboardCount: URL + "dashboard/getDashboard",
  artistCount: URL + "user/dashboard",
  artistAlbum: URL + "albums/getByArtist",
  adminartistAlbum: URL + "albums/admin/getByArtist",

  musicByAlbum: URL + "musics/getByAlbum",
  adminmusicByAlbum: URL + "musics/admin/getByAlbum",
  podcastsByArtist: URL + "podcasts/getByArtist",
  adminpodcastsByArtist: URL + "podcasts/admin/getByArtist",
  newsByArtist: URL + "news/getByArtist",
  adminnewsByArtist: URL + "news/admin/getByArtist",
  eventsByArtist: URL + "events/getByArtist",
  admineventsByArtist: URL + "events/admin/getByArtist",
  getCategories: URL + "categories",
  getCountry: URL + "countries",
  getState: URL + "states",
  getCity: URL + "cities",
  admingetCountry: URL + "countries/admin",
  admingetState: URL + "states/admin",
  admingetCity: URL + "cities/admin",
  getSubCategories: URL + "subcategories?filter=category.id||$eq||",
  getOnlySubCategory: URL + "subcategories",
  deleteAlbum: URL + "albums/deleteAlbum" ,
  deleteepisode: URL + "episode/deleteEpisode" ,
  deleteNews: URL + "news/deleteNews" ,
  deleteEvent: URL + "events/deleteEvents" ,
  deletePodcast: URL + "podcasts/deletePodcast" ,
  supportedImageFiles: [".jpg", ".jpeg", ".png", ".gif"],
  acceptedAudio:
  "audio/opus,audio/flac,audio/webm,audio/weba,audio/wav,audio/ogg,audio/m4a,audio/mp3,audio/oga,audio/mid,audio/amr,audio/aiff,audio/wma,audio/au,audio/aac,audio/mp4,audio/avi,audio/mov,audio/*",
acceptedVideo:
  "video/mp3,video/mpeg,video/ogm,video/mpg,video/wmv,video/webm,video/mp4,video/avi,video/mov,video/x-m4v,video/*",
  
  // podcastFileURL: URL + "podcastfiles/",
  // musicURL: URL + "files/music.png",
  // videoURL: URL + "files/video.png",
  // newsFileURL: URL + "newsfiles/",
  // eventsFileURL: URL + "eventsfiles/",
};


