import React from "react";

export function CardLocation(props) {
  return (
    <div>
      <div className="addBorder">
        <p className="header-poppins-12 lineheight-28 ">Location</p>
        <p className="header-type-3 lineheight-18  ">{props.location}</p>
      </div>
    </div>
  );
}
export function CardEmail(props) {
  return (
    <div>
      <div className="addBorder">
        <p className="header-type-3 lineheight-28 ">Email</p>
        <p className=" header-poppins-12 lineheight-18  ">{props.email}</p>
      </div>
    </div>
  );
}
export function CardSubscribe(props) {
  return (
    <div>
      <div className="addBorder">
        <p className="header-poppins-12 lineheight-28 ">Subscribe</p>
        <p className="  header-type-3 lineheight-18  ">
          {props.subscribe === true ? (
            <span>Channel Subscribed</span>
          ) : (
            <span> Channel Not Subscribed</span>
          )}
        </p>
      </div>
    </div>
  );
}
export function CardPublishDate(props) {
  return (
    <div>
      <div className="addBorder">
        <p className="header-type-3 lineheight-28 ">Created Date</p>
        <p className=" header-poppins-12 lineheight-18  ">
          {props.publishDate}
        </p>
      </div>
    </div>
  );
}
export function CardDuration() {
  return (
    <div>
      <div className="addBorder">
        <p className="header-type-3 lineheight-28 ">Duration</p>
        <p className=" header-poppins-12 lineheight-18  ">{" 3:32 PM"}</p>
      </div>
    </div>
  );
}

export function CardStatus(props) {
  const { status } = props;
  return (
    <div>
      <div className="addBorder">
        <p className="header-type-3 lineheight-28 ">Status</p>
        <p
          className=" header-poppins-12 lineheight-18  "
          style={{ color: "#4CC47C" }}
        >
          {status}
        </p>
      </div>
    </div>
  );
}
export function CardCity() {
  return (
    <div>
      <div className="addBorder">
        <p className="header-type-3 lineheight-28 ">City</p>
        <p
          className=" header-poppins-12 lineheight-18  "
          // style={{ color: "#4CC47C" }}
        >
          {" Smart City"}
        </p>
      </div>
    </div>
  );
}
export function CardState() {
  return (
    <div>
      <div className="addBorder">
        <p className="header-type-3 lineheight-28 ">State</p>
        <p
          className=" header-poppins-12 lineheight-18  "
          // style={{ color: "#4CC47C" }}
        >
          {" Los Vegas"}
        </p>
      </div>
    </div>
  );
}
