import React, { useState } from "react";
import "./CardNewComponent.scss";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Button, Grid } from "@material-ui/core";
import ArtistAvtar from "../../components/ArtistDetails/ArtistAvtar.png";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const useStyles = makeStyles((theme) => ({
  outlined: {
    borderColor: "#FFD874",
    color: "#FFD874",
    fontSize: "8px",
    width: "80%",
  },
}));
export default function CardSmallUserInformation(props) {
  const classes = useStyles();
  let history = useHistory();
  const {
    isBlack,
    isGray,
    isTransparentprofile,
    showButton,
    font_size,
    urlImageUser,
    userName,
    userEmail,
    userid
  } = props;

  const changeBackground = classNames("UserCardSetting", {
    backBlack: isBlack === true,
    backLightGray: isGray === true,
  });
  const changeBackgroundToTransParent = classNames("paper-main", {
    backTransparent: isTransparentprofile === true,
  });

  return (
    <div>
      <div className={changeBackground}>
        <Grid container spacing={2} style={{ margin: "0px" }}>
          <Grid item xs={8} md={8}>
            <div className={changeBackgroundToTransParent}>
              <img
                src={urlImageUser}
                alt="not available"
                className="image-50 border-radius-10"
              />
              <div style={{ padding: "10px" }}>
                <div>
                  <p
                    className={
                      "header-poppins-14 " + font_size + " lineheight-28"
                    }
                  >
                    {userName}
                  </p>
                  <p className="header-type-3">{userEmail}</p>
                </div>
              </div>
            </div>
          </Grid>
          {showButton ? (
            <Grid item xs={4} md={4} style={{ alignSelf: "center" }}>
              <Button variant="outlined" fullWidth className={classes.outlined} onClick={() => history.push({
                pathname: `user-details/${userid}`,
                state: {
                  ArtistId: userid,
                },
              })}>
                view Artist
              </Button>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </div>
    </div>
  );
}
CardSmallUserInformation.propTypes = {
  //   isTransparent: PropTypes.bool,
  isBlack: PropTypes.bool,
  isGray: PropTypes.bool,
  isTransparentprofile: PropTypes.bool,
  showButton: PropTypes.bool,
};
