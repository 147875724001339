import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import video from "../../assets/video.png";
import CloseIcon from "@material-ui/icons/Close";
import { DialogContent, DialogTitle, Grid } from "@material-ui/core";
import {
  CustomButtonContained,
  CustomButtonOutlined,
} from "../Controls/CustomButton";
import UploadImage from "../../assets/UploadImage/Upload.png";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomTextField from "../Controls/CustomTextField";
import { Form, useForm } from "../Controls/useForm";
import Files from "react-files";
import "./VedioUpload.scss";
import reactDom from "react-dom";
import { getdata, postDataApi } from "../../utility/apiCalling";
import { constants } from "../../utility/constants";
import {
  addInformationMessage,
  addLoader,
  addSuccessMessage,
  removeLoader,
} from "../../utility/LoadingContent";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomProgress from "../CustomProgress/CustomProgress";

const initialFValues = {
  id: 0,
  bigstagetitle: "",
  artistname: "",
  VedioOptionBox: "",
};
const VedioOption = [
  { title: "Music" },
  { title: "Event" },
  { title: "Podcast" },
  { title: "Audio" },
];

export default function VedioUpload(props) {
  const [bigStageData, setBigStageData] = useState(null);
  const { data, renderElement } = props;
  console.log(props);
  let history = useHistory();
  const [selectedFile1, setSelectedFile1] = useState([]);
  const [artist, setartist] = useState([]);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [showError, setShowError] = useState(false);
  // const [imageUrl, setImageUrl] = useState("");
  const [user, setuser] = useState("");
  const [progressValue, setProgressValue] = useState(15);
  const [progressShow, setprogressShow] = useState(false);
  var imageUrl = "";
  var vedioUrl = "";
  var duration = "0";
  // var user = "";
  useEffect(() => { }, [progressValue]);


  const [open, setOpen] = React.useState(true);
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("bigstagetitle" in fieldValues) {
      temp.bigstagetitle = fieldValues.bigstagetitle
        ? ""
        : "This field is required.";
    }
    // if ("artistname" in fieldValues)
    //   temp.artistname = fieldValues.artistname ? "" : "This field is required.";
    // if ("VedioOptionBox" in fieldValues)
    //   temp.VedioOptionBox = fieldValues.VedioOptionBox
    //     ? ""
    //     : "This field is required.";
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );
  const onFilesError1 = (error, file) => {
    console.log("error");
    if (error) {
      let FileErrorMessage = error.message;
      // addErrorMessage(FileErrorMessage);
    }
  };
  const onFilesChange1 = (files) => {
    if (files.length !== 0) {
      let fileMessage = files[0].name + " Added Successfully";
      setSelectedFile1(files[0]);
      addInformationMessage(fileMessage);
    }
    console.log(files);
  };

  const onFilesError2 = (error, file) => {
    console.log("error");
    if (error) {
      let FileErrorMessage = error.message;
      // addErrorMessage(FileErrorMessage);
    }
  };
  const onFilesChange2 = (files) => {
    console.log(selectedFile2);
    if (files.length !== 0) {
      let fileMessage = files[0].name + " Added Successfully";
      setSelectedFile2(files[0]);
      addInformationMessage(fileMessage);

      // addSuccessMessage(fileMessage);
    }
    // console.log(files);
  };

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const handleClose = () => {
    console.log("---------------handle close");
    // history.push("thebigstage");
    // window.location.reload();
    // history.push({
    //   pathname: ``,
    // });
    reactDom.unmountComponentAtNode(renderElement);
    setOpen(false);
  };
  const handleCloseTab = () => {
    setSelectedFile1([]);
    setSelectedFile2(null);
  };
  const handleCloseVedioTab = () => {
    setSelectedFile1([]);
  };
  const handleSubmit = () => {
    // uploadBigStageImage();
    // uploadBigStage();
    // createBigStage();
    // addLoader();

    //  console.log(UserId)
    if (validate()) {
      if (selectedFile1.length === 0 || selectedFile2 === null) {
        setShowError(true);
      } else {
        setShowError(false);
        setprogressShow(true);
        setProgressValue("28");
        uploadBigStage();
        // addLoader();
      }
    }
  };
  const uploadBigStage = async () => {
    setProgressValue("31");
    console.log(selectedFile1);
    var data = new FormData();
    data.append(constants.uplFileParameter, selectedFile1);

    // console.log(constants.uploadBigStage);

    let uploadVedio = await postDataApi(constants.bigstageVideoFileUploadURL, data);//let uploadVedio = await postDataApi(constants.bigStageVideo, data);
    console.log(uploadVedio.data);
    if (uploadVedio.data.status === 200) {
      vedioUrl = uploadVedio.data.data.filename;
      console.log(selectedFile1)
      if (selectedFile1.type !== "video/quicktime") {
        duration = await getVideoDuration(selectedFile1);
      }

      // console.log(duration)
      // var vedioUrl = uploadVedio.data.filename;
      // setVedioUrl(uploadVedio.data.data.filename);
      // setURL({ vedioUrl: uploadVedio.data.data.filename });
      // console.log(URL.vedioUrl);
      setProgressValue("58");
      uploadBigStageImage();
      // console.log("file uploaded");
    } else {
      setprogressShow(false);
      // console.log("helllo data not upload successfully");
    }
  };
  const getVideoDuration = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(media.duration);
      };
      reader.readAsDataURL(file);
      console.log("jjjjj")
      reader.onerror = (error) => reject(error);
    });
  const uploadBigStageImage = async () => {
    setProgressValue("75");
    var data = new FormData();
    data.append(constants.uplFileParameter, selectedFile2);
    // console.log(constants.bigStageThumbnail);

    let uploadThumbnail = await postDataApi(constants.bigstageThumbnailFileUploadURL, data);//let uploadThumbnail = await postDataApi(constants.bigStageThumbnail, data);
    if (uploadThumbnail.data.status === 200) {
      console.log(uploadThumbnail.data.data.filename);
      // setImageUrl(uploadThumbnail.data.data.filename);
      imageUrl = uploadThumbnail.data.data.filename;
      // setImageUrl("hello");
      // setURL({ imageUrl: uploadThumbnail.data.data.filename });
      setProgressValue("85");
      // console.log("Image uploaded");

      createBigStage();
    } else {
      setprogressShow(false);
      // console.log("helllo data not upload successfully");
    }
  };
  const createBigStage = async () => {
    if (user == "") {
      return addInformationMessage("Please Select Artist");
    }
    setProgressValue("90");
    console.log();
    const jsonData = {
      name: values.bigstagetitle,
      user: parseInt(user),
      videoURL: vedioUrl,
      thumbnailURL: imageUrl,
      duration: duration,
      order: bigStageData + 1
    };
    console.log(jsonData);
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let createBigStageProfile = await postDataApi(
      constants.createBigStage,
      formBody,
      authHeader
    );
    if (createBigStageProfile.status === 201) {
      setProgressValue("100");
      setprogressShow(false);
      // history.push({
      //   pathname: `thebigstage`,
      // });

      addSuccessMessage("BigStage Create");
      window.location.reload();
      reactDom.unmountComponentAtNode(renderElement);
      // handleClose();
      setOpen(false);

      // console.log("Create User Successfully");
    } else {
      setprogressShow(false);
      // console.log("helllo data not upload successfully");
    }
  };
  const callartist = async () => {
    let respUser = await getdata(
      constants.baseURL + "user?filter=isDeleted||$eq||0&filter=usertype||$eq||artist&filter=status||$eq||1"
    );
    console.log(respUser)
    if (respUser !== undefined && respUser.data.length > 0) {
      setartist(respUser.data)
    }
  }
  async function getBigStage(page) {
    let response = await getdata(
      constants.getBigStage + "/getAll?page=" + page + "&limit=10"
    );
    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      // console.log("hello");
      console.log(response.data);
      if (response.data.data.length !== 0) {
        // return setPosts(response.data);
        if (response.data.data.length === 10) {
          // setshowPageNumberButton(true);
        }
        return setBigStageData(response.data.totalCount);
      } else {
        setBigStageData(response.data.totalCount);
        // return setStopLoader(false);
      }
    }
  }
  useEffect(() => {
    callartist()
    getBigStage(1)
  }, [])
  return (
    <div>
      <Dialog
        maxWidth="sm"
        onClose={handleClose}
        // aria-labelledby="customized-dialog-title"
        open={open}
        style={{ borderRadius: "32px" }}
      // className={classes.dialog}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        //   className={classes.dialogtitle}
        >
          <div className="dis-flex-between">
            <div> Upload Big Stage</div>
            {/* <CustomProgress value={progressValue} /> */}
            {progressShow && (
              <div>
                <CustomProgress value={progressValue} />
              </div>
            )}
          </div>
          {/* {open ? (
            <IconButton
              aria-label="close"
              
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null} */}
          {/* Upload Big Stage */}
        </DialogTitle>
        <DialogContent
        // className={classes.DialogContent}
        >
          <Form
          //   onSubmit={handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <div>
                  <Files
                    className="files-dropzone"
                    onChange={onFilesChange1}
                    onError={onFilesError1}
                    accepts={["video/mp4", "video/quicktime"]}
                    // multiple
                    // maxFiles={1}
                    // maxFileSize={3000000}
                    minFileSize={0}
                    // onError={true}
                    clickable
                  >
                    <div className="ImageUpload ">
                      <img src={UploadImage} alt="not found"></img>
                      <div className="font-14">Upload Video</div>
                      <div className="header-type-3">
                        Drag & drop your file here or
                        <span style={{ color: "#ffd874" }}>browse</span>
                      </div>
                    </div>
                  </Files>
                </div>
              </Grid>
              <Grid item md={12} xs={12}>
                {selectedFile1.length !== 0 ? (
                  <div
                    className="addBorder dis-flex-between"
                    style={{
                      background: "rgba(161, 152, 180, 0.1)",
                    }}
                  >
                    <div className="dis-inline-row">
                      <div>
                        <img
                          src={video}
                          alt="notfound"
                          className="table-image image-40"
                        ></img>
                      </div>
                      <div className="header-poppins-12">
                        {selectedFile1.name}
                      </div>
                    </div>
                    <IconButton
                      aria-label="close"
                      //   className={classes.closeButton}
                      onClick={handleCloseVedioTab}
                    >
                      <CloseIcon htmlColor="#FFD874" />
                    </IconButton>
                  </div>
                ) : (
                  ""
                )}
              </Grid>

              <Grid item md={12} xs={12}>
                <div className="dis-flex-between">
                  <div className="header-type-3">Upload Video Thumbnail</div>
                  <div>
                    <Files
                      className="files-dropzone"
                      onChange={onFilesChange2}
                      onError={onFilesError2}
                      accepts={["image/*"]}
                      // multiple
                      // maxFiles={1}
                      maxFileSize={3000000}
                      minFileSize={0}
                      // onError={true}
                      clickable
                    >
                      <CustomButtonOutlined
                        text="Browse"
                      // onClick={handleClose}
                      />
                    </Files>
                  </div>
                </div>
              </Grid>
              <Grid item md={12} xs={12}>
                {selectedFile2 && (
                  <div
                    className="addBorder dis-flex-between"
                    style={{
                      background: "rgba(161, 152, 180, 0.1)",
                    }}
                  >
                    <div className="dis-inline-row">
                      <div>
                        <img
                          src={selectedFile2.preview.url}
                          alt="notfound"
                          className="table-image image-40 addBorder"
                        ></img>
                      </div>
                      <div className="header-poppins-12">
                        {selectedFile2.name}
                      </div>
                    </div>
                    <IconButton
                      aria-label="close"
                      //   className={classes.closeButton}
                      onClick={handleCloseTab}
                    >
                      <CloseIcon htmlColor="#FFD874" />
                    </IconButton>
                  </div>
                )}
              </Grid>

              <Grid item md={12} xs={12}>
                <CustomTextField
                  name="bigstagetitle"
                  label="Big Stage Title"
                  value={values.bigstagetitle}
                  onChange={handleInputChange}
                  error={errors.bigstagetitle}
                  fullWidth
                ></CustomTextField>
              </Grid>
              <Grid item md={12} xs={12}>
                <div className="complete-profile removeBackgroundColor">
                  <div className="floating-label mt-20">
                    <select
                      className="floating-input floating-select1"

                      placeholder="Select Country"
                      name="country"
                      value={user}

                      onChange={(e) => setuser(e.target.value)}
                    >
                      <option selected value="Select Country" >Select Artist</option>
                      {artist.map((option) => (<>

                        <option key={option.id} value={option.id}>
                          {option.firstName + " " + option.lastName}
                        </option>
                      </>
                      ))}

                    </select>
                  </div>
                  {/* <span
                    style={{
                      color: "red",

                      top: "5px",
                      fontSize: "10px",
                    }}
                  >
                     {errors.bigstagetitle}
                  </span> */}
                </div>
              </Grid>
              {/* <Grid item md={12}>
                <CustomTextField
                  name="artistname"
                  label="Artist Name"
                  value={values.artistname}
                  onChange={handleInputChange}
                  error={errors.artistname}
                  fullWidth
                ></CustomTextField>
              </Grid> */}
              {/* <Grid item md={12}>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={VedioOption}
                  getOptionLabel={(option) => option.title}
                  defaultValue={[VedioOption[1]]}
                  filterSelectedOptions
                  fullWidth
                  renderInput={(params) => (
                    <CustomTextField
                      {...params
                      label="Add Category"
                      name="VedioOptionBox"
                    />
                  )}
                />
              </Grid> */}

              {showError ? (
                <Grid item md={12} xs={12}>
                  <div
                    className="header-type-3"
                    style={{ color: "red", textAlign: "center" }}
                  >
                    Please Upload Video and Thumbnail.
                  </div>
                </Grid>
              ) : (
                ""
              )}

            </Grid>
          </Form>
        </DialogContent>

        <Grid item md={12} xs={12}>
          <Grid container spacing={2} style={{ margin: "0px" }}>
            <Grid item xs={6} md={6}>
              <CustomButtonOutlined text="Cancel" onClick={handleClose} />
            </Grid>

            <Grid item xs={6} md={6}>
              <CustomButtonContained
                text="Create"
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
