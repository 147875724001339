import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import {
  CustomButtonContained,
  CustomButtonOutlined,
} from "./Controls/CustomButton";
import CustomTextField from "./Controls/CustomTextField";
import reactDom from "react-dom";
import { constants } from "../utility/constants";
import { patchDataApi, postDataApi } from "../utility/apiCalling";
import { Form, useForm } from "./Controls/useForm";
import { addErrorMessage, addInformationMessage, addSuccessMessage } from "../utility/LoadingContent";
import Files from "react-files";

const styles = (theme) => ({
  root: {
    margin: 0,
    // padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    // padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CountryEditDialog(props) {
  console.log(props)
  const {  Name,renderElement,coun,flag,cit,callbac} = props;
  const initialFValues = {
    id: Name.id,
    name: Name.name,
    alpha2code:Name.alpha2code,
    callingCodes:Name.callingCodes,

  };

  const [open, setOpen] = useState(true);
  const [selectedValue, setSelectedValue] = useState(null);
  const [errors, setError] = useState({});


 
  const textRegex =
    /^[/\a-zA-Z0-9\s\[\]\{\}\.\`\~\!\@\$\^\&\(\)\_\+\-\=\;\,]*$/;
  const handleClose = () => {
    reactDom.unmountComponentAtNode(renderElement);
    setOpen(false);
  };
  const validate = (fieldValues = values) => {
    let errors = {};
    let formIsValid = true;
    
    if (!fieldValues.name) {
      formIsValid = false;
      errors["name"] = "Name is required";
    }
    else if(fieldValues?.name?.trim()==""){
      formIsValid = false;
      errors["name"] = "Name is required";
    }
    else if (!textRegex.test(fieldValues.name)) {
      formIsValid = false;
      errors["name"] = "Invalid Name";
    }
    if(!flag){
      if (!fieldValues.alpha2code) {
        formIsValid = false;
        errors["alpha2code"] = "Alpha2code is required";
      }
      else if(fieldValues?.alpha2code?.trim()==""){
        formIsValid = false;
        errors["alpha2code"] = "Alpha2code is required";
      }
      else if (!textRegex.test(fieldValues.alpha2code)) {
        formIsValid = false;
        errors["alpha2code"] = "Invalid Alpha2code";
      }
      if (!fieldValues.callingCodes) {
        formIsValid = false;
        errors["callingCodes"] = "CallingCodes is required";
      }
      else if(fieldValues?.callingCodes?.trim()==""){
        formIsValid = false;
        errors["callingCodes"] = "CallingCodes is required";
      }
      else if (!textRegex.test(fieldValues.callingCodes)) {
        formIsValid = false;
        errors["callingCodes"] = "Invalid CallingCodes";
      }
    }
   
    

   

    setError(errors);
    return formIsValid;
  };
  const saveChanges = () => {
    if (validate()) {
     
      getSave();
    }
  };
 
  async function getSave() {

    let jsonData
    let jsonData1

    if(flag==1){
        jsonData = {
            name: values.name.trim(),
            countryId: coun,
          };
    }else if(flag==2){
      jsonData = {
          name: values.name.trim(),
          stateId: cit,
        };
  }else{
        jsonData = {
            name: values.name.trim(),
            alpha2code: values.alpha2code.trim(),
            callingCodes:values.callingCodes.trim()
          };   
    }
    if(flag==1){
      jsonData1 = {
        name: values.name.trim(),
        countryId: coun,
      };
    }else if(flag==2){
      jsonData1 = {
        name: values.name.trim(),
        stateId: cit,
      };
    }else{
      jsonData1 = {
        name: values.name.trim(),
      };
    }
      let CreateCategory1 = await postDataApi(
        (flag==1?constants.admingetState:flag==2?constants.admingetCity:constants.admingetCountry) + "/check" +(flag==1?"State":flag==2?"City":"Country"),
        jsonData1
      );
      console.log(CreateCategory1)
    // console.log(id);
    // console.log("handle Not Show----------------------->");
    // console.log(constants.getOnlySubCategory + "/" + data);

    if(!CreateCategory1.data){let CategorySave = await patchDataApi(
      (flag==1?constants.getState:flag==2?constants.getCity:constants.getCountry) + "/" + values.id,
      jsonData
    );
    console.log(CategorySave);
    if (CategorySave.status === 200 || CategorySave.statusText === "OK") {
      addSuccessMessage("Edit Success");
      callbac()
      reactDom.unmountComponentAtNode(renderElement);
      // window.location.reload()
    } else {
      // console.log("User Not  successfully Deleted");
    }}else{
    return addInformationMessage("This Name alredy exist! ")

    }
    
  }
  const { values, setValues, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate,
    true
  );
  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Open dialog
            </Button> */}
      <Dialog
        //   scroll="body"
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        style={{ borderRadius: "32px" }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Edit {flag==1?"State":flag==2?"City":"Country"}
          
        </DialogTitle>
        <DialogContent>
          <Form>
          <Grid container spacing={2} style={{ margin: "0px" }}>
            
            <Grid item xs={12} md={12}>
              <CustomTextField
                label="Name"
                name="name"
                value={values.name}
                onChange={handleInputChange}
                // error={errors.name}
                fullWidth
              />
              <span
                  style={{
                    color: "red",

                    top: "5px",
                    fontSize: "10px",
                  }}
                >
                  {errors["name"]}
                </span>
            </Grid>
            {flag?null:<><Grid item xs={12} md={12}>
                <CustomTextField
                  label="Alpha2Code"
                  name="alpha2code"
                  value={values.alpha2code}
                  onChange={handleInputChange}
                  // error={errors.alpha2code}
                  fullWidth
                />
                 <span
                  style={{
                    color: "red",

                    top: "5px",
                    fontSize: "10px",
                  }}
                >
                  {errors["alpha2code"]}
                </span>
              </Grid>
              <Grid item xs={12} md={12}>
                <CustomTextField
                  label="Calling Codes"
                  name="callingCodes"
                  value={values.callingCodes}
                  onChange={handleInputChange}
                  // error={errors.callingCodes}
                  fullWidth
                />
                <span
                  style={{
                    color: "red",

                    top: "5px",
                    fontSize: "10px",
                  }}
                >
                  {errors["callingCodes"]}
                </span>
              </Grid></>}
            <Grid item xs={12} md={6}>
              <CustomButtonOutlined text="Cancel" onClick={handleClose} />
            </Grid>

            <Grid item xs={12} md={6}>
              <CustomButtonContained
                text="Create"
                onClick={() => saveChanges()}
              />
            </Grid>
          </Grid>
          </Form>
        </DialogContent>

        {/* <DialogActions>
                    <Grid container spacing={2} style={{ margin: "0px" }}>
                        <Grid item xs={12} md={6}>
                            <CustomButtonOutlined text="Cancel" />

                        </Grid>

                        <Grid item xs={12} md={6}>
                            <CustomButtonContained text="Block Artist" />

                        </Grid>
                    </Grid>
                </DialogActions> */}
      </Dialog>
    </div>
  );
}
