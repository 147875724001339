export const ICONS = {
  BlockedIcon: require("../assets/CardIcon/BlockedIcon.svg").default,
  CustomerIcon: require("../assets/CardIcon/CustomerIcon.svg").default,
  FeaturedIcon: require("../assets/CardIcon/FeaturedIcon.svg").default,
  ReportedIcon: require("../assets/CardIcon/ReportedIcon.svg").default,
  staffIcon: require("../assets/CardIcon/staffIcon.svg").default,
  StandardIcon: require("../assets/CardIcon/StandardIcon.svg").default,
  StoreIcon: require("../assets/CardIcon/StoreIcon.svg").default,
  UserBlueIcon: require("../assets/CardIcon/UserBlueIcon.svg").default,
  UserOrangeIcon: require("../assets/CardIcon/UserOrangeIcon.svg").default,
  AudioNotIcon: require("../assets/CardIcon/AudioNotIcon.svg").default,
  ViewIcon: require("../assets/CardIcon/ViewIcon.svg").default,
  CommentIcon: require("../assets/CardIcon/CommentIcon.svg").default,
};

export const IMAGES = {
  BlueMask: require("../assets/CardBackground/BlueMask.svg").default,
  GreenMask: require("../assets/CardBackground/GreenMask.svg").default,
  OrangeMask: require("../assets/CardBackground/OrangeMask.svg").default,
  PinkMask: require("../assets/CardBackground/PinkMask.svg").default,
  ArtistImage1: require("../assets/images/ArtistImage/ArtistImage1.png")
    .default,
  ArtistImage2: require("../assets/images/ArtistImage/ArtistImage2.png")
    .default,
  // ArtistImage3: require("../assets/images/ArtistImage/ArtistImage3.png")
  //   .default,
  // ArtistImage4: require("../assets/images/ArtistImage/ArtistImage4.png")
  //   .default,
};
