import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import UserInformation from "../../components/CardNew/UserInformation";

import {
  CardPublishDate,
  CardStatus,
} from "../../components/CardNew/CardLocation";
import {
  CommentCard,
  LikeCard,
  ReportedCard,
  ViewCard,
} from "../../components/CardNew/CommonCards";
import {
  CustomButtonContained,
  CustomButtonOutlined,
} from "../../components/Controls/CustomButton";
import {
  openViewDialog,
  openCommentDialog,
  openConfirmationDeleteDialog,
  openReportedSongDialog,
  openReportDialog,
  openLikeDialog,
} from "../../utility/dialogAppear";
import NavSearchBar from "../../components/SearchBar/NavSearchBar";
import { deleteDataApi, getdata, patchDataApi } from "../../utility/apiCalling";
import { constants } from "../../utility/constants";
import reactDom from "react-dom";
import {
  addErrorMessage,
  addLoader,
  addSuccessMessage,
  removeLoader,
} from "../../utility/LoadingContent";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

import CustomSwitch from "../../components/Controls/CustomSwitch"; //Hitendra
import LoaderforArtistCommonCard from "../../components/SkeletonLoader/LoaderforArtistCommonCard";

export default function BigStageDetails(props) {
  console.log("bigStage---------------------props");
  const { id } = props.location.state;
  let history = useHistory();
  console.log(props.location.state);
  var [bigBlock, setBigBlock] = useState(null);
  var imagelink = constants.bigStageThumbnail //constants.baseURL + "bigstagethumbnailfiles";
  var Userimagelink = constants.profileURL; //constants.baseURL + "profilefiles";
  const [bigStageUser, setBigStageUser] = useState([]);

  useEffect(() => {
    getBigstageUser();
    // getBigstageUser(22);
  }, []);
  // const getBigstageUser = (userId) => {
  //   window.alert(userId);
  // };
  async function getBigstageUser() {
    console.log(constants.getBigStage + "/getById/" + id);
    let response = await getdata(constants.getBigStage + "/getById/" + id);

    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      console.log(response.data.data);
      setBigBlock(response.data.data.bigstage_status);
      return setBigStageUser(response.data.data);
    }
  }

  const handleBlock = () => {
    // window.alert("hande block");
    // window.alert(bigBlock);
    {
      bigBlock === 1
        ? openConfirmationDeleteDialog(Block, "Block")
        : openConfirmationDeleteDialog(unBlock, "Unblock");
    }
  };
  const unBlock = async () => {
    // window.alert(bigBlock);
    let jsonData = {
      status: 1,
    };
    console.log(id);
    console.log("handle block----------------------->");
    console.log(constants.getBigStage + "/" + bigStageUser.bigstage_id);
    let blockBigStage = await patchDataApi(
      constants.getBigStage + "/" + bigStageUser.bigstage_id,
      jsonData
    );
    if (blockBigStage.status === 200 || blockBigStage.statusText === "OK") {
      // console.log("User Successfully Block");
      // setBigBlock(0);

      setBigBlock(0);
      // window.alert(bigBlock);
      getBigstageUser(props.location.state.id);
      addSuccessMessage("BigStage UnBlocked");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      // console.log("User Not  successfully Deleted");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };
  const Block = async () => {
    // window.alert(bigBlock);
    let jsonData = {
      status: 0,
    };
    console.log(id);
    console.log("handle block----------------------->");
    console.log(constants.getBigStage + "/" + bigStageUser.bigstage_id);
    let blockBigStage = await patchDataApi(
      constants.getBigStage + "/" + bigStageUser.bigstage_id,
      jsonData
    );
    if (blockBigStage.status === 200 || blockBigStage.statusText === "OK") {
      // console.log("User Successfully Block");
      setBigBlock(1);
      // window.alert(bigBlock);
      getBigstageUser(props.location.state.id);
      addSuccessMessage("BigStage Blocked");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      // console.log("User Not  successfully Deleted");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };
  const handleRemove = () => {
    openConfirmationDeleteDialog(remove, "Remove");
  };
  const remove = async () => {
    let jsonData = {
      isDeleted: 1,
    };
    let deleteData = await patchDataApi(
      constants.getBigStage + "/" + props.location.state.id,
      jsonData
    );
    if (deleteData.status === 200 || deleteData.statusText === "OK") {
      addSuccessMessage("Big Stage Delete");
      history.goBack()
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      addErrorMessage("Big Stage Not Delete");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
    // let removeBigStage = await deleteDataApi(
    //   constants.getBigStage + "/" + props.location.state.id
    // );
    // if (removeBigStage.status === 200 && removeBigStage.statusText === "OK") {
    //   addSuccessMessage("Big Stage Remove Successfully");
    //   history.push("thebigstage");
    //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    // } else {
    //   // console.log("User Not  successfully Deleted");
    //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    // }
  };
  const handleReport = (data) => {
    // window.alert("report");
    const ReportData = getReportData(data);
    openReportDialog(ReportData);
  };
  const getReportData = (dataItem) => {
    console.log(dataItem);
    if (dataItem.length !== 0 && dataItem.length !== undefined) {
      let formattedData = dataItem.map((reportedData, key) => {
        return [
          {
            reportDate: reportedData.reportbigstage_created,
            reportMessage: reportedData.reportbigstage_comment,
            userImage: reportedData.user.urlImage,
            userName:
              reportedData.user.firstName + " " + reportedData.user.lastName,
            userPhone: reportedData.user.phone,
          },
        ];
      });
      return formattedData;
    } else {
      return [];
    }
    //  openCommentDialog(data);
  };
  const handleView = (data) => {
    openViewDialog(data);
  };
  // const handleComment = (data) => {
  //   openCommentDialog(data);
  // };

  const handleComment = (data) => {
    const commentData = getCommentData(data);
    openCommentDialog(commentData);
  };
  const getCommentData = (dataItem) => {
    console.log(dataItem);
    if (dataItem.length !== 0 && dataItem.length !== undefined) {
      let formattedData = dataItem.map((commentData, key) => {
        return [
          {
            commentDate: commentData.bigstagecomments_created,
            commentMessage: commentData.bigstagecomments_comment,
            userImage: commentData.user.urlImage,
            userName:
              commentData.user.firstName + " " + commentData.user.lastName,
            userPhone: commentData.user.phone,
          },
        ];
      });
      return formattedData;
    } else {
      return [];
    }
    //  openCommentDialog(data);
  };

  const handleLike = (data) => {
    openLikeDialog(data);
  };
  //Hitendra - START
  const onChangeShowOnTopTen = async (i) => {
    if(i === 0 && bigStageUser.bigstage_mainBigstage === 1){
      //alert("Main bigstage is not allow to show on top ten");
      addErrorMessage("Main bigstage is not allow to show on top ten");
      return;
    }
    console.log(i)
    console.log(
      "bigStageUser.showOnTopTen: ",
      bigStageUser.bigstage_showOnTopTen
    );

    let respArtist = await getdata(
      constants.getBigStage + "?filter=showOnTopTen||$eq||1&filter=isDeleted||$eq||0"
    );
    if (respArtist.data.length !== 10 || bigStageUser.bigstage_showOnTopTen == 1) {
      if (bigStageUser.bigstage_showOnTopTwenty == 1) {
        // addErrorMessage("The BigStage is already in top twenty list");
        openConfirmationDeleteDialog(confirmToMakeTopTen, "Yes");
        return;
      }
      if (
        bigStageUser.bigstage_showOnTopTen == 1 ||
        bigStageUser.bigstage_showOnTopTen == 0
      ) {
        // addSuccessMessage("hello")
        checkAndUncheckShowTen();
      }
    } else {
      addErrorMessage("You crosses the limit !");
    }
  };

  const checkAndUncheckShowTen = async () => {
    console.log(
      "bigStageUser.showOnTopTen: ",
      bigStageUser.bigstage_showOnTopTen
    );

    let jsonData = {
      showOnTopTen: bigStageUser.bigstage_showOnTopTen == 0 ? 1 : 0,
      // showOnTopTwenty:0,
    };
    let respBigStage = await patchDataApi(
      constants.getBigStage + "/" + bigStageUser.bigstage_id,
      jsonData
    );
    console.log("respBigStage: ", respBigStage);
    if (respBigStage.status === 200 || respBigStage.statusText === "OK") {
      getBigstageUser(props.location.state.id);
      addSuccessMessage(
        bigStageUser.bigstage_showOnTopTen == 1
          ? "Show On Top Ten Disabled"
          : "Show On Top Ten Enabled"
      );
    } else {
      console.log("Error:");
      addErrorMessage("Error while updating show on top twenty");
    }
  };
  const confirmToMakeTopTen = async () => {
    let jsonData = {
      showOnTopTen: bigStageUser.bigstage_showOnTopTen == 0 ? 1 : 0,
      showOnTopTwenty: 0,
    };
    let respBigStage = await patchDataApi(
      constants.getBigStage + "/" + bigStageUser.bigstage_id,
      jsonData
    );
    console.log("respBigStage: ", respBigStage);
    if (respBigStage.status === 200 || respBigStage.statusText === "OK") {
      getBigstageUser(props.location.state.id);
      addSuccessMessage(
        bigStageUser.bigstage_showOnTopTen == 0
          ? "Show On Top Ten Enabled"
          : "Show On Top Ten Disabled"
      );
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      console.log("Error:");
      addErrorMessage("Error while updating show on top ten");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };
  const onChangeShowOnTopTwenty = async (i) => {
    if(i === 0 && bigStageUser.bigstage_mainBigstage === 1){
      //alert("Main bigstage is not allow to show on top twenty");
      addErrorMessage("Main bigstage is not allow to show on top twenty");
      return;
    }
    console.log(
      "bigstage_showOnTopTwenty: ",
      bigStageUser.bigstage_showOnTopTwenty
    );

    let respArtist = await getdata(
      constants.getBigStage + "?filter=showOnTopTwenty||$eq||1&filter=isDeleted||$eq||0"
    );
    if (respArtist.data.length !== 20 || bigStageUser.bigstage_showOnTopTwenty == 1) {
      if (bigStageUser.bigstage_showOnTopTen == 1) {
        // addErrorMessage("The BigStage is already in top ten list");
        openConfirmationDeleteDialog(confirmToMakeTopTwenty, "Yes");
        return;
      }
      if (
        bigStageUser.bigstage_showOnTopTwenty == 1 ||
        bigStageUser.bigstage_showOnTopTwenty == 0
      ) {
        // addSuccessMessage("hello")
        checkAndUncheckShowTwenty();
      }
    } else {
      addErrorMessage("You crosses the limit !");
    }
  };
  const onChangeMainBigstage = async (i) => {
    console.log(
      "bigstage_mainBigstage: ",
      bigStageUser.bigstage_mainBigstage
    );

    let respMainBG = await getdata(
      constants.getBigStage + "?filter=mainBigstage||$eq||1&filter=isDeleted||$eq||0"
    );
    console.log("--onChangeMainBigstage--",respMainBG);
    //if (bigStageUser.bigstage_mainBigstage == 1) {
      //if (bigStageUser.bigstage_mainBigstage == 1) {
        openConfirmationDeleteDialog(confirmToMakeMainBigstage, "Yes");
        if(respMainBG !== undefined && respMainBG.data !== undefined && respMainBG.data.length > 0){
          setPreviousMainBigstageDisable(respMainBG.data[0].id);
        }
      //  return;
      // }
      // if (
      //   bigStageUser.bigstage_mainBigstage == 1 ||
      //   bigStageUser.bigstage_mainBigstage == 0
      // ) {
        // addSuccessMessage("hello")
        //checkAndUncheckMainBigstage();
      //}
    // } else {
    //   addErrorMessage("You crosses the limit !");
    // }
  };

  const checkAndUncheckMainBigstage = async () => {
    let jsonData = {
      showOnTopTwenty: bigStageUser.bigstage_mainBigstage == 0 ? 1 : 0,
      // showOnTopTwenty:0,
    };
    let respBigStage = await patchDataApi(
      constants.getBigStage + "/" + bigStageUser.bigstage_id,
      jsonData
    );
    console.log("respBigStage: ", respBigStage);
    if (respBigStage.status === 200 || respBigStage.statusText === "OK") {
      getBigstageUser(props.location.state.id);
      addSuccessMessage(
        bigStageUser.bigstage_mainBigstage == 0
          ? "Main Bigstage Enabled"
          : "Main Bigstage Disabled"
      );
    } else {
      console.log("Error:");
      addErrorMessage("Error while updating main bigstage");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };


  const checkAndUncheckShowTwenty = async () => {
    let jsonData = {
      showOnTopTwenty: bigStageUser.bigstage_showOnTopTwenty == 0 ? 1 : 0,
      // showOnTopTwenty:0,
    };
    let respBigStage = await patchDataApi(
      constants.getBigStage + "/" + bigStageUser.bigstage_id,
      jsonData
    );
    console.log("respBigStage: ", respBigStage);
    if (respBigStage.status === 200 || respBigStage.statusText === "OK") {
      getBigstageUser(props.location.state.id);
      addSuccessMessage(
        bigStageUser.bigstage_showOnTopTwenty == 0
          ? "Show On Top Twenty Enabled"
          : "Show On Top Twenty Disabled"
      );
    } else {
      console.log("Error:");
      addErrorMessage("Error while updating show on top twenty");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };

  const confirmToMakeTopTwenty = async () => {
    let jsonData = {
      showOnTopTwenty: bigStageUser.bigstage_showOnTopTwenty == 0 ? 1 : 0,
      showOnTopTen: 0,
    };
    let respBigStage = await patchDataApi(
      constants.getBigStage + "/" + bigStageUser.bigstage_id,
      jsonData
    );
    console.log("respBigStage: ", respBigStage);
    if (respBigStage.status === 200 || respBigStage.statusText === "OK") {
      getBigstageUser(props.location.state.id);
      addSuccessMessage(
        bigStageUser.bigstage_showOnTopTwenty == 0
          ? "Show On Top Twenty Enabled"
          : "Show On Top Twenty Disabled"
      );
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      console.log("Error:");
      addErrorMessage("Error while updating show on top twenty");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };

  const confirmToMakeMainBigstage = async () => {
    let jsonData = {
      mainBigstage: bigStageUser.bigstage_mainBigstage == 0 ? 1 : 0,
    };
    let respBigStage = await patchDataApi(
      constants.getBigStage + "/" + bigStageUser.bigstage_id,
      jsonData
    );
    console.log("respBigStage: ", respBigStage);
    if (respBigStage.status === 200 || respBigStage.statusText === "OK") {
      getBigstageUser(props.location.state.id);
      addSuccessMessage(
        bigStageUser.bigstage_mainBigstage == 0
          ? "Main Bigstage Enabled"
          : "Main Bigstage Disabled"
      );
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      console.log("Error:");
      addErrorMessage("Error while updating main bigstage");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };
  const setPreviousMainBigstageDisable = async (PreviousMainBigstageId) => {
    console.log(PreviousMainBigstageId, " : PreviousMainBigstageId ");

    let jsonData = {
      mainBigstage: 0,
    };
    let respArtist = await patchDataApi(
      constants.getBigStage + "/" + PreviousMainBigstageId,
      jsonData
    );
    console.log("respArtist: ", respArtist);
    if (respArtist.status === 200 ) {
      // getArtist()
      //   addSuccessMessage("Main Artist Enabled");
      //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      console.log("Error:");
      addErrorMessage("Error while updating previous main bigstage");
      //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };

  //Hitendra - END
  if (bigStageUser.length !== 0) {
    return (
      <div className="main-window">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <NavSearchBar title="Big Stage" />
          </Grid>
          <Grid item xs={12} sm={12}>
            <div>
              <img
                src={
                  bigStageUser.bigstage_thumbnailURL === ""
                    ? constants.musicURL
                    : imagelink + bigStageUser.bigstage_thumbnailURL
                }
                alt="NotFound"
                className="addBorder"
                style={{ width: "100%", borderRadius: "32px", height: "450px" }}
              ></img>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <div className="font-18 header-poppins-14">
                  {bigStageUser.bigstage_name}
                </div>
              </Grid>
              {/* Hitendra */}
              <Grid item xs={6} md={2}>
                <div className="addBorder">
                  <span className="header-type-3">{"Show On Top Ten"}</span>
                  <CustomSwitch
                    //disable={true}
                    checked={
                      bigStageUser.bigstage_showOnTopTen === 1 ? true : false
                    }
                    onChange={()=>onChangeShowOnTopTen(bigStageUser.bigstage_showOnTopTen)}
                  />
                </div>
              </Grid>
              <Grid item xs={6} md={2}>
                <div className="addBorder">
                  <span className="header-type-3">{"Show On Top Twenty"}</span>
                  <CustomSwitch
                    checked={
                      bigStageUser.bigstage_showOnTopTwenty == 1 ? true : false
                    }
                    onChange={()=>onChangeShowOnTopTwenty(bigStageUser.bigstage_showOnTopTwenty)}
                  />
                </div>
              </Grid>
              <Grid item xs={6} md={4}>
                <div className="addBorder">
                  <span className="header-type-3">{"Main Bigstage"}</span>
                  <CustomSwitch
                    checked={
                      bigStageUser.bigstage_mainBigstage == 1 ? true : false
                    }
                    onChange={()=>onChangeMainBigstage(bigStageUser.bigstage_mainBigstage)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div
                  onClick={() => handleLike(bigStageUser.likes)}
                  style={{ cursor: "pointer" }}
                >
                  <LikeCard totalLike={bigStageUser.likes.length} />
                </div>
              </Grid>
              {/* Hitendra */}
              <Grid item xs={12} md={4}>
                <CardPublishDate
                  publishDate={moment(bigStageUser.bigstage_created).format(
                    "DD MMM yyyy"
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CardStatus
                  status={
                    bigStageUser.bigstage_status === 1 ? "Active" : "Inactive"
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <div
                  onClick={() => handleComment(bigStageUser.comments)}
                  style={{ cursor: "pointer" }}
                >
                  <CommentCard totalComment={bigStageUser.comments.length} />
                </div>
              </Grid>
              <Grid item xs={6} md={4}>
                <CustomButtonOutlined
                  onClick={() => {
                    handleRemove();
                  }}
                  text="remove"
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <CustomButtonContained
                  onClick={() => {
                    handleBlock();
                  }}
                  text={bigBlock === 1 ? "Block" : "UnBlock"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <div
                  onClick={() => handleReport(bigStageUser.reported)}
                  style={{ cursor: "pointer" }}
                >
                  <ReportedCard totalReport={bigStageUser.reported.length} />
                  {/* <CardNewComponent
                    backImage={IMAGES.PinkMask}
                    icon={ICONS.ReportedIcon}
                    
                  ></CardNewComponent> */}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container spacing={3}>
              {/* <Grid item xs={12} md={12}>
                <UserInformation
                  urlImageUser={
                    Userimagelink + bigStageUser.user.urlImage
                  }
                  userEmail={bigStageUser.user.email}
                  userName={
                    bigStageUser.user.firstName +
                    " " +
                    bigStageUser.user.lastName
                  }
                  userAlbum={bigStageUser.user.albums.length}
                  userFollower={bigStageUser.user.followed.length}
                  userFollowing={bigStageUser.user.following.length}
                ></UserInformation>
              </Grid> */}
              {/* <Grid item xs={12} md={12}>
              <CardCategory
                name="Category's"
                isTransparent
                isGray
              ></CardCategory>
            </Grid> */}
              {/* <Grid item xs={12} md={6}>
                <div
                  // onClick={() => handleView()}
                  style={{ cursor: "pointer" }}
                >
                  <ViewCard />
                </div>
              </Grid> */}

              {/* <Grid item xs={12} md={6}>
                <CardSwitch></CardSwitch>
              </Grid>
              <Grid item xs={12} md={6}>
                <CardMore></CardMore>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return (
      <div className="main-window">
        <LoaderforArtistCommonCard />
      </div>
    );
  }
}
