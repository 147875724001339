import { Divider, Grid, Typography } from "@material-ui/core";
import NavSearchBar from "../../components/SearchBar/NavSearchBar";
import React, { useEffect, useState } from "react";
import CardSwitch from "../../components/CardNew/CardSwitch";
import CardMore from "../../components/CardNew/CardMore";
import CardCategory from "../../components/CardNew/CardCategory";
import UserInformation from "../../components/CardNew/UserInformation";

import {
  BlueCard,
  CommentCard,
  LikeCard,
  PinkCard,
  ViewCard,
} from "../../components/CardNew/CommonCards";
import {
  openCommentDialog,
  openConfirmationDeleteDialog,
  openLikeDialog,
  openReportDialog,
  openViewDialog,
} from "../../utility/dialogAppear";
import {
  CustomButtonContained,
  CustomButtonOutlined,
} from "../../components/Controls/CustomButton";
import { deleteDataApi, getdata, patchDataApi } from "../../utility/apiCalling";
import { constants } from "../../utility/constants";
import reactDom from "react-dom";
import {
  addErrorMessage,
  addLoader,
  addSuccessMessage,
  removeLoader,
} from "../../utility/LoadingContent";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ReportedCard } from "../../components/CardNew/CommonCards";
import LoaderforNews from "../../components/SkeletonLoader/LoaderforNews";

export default function NewsDetailsPage(props) {
  // window.alert("heleo news")
  const { newsId } = props.location.state;
  let history = useHistory();
  const [newsUser, setNewsUser] = useState([]);
  var [newsBlock, setNewsBlock] = useState(null);
  var imagelink = constants.newsFileURL; //constants.baseURL + "newsfiles/";
  var Userimagelink = constants.profileURL; //constants.baseURL + "profilefiles";
  useEffect(() => {
    getNewsUser(props.location.state.newsId);
    // getBigstageUser(22);
  }, []);

  async function getNewsUser(userId) {
    console.log(constants.getNewsUser + "/getById/" + userId);
    let response = await getdata(constants.getnewsURL + "/getById/" + userId);

    if (
      response.request.status === 200 ||
      response.request.statusText === "OK"
    ) {
      console.log(response.data.data);
      setNewsBlock(response.data.data.news_status);
      return setNewsUser(response.data.data);
    }
  }

  const handleBlock = () => {
    // window.alert("hande block");
    // window.alert(bigBlock);
    {
      newsBlock === 1
        ? openConfirmationDeleteDialog(Block, "Block")
        : openConfirmationDeleteDialog(unBlock, "Unblock");
    }
  };

  const unBlock = async () => {
    // window.alert(bigBlock);
    let jsonData = {
      status: 1,
    };
    // console.log(id);
    console.log("handle block----------------------->");
    console.log(constants.getnewsURL + "/" + newsUser.news_id);
    let blockNewsData = await patchDataApi(
      constants.getnewsURL + "/" + newsUser.news_id,
      jsonData
    );
    if (blockNewsData.status === 200 || blockNewsData.statusText === "OK") {
      // console.log("User Successfully Block");
      // setBigBlock(0);

      setNewsBlock(0);
      // window.alert(bigBlock);
      getNewsUser(props.location.state.newsId);

      addSuccessMessage("News UnBlocked");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      // console.log("User Not  successfully Deleted");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };
  const Block = async () => {
    // window.alert(bigBlock);
    let jsonData = {
      status: 0,
    };
    // console.log(id);
    console.log("handle block----------------------->");
    console.log(constants.getnewsURL + "/" + newsUser.news_id);
    let blockNewsData = await patchDataApi(
      constants.getnewsURL + "/" + newsUser.news_id,
      jsonData
    );
    if (blockNewsData.status === 200 || blockNewsData.statusText === "OK") {
      // console.log("User Successfully Block");
      setNewsBlock(1);
      // window.alert(bigBlock);
      getNewsUser(props.location.state.newsId);
      addSuccessMessage("News Blocked");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      // console.log("User Not  successfully Deleted");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
  };

  const handleRemove = () => {
    openConfirmationDeleteDialog(remove, "Remove");
  };
  const remove = async () => {

    let jsonData = {
      isDeleted: 1,
    };
    let deleteData = await patchDataApi(
      constants.getnewsURL + "/" + props.location.state.newsId,
      jsonData
    );
    // console.log(props)
    if (deleteData.status === 200 || deleteData.statusText === "OK") {
      addSuccessMessage("News Delete");
      history.goBack()
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    } else {
      addErrorMessage("News Not Delete");
      reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    }
    // let removeNews = await deleteDataApi(
    //   constants.getnewsURL + "/" + props.location.state.newsId
    // );
    // if (removeNews.status === 200 && removeNews.statusText === "OK") {
    //   addSuccessMessage("News Remove");
    //   history.push("news");
    //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    // } else {
    //   // console.log("User Not  successfully Deleted");
    //   reactDom.unmountComponentAtNode(document.getElementById("dialogcontent"));
    // }
  };

  // const handleReport = (data) => {
  //   // window.alert("report");
  //   openReportDialog(data);
  // };
  const handleReport = (data) => {
    // window.alert("report");
    const ReportData = getReportData(data);
    openReportDialog(ReportData);
  };
  const getReportData = (dataItem) => {
    console.log(dataItem);
    if (dataItem.length !== 0 && dataItem.length !== undefined) {
      let formattedData = dataItem.map((reportedData, key) => {
        return [
          {
            reportDate: reportedData.reportnews_created,
            reportMessage: reportedData.reportnews_comment,
            userImage: reportedData.user.urlImage,
            userName:
              reportedData.user.firstName + " " + reportedData.user.lastName,
            userPhone: reportedData.user.phone,
          },
        ];
      });
      return formattedData;
    } else {
      return [];
    }
    //  openCommentDialog(data);
  };
  const handleView = (data) => {
    openViewDialog(data);
  };
  // const handleComment = (data) => {
  //   openCommentDialog(data);
  // };
  const handleComment = (data) => {
    const commentData = getCommentData(data);
    openCommentDialog(commentData);
  };
  const getCommentData = (dataItem) => {
    console.log(dataItem);
    if (dataItem.length !== 0 && dataItem.length !== undefined) {
      let formattedData = dataItem.map((commentData, key) => {
        return [
          {
            commentDate: commentData.newscomments_created,
            commentMessage: commentData.newscomments_comment,
            userImage: commentData.user.urlImage,
            userName:
              commentData.user.firstName + " " + commentData.user.lastName,
            userPhone: commentData.user.phone,
          },
        ];
      });
      return formattedData;
    } else {
      return [];
    }
    //  openCommentDialog(data);
  };
  const handleLike = (data) => {
    openLikeDialog(data);
  };
  if (newsUser.length !== 0) {

    return (
      <div className="main-window">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <NavSearchBar title="News" />
          </Grid>
          <Grid item xs={12} sm={8}>
            <div>
              <img
                src={
                  newsUser.news_urlImage === ""
                    ? constants.musicURL
                    : imagelink + newsUser.news_urlImage
                }
                alt="NotFound"
                style={{ width: "100%", borderRadius: "32px", height: "50vh" }}
              ></img>
            </div>
            <Typography variant="subtitle1" align="justify">
              {newsUser.news_subtitle}
            </Typography>
            <br></br>
            <Divider style={{ backgroundColor: "#8b8398" }} />
            <br></br>
            <div
              className="header-type-3 lineheight-18"
              style={{ fontSize: "12px" }}
            >
              {newsUser.news_description}
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <UserInformation
                  urlImageUser={Userimagelink + newsUser.user.urlImage}
                  userEmail={newsUser.user.email}
                  userName={
                    newsUser.user.firstName + " " + newsUser.user.lastName
                  }
                  userAlbum={newsUser.user.albums.length}
                  userFollower={newsUser.user.followed.length}
                  userFollowing={newsUser.user.following.length}
                />
              </Grid>
              {/* <Grid item xs={12} md={12}>
                <CardCategory
                  name="Category's"
                  isTransparent
                  isGray
                ></CardCategory>
              </Grid> */}
              <Grid item xs={12} md={6}>
                <div
                  // onClick={() => handleView()}
                  style={{ cursor: "pointer" }}
                >
                  <ViewCard />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div
                  onClick={() => handleLike(newsUser.likes)}
                  style={{ cursor: "pointer" }}
                >
                  <LikeCard totalLike={newsUser.likes.length} />
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <div
                  onClick={() => handleComment(newsUser.comments)}
                  style={{ cursor: "pointer" }}
                >
                  <CommentCard totalComment={newsUser.comments.length} />
                </div>
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <div
                  onClick={() => handleReport(newsUser.reported)}
                  style={{ cursor: "pointer" }}
                >
                  <ReportedCard totalReport={newsUser.reported.length} />
                </div>
              </Grid> */}
              {/* <Grid item xs={12} md={6}>
                <CardSwitch></CardSwitch>
              </Grid>
              <Grid item xs={12} md={6}>
                <CardMore></CardMore>
              </Grid> */}
              <Grid item xs={6} md={6}>
                <CustomButtonOutlined
                  onClick={() => {
                    handleRemove();
                  }}
                  text="remove"
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <CustomButtonContained
                  onClick={() => {
                    handleBlock();
                  }}
                  text={newsBlock === 1 ? "Block" : "UnBlock"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return <div className="main-window"><LoaderforNews /></div>;
  }
}
