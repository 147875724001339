import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  CustomButtonOutlined,
  CustomButtonContained,
} from "../Controls/CustomButton";
const useStyles = makeStyles((theme) => ({
  paper: {
    // backgroundColor: "#181a20",
  },
}));
export default function Popup(props) {
  const classes = useStyles();
  const { title, children, openPopup, setOpenPopup, buttonClick, buttonText } =
    props;
  const handleClose = () => {
    setOpenPopup(false);
  };
  return (
    <Dialog
      open={openPopup}
      maxWidth="xs"
      onClose={handleClose}
      className={classes.paper}
      // style={{ backgroundColor: "#181a20" }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Grid container spacing={2} style={{ margin: "0px" }}>
          <Grid item xs={12} md={6}>
            <CustomButtonOutlined
              text="Cancel"
              onClick={() => setOpenPopup(false)}
            ></CustomButtonOutlined>
          </Grid>

          <Grid item xs={12} md={6}>
            <CustomButtonContained
              //   onClick={() => {
              //     handleBlock();
              //   }}
              //   text={artistBlock === true ? "Block" : "UnBlock"}
              onClick={buttonClick}
              text={buttonText}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
