import React from "react";
import { Grid } from "@material-ui/core";
import ListPodcast from "../../components/ListPodcast/ListPodcast";
import { ViewCard, CommentCard } from "../../components/CardNew/CommonCards";
import CardCategory from "../../components/CardNew/CardCategory";
export default function ArtistPodcastDetails() {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={8}>
          <ListPodcast />
        </Grid>
        <Grid item md={4}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <ViewCard />
            </Grid>
            <Grid item md={6}>
              <CommentCard />
            </Grid>
            <Grid item md={6}>
              <ViewCard />
            </Grid>
            <Grid item md={6}>
              <CommentCard />
            </Grid>

            <Grid item md={12}>
              <CardCategory name="Category's" isGray isTransparent />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
